import Skeleton from "react-loading-skeleton";

const WalletLoader = () => {
  return (
    <>
      <div className="d-flex flex-column gap-3">
        <div>
          <Skeleton width="80px" height="15px" borderRadius="8px" />
          <div className="d-flex justify-content-between">
            <Skeleton width="170px" height="15px" borderRadius="8px" />
            <Skeleton width="50px" height="15px" borderRadius="8px" />
          </div>
          <div className="ms-4">
            <Skeleton width="80px" height="15px" borderRadius="8px" />
          </div>
        </div>
        <div>
          <Skeleton width="80px" height="15px" borderRadius="8px" />
          <div className="d-flex justify-content-between">
            <Skeleton width="170px" height="15px" borderRadius="8px" />
            <Skeleton width="50px" height="15px" borderRadius="8px" />
          </div>
          <div className="ms-4">
            <Skeleton width="80px" height="15px" borderRadius="8px" />
          </div>
        </div>
        <div>
          <Skeleton width="80px" height="15px" borderRadius="8px" />
          <div className="d-flex justify-content-between">
            <Skeleton width="170px" height="15px" borderRadius="8px" />
            <Skeleton width="50px" height="15px" borderRadius="8px" />
          </div>
          <div className="ms-4">
            <Skeleton width="80px" height="15px" borderRadius="8px" />
          </div>
        </div>
        <div>
          <Skeleton width="80px" height="15px" borderRadius="8px" />
          <div className="d-flex justify-content-between">
            <Skeleton width="170px" height="15px" borderRadius="8px" />
            <Skeleton width="50px" height="15px" borderRadius="8px" />
          </div>
          <div className="ms-4">
            <Skeleton width="80px" height="15px" borderRadius="8px" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletLoader;
