import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { commonType } from "../../../../pages/main/type";
import { Auth } from "../../../../utils/context/AuthContext";
import {
  isUserValid,
  setOverflowToBodyHidden,
} from "../../../../utils/function/function";
import { AngleDownIcon, UserDefault } from "../../../shared/Icons";
import ShowInitialImage from "../../../shared/InitialImage";
import SearchLocation from "./SearchLocation";
import MapView from "./ChooseLocation";
import {
  checkServiceInLocation,
  getAddressFromLatLng,
} from "../../../../lib/api functions/address/address";
import BottomTopAnimation from "../../../shared/BottomTopAnimation";
import AppBanner from "../App Banner";

const Location = () => {
  const { homepage_sections, user, latitude, longitude, useGeocode } =
    useContext(Auth);
  const [waNumber, setWaNumber] = useState("");
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [gotCurrentAddress, setGotCurrentAddress] = useState<any>();
  const [latLng, setLatLng] = useState<any>({
    lat: latitude,
    long: longitude,
  });

  const fn = {
    openModal: (check?: string) => {
      setCommonData({
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    getAddress: () => {
      const finalLatLng = {
        lat: latitude,
        long: longitude,
      };
      getAddressFromLatLng(finalLatLng)
        .then((res: any) => {
          if (res) {
            res.data.results.forEach((addresses: any) => {
              addresses.address_components.forEach((address: any) => {
                address.types.forEach((item: any) => {
                  if (item === "locality") {
                    setGotCurrentAddress(() => {
                      return { address: res, city: address.long_name };
                    });
                  }
                });
              });
            });
          }
        })
        .catch((err: any) => {});
    },
    getAddressFromLocationSlot: async () => {
      const lat = latitude;
      const long = longitude;
      const res = await checkServiceInLocation({
        lat,
        long,
        // phone: user?.phone ?? "",
      });
      if (res?.data?.msg) {
        setGotCurrentAddress(() => {
          return { address: "", city: "Location" };
        });
      } else {
        setGotCurrentAddress(() => {
          return { address: "", city: res?.data.city };
        });
      }
    },
  };

  useEffect(() => {
    if (latitude && longitude) {
      if (localStorage.getItem("useGeocode")) {
        console.log(true);
        fn.getAddress();
      } else {
        console.log(false);
        fn.getAddressFromLocationSlot();
      }
    }
  }, [latitude, longitude, useGeocode]);

  useEffect(() => {
    if (homepage_sections && Object.keys(homepage_sections)?.length > 0) {
      Object.keys(homepage_sections).forEach((key) => {
        if (homepage_sections[key]?.id === 63) {
          setWaNumber(homepage_sections[key]?.items_required);
        }
      });
    }
  }, [homepage_sections]);

  return (
    <>
      <div className="home_header">
        <AppBanner />

        <div className="home_header_location d-flex justify-content-between align-items-center gap-2">
          <div
            className=" w-100 d-flex align-items-center gap-1"
            onClick={() => fn.openModal("search_location")}
          >
            <div className="location_icon_container">
              <img src="/static/svg/location.svg" alt="location" />
            </div>
            <div>
              <div className="d-flex align-items-center gap-2 cursor_pointer">
                <h3 className="mb-0 textColor1 font18x600">
                  {gotCurrentAddress?.city}
                </h3>
                <AngleDownIcon width="12" height="12" fill="#AF031A" />
              </div>
              <p className="mb-0 header-address font12x400">
                {gotCurrentAddress?.address?.data?.results[0]?.formatted_address.substring(
                  0,
                  30
                )}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex align-items-center gap-3">
              <Link
                to="https://api.whatsapp.com/send/?phone=919513862350&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <img
                  src="/static/svg/whatsapp-menu.svg"
                  alt="whatsapp-logo"
                  width="36"
                  height="36"
                />
              </Link>
              <Link className="text-decoration-none" to={`/profile`}>
                <div>
                  {!isUserValid() || user?.profile_image?.length > 0 ? (
                    <UserDefault width="36" height="36" />
                  ) : (
                    <ShowInitialImage
                      width="36px"
                      height="36px"
                      data={user?.first_name?.trim()}
                      fontSize="18px"
                    />
                  )}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* search location */}
      {commonData?.modal && commonData?.check === "search_location" && (
        <BottomTopAnimation>
          <SearchLocation
            state={commonData}
            setState={setCommonData}
            setLatLng={setLatLng}
          />
        </BottomTopAnimation>
      )}

      {/* search location */}
      {commonData?.modal && commonData?.check === "choose_location" && (
        <MapView propsLatLng={latLng} setState={setCommonData} />
      )}
    </>
  );
};

export default Location;
