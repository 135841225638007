import Api from "../../../config/api";
import { showToast } from "../../toast/showToast";
import { SEND_OTP, VERIFY_OTP } from "../../../utils/constant/constant";

export const otpLogin = async (body: any) => {
  try {
    const res = await Api(false).post(SEND_OTP, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};

export const verifyOtp = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(VERIFY_OTP, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
