import moment from "moment";
import AllServeLocation from "./AllLocation";
import CompanyLinks from "./Company";
import ContactUsLinks from "./ContactUs";
import FooterSeparator from "./Separator";
import ServicesLinks from "./ServicesLinks";
import SocialLinks from "./SocialLinks";

const Footer = () => {
  return (
    <>
      <footer className="footer_wrapper">
        <div className="pages_links_wrapper">
          <CompanyLinks />
          <ServicesLinks />
          <ContactUsLinks />
        </div>
        <FooterSeparator />

        <AllServeLocation />

        <FooterSeparator />

        <SocialLinks />

        <FooterSeparator />

        <div className="py-4">
          <p className="mb-0 text-center font16x400 text-white">
            {`Copyright©${moment().year()} Getlook. All rights reserved`}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
