import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Auth } from "../../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../../utils/function/function";

const DesktopServices = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const service = filterHomepageSections(homepage_sections, "parent")?.sort(
    (a: any, b: any) => {
      return a.category_order - b.category_order;
    }
  );

  return (
    <>
      <div className="desktop_services_wrapper">
        <h2 className="mb-0 font30x600 textColor3">
          Book our services at affordable price
        </h2>
        <div className="desktop_services_section">
          {mainLoading ? (
            <>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
            </>
          ) : (
            service?.map((item: any, i: number) => {
              return (
                <Link
                  key={i}
                  to={`/services/${item?.id}`}
                  className="text-decoration-none"
                >
                  <div className="text-center">
                    <img
                      src={item?.category_image}
                      alt="category"
                      style={{
                        borderRadius: 16,
                        width: "100%",
                        height: "auto",
                      }}
                    />
                    <p className="mb-0 mt-2 px-2 font16x500 textColor3">
                      {item?.name}
                    </p>
                  </div>
                </Link>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default DesktopServices;
