import { useContext, useState } from "react";
import {
  membershipId,
  package99Ids,
  packageIds,
} from "../../../../utils/constant/constant";
import { Auth } from "../../../../utils/context/AuthContext";
import {
  increaseDecreaseItem,
  setOverflowToBodyHidden,
} from "../../../../utils/function/function";
import { ClockIcon } from "../../../shared/Icons";
import Separator from "../../../shared/Separator";
import { BsInfoCircle } from "react-icons/bs";
import { commonType } from "../../../../pages/main/type";
import BottomTopAnimation from "../../../shared/BottomTopAnimation";
import PriceDropServiceInfoModal from "./PriceDropInfo";

const CartItem = () => {
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
    data: undefined,
  });
  const { cartItemsInContext, setCartItemsInContext } = useContext(Auth);
  const appliedCoupon =
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;

  const fn = {
    handleClickOnCross: (item: any) => {
      // remove single item from cart
      let serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      serviceList = serviceList.filter((serviceItem: any) => {
        if (serviceItem?.serviceType?.id === item?.serviceType?.id) {
          if (packageIds.includes(item?.serviceType?.id)) {
            const upgradedItems = serviceList?.map((item2: any) => {
              if (item2?.previousServiceId === item?.serviceType?.id) {
                delete item2.previousServiceId;
                delete item2.update;
              }
              return item2;
            });
            localStorage.setItem("cartItems", JSON.stringify(upgradedItems));
          }
          return false;
        } else if (packageIds.includes(item?.previousServiceId)) {
          if (serviceItem?.serviceType?.id === item?.previousServiceId) {
            // update special instruction in package(item.previousServic
            const filteredIns = serviceItem?.special_instructions?.filter(
              (insObj: any) => {
                return item?.serviceType?.id !== insObj.id;
              }
            );
            serviceItem.special_instructions = filteredIns;
          }
          return true;
        } else if (serviceItem?.serviceType?.id === item?.previousServiceId) {
          return true;
        } else {
          return true;
        }
      });

      serviceList = serviceList.filter((serviceItem: any) => {
        if (
          serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
          appliedCoupon?.type == "Service"
        ) {
          return false;
        } else {
          return true;
        }
      });

      // removing coupon
      localStorage.removeItem("coupon_applied");
      localStorage.removeItem("conveyanceCharge");
      localStorage.removeItem("slotInfo");
      localStorage.removeItem("previousDues");

      localStorage.setItem("cartItems", JSON.stringify(serviceList));
      setCartItemsInContext(serviceList);
    },
    handleClickOnMinusPlus: (item: any, button: string) => {
      // handling increase and descrease of items
      setCartItemsInContext(
        increaseDecreaseItem(item, button, cartItemsInContext)
      );
    },
    renderServiceNameList: (service: any) => {
      const splitedNames = service?.name?.split(":");
      if (splitedNames[1]?.includes(" OR ")) {
        return (
          <div className="mb-2 package_services_container">
            {splitedNames[1]?.split(" OR ")?.map((info: any, k: any) => {
              return (
                <li className="mb-0 font11x400 textColor3" key={k}>
                  {info}
                </li>
              );
            })}
          </div>
        );
      } else if (splitedNames[1]?.includes(" , ")) {
        return (
          <div className="mb-2 package_services_container">
            {splitedNames[1]?.split(" , ")?.map((info: any, k: any) => {
              return (
                <li className="mb-0 font11x400 textColor3" key={k}>
                  {info}
                </li>
              );
            })}
          </div>
        );
      } else if (splitedNames[1]?.includes(" + ")) {
        return (
          <div className="mb-2 package_services_container">
            {splitedNames[1]?.split(" + ")?.map((info: any, k: any) => {
              return (
                <li className="mb-0 font11x400 textColor3" key={k}>
                  {info}
                </li>
              );
            })}
          </div>
        );
      } else {
        <></>;
      }
    },
    openModal: (check?: string, data?: any) => {
      setCommonData({
        modal: true,
        check: check,
        data,
      });
      setOverflowToBodyHidden();
    },
  };

  return (
    <>
      <div className="cart-items-container d-flex flex-column bg-white pt-2 mt-2">
        {cartItemsInContext?.map((item: any, i: number) => {
          return (
            item?.added &&
            item?.serviceType?.id !== membershipId && (
              <div key={i} className="cart_items_wrapper">
                {/* cart other items */}
                <div>
                  <div className="single_cart_item d-flex justify-content-between align-items-start gap-4">
                    <div>
                      <div className="d-flex align-items-start gap-2">
                        <div className="d-flex flex-column justify-content-center gap-1">
                          <div className="mb-1 d-flex gap-2 align-items-center">
                            <p className="mb-0 font14x600 textColor3">
                              {item?.serviceType?.name?.split(":")[0]}
                            </p>
                            {/* condition for price drop services */}
                            {item?.serviceType?.isPackage === 99 &&
                              item?.serviceType?.cost_promotion && (
                                <BsInfoCircle
                                  className="textColor1 cursor_pointer"
                                  onClick={() =>
                                    fn.openModal("priceDropInfo", {
                                      parentId:
                                        item?.serviceType?.cost_promotion,
                                    })
                                  }
                                />
                                // <p className="mb-0 service_tag font10x600">
                                //   Not applicable on weekend
                                // </p>
                              )}
                          </div>
                          <p className="mb-1 font12x400 textColor7 d-flex align-items-center gap-1">
                            {!item?.isProduct && <ClockIcon />}
                            {`${item?.serviceType?.time} ${
                              item?.isProduct ? "ml" : "mins"
                            }`}
                          </p>
                        </div>
                      </div>
                      {item?.serviceType?.chosenService &&
                      item.serviceType?.chosenService?.length > 0 ? (
                        <div className="mb-2 package_services_container">
                          {item?.serviceType?.chosenService?.map(
                            (cSItem: any, cSIndex: number) => {
                              return (
                                <li
                                  className="mb-0 font11x400 textColor3"
                                  key={cSIndex}
                                >
                                  {cSItem?.newLabel
                                    ? cSItem?.newLabel
                                    : cSItem?.label}
                                </li>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        fn.renderServiceNameList(item?.serviceType)
                      )}
                    </div>
                    <img
                      onClick={() => {
                        fn.handleClickOnCross(item);
                      }}
                      className="cursor_pointer"
                      src="/static/svg/remove-cart-item.svg"
                      alt="remove_cart_item"
                    />
                  </div>

                  {/* quantity and price */}
                  <div className="pb-3 d-flex justify-content-between align-items-center">
                    {(appliedCoupon?.type == "Service" &&
                      appliedCoupon?.discount == item.serviceType?.id) ||
                    (cartItemsInContext.find((cartItem: any) => {
                      return (
                        cartItem?.serviceType?.category?.id ==
                        item?.serviceType?.category?.id
                      );
                    }) &&
                      package99Ids.includes(
                        item?.serviceType?.category?.id
                      )) ? (
                      ""
                    ) : item?.type === "special_offer" ? (
                      // this section only for special offer service
                      <div className="d-flex gap-2 align-items-center">
                        <p className="mb-0 font14x400 textColor7">
                          Special Offer Service
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0 font12x400 textColor3">Quantity:</p>
                        <div className="item_inc_dec d-flex align-items-center justify-content-between">
                          <p
                            onClick={() => {
                              fn.handleClickOnMinusPlus(item, "-");
                            }}
                            className="mb-0 font18x600 cursor_pointer"
                          >
                            -
                          </p>
                          <p className="mb-0 font14x600">{item.count}</p>
                          <p
                            onClick={() => {
                              fn.handleClickOnMinusPlus(item, "+");
                            }}
                            className="mb-0 font18x600 cursor_pointer"
                          >
                            +
                          </p>
                        </div>
                      </div>
                    )}
                    {appliedCoupon?.type == "Service" &&
                    appliedCoupon?.discount == item.serviceType?.id ? (
                      <div className="d-flex gap-2 align-items-center">
                        <p className="mb-0 font14x400 textColor7">
                          Free Service
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex gap-2 align-items-center">
                        {item?.serviceType?.cost > item?.cost && (
                          <p className="mb-0 font14x400 text-decoration-line-through textColor7">
                            {`₹${item?.serviceType?.cost * item?.count}`}
                          </p>
                        )}
                        <p className="mb-0 font14x700 textColor3">
                          {`₹${item?.cost * item?.count}`}
                        </p>
                      </div>
                    )}
                  </div>
                  {cartItemsInContext?.length - 1 === i ? "" : <Separator />}
                </div>
              </div>
            )
          );
        })}
      </div>

      {/* safety & hygiene fee modal */}
      {commonData?.modal && commonData?.check === "priceDropInfo" && (
        <BottomTopAnimation>
          <PriceDropServiceInfoModal
            state={commonData}
            setState={setCommonData}
          />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default CartItem;
