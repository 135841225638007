import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../../../../pages/main/type";
import Header from "../../../../shared/Header";
import { CurrentLocationIcon } from "../../../../shared/Icons";
import SearchLocation from "../SearchLocation";
import AddressMap from "./map";
import { Auth } from "../../../../../utils/context/AuthContext";
import { showToast } from "../../../../../lib/toast/showToast";
import { getAddressFromLatLng } from "../../../../../lib/api functions/address/address";
import { useLocation, useNavigate } from "react-router-dom";
import BottomTopAnimation from "../../../../shared/BottomTopAnimation";
import {
  setOverflowToBodyHidden,
  setOverflowToBodyUnset,
} from "../../../../../utils/function/function";

type propType = {
  state?: commonType;
  setState?: (val: commonType) => void;
  propsLatLng?: any;
};

const MapView: React.FC<propType> = (props) => {
  const { state, setState, propsLatLng } = props;
  const { getLocationId, setCartItemsInContext, setUseGeocode } =
    useContext(Auth);
  const [latLng, setLatLng] = useState<any>({
    lat: "",
    long: "",
  });
  const [commonData, setCommonData] = useState<any>({
    modal: false,
    check: "",
  });
  const [gotCurrentAddress, setGotCurrentAddress] = useState<any>({
    city: "",
    address: "",
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const fn = {
    setPositionToBody: () => {
      setState?.({
        modal: true,
        check: "search_location",
      });
      setOverflowToBodyUnset();
    },
    handleClickOnChange: () => {
      setCommonData({
        modal: true,
        check: "change_location",
      });
      setOverflowToBodyHidden();
    },
    handleOnDrag: (latlng: any) => {
      setLatLng({
        lat: latlng.center.lat(),
        long: latlng.center.lng(),
      });
    },
    handleClickOnConfirmLocation: async () => {
      window.localStorage.setItem("latitude", JSON.stringify(latLng?.lat));
      window.localStorage.setItem("longitude", JSON.stringify(latLng?.long));
      window.localStorage.setItem("useGeocode", "true");
      setUseGeocode(true);
      // close modal
      setState?.({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();

      // calling location slot API for getting new location id
      getLocationId(latLng?.lat, latLng?.long);

      // when location is changed flush cart from local storage
      window.localStorage.removeItem("cartItems");
      setCartItemsInContext([]);

      // navigate to homepage whenever location is changed
      if (pathname === "/select-address") {
        navigate("/");
      }
    },
    getcurrentlocation: () => {
      window.navigator.geolocation.getCurrentPosition(
        (coords) => {
          if (coords.coords.latitude && coords.coords.longitude) {
            setLatLng({
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            });
            const latLng = {
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            };
            getAddressFromLatLng(latLng).then((res: any) => {
              setGotCurrentAddress((addressInfo: any) => {
                return { ...addressInfo, address: res };
              });
              res.data.results.forEach((addresses: any) => {
                addresses.address_components.forEach((address: any) => {
                  address.types.forEach((item: any) => {
                    if (item === "locality") {
                      setGotCurrentAddress((addressInfo: any) => {
                        return { ...addressInfo, city: address.long_name };
                      });
                    }
                  });
                });
              });
            });
          }
        },
        (err) => {
          showToast(
            "Please enable location permission from settings and try again",
            "error"
          );
        }
      );
    },
  };

  useEffect(() => {
    if (propsLatLng?.lat && propsLatLng?.long) {
      setLatLng({
        lat: propsLatLng?.lat,
        long: propsLatLng?.long,
      });
    } else {
      fn.getcurrentlocation();
    }
  }, [propsLatLng]);

  useEffect(() => {
    if (latLng?.lat && latLng?.long) {
      getAddressFromLatLng(latLng).then((res: any) => {
        setGotCurrentAddress((addressInfo: any) => {
          return { ...addressInfo, address: res };
        });
        setCommonData((prev: any) => {
          return {
            ...prev,
            address1: res?.data?.results[0]?.formatted_address,
          };
        });
        res.data.results.forEach((addresses: any) => {
          addresses.address_components.forEach((address: any) => {
            address.types.forEach((item: any) => {
              if (item === "locality") {
                setGotCurrentAddress((addressInfo: any) => {
                  return { ...addressInfo, city: address.long_name };
                });
              }
            });
          });
        });
      });
    }
  }, [latLng]);

  return (
    <>
      <div className="mobile">
        <div className="full_page_modal choose_address_location">
          <Header
            label="Choose Location"
            navigate={() => fn.setPositionToBody()}
          />

          {latLng.lat && latLng.long && (
            <AddressMap latLng={latLng} handleOnDrag={fn.handleOnDrag} />
          )}

          {/* proceed btn */}
          <div className="bg-white">
            <div className="location_wrapper">
              <div className="py-3 use_current_location_wrapper d-flex justify-content-between align-items-center gap-3">
                <div className="d-flex gap-2">
                  <CurrentLocationIcon />
                  <div className="w-100">
                    <p className="mb-0 font16x700 textColor3">
                      {gotCurrentAddress?.city}
                    </p>
                    <p className="mb-0 font14x400 textColor7">
                      {gotCurrentAddress?.address?.data?.results[0]?.formatted_address?.substring(
                        0,
                        30
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="mb-0 font14x400 textColor1"
                    onClick={() => {
                      fn.handleClickOnChange();
                    }}
                  >
                    CHANGE
                  </p>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
              onClick={() => {
                fn.handleClickOnConfirmLocation();
              }}
            >
              Confirm Location
            </button>
          </div>
        </div>

        {commonData?.modal && commonData.check == "change_location" && (
          <BottomTopAnimation>
            <SearchLocation
              state={commonData}
              setState={setCommonData}
              setLatLng={setLatLng}
            />
          </BottomTopAnimation>
        )}
      </div>
    </>
  );
};

export default MapView;
