import { useState } from "react";

const savingsData = [
  {
    noOfServices: 2,
    amount: 288,
  },
  {
    noOfServices: 4,
    amount: 576,
  },
  {
    noOfServices: 6,
    amount: 864,
  },
  {
    noOfServices: 8,
    amount: 1152,
  },
  {
    noOfServices: 10,
    amount: 1440,
  },
  {
    noOfServices: 12,
    amount: 1728,
  },
];

const SavingsInfo = () => {
  const [servicesNo, setServicesNo] = useState<number>(6);
  const [saving, setSaving] = useState<number>(864);

  const fn = {
    setSavings: (serviceNo: number, amount: number) => {
      setServicesNo(serviceNo);
      setSaving(amount);
    },
  };

  return (
    <>
      <div className="savings_info">
        <h2 className="font16x600 textColor3">Estimated savings</h2>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <p className="mb-0 font36x700 textColor4">{`₹${saving}`}</p>
          <img src="/static/svg/piggy-bank.svg" alt="piggy_bank" />
        </div>
        <div className="pb-4">
          <h3 className="font14x500 textColor3">No. of services / year</h3>
          <div className="mt-3 d-flex gap-3 flex-wrap">
            {savingsData?.map((item: any, i: number) => {
              return (
                <div
                  key={i}
                  className={`services_per_year font14x500 ${
                    item?.noOfServices === servicesNo
                      ? "textColor3"
                      : "textColor7"
                  }`}
                  style={{
                    background:
                      item?.noOfServices === servicesNo
                        ? "rgba(42, 134, 135, 0.12)"
                        : "#fff",
                  }}
                  onClick={() =>
                    fn.setSavings(item?.noOfServices, item?.amount)
                  }
                >
                  {item?.noOfServices}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SavingsInfo;
