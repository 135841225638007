import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import {
  filterHomepageSections,
  getServiceById,
  increaseDecreaseItem,
  isUserValid,
  setOverflowToBodyHidden,
} from "../../../../utils/function/function";
import { ClockIcon } from "../../../shared/Icons";
import { IoLocationSharp } from "react-icons/io5";
import { commonType } from "../../../../pages/main/type";
import BottomTopAnimation from "../../../shared/BottomTopAnimation";
import ViewDetails from "../../../shared/ViewServiceDetails";

const MostBookedServices = () => {
  const {
    homepage_sections,
    setCartItemsInContext,
    cartItemsInContext,
    mainLoading,
  } = useContext(Auth);
  const mostBookedServices = filterHomepageSections(homepage_sections, "mbs");

  const allCategories = filterHomepageSections(
    homepage_sections,
    "parent"
  )?.sort((a: any, b: any) => {
    return a.category_order - b.category_order;
  });

  const [categories, setCategories] = useState<any>([]);

  const [activeCategory, setActiveCategory] = useState<any>("");

  const [services, setServices] = useState<any>([]);

  const navigate = useNavigate();

  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
    data: undefined,
  });

  const navigateToLogin = (item: any, serviceTobeAdded?: any) => {
    // updating current location in history
    navigate(`/`, { replace: true });

    // navigate to login
    navigate("/otp-login", {
      state: {
        previousURL: `/`,
        serviceTobeAdded,
      },
    });
  };

  const handleClickOnMinusPlus = (item: any, button: string) => {
    // handling increase and descrease of items
    setCartItemsInContext(
      increaseDecreaseItem(item, button, cartItemsInContext)
    );
  };

  const handleClickOnAdd = (item: any) => {
    const paymentDetailsList =
      JSON.parse(`${localStorage.getItem("paymentDetails")}`) || [];
    const serviceList =
      JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

    // please add service info
    if (item.id == "620") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "546") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "547") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "548") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "538") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "521") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "605") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "497") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else {
      item.count = 1;
      item.added = true;
      item.isService = true;
      serviceList.push(item);

      if (isUserValid()) {
        // checking special offer service is exist or not in cart
        const filteredCart = serviceList?.filter((cartItem: any) => {
          return cartItem?.parentId !== item?.serviceType?.id;
        });

        // original cart updation
        setCartItemsInContext(filteredCart);
        localStorage.setItem("cartItems", JSON.stringify(filteredCart));

        // updating current location in history
        // setNewURLAfterReplace(
        //   `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
        // );
        // navigate(
        //   `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        //   { replace: true }
        // );
      } else {
        // temporary cart updation
        navigateToLogin(item, serviceList);
      }
    }
  };

  const handleOnClickOnCategory = (id: any) => {
    setServices([]);
    setActiveCategory(id);
    const finalServices: any = [];
    mostBookedServices.forEach((item: any) => {
      if (+item?.items_required?.split("/")[0] === id) {
        finalServices.push(item);
      }
    });
    setServices(finalServices);
  };

  const openModal = (serviceId: any) => {
    const serviceData = getServiceById(serviceId);
    setCommonData({
      data: serviceData,
      modal: true,
      check: "view_details",
    });
    setOverflowToBodyHidden();
  };

  useEffect(() => {
    if (mainLoading) {
      setServices([]);
      setCategories([]);
    } else {
      if (mostBookedServices?.length > 0) {
        setServices(mostBookedServices);
        const categoriesId: any = [];
        mostBookedServices.forEach((item: any) => {
          allCategories.forEach((category: any) => {
            if (
              (getServiceById(+item?.items_required?.split("/")[0]),
              +item?.items_required?.split("/")[0] === category?.id)
            ) {
              if (!categoriesId.includes(category?.id)) {
                categoriesId.push(category?.id);
                setCategories((prev: any) => {
                  return [...prev, category];
                });
              }
            }
          });
        });
      }
    }
  }, [mainLoading]);

  return (
    <>
      {mostBookedServices?.length > 0 && services?.length > 0 && (
        <div className="booked_services_wrapper mt-2">
          <h2 className="font14x700 text-center p-0 most-booked-heading">
            Most Booked Services
          </h2>
          <div className="most-booked-incity mb-3">
            <IoLocationSharp />
            In{" "}
            {localStorage.getItem("city") &&
              JSON.parse(localStorage.getItem("city")!)}
          </div>
          <div className="most-booked-categories">
            {categories
              ?.sort((a: any, b: any) => {
                return a.category_order - b.category_order;
              })
              .map((category: any) => {
                return (
                  <div>
                    <button
                      className={`${
                        activeCategory === category?.id ? "active" : ""
                      }`}
                      onClick={() => {
                        handleOnClickOnCategory(category?.id);
                      }}
                    >
                      {category?.name}
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="booked_services_wrapper_scroll">
            <div className="booked_service_section mt-3">
              {mainLoading ? (
                <>
                  <div className="single_booked_service">
                    <Skeleton width="122px" height="123px" borderRadius="8px" />
                  </div>
                  <div className="single_booked_service">
                    <Skeleton width="122px" height="123px" borderRadius="8px" />
                  </div>
                  <div className="single_booked_service">
                    <Skeleton width="122px" height="123px" borderRadius="8px" />
                  </div>
                </>
              ) : (
                services
                  ?.sort((a: any, b: any) => {
                    return a.category_order - b.category_order;
                  })
                  .map((item: any, i: number) => {
                    return (
                      <div className="booked-service-container">
                        <div
                          key={i}
                          // to={`/services/${item?.items_required}`}
                          className="text-decoration-none"
                        >
                          <div className="single_booked_service">
                            <img
                              onClick={() =>
                                openModal(+item?.items_required?.split("/")[2])
                              }
                              className="border_radius_8"
                              src={item?.category_image}
                              alt="service"
                              width={150}
                              height={150}
                            />
                            <div className="d-flex flex-column justify-content-between">
                              <p className="mb-2 mt-2 font12x500 textColor3">
                                {item?.name}
                              </p>
                              <div>
                                <p className="mb-2 font12x500 textColor7 d-flex align-items-center gap-1">
                                  <ClockIcon />
                                  {`${
                                    getServiceById(
                                      +item?.items_required?.split("/")[2]
                                    )?.serviceType?.time
                                  } mins`}
                                </p>
                                <div className="d-flex gap-2 align-items-center">
                                  <p className="mb-0 font12x500 textColor3">
                                    {/* original price */}
                                    {`₹${
                                      getServiceById(
                                        +item?.items_required?.split("/")[2]
                                      )?.cost
                                    }`}
                                  </p>
                                  {/* cut price */}
                                  {getServiceById(
                                    +item?.items_required?.split("/")[2]
                                  )?.cost !==
                                    getServiceById(
                                      +item?.items_required?.split("/")[2]
                                    )?.serviceType?.cost && (
                                    <p className="mb-0 font12x700 text-decoration-line-through textColor7">
                                      {`₹${
                                        getServiceById(
                                          +item?.items_required?.split("/")[2]
                                        )?.serviceType?.cost
                                      }`}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          {/** @author Deepanshu Tyagi */}
                          {cartItemsInContext.find((cartItem: any) => {
                            return (
                              cartItem?.serviceType?.id ===
                              getServiceById(
                                +item?.items_required?.split("/")[2]
                              )?.serviceType?.id
                            );
                          }) ? (
                            <div className="d-flex minus_plus_button_container w-100 align-items-center justify-content-between">
                              <button
                                onClick={() => {
                                  handleClickOnMinusPlus(
                                    getServiceById(
                                      +item?.items_required?.split("/")[2]
                                    ),
                                    "-"
                                  );
                                }}
                                className="font14x600 textColor1 d-flex border-0"
                              >
                                -
                              </button>
                              <p className="font14x600 textColor1 mb-0">
                                {
                                  cartItemsInContext.find((cartItem: any) => {
                                    return (
                                      cartItem?.serviceType?.id ==
                                      getServiceById(
                                        +item?.items_required?.split("/")[2]
                                      )?.serviceType?.id
                                    );
                                  })?.count
                                }
                              </p>
                              <button
                                onClick={() => {
                                  handleClickOnMinusPlus(
                                    getServiceById(
                                      +item?.items_required?.split("/")[2]
                                    ),
                                    "+"
                                  );
                                }}
                                className="font14x600 textColor1 border-0"
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                // handleGetServiceItem(
                                //   +item?.items_required?.split("/")[2]
                                // );
                                handleClickOnAdd(
                                  getServiceById(
                                    +item?.items_required?.split("/")[2]
                                  )
                                );
                              }}
                              className="font14x600 add_button w-100 textColor1 d-flex align-items-center justify-content-center"
                            >
                              ADD+
                            </button>
                          )}
                          {/** */}
                        </div>
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        </div>
      )}

      {commonData?.modal && commonData?.check === "view_details" && (
        <BottomTopAnimation>
          <ViewDetails state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default MostBookedServices;
