/**
 * @author Deepanshu Tyagi
 */

import "./index.css";
import { commonType } from "../../../../pages/main/type";
import InputField from "../../InputField";
import { useContext, useEffect, useState } from "react";
import { CloseIcon } from "../../Icons";
import useLoading from "../../../../hooks/useLoading";
import { postAddress } from "../../../../lib/api functions/address/address";
import { useFormik } from "formik";
import { addressType } from "./type";
import { addressSchema } from "../../../../validation/validation";
import InputError from "../../InputError/InputError";
import { showToast } from "../../../../lib/toast/showToast";
import { Auth } from "../../../../utils/context/AuthContext";
import SpinLoader from "../../Loader/SpinLoader";
import InputMobile from "../../InputMobile";
import { setOverflowToBodyUnset } from "../../../../utils/function/function";

type propType = {
  state: any;
  setState: (val: commonType) => void;
  getUserAddresses: () => void;
  setPositionToBody: () => void;
  latLng: any;
};
const CompleteAddress: React.FC<propType> = (props) => {
  const { state, setState, getUserAddresses, setPositionToBody, latLng } =
    props;
  const { user } = useContext(Auth);
  const [isOtherType, setIsOtherType] = useState(false);
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  const initialValues: addressType = {
    address: "",
    address1: "",
    apartmentName: "",
    name: user?.first_name?.trim() ?? "",
    phone: user?.phone ?? "",
    title: "",
    area: "",
    latitude: latLng?.lat,
    longitude: latLng?.long,
    landmark: "",
    isDefault: false,
    type: "post",
    id: "",
    city: "",
  };

  useEffect(() => {
    if (state?.data) {
      formik.setFieldValue(
        "address",
        state?.data?.address?.split("|")[0]?.trim() ?? ""
      );
      formik.setFieldValue(
        "apartmentName",
        state?.data?.address?.split("|")[1]?.trim() ?? ""
      );
      formik.setFieldValue("address1", state?.data?.address1 ?? "");
      formik.setFieldValue("phone", state?.data?.phone);
      formik.setFieldValue("area", state?.data?.area?.id);
      formik.setFieldValue("landmark", state?.data?.landmark);
      if (
        state?.data?.title == "Work" ||
        state?.data?.title == "Home" ||
        state?.data?.title == "Hotel"
      ) {
        formik.setFieldValue("title", state?.data?.title);
      } else {
        setIsOtherType(true);
        formik.setFieldValue("title", state?.data?.title);
      }
    }
  }, [state]);

  const formik = useFormik({
    initialValues,
    validationSchema: addressSchema,
    onSubmit: async (values) => {
      const address = {
        address: `${values.address} | ${values.apartmentName}`,
        address1: values.address1,
        name: values?.name,
        phone: values?.phone ?? "",
        title: values?.title ? values?.title : "Others",
        area: values?.area,
        latitude: values?.latitude,
        longitude: values?.longitude,
        landmark: values?.landmark,
        isDefault: false,
        type: "post",
        id: values?.id,
        city: values?.city,
      };

      if (isOtherType && values.title?.length === 0) {
        formik.setFieldError("title", "Required");
      } else {
        showLoader();

        if (state.data) {
          address.type = "update";
          address.id = state?.data?.id;
          const res = await postAddress(address);
          if (res) {
            getUserAddresses();
            showToast("Address updated successfully", "success");
            localStorage.removeItem("userAddress");
            fn.setPositionToBody();
            setPositionToBody();
            hideLoader();
          }
        } else {
          const res = await postAddress(address);
          if (res) {
            getUserAddresses();
            showToast("Address added successfully", "success");
            localStorage.removeItem("userAddress");
            fn.setPositionToBody();
            setPositionToBody();
            hideLoader();
          }
        }
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("address1", state?.address1);
    formik.setFieldValue("city", state?.city);
    formik.setFieldValue("area", state?.areaId);
  }, [state]);

  return (
    <div className="mobile">
      <div className="complete_address_page">
        {/* cancel */}
        <div className="cancel-container">
          <button
            onClick={fn.setPositionToBody}
            className="d-flex align-items-center justify-content-center bg-black text-white border-0"
          >
            <CloseIcon width="20" height="20" fill="#fff" />
          </button>
        </div>
        <div className="complete_address_container">
          <div className="enter_complete_address_text_container">
            <h1 className="mb-0 font16x600 textColor3">
              Enter Complete Address
            </h1>
          </div>

          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="input_container mt-3">
                <InputField
                  id="name"
                  label="Name*"
                  type="text"
                  state={formik.values.name}
                  setState={(e) => {
                    formik.setFieldValue("name", e.target.value);
                  }}
                  border="1px solid var(--border-color4)"
                  onBlur={formik.handleBlur}
                  width="100%"
                  bgColor="#fff"
                  labelColor="textColor3"
                  isDisabled={false}
                />
                {formik.touched.name && formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>
              <div className="input_container">
                <InputMobile
                  id="mobile"
                  label="Mobile*"
                  state={formik.values.phone}
                  setState={(e) => {
                    let isString = e.target.value.match(/[A-z]/g);
                    if (isString) {
                      showToast("Only number is allowed", "error");
                    } else {
                      formik.setFieldValue("phone", e.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  width="100%"
                  border="1px solid var(--border-color4)"
                  bgColor="#fff"
                  max={10}
                  labelColor="textColor3"
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <InputError error={formik.errors.phone} />
                ) : null}
              </div>
              <div className="input_container">
                <InputField
                  id="address"
                  label="Flat No/H.No./Block No*"
                  type="text"
                  state={formik.values.address}
                  setState={(e) => {
                    formik.setFieldValue("address", e.target.value);
                  }}
                  border="1px solid var(--border-color4)"
                  onBlur={formik.handleBlur}
                  width="100%"
                  bgColor="#fff"
                  labelColor="textColor3"
                />
                {formik.touched.address && formik.errors.address ? (
                  <InputError error={formik.errors.address} />
                ) : null}
              </div>
              <div className="input_container">
                <InputField
                  id="apartmentName"
                  label="Apartment Name/Society/Street No.*"
                  type="text"
                  state={formik.values.apartmentName}
                  setState={(e) => {
                    formik.setFieldValue("apartmentName", e.target.value);
                  }}
                  border="1px solid var(--border-color4)"
                  onBlur={formik.handleBlur}
                  width="100%"
                  bgColor="#fff"
                  isDisabled={false}
                  labelColor="textColor3"
                />
                {formik.touched.apartmentName && formik.errors.apartmentName ? (
                  <InputError error={formik.errors.apartmentName} />
                ) : null}
              </div>
              <div className="input_container">
                <InputField
                  id="landmark"
                  label="Landmark"
                  type="text"
                  state={formik.values.landmark}
                  setState={(e) => {
                    formik.setFieldValue("landmark", e.target.value);
                  }}
                  border="1px solid var(--border-color4)"
                  onBlur={formik.handleBlur}
                  width="100%"
                  bgColor="#fff"
                  labelColor="textColor3"
                />
                {formik.touched.landmark && formik.errors.landmark ? (
                  <InputError error={formik.errors.landmark} />
                ) : null}
              </div>

              <div className="address_types_section input_container mt-0 ms-0 me-0 p-0">
                <div>
                  <p className="font12x400 mb-2">Save address as</p>
                </div>
                <div className="address_types_container">
                  {!isOtherType && (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          formik.setFieldValue("title", "Home");
                        }}
                        className={`font14x400 address_type ${
                          formik.values.title == "Home" ? "active" : ""
                        }`}
                      >
                        Home
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          formik.setFieldValue("title", "Work");
                        }}
                        className={`font14x400 address_type ${
                          formik.values.title == "Work" ? "active" : ""
                        }`}
                      >
                        Work
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          formik.setFieldValue("title", "Hotel");
                        }}
                        className={`font14x400 address_type ${
                          formik.values.title == "Hotel" ? "active" : ""
                        }`}
                      >
                        Hotel
                      </button>
                    </>
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      setIsOtherType(true);
                      formik.setFieldValue("title", "");
                    }}
                    className={`font14x400 address_type ${
                      isOtherType ? "active" : ""
                    }`}
                  >
                    Others
                  </button>
                  {isOtherType && (
                    <>
                      <div className="other_container d-flex align-items-center justify-content-between nb-2">
                        <input
                          id="title"
                          name="title"
                          onChange={(e) => {
                            formik.setFieldValue("title", e.target.value);
                          }}
                          value={formik.values.title}
                          className="border-0"
                          type="text"
                          placeholder="Save as"
                          onBlur={formik.handleBlur}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setIsOtherType(false);
                          }}
                          className="border-0 bg-white close_button"
                        >
                          <CloseIcon width="20" height="20" fill="black" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
                {formik.touched.title && formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              <div className="d-flex align-items-center input_container">
                <div>
                  <input
                    onChange={(e) => {
                      formik.setFieldValue("isDefault", e.target.checked);
                    }}
                    className="checkbox"
                    id="default"
                    type="checkbox"
                  ></input>
                  <label htmlFor="default"></label>
                </div>
                <div className="d-flex align-items-center">
                  <label className="font12x500 ms-2 mb-1" htmlFor="default">
                    Set as default
                  </label>
                </div>
              </div>
            </form>
            {/* Save Address */}
            <div className="fix_section_at_bottom">
              <button
                type="button"
                className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
                onClick={() => {
                  formik.handleSubmit();
                }}
                disabled={loading ? true : false}
              >
                {loading ? (
                  <SpinLoader height="22" width="22" color="#fff" />
                ) : (
                  "Save Address"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompleteAddress;
