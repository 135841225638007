import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { generalFAQs } from "../../../../../components/shared/FAQ/constant";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import Header from "../../../../../components/shared/Header";
import { FAQPage } from "../../../../../utils/metaInfo/metaInfo";

const FAQ = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [whichFAQSelected, setWhichFAQSelected] = useState<number>();

  const fn = {
    openFAQ: (id: number) => {
      if (show && whichFAQSelected === id) {
        setShow(false);
      } else {
        setWhichFAQSelected(id);
        setShow(true);
      }
    },
  };

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Helmet>
          <title>{FAQPage.title}</title>
          <meta name="description" content={FAQPage.description} />
          <meta name="keywords" content={FAQPage.keywords} />
        </Helmet>

        <Header label="FAQs" navigate={() => navigate(-1)} />

        {/* page container */}
        <div className="faq_wrapper">
          {generalFAQs?.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className="single_faq cursor_pointer"
                onClick={() => fn.openFAQ(item?.id)}
              >
                <div className="d-flex justify-content-between gap-3">
                  <div className="app_faq_header gap-3">
                    <div className="faq_dot"></div>
                    <p className="mb-0 font14x500 textColor3">
                      {item?.question}
                    </p>
                  </div>
                  <i className="textColor1">
                    <MdOutlineKeyboardArrowDown
                      style={{
                        fontSize: 20,
                      }}
                    />
                  </i>
                </div>
                {show && item?.id === whichFAQSelected && (
                  <div className="faq_desc_wrapper">
                    <p className="mb-0 font14x400 textColor3">{item?.answer}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FAQ;
