import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

const gtm = Analytics({
  app: "Getlook",
  plugins: [
    googleTagManager({
      containerId: process.env.REACT_APP_GTM_ID,
    }),
  ],
});

export { gtm };
