import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../../../pages/main/type";
import Header from "../../Header";
import { CurrentLocationIcon } from "../../Icons";
import CompleteAddress from "../CompleteAddress";
import {
  checkServiceInLocation,
  getAddressFromLatLng,
} from "../../../../lib/api functions/address/address";
import { showToast } from "../../../../lib/toast/showToast";
import { Auth } from "../../../../utils/context/AuthContext";
import AddressMap from "./map";
import BottomTopAnimation from "../../BottomTopAnimation";
import useLoading from "../../../../hooks/useLoading";
import SpinLoader from "../../Loader/SpinLoader";
import { useNavigate } from "react-router-dom";
import { setOverflowToBodyHidden, setOverflowToBodyUnset } from "../../../../utils/function/function";

type propType = {
  state?: commonType;
  setState?: (val: commonType) => void;
  getUserAddresses: () => void;
  addresses: any;
  latLngFromProp?: any;
};

const MapView: React.FC<propType> = (props) => {
  const { state, setState, getUserAddresses, addresses, latLngFromProp } =
    props;
  const { user, latitude, longitude } = useContext(Auth);
  const [commonData, setCommonData] = useState<any>({
    modal: false,
    check: "",
    city: "",
    address1: "",
  });
  const [latLng, setLatLng] = useState<any>({
    lat: "",
    long: "",
  });
  const [gotCurrentAddress, setGotCurrentAddress] = useState<any>({
    city: "",
    address: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();

  const fn = {
    setPositionToBody: () => {
      setState?.({
        modal: true,
        check: "select_address",
      });
      setOverflowToBodyUnset();
    },
    handleClickOnChange: () => {
      setState?.({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
      // navigate("/select-address");
    },
    handleCheckServiceInLocation: async () => {
      showLoader();
      const res = await checkServiceInLocation({
        ...latLng,
        phone: user?.phone ?? "",
      });

      if (res) {
        hideLoader();
        if (res?.data?.msg) {
          showToast(res?.data?.msg, "error");
          return false;
        } else {
          setCommonData((prev: any) => {
            return {
              ...prev,
              data: state?.data,
              city: res?.data?.city,
              modal: true,
              areaId: res?.data?.locationId,
              check: "complete_address",
            };
          });
          return true;
        }
      }
    },
    handleClickOnConfirmLocation: async () => {
      if (await fn.handleCheckServiceInLocation()) {
        setOverflowToBodyHidden();
        if (state?.data) {
          setCommonData((prev: any) => {
            return {
              ...prev,
              data: state?.data,
              modal: true,
              check: "complete_address",
            };
          });
        } else {
          setCommonData((prev: any) => {
            return {
              ...prev,
              modal: true,
              check: "complete_address",
            };
          });
        }
      } else {
        setCommonData({
          modal: false,
          check: "",
        });
      }
    },
    getcurrentlocation: () => {
      window.navigator.geolocation.getCurrentPosition(
        (coords) => {
          if (coords.coords.latitude && coords.coords.longitude) {
            setLatLng({
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            });
          }
        },
        (err) => {
          showToast(
            "Please enable location permission from settings and try again",
            "error"
          );
        }
      );
    },
    handleClickOnAddressSelectProceed: () => {
      setCommonData((prev: any) => {
        return {
          ...prev,
          modal: false,
          check: "",
        };
      });
    },
  };

  useEffect(() => {
    if (state?.data) {
      setLatLng({
        lat: state?.data?.latitude,
        long: state?.data?.longitude,
      });
    } else if (latLngFromProp?.lat && latLngFromProp?.long) {
      setLatLng({
        lat: latLngFromProp?.lat,
        long: latLngFromProp?.long,
      });
    } else if (latitude && longitude) {
      setLatLng({
        lat: latitude,
        long: longitude,
      });
    } else {
      fn.getcurrentlocation();
    }
  }, [latLngFromProp]);

  useEffect(() => {
    if (latLng.lat && latLng.long) {
      getAddressFromLatLng(latLng).then((res: any) => {
        setGotCurrentAddress((addressInfo: any) => {
          return { ...addressInfo, address: res };
        });
        setCommonData((prev: any) => {
          return {
            ...prev,
            address1: res?.data?.results[0]?.formatted_address,
          };
        });
        res.data.results.forEach((addresses: any) => {
          addresses.address_components.forEach((address: any) => {
            address.types.forEach((item: any) => {
              if (item === "locality") {
                setGotCurrentAddress((addressInfo: any) => {
                  return { ...addressInfo, city: address.long_name };
                });
              }
            });
          });
        });
      });
    }
  }, [latLng]);

  return (
    <>
      <div className="full_page_modal choose_address_location">
        <Header
          label="Choose Location"
          navigate={() => fn.setPositionToBody()}
        />

        {latLng.lat && latLng.long && (
          <AddressMap latLng={latLng} setLatLng={setLatLng} />
        )}

        {/* proceed btn */}
        <div className="bg-white">
          <div className="location_wrapper">
            <div className="py-3 use_current_location_wrapper d-flex justify-content-between align-items-center gap-3">
              <div className="d-flex gap-2">
                <CurrentLocationIcon />
                <div className="w-100">
                  <p className="mb-0 font16x700 textColor3">
                    {gotCurrentAddress?.city}
                  </p>
                  <p className="mb-0 font14x400 textColor7">
                    {gotCurrentAddress?.address?.data?.results[0]?.formatted_address?.substring(
                      0,
                      30
                    )}
                  </p>
                </div>
              </div>
              <div>
                <p
                  className="mb-0 font14x400 textColor1"
                  onClick={() => {
                    fn.handleClickOnChange();
                  }}
                >
                  CHANGE
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
            onClick={() => {
              fn.handleClickOnConfirmLocation();
            }}
            disabled={loading ? true : false}
          >
            {loading ? (
              <SpinLoader height="22" width="22" color="#fff" />
            ) : (
              "Confirm Location"
            )}
          </button>
        </div>
      </div>

      {commonData?.modal && commonData.check == "complete_address" && (
        <BottomTopAnimation>
          <CompleteAddress
            getUserAddresses={getUserAddresses}
            state={commonData}
            setState={setCommonData}
            setPositionToBody={fn.setPositionToBody}
            latLng={latLng}
          />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default MapView;
