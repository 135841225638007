import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import Header from "../../../../../components/shared/Header";
import useLoading from "../../../../../hooks/useLoading";
import { getWallet } from "../../../../../lib/api functions/wallet/wallet";
import { WalletPage } from "../../../../../utils/metaInfo/metaInfo";
import WalletLoader from "./loader";
import { BsInfoCircle } from "react-icons/bs";
import { setOverflowToBodyHidden } from "../../../../../utils/function/function";
import { commonType } from "../../../type";
import TransactionInfo from "../../../../../components/pages/MyProfile/SubPages/wallet/TransactionInfo";

const Wallet = () => {
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [wallet, setWallet] = useState<any>([]);
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
    data: undefined,
  });

  const fn = {
    getWalletData: async (params: any) => {
      showLoader();
      const res = await getWallet(params);
      if (res) {
        setWallet(res.data);
        hideLoader();
      }
    },

    openModal: (check?: string, data?: any) => {
      setCommonData({
        modal: true,
        check: check,
        data,
      });
      setOverflowToBodyHidden();
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fn.getWalletData({});
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "var(--bg-color2)",
        }}
      >
        <Helmet>
          <title>{WalletPage.title}</title>
          <meta name="description" content={WalletPage.description} />
          <meta name="keywords" content={WalletPage.keywords} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>

        <Header label="Wallet" navigate={() => navigate(-1)} />

        {/* available balance info */}
        <div className="balance_info_wrapper">
          <div className="balance_info_card">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-8">
                  <p className="mb-0 font16x400 textColor3">
                    Available Balance
                  </p>
                  <p className="mb-0 font32x700 textColor4">
                    {wallet?.length > 0
                      ? `₹${Number(wallet[0]?.customer?.wallet_balance).toFixed(
                          2
                        )}`
                      : `₹0.00`}
                  </p>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <img
                    src="/static/svg/wallet-balance.svg"
                    alt="wallet_balance"
                  />
                </div>
              </div>
            </div>
            <div className="card_separator my-3"></div>
            <p className="mb-0 font12x400 textColor3">
              G-Cash can be used for all your orders across categories (Facial,
              Waxing & more)
            </p>
          </div>
        </div>

        {/* wallet activity */}
        <div className="wallet_activity_wrapper mt-2">
          <h2 className="font16x600 textColor3">Wallet activity</h2>
          <div className="d-flex flex-column gap-2 my-4">
            {wallet?.length > 0 ? (
              wallet?.map((item: any, i: number) => {
                return (
                  <div key={i} className="single_activity">
                    <p className="mb-0 font12x400 textColor2">
                      {moment(item?.requestInsertTime).format("MMM D, YYYY")}
                    </p>
                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex align-items-start gap-2">
                        <img src="/static/svg/giftbox.svg" alt="gift_box" />
                        <div>
                          <p className="mb-1 font14x500 textColor3">
                            {item?.description
                              ? `G Cash: ${item.description?.split("|")[0]}`
                              : "--"}

                            {item?.description?.split("|")?.length > 1 ? (
                              <button
                                onClick={() => {
                                  fn.openModal(
                                    "showTransactionInfo",
                                    item?.description?.split("|")
                                  );
                                }}
                                className="border-0 bg-white"
                              >
                                <BsInfoCircle
                                  fill="var(--bg-color1)"
                                  className="cursor_pointer ms-1"
                                />
                              </button>
                            ) : (
                              ""
                            )}
                          </p>
                          {/* {moment().isBefore(item?.expiry_date) ? (
                            <p className="mb-0 font12x500 textColor2">
                              Expires On:{" "}
                              {moment(item?.expiry_date).format("ll")}
                            </p>
                          ) : (
                            <p className="mb-0 font12x500 textColor2">
                              Expires
                            </p>
                          )} */}

                          {item?.trxn_type === 1 && !item?.isUsed ? (
                            <p className="mb-0 font12x500 textColor2">
                              Expires On:{" "}
                              {moment(item?.expiry_date).format("ll")}
                            </p>
                          ) : (
                            <p className="mb-0 font12x500 textColor1">
                              Expired
                            </p>
                          )}
                        </div>
                      </div>
                      <p
                        className={`mb-0 font16x500 text-end ${
                          item?.trxn_type === 1 ? "textColor5" : "textColor6"
                        }`}
                      >
                        {item?.trxn_type === 1
                          ? `+ ₹${item?.amount}`
                          : `- ₹${item?.amount}`}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : loading ? (
              <>
                <WalletLoader />
              </>
            ) : (
              <p className="mb-0 font14x500 textColor3">No activity found</p>
            )}
          </div>
        </div>
      </div>
      {commonData?.modal && commonData?.check === "showTransactionInfo" && (
        <TransactionInfo state={commonData} setState={setCommonData} />
      )}
    </>
  );
};

export default Wallet;
