import moment from "moment";
import React, { useState } from "react";
import {
  conveyanceId,
  couponId,
  handlingFeeId,
  membershipDiscountId,
  membershipId,
  ORDER_DETAILS_STATUS,
  premiumId,
  previousDuesId,
  rescheduleChargeId,
  safetyId,
  surgeId,
  upgradeId,
  walletId,
} from "../../../../../../../utils/constant/constant";
import { getDataFromSIns, setOverflowToBodyUnset } from "../../../../../../../utils/function/function";
import { ClockIcon, CloseIcon } from "../../../../../../shared/Icons";
import Separator from "../../../../../../shared/Separator";
import StylistInfo from "../StylistInfo";
import { Link } from "react-router-dom";
import { commonType } from "../../../../../../../pages/main/type";
import BottomTopAnimation from "../../../../../../shared/BottomTopAnimation";

type PropType = {
  singleOrder: any;
};

const CancellationFee: React.FC<PropType> = (props) => {
  const { singleOrder } = props;

  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  return (
    <>
      <div className="order_details_wrapper d-flex align-items-center gap-3">
        <div>
          <img width={40} height={40} src="/static/img/insurance.png" />
        </div>
        <div>
          <h3 className="mb-0 font14x700 textColor3">₹200 Cancellation Fee</h3>
          <p className="mt-2 mb-2 font12x400 textColor3">
            Fee be charged for a confirmed appointment.
          </p>
          <p onClick={() => {
            setShowPopUp(true)
          }} className="mb-0 font12x500 textColor1 text-decoration-underline">
            Check Cancellation Policy
          </p>
        </div>
      </div>

      {/* coupon applied */}
      {showPopUp ? (
        <BottomTopAnimation>
          <div className="home-page-rating-review-page">
            <div className="d-flex align-items-end justify-content-end">
              <button
                onClick={() => {
                  setShowPopUp(false);
                  setOverflowToBodyUnset();
                }}
                className="home-page-rating-popup-close-button"
              >
                <CloseIcon width="16" height="16" fill="white" />
              </button>
            </div>
            <div className="cancellation-fee-white">
              <h2 className="mb-3 font14x700 textColor3">
                Cancellation Policy
              </h2>
              <Separator />
              <div className="d-flex align-items-center gap-2 mt-3">
                <img
                  src="/static/img/regular_stylist.png"
                  alt="regular_stylist"
                />
                <div>
                  <h3 className="mb-0 font14x700 textColor3">
                    100% fee goes to service professional
                  </h3>
                  <p className="mt-2 mb-2 font11x400 textColor3">
                    Our professionals work hard, and canceling a confirmed
                    appointment prevents them from booking another client. No
                    fee is charged if the booking is not confirmed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BottomTopAnimation>
      ) : (
        ""
      )}
    </>
  );
};

export default CancellationFee;
