import { commonType } from "../../../../../pages/main/type";
import { setOverflowToBodyUnset } from "../../../../../utils/function/function";
import { CloseIcon } from "../../../../shared/Icons";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const ThankYouModal: React.FC<propType> = (props) => {
  const { state, setState } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper">
        <div className="modal_section pt-2">
          <div className="text-end">
            <button className="close_button">
              <CloseIcon
                width="10"
                height="10"
                fill="#AF031A"
                clickHandler={fn.setPositionToBody}
              />
            </button>
          </div>
          <div className="w-75 mx-auto text-center">
            <img src="/static/svg/brochure-thank-u.svg" alt="thank-u" />
            <p className="mt-4 font14x400 textColor3">
              Thanks for showing interest in{" "}
              <span className="font14x600">GetLook Franchise.</span>
            </p>
            <p className="mb-0 font14x400 textColor3">
              Our Executive will contact you within 24 hrs
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouModal;
