import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../utils/function/function";

const ShopByService = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const service = filterHomepageSections(homepage_sections, "parent")?.sort(
    (a: any, b: any) => {
      return a.category_order - b.category_order;
    }
  );

  return (
    <>
      {service?.length > 0 && (
        <div className="services_wrapper shop_by_service_section mt-2">
          <h2 className="font14x700 text-center shop-by-service-heading">
            Complete Beauty Care
          </h2>
          <div className="shopby-service-container services_section">
            {mainLoading ? (
              <>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
              </>
            ) : (
              service?.map((item: any, i: number) => {
                return (
                  <>
                    {item?.stylist_information === "Home" ? (
                      <Link
                        key={i}
                        to={`/services/${item?.id}`}
                        className="text-decoration-none"
                      >
                        <div className="single_service text-center">
                          <img
                            src={item?.category_image}
                            alt="category"
                            style={{
                              borderRadius: "8px",
                              width: "100%",
                              height: "auto",
                            }}
                          />
                          <p className="mb-0 mt-2 px-2 font11x500 textColor3 service_name">
                            {item?.name}
                          </p>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                  </>
                );
              })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ShopByService;
