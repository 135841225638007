import { commonType } from "../../../pages/main/type";
import { setOverflowToBodyUnset } from "../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  handlerFn: () => void;
};

const PopupModal: React.FC<propType> = (props) => {
  const { state, setState, handlerFn } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper">
        <div className="modal_section">
          <p className="mt-3 text-center font16x400 textColor3">
            Are you sure you want to Logout?
          </p>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="button"
              className="btn_outline font14x400 textColor1"
              onClick={fn.setPositionToBody}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn_login font14x400"
              onClick={handlerFn}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupModal;
