import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TravellingModal from "../../../components/pages/Cart/TravellingModal";
import MapView from "../../../components/shared/Address/ChooseLocation";
import SavedAddress from "../../../components/shared/Address/SavedAddress";
import BottomTopAnimation from "../../../components/shared/BottomTopAnimation";
import Header from "../../../components/shared/Header";
import {
  CurrentLocationIcon,
  Plus,
  RightChevronIcon,
  SearchIcon,
} from "../../../components/shared/Icons";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import CityNotMatched from "../../../components/shared/PopupModal/CityNotMatched";
import Separator from "../../../components/shared/Separator";
import useLoading from "../../../hooks/useLoading";
import {
  checkServiceInLocation,
  getAddressFromLatLng,
} from "../../../lib/api functions/address/address";
import { showToast } from "../../../lib/toast/showToast";
import {
  ADDRESS_TYPE,
  PLACES_API_CHARACTER_HITS,
  TEMP_ADDRESS_TYPE,
} from "../../../utils/constant/constant";
import { Auth } from "../../../utils/context/AuthContext";
import {
  calculatePaymentDetails,
  checkingTrackingTimeDifference,
  checkUserAddress,
  getDeviceSource,
  isUserValid,
  setOverflowToBodyHidden,
  setOverflowToBodyUnset,
} from "../../../utils/function/function";
import { commonType } from "../type";
import moment from "moment";

const SelectAddress = () => {
  const navigate = useNavigate();
  const [gotCurrentAddress, setGotCurrentAddress] = useState<any>();
  const [currentLatLng, setCurrentLatLng] = useState<any>({
    lat: "",
    long: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const {
    user,
    addresses,
    getUserAddresses,
    trackUserActivity,
    cartItemsInContext,
  } = useContext(Auth);
  const user_activity = JSON.parse(localStorage.getItem("user_activity")!);
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [latLng, setLatLng] = useState<any>({
    lat: "",
    long: "",
  });

  const { addressLoading } = useContext(Auth);
  const [inputValue, setInputValue] = useState("");
  const [isListenerAdded, setIsListenerAdded] = useState(false);

  const fn = {
    openModal: (check?: string) => {
      setCommonData({
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    handleClickOnUseCurrentLocation: () => {
      if (gotCurrentAddress && gotCurrentAddress.data.results.length > 0) {
        setLatLng({
          lat: currentLatLng.lat,
          long: currentLatLng.long,
        });
        setCommonData?.({
          modal: true,
          check: "choose_location",
        });
        setOverflowToBodyHidden();
      } else {
        fn.getcurrentlocation();
      }
    },
    handleCheckServiceInLocation: async (latLng: any) => {
      const subTotal = paymentDetails?.subTotal - paymentDetails?.discount;
      const res = await checkServiceInLocation({
        ...latLng,
        phone: user?.phone ?? "",
      });

      if (res) {
        if (res?.data?.msg) {
          showToast(res?.data?.msg, "error");
          return false;
        } else {
          if (res?.data?.minServiceValue > subTotal) {
            hideLoader();
            showToast(
              `Minimum amount should be ${res?.data?.minServiceValue}`,
              "error"
            );
          } else if (
            subTotal <= res?.data?.minCharge &&
            subTotal >= res?.data?.minServiceValue
          ) {
            hideLoader();
            setCommonData({
              modal: true,
              check: "confirm_travelling_charge",
              data: res?.data,
            });
            return false;
          } else {
            return true;
          }
        }
      }
    },
    handleClickOnProceed: async () => {
      if (checkUserAddress(addresses)) {
        showLoader();
        const address: any = JSON.parse(
          `${localStorage.getItem("userAddress")}`
        );
        const city: any = JSON.parse(`${localStorage.getItem("city")}`);
        if (city == address?.area?.city) {
          const latLng = {
            lat: address?.latitude,
            long: address?.longitude,
          };
          if (await fn.handleCheckServiceInLocation(latLng)) {
            setCommonData?.({
              modal: false,
              check: "",
            });
            localStorage.setItem("userAddress", JSON.stringify(address));
            setOverflowToBodyUnset();
            navigate("/slot");
            fn.userTracking(address?.address1);
          }
        } else {
          hideLoader();
          fn.openModal("city_not_matched");
        }
      } else {
        showToast("Please select address or add new address", "error");
      }
    },
    handleClickOnEditIcon: (add: string) => {
      setCommonData?.({
        data: add,
        modal: true,
        check: "choose_location",
      });
      setOverflowToBodyUnset();
    },
    placeSelected: (place: any) => {
      const input: any = document.getElementById("search-location-address");
      input.value = "";
      setLatLng({
        lat: place?.geometry?.location?.lat(),
        long: place?.geometry?.location?.lng(),
      });
      setCommonData?.({
        modal: true,
        check: "choose_location",
      });
      setOverflowToBodyUnset();
    },
    getcurrentlocation: () => {
      window.navigator.geolocation.getCurrentPosition(
        (coords) => {
          if (coords.coords.latitude && coords.coords.longitude) {
            const latLng = {
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            };
            setCurrentLatLng({
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            });
            getAddressFromLatLng(latLng)
              .then((res: any) => {
                setGotCurrentAddress(res);
              })
              .catch((err: any) => {});
          }
        },
        (err) => {
          showToast(
            "Please enable location permission from settings and try again",
            "error"
          );
        }
      );
    },
    handleClickOnAdd: () => {
      setCommonData?.({
        modal: true,
        check: "choose_location",
      });
    },
    handleClickOnYesOnTravellingModal: () => {
      const address: any = JSON.parse(`${localStorage.getItem("userAddress")}`);
      localStorage.setItem("conveyanceCharge", commonData.data?.charges);
      localStorage.setItem("userAddress", JSON.stringify(address));
      setOverflowToBodyUnset();
      navigate("/slot");
      fn.userTracking(address?.address1);
    },
    userTracking: (address: string) => {
      // user tracking
      if (isUserValid()) {
        const trackingTimeDiff = checkingTrackingTimeDifference();
        if (trackingTimeDiff) {
          // removing user activity after 6 hours
          window.localStorage.removeItem("user_activity");

          trackUserActivity({
            type: ADDRESS_TYPE,
            tempType: TEMP_ADDRESS_TYPE,
            area_id: localStorage.getItem("area_id")!,
            source: getDeviceSource(),
          });

          window.localStorage.setItem(
            "tracking_cache_timestamp",
            JSON.stringify(moment())
          );
        } else {
          if (user_activity) {
            if (TEMP_ADDRESS_TYPE > user_activity?.tempType) {
              trackUserActivity({
                ...user_activity,
                type: ADDRESS_TYPE,
                tempType: TEMP_ADDRESS_TYPE,
                address,
              });
            }
          }
        }
      }
    },
  };

  useEffect(() => {
    localStorage.removeItem("slotInfo");
    localStorage.removeItem("previousDues");
    localStorage.removeItem("conveyanceCharge");
    if (cartItemsInContext.length > 0) {
      const payAmount = calculatePaymentDetails();
      setPaymentDetails(payAmount);
    }
  }, [cartItemsInContext]);

  useEffect(() => {
    window.scrollTo(0, 0);

    fn.getcurrentlocation();
  }, []);

  useEffect(() => {
    if (inputValue.length > PLACES_API_CHARACTER_HITS && !isListenerAdded) {
      const { maps } = (window as any).google;
      let input = document.getElementById("search-location-address");
      let options = {
        componentRestrictions: { country: "in" },
        fields: ["geometry"],
      };
      let autocomplete = new maps.places.Autocomplete(input, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        fn.placeSelected(place);
      });
      setIsListenerAdded(true);
    }
  }, [inputValue]);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Header label="Select Address" navigate={() => navigate(-1)} />

        {/* search */}
        <div className="search_wrapper pt-3 pb-0">
          <div className="search_input_section">
            <input
              id="search-location-address"
              type="text"
              placeholder="Enter your area or apartment name"
              onChange={(e: any) => {
                setInputValue(e.target.value);
              }}
            />
            <SearchIcon />
          </div>

          {/* current location */}
          <div
            className="py-3 use_current_location_wrapper d-flex justify-content-between align-items-center gap-3"
            onClick={() => {
              fn.handleClickOnUseCurrentLocation();
            }}
          >
            <div className="d-flex gap-2">
              <CurrentLocationIcon />
              <div className="w-100">
                <p className="mb-1 font16x400 textColor1">
                  Use current location
                </p>
                <p className="mb-0 font14x400 textColor7">
                  {gotCurrentAddress?.data?.results[0]?.formatted_address.substring(
                    0,
                    30
                  )}
                </p>
              </div>
            </div>
            <div>
              <RightChevronIcon width="16" height="16" fill="#AF031A" />
            </div>
          </div>
          <Separator />
        </div>

        {/* add address */}
        <div
          className="add_address_wrapper py-3"
          onClick={() => {
            fn.handleClickOnAdd();
          }}
        >
          <div className="d-flex align-items-center gap-3 add_address_link_section">
            <Plus width="16" height="16" />
            <p className="mb-0 font14x500 textColor3">Add Address</p>
          </div>
        </div>
        <Separator />

        {/* saved address */}
        {addressLoading ? (
          <div className="mt-5">
            <SpinLoader height="60" width="60" color="#AF031A" />
          </div>
        ) : (
          <SavedAddress
            handleClickOnEditIcon={fn.handleClickOnEditIcon}
            getUserAddresses={getUserAddresses}
            addresses={addresses}
            isSelectAddress={true}
          />
        )}

        {/* proceed btn */}
        <div className="fix_section_at_bottom">
          <button
            type="button"
            className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
            onClick={() => {
              fn.handleClickOnProceed();
            }}
          >
            {loading ? (
              <SpinLoader height="22" width="22" color="#fff" />
            ) : (
              "Proceed"
            )}
          </button>
        </div>
      </div>

      {/* travelling charges modal */}
      {commonData?.modal &&
        commonData?.check === "confirm_travelling_charge" && (
          <BottomTopAnimation>
            <TravellingModal
              state={commonData}
              setState={setCommonData}
              handleClickOnYesOnTravellingModal={
                fn.handleClickOnYesOnTravellingModal
              }
            />
          </BottomTopAnimation>
        )}

      {/* map view */}
      {commonData?.modal && commonData?.check === "choose_location" && (
        <MapView
          getUserAddresses={getUserAddresses}
          addresses={addresses}
          state={commonData}
          setState={setCommonData}
          latLngFromProp={latLng}
        />
      )}

      {commonData?.modal && commonData?.check === "city_not_matched" && (
        <BottomTopAnimation>
          <CityNotMatched state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default SelectAddress;
