import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../../../../pages/main/type";
import Header from "../../../../shared/Header";
import {
  CurrentLocationIcon,
  RightChevronIcon,
  SearchIcon,
} from "../../../../shared/Icons";
import Separator from "../../../../shared/Separator";
import { Auth } from "../../../../../utils/context/AuthContext";
import SavedAddresses from "../Saved Address";
import { showToast } from "../../../../../lib/toast/showToast";
import { getAddressFromLatLng } from "../../../../../lib/api functions/address/address";
import { PLACES_API_CHARACTER_HITS } from "../../../../../utils/constant/constant";
import { setOverflowToBodyUnset } from "../../../../../utils/function/function";

type propType = {
  state?: commonType;
  setState?: (val: commonType) => void;
  setLatLng?: (val: any) => void;
};

const SearchLocation: React.FC<propType> = (props) => {
  const { state, setState, setLatLng } = props;
  const { addresses, latitude, longitude } = useContext(Auth);
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [currentLatLng, setCurrentLatLng] = useState<any>({
    lat: "",
    long: "",
  });
  const [gotCurrentAddress, setGotCurrentAddress] = useState<any>();
  const [inputValue, setInputValue] = useState("");
  const [isListenerAdded, setIsListenerAdded] = useState(false);
  const [isLocationAllowed, setIsLocationAllowed] = useState(false);

  const fn = {
    setPositionToBody: () => {
      setState?.({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
    handleClickOnEditIcon: (add: string) => {
      setCommonData?.({
        data: add,
        modal: true,
        check: "choose_location",
      });
      setOverflowToBodyUnset();
    },
    handleClickOnUseCurrentLocation: () => {
      if (
        (gotCurrentAddress && gotCurrentAddress.data.results.length > 0) ||
        isLocationAllowed
      ) {
        setLatLng?.({
          lat: currentLatLng.lat,
          long: currentLatLng.long,
        });
        setState?.({
          modal: true,
          check: "choose_location",
        });
        setOverflowToBodyUnset();
      } else {
        fn.getcurrentlocation();
      }
    },
    placeSelected: (place: any) => {
      setLatLng?.({
        lat: place?.geometry?.location?.lat(),
        long: place?.geometry?.location?.lng(),
      });
      setState?.({
        modal: true,
        check: "choose_location",
      });
      setOverflowToBodyUnset();
    },
    getcurrentlocation: () => {
      window.navigator.geolocation.getCurrentPosition(
        (coords) => {
          if (coords.coords.latitude && coords.coords.longitude) {
            setIsLocationAllowed(true);
            const latLng = {
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            };
            setCurrentLatLng({
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            });
            setLatLng?.({
              lat: coords.coords.latitude,
              long: coords.coords.longitude,
            });

            if (localStorage.getItem("useGeocode")) {
              getAddressFromLatLng(latLng)
                .then((res: any) => {
                  setGotCurrentAddress(res);
                })
                .catch((err: any) => {});
            }
          }
        },
        (err) => {
          showToast(
            "Please enable location permission from settings and try again",
            "error"
          );
        }
      );
    },
  };

  useEffect(() => {
    if (inputValue.length > PLACES_API_CHARACTER_HITS && !isListenerAdded) {
      const { maps } = (window as any).google;
      let input = document.getElementById("search-location");
      let options = {
        componentRestrictions: { country: "in" },
        fields: ["geometry"],
      };
      let autocomplete = new maps.places.Autocomplete(input, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        fn.placeSelected(place);
      });
      setIsListenerAdded(true);
    }
  }, [inputValue]);

  useEffect(() => {
    fn.getcurrentlocation();
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="full_page_modal pb-0">
          <Header
            label="Search Location"
            navigate={() => {
              if (latitude && longitude) {
                fn.setPositionToBody();
              } else {
                showToast(
                  "Please enable location permission from settings and try again",
                  "error"
                );
              }
            }}
          />

          {/* search */}
          <div className="search_wrapper pt-3">
            <div className="search_input_section">
              <input
                id="search-location"
                type="text"
                placeholder="Enter your area or apartment name"
                onChange={(e: any) => {
                  setInputValue(e.target.value);
                }}
              />
              <SearchIcon />
            </div>

            {/* current location */}
            <div
              className="py-3 use_current_location_wrapper d-flex justify-content-between align-items-center gap-3"
              onClick={() => {
                fn.handleClickOnUseCurrentLocation();
              }}
            >
              <div className="d-flex gap-2">
                <CurrentLocationIcon />
                <div className="w-100">
                  <p className="mb-1 font16x400 textColor1">
                    Use current location
                  </p>
                  <p className="mb-0 font14x400 textColor7">
                    {gotCurrentAddress?.data?.results[0]?.formatted_address.substring(
                      0,
                      30
                    )}
                  </p>
                </div>
              </div>
              <div>
                <RightChevronIcon width="16" height="16" fill="#AF031A" />
              </div>
            </div>
            <Separator />
          </div>

          {/** @author Deepanshu Tyagi */}
          {addresses?.length > 0 && (
            <SavedAddresses
              setPositionToBody={fn.setPositionToBody}
              addresses={addresses}
            />
          )}
          {/** */}
        </div>
      </div>
    </>
  );
};

export default SearchLocation;
