export const BottomBarMenus = [
  {
    icon: "/static/svg/home.svg",
    colorIcon: "/static/svg/home-color.svg",
    label: "Home",
    link: "/",
    check: "/",
  },
  {
    icon: "/static/svg/category.svg",
    colorIcon: "/static/svg/category-color.svg",
    label: "Categories",
    link: "/category-new",
    check: "/category-new",
  },
  {
    icon: "/static/svg/offer.svg",
    colorIcon: "/static/svg/offer-color.svg",
    label: "Offers",
    // link: "/offers-deals",
    link: "/offers-deals#Coupon_Discount",
    check: "/offers-deals",
  },
  {
    icon: "/static/svg/bookings.svg",
    colorIcon: "/static/svg/bookings-color.svg",
    label: "Appointments",
    link: "/order",
    check: "/order",
  },
];
