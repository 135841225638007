const Separator = () => {
  return (
    <>
      <div
        className="menu_separator"
        style={{
          borderBottom: "1px solid var(--border-color6)",
        }}
      ></div>
    </>
  );
};

export default Separator;
