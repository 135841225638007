import { Outlet } from "react-router-dom";
import AuthContext from "../../../utils/context/AuthContext";

const RouterAuth = () => {
  return (
    <AuthContext>
      <Outlet />
    </AuthContext>
  );
};

export default RouterAuth;
