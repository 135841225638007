export const LoginPage = {
  title: "Getlook - Login",
  description: "Login through OTP",
  keywords: "",
};
export const MyProfilePage = {
  title: "Getlook - Profile",
  description: "profile",
  keywords: "",
};
export const HomePage = {
  title: "GetLook - Salon at Home | Beauty Parlour Services at Home",
  description: "GetLook is a leading salon-at-home service provider in India, offering the convenience of bringing highly trained beauty professionals directly to your doorstep. Hurry booking now!",
  keywords: "Salon at Home",
};
export const ProfileViewPage = {
  title: "Getlook - View Profile",
  description: "profile view",
  keywords: "",
};
export const OffersPage = {
  title: "Getlook - Offers and Deals",
  description: "Get incredible deals and money-saving coupons on GetLook, making your salon at home experience even more rewarding",
  keywords: "",
};
export const ReferPage = {
  title: "Refer and Earn With GetLook",
  description: "Invite your friends to try GetLook services. They will get instant Rs. 200 off and you get Rs. 200 off once they take a service",
  keywords: "",
};
export const WalletPage = {
  title: "Getlook - Your Wallet",
  description: "wallet info",
  keywords: "",
};
export const OrderPage = {
  title: "Getlook - Your Orders",
  description: "order info",
  keywords: "",
};
export const AboutUsPage = {
  title: "Getlook - About Us",
  description: "GetLook is a leading salon at home service in India, currently operating in more than 11+ cities across the country.",
  keywords: "",
};
export const PrivacyPolicyPage = {
  title: "Getlook - Privacy Policy",
  description: "Protect your personal information with GetLook comprehensive privacy policy",
  keywords: "",
};
export const AddressBookPage = {
  title: "Getlook - Your Addresses",
  description: "addresses",
  keywords: "",
};
export const GetlookGoldPage = {
  title: "Getlook Gold Membership",
  description: "Save upto Rs.200 on every order with GetLook Gold Membership.",
  keywords: "",
};
export const FranchisePage = {
  title: "Getlook Franchise",
  description: "Discover the best franchise opportunities and start your own successful business today with GetLook",
  keywords: "",
};
export const CategoriesPage = {
  title: "Categories - Getlook",
  description: "GetLook provides salon at home services like makeup, facial, waxing and many more at most affordable prices.  Book Now ✓ Hygienic Services ✓ Experienced beautician",
  keywords: "",
};
export const CartPage = {
  title: "Getlook - Your Cart",
  description: "cart info",
  keywords: "",
};
export const FAQPage = {
  title: "FAQs  - Getlook",
  description: "Here are some frequently asked questions that are asked by our customers about GetLook.",
  keywords: "",
};
export const ServicePage = {
  title: "Service - Getlook",
  description: "Get the ultimate salon at home experience with GetLook wide range of professional salon services, tailored to enhance your beauty from the comfort of your home.",
  keywords: "",
};
export const ProductPage = {
  title: "Getlook Product",
  description: "Enhance your beauty with GetLook premium beauty products. Discover from wide range of high-quality beauty products.",
  keywords: "",
};
export const ContactPage = {
  title: "Contact Us - GetLook",
  description: "Connect with us effortlessly via our contact details and allow us to provide you with the support and information you need.",
  keywords: "",
};