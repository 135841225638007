import {
    Expenses,
    FranchiseSetupCost,
    MonthlyProjections,
} from "../../../../../components/pages/MyProfile/SubPages/Franchise/constant";
import { CloseIcon } from "../../../../../components/shared/Icons";
import { commonType } from "../../../../../pages/main/type";
import { setOverflowToBodyUnset } from "../../../../../utils/function/function";

type propType = {
    state: commonType;
    setState: (val: commonType) => void;
};

const SetupCostModal: React.FC<propType> = (props) => {
    const { state, setState } = props;

    const fn = {
        setPositionToBody: () => {
            setState({
                modal: false,
                check: "",
            });
            setOverflowToBodyUnset();
        },
    };

    return (
        <div className="view_details_page">
            <div className="cancel-container">
                <button
                    onClick={fn.setPositionToBody}
                    className="d-flex align-items-center justify-content-center bg-black text-white border-0"
                >
                    <CloseIcon width="20" height="20" fill="#fff" />
                </button>
            </div>
            <div className="view_details_container">
                <div>
                    <h2 className="font16x500 textColor1 py-3 text-center m-0">
                        Franchise Setup Cost
                    </h2>
                    <div>
                        <div className="setup_cost_table_heading d-flex justify-content-between align-items-center p-3">
                            <p className="mb-0 font16x600 textColor3">Particulars</p>
                            <p className="mb-0 font16x600 textColor3">Cost</p>
                        </div>

                        {FranchiseSetupCost.map((item: any, i: number) => (
                            <div
                                key={i}
                                className="cost_particulars_container px-3 py-3 d-flex justify-content-between gap-2"
                            >
                                <div>
                                    <p className="mb-0 font12x600 textColor3">{item?.title}</p>
                                    {item?.description && (
                                        <p className="mb-0 font12x400 textColor3">
                                            {item?.description}
                                        </p>
                                    )}
                                </div>
                                <p className="mb-0 font12x500 textColor3">{item?.cost}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-5">
                    <h2 className="font16x500 textColor1 m-0 text-center">
                        Expected Monthly Earnings
                    </h2>
                    <div className="mt-2">
                        <h3 className="font14x500 textColor7 mb-3 text-center">
                            MONTHLY PROJECTIONS
                        </h3>
                        <div className="setup_cost_table_heading d-flex justify-content-between align-items-center p-3">
                            <p className="mb-0 font16x600 textColor3">Particulars</p>
                            <p className="mb-0 font16x600 textColor3">Cost</p>
                        </div>

                        {MonthlyProjections.map((item: any, i: number) => (
                            <div
                                key={i}
                                className="cost_particulars_container px-3 py-3 d-flex justify-content-between gap-2"
                            >
                                <div>
                                    <p className="mb-0 font12x600 textColor3">{item?.title}</p>
                                    {item?.description && (
                                        <p className="mb-0 font12x400 textColor3">
                                            {item?.description}
                                        </p>
                                    )}
                                </div>
                                <p className="mb-0 font12x500 textColor3">{item?.cost}</p>
                            </div>
                        ))}
                    </div>

                    <div className="mt-5">
                        <h3 className="font14x500 textColor7 mb-3 text-center">EXPENSES</h3>
                        <div className="setup_cost_table_heading d-flex justify-content-between align-items-center p-3">
                            <p className="mb-0 font16x600 textColor3">Particulars</p>
                            <p className="mb-0 font16x600 textColor3">Cost</p>
                        </div>

                        {Expenses.map((item: any, i: number) => (
                            <div
                                key={i}
                                className="cost_particulars_container px-3 py-3 d-flex justify-content-between gap-2"
                            >
                                <div>
                                    <p className="mb-0 font12x600 textColor3">{item?.title}</p>
                                    {item?.description && (
                                        <p className="mb-0 font12x400 textColor3">
                                            {item?.description}
                                        </p>
                                    )}
                                </div>
                                <p className="mb-0 font12x500 textColor3">{item?.cost}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="net_profit_container d-flex justify-content-between align-items-center px-3 py-4">
                    <p className="mb-0 font16x600">Net Profit</p>
                    <p className="mb-0 font16x600">67,000</p>
                </div>
            </div>
        </div>
    );
};

export default SetupCostModal;
