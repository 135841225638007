import { useLocation, useNavigate } from "react-router-dom";
import CartItem from "../../../components/pages/Cart/CartItem";
import Instruction from "../../../components/pages/Cart/Instruction";
import Header from "../../../components/shared/Header";
import CouponSection from "../../../components/pages/Cart/Coupon";
import PaymentDetails from "../../../components/pages/Cart/PaymentDetails";
import GrandTotal from "../../../components/pages/Cart/GrandTotal";
import CouponApplied from "../../../components/pages/Cart/CouponAppliedModal";
import { useContext, useEffect, useState } from "react";
import { commonType } from "../type";
import { Helmet } from "react-helmet";
import { CartPage } from "../../../utils/metaInfo/metaInfo";
import RecommendedUpgrade from "../../../components/pages/Cart/Recommended Upgrade";
import RecommendedAddOn from "../../../components/pages/Cart/Recommended AddOn";
import { Auth } from "../../../utils/context/AuthContext";
import { CartEmpty } from "../../../components/shared/Icons";
import {
  calculatePaymentDetails,
  calculatePaymentDetailsExceptSpecialOffers,
  cartItemsToSendInFacebookPixel,
  cartItemsToSendInGtm,
  checkingTrackingTimeDifference,
  checkItemsInCart,
  checkUserExpiry,
  getDeviceSource,
  getItemsId,
  isUserValid,
} from "../../../utils/function/function";
import {
  ANDROID,
  CART_TYPE,
  conveyanceId,
  couponId,
  handlingFeeId,
  membershipDiscountId,
  membershipId,
  premiumId,
  previousDuesId,
  rescheduleChargeId,
  safetyId,
  surgeId,
  TEMP_CART_TYPE,
  upgradeId,
  walletId,
} from "../../../utils/constant/constant";
import Membership from "../../../components/pages/Cart/Membership";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import BottomTopAnimation from "../../../components/shared/BottomTopAnimation";
import moment from "moment";
import useLoading from "../../../hooks/useLoading";
import FullPageLoader from "../../../components/shared/FullPageLoader";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import SpecialOffers from "../../../components/pages/Cart/SpecialOffers";
import Separator from "../../../components/shared/Separator";
import { showToast } from "../../../lib/toast/showToast";
import { getWallet } from "../../../lib/api functions/wallet/wallet";
import { BsInfoCircle } from "react-icons/bs";
import { GiTwoCoins } from "react-icons/gi";

const Cart = () => {
  const {
    user,
    cartItemsInContext,
    trackUserActivity,
    setCartItemsInContext,
    updateUserData,
    setUser,
    homepage_sections,
  } = useContext(Auth);

  const navigate = useNavigate();
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [loading, showLoader, hideLoader] = useLoading();
  const { state } = useLocation();
  const [walletInfo, setWalletInfo] = useState<any>({
    balance: "",
    isApplyWallet: false,
    isWalletBalance: null,
    walletBalance: 0,
  });

  const [isPriceDropServiceExist, setIsPriceDropServiceExist] =
    useState<boolean>(false);

  const [appliedCoupon, setAppliedCoupon] = useState<any>(
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null
  );

  const [information, setInformation] = useState<any>("");

  const fn = {
    removeCartDetailsFromLocal: () => {
      localStorage.removeItem("coupon_applied");
      localStorage.removeItem("conveyanceCharge");
      localStorage.removeItem("slotInfo");
      localStorage.removeItem("previousDues");
      localStorage.removeItem("extra_instruction");
    },
    handleClickOnBack: () => {
      const appliedCoupon =
        JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;
      let serviceList = cartItemsInContext;
      serviceList = serviceList.filter((serviceItem: any) => {
        if (
          serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
          appliedCoupon?.type == "Service"
        ) {
          return false;
        } else {
          return true;
        }
      });
      setCartItemsInContext(serviceList);
      localStorage.setItem("cartItems", JSON.stringify(serviceList));
      fn.removeCartDetailsFromLocal();
      navigate(-1);
    },
    handleClickOnBrowseCategories: () => {
      fn.removeCartDetailsFromLocal();
      navigate("/category-new");
    },
    checkingAndUpdatingIsMember: async () => {
      showLoader();
      if (user?.isMember) {
        if (
          checkUserExpiry(
            user?.membershipActivationDate,
            user?.membershipDuration
          )
        ) {
          const latestUserData = await updateUserData();
          if (latestUserData?.isMember) {
            // check membership expired or not
            if (
              checkUserExpiry(
                latestUserData?.membershipActivationDate,
                latestUserData?.membershipDuration
              )
            ) {
              window.localStorage.setItem(
                "user",
                JSON.stringify({
                  ...user,
                  isMember: false,
                })
              );
              setUser({
                ...user,
                isMember: false,
              });
            }
          } else {
            window.localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                isMember: false,
              })
            );
            setUser({
              ...user,
              isMember: false,
            });
          }
        }
      } else {
        const latestUserData = await updateUserData();
        if (latestUserData?.isMember) {
          // check membership expired or not
          if (
            checkUserExpiry(
              latestUserData?.membershipActivationDate,
              latestUserData?.membershipDuration
            )
          ) {
            window.localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                isMember: false,
              })
            );
            setUser({
              ...user,
              isMember: false,
            });
          }
        } else {
        }
      }
      hideLoader();
    },
    getWalletData: async () => {
      const params = "";
      const res = await getWallet(params);
      let walletBalance;
      if (res) {
        const walletList = res?.data;

        let expiresData: any;
        const daysDifference: any = [];
        const walletDataWithDayDiff: any = {};
        walletList.forEach((walletItem: any) => {
          if (!walletItem?.isUsed && walletItem?.trxn_type === 1) {
            // days diff
            const dayDiff = moment(walletItem?.expiry_date).diff(
              moment(),
              "days"
            );
            daysDifference.push(dayDiff);
            walletDataWithDayDiff[dayDiff] = walletItem;
          }
        });

        expiresData = walletDataWithDayDiff[Math.min(...daysDifference)];
        const walletData = res?.data[0];
        walletBalance = walletData?.customer?.wallet_balance;

        let totalServicesAmount = 0;

        cartItemsInContext.forEach((element: any) => {
          if (
            element?.serviceType?.id != premiumId &&
            element?.serviceType?.id != safetyId &&
            element?.serviceType?.id != surgeId &&
            element?.serviceType?.id != upgradeId &&
            element?.serviceType?.id != conveyanceId &&
            element?.serviceType?.id != membershipDiscountId &&
            element?.serviceType?.id != membershipId &&
            element?.serviceType?.id != walletId &&
            element?.serviceType?.id != couponId &&
            element?.serviceType?.id != rescheduleChargeId &&
            element?.serviceType?.id != previousDuesId &&
            element?.serviceType?.id != handlingFeeId
          ) {
            totalServicesAmount =
              totalServicesAmount + element.cost * element.count;
          }
        });

        let percentageOfServices = 0;

        Object.keys(homepage_sections).forEach((key) => {
          if (homepage_sections[key]?.id === 63) {
            // const amount = Math.round(
            //   (paymentDetails?.grandTotal *
            //     +homepage_sections[key]?.information) /
            //     100
            // );
            percentageOfServices = homepage_sections[key]?.information;

            // if (amount > 200) {

            //   setInformation(200);
            // } else {
            //   percentageOfServices = amount;
            //   setInformation(percentageOfServices);
            // }
          }
        });

        const tenPercent = (totalServicesAmount * percentageOfServices) / 100;

        if (walletBalance > tenPercent) {
          setWalletInfo({
            expiresData: expiresData,
            ...walletInfo,
            balance: Math.round(tenPercent),
            isWalletBalance: true,
          });
        } else {
          if (
            res?.data[0]?.customer?.wallet_balance == 0 ||
            res?.data[0]?.customer?.wallet_balance > 0
          ) {
            setWalletInfo((prev: any) => {
              return {
                expiresData: expiresData,
                ...prev,
                balance:
                  res?.data?.length > 0
                    ? res?.data[0]?.customer?.wallet_balance
                    : "0.00",
                walletBalance:
                  res?.data?.length > 0
                    ? res?.data[0]?.customer?.wallet_balance
                    : 0,
                isWalletBalance: true,
              };
            });
          } else if (res?.data[0]?.customer?.wallet_balance < 0) {
            setWalletInfo((prev: any) => {
              return {
                expiresData: expiresData,
                ...prev,
                balance: res?.data[0]?.customer?.wallet_balance,
                walletBalance: res?.data[0]?.customer?.wallet_balance,
                isWalletBalance: false,
              };
            });

            setPaymentDetails(calculatePaymentDetails());
          } else {
            setWalletInfo((prev: any) => {
              return {
                expiresData: expiresData,
                ...prev,
                balance:
                  res?.data?.length > 0
                    ? res?.data[0]?.customer?.wallet_balance
                    : "0.00",
                walletBalance:
                  res?.data?.length > 0
                    ? res?.data[0]?.customer?.wallet_balance
                    : 0,
                isWalletBalance: false,
              };
            });
          }
        }
      }
    },
    handleOnchangeWallet: (e: any) => {
      // check membership service in cartitems
      const isMembershipService = cartItemsInContext.some((item: any) => {
        return item?.serviceType?.id == 519;
      });
      if (e.target.checked) {
        if (walletInfo.isWalletBalance == true) {
          if (paymentDetails.grandTotal >= 999) {
            if (appliedCoupon?.couponCode && walletInfo?.balance >= 0) {
              e.target.checked = false;
              showToast("You can't apply wallet with coupon", "error");
            } else if (appliedCoupon?.couponCode && walletInfo?.balance < 0) {
              setWalletInfo((prev: any) => {
                return {
                  ...prev,
                  isApplyWallet: e.target.checked,
                };
              });
              setPaymentDetails((prev: any) => {
                return {
                  ...prev,
                  grandTotal:
                    Math.round(paymentDetails.grandTotal) -
                    Math.round(walletInfo?.balance),
                  walletDiscount: Math.round(walletInfo?.balance),
                };
              });
              localStorage.setItem(
                "walletDiscount",
                `${Math.round(walletInfo?.balance)}`
              );
            } else {
              if (user?.isMember || isMembershipService) {
                setWalletInfo((prev: any) => {
                  return {
                    ...prev,
                    isApplyWallet: e.target.checked,
                  };
                });
                if (walletInfo?.balance / 2 > paymentDetails.grandTotal) {
                  setPaymentDetails((prev: any) => {
                    return {
                      ...prev,
                      grandTotal:
                        Math.round(paymentDetails.grandTotal) -
                        Math.round(paymentDetails.grandTotal),
                      walletDiscount: Math.round(paymentDetails.grandTotal),
                    };
                  });
                  localStorage.setItem(
                    "walletDiscount",
                    `${Math.round(paymentDetails.grandTotal)}`
                  );
                } else {
                  setPaymentDetails((prev: any) => {
                    return {
                      ...prev,
                      grandTotal:
                        Math.round(paymentDetails.grandTotal) -
                        Math.round(walletInfo?.balance / 2),
                      walletDiscount: Math.round(walletInfo?.balance / 2),
                    };
                  });
                  localStorage.setItem(
                    "walletDiscount",
                    `${Math.round(walletInfo?.balance / 2)}`
                  );
                }
              } else {
                setWalletInfo((prev: any) => {
                  return {
                    ...prev,
                    isApplyWallet: e.target.checked,
                  };
                });
                if (walletInfo?.balance > paymentDetails.grandTotal) {
                  setPaymentDetails((prev: any) => {
                    return {
                      ...prev,
                      grandTotal:
                        Math.round(paymentDetails.grandTotal) -
                        Math.round(paymentDetails.grandTotal),
                      walletDiscount: Math.round(paymentDetails.grandTotal),
                    };
                  });
                  localStorage.setItem(
                    "walletDiscount",
                    `${Math.round(paymentDetails.grandTotal)}`
                  );
                } else {
                  setPaymentDetails((prev: any) => {
                    return {
                      ...prev,
                      grandTotal:
                        Math.round(paymentDetails.grandTotal) -
                        Math.round(walletInfo?.balance),
                      walletDiscount: Math.round(walletInfo?.balance),
                    };
                  });
                  localStorage.setItem(
                    "walletDiscount",
                    `${Math.round(walletInfo?.balance)}`
                  );
                }
              }
            }
          } else {
            e.target.checked = false;
            showToast("Minimum amount should be 999", "error");
          }
        } else if (walletInfo.isWalletBalance == false) {
          e.target.checked = false;
          showToast("Amount is not available", "error");
        }
      } else {
        const paymentDetails = calculatePaymentDetails();
        setWalletInfo((prev: any) => {
          return {
            ...prev,
            isApplyWallet: e.target.checked,
          };
        });
        setPaymentDetails((prev: any) => {
          return {
            ...prev,
            ...paymentDetails,
            walletDiscount: 0,
          };
        });
        localStorage.removeItem("walletDiscount");
      }
    },
  };

  useEffect(() => {
    if (
      !(
        state?.previousURL &&
        (state?.previousURL?.includes("/services") ||
          state?.previousURL?.includes("/products") ||
          state?.previousURL?.includes("/membership"))
      )
    ) {
      fn.checkingAndUpdatingIsMember();
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("slotInfo");
    localStorage.removeItem("previousDues");
    localStorage.removeItem("conveyanceCharge");
    // setWalletInfo({
    //   balance: "",
    //   isApplyWallet: false,
    //   isWalletBalance: null,
    //   walletBalance: 0,
    // });
    if (cartItemsInContext.length > 0) {
      const payAmount: any = calculatePaymentDetails();
      delete payAmount?.walletDiscount;
      setPaymentDetails(payAmount);

      // special offers from cart
      const payAmountExceptSpecialOffers =
        calculatePaymentDetailsExceptSpecialOffers();
      const filteredSpecialOffers = cartItemsInContext?.filter(
        (cartItem: any) =>
          cartItem?.type !== "special_offer" ||
          cartItem?.worthPrice < payAmountExceptSpecialOffers?.grandTotal
      );
      if (cartItemsInContext?.length !== filteredSpecialOffers?.length) {
        setCartItemsInContext(filteredSpecialOffers);
        localStorage.setItem(
          "cartItems",
          JSON.stringify(filteredSpecialOffers)
        );
      }
    }

    const isPriceDropServiceExist = cartItemsInContext?.some(
      (item: any) => item?.serviceType?.isPackage === 99
    );
    if (isPriceDropServiceExist) {
      setIsPriceDropServiceExist(true);
    } else {
      setIsPriceDropServiceExist(false);
    }

    if (cartItemsInContext.length > 0) {
      fn.getWalletData();
    }
  }, [cartItemsInContext]);

  // user tracking
  useEffect(() => {
    const user_activity = JSON.parse(localStorage.getItem("user_activity")!);
    if (cartItemsInContext?.length > 0) {
      if (isUserValid()) {
        const trackingTimeDiff = checkingTrackingTimeDifference();
        if (trackingTimeDiff) {
          // removing user activity after 6 hours
          window.localStorage.removeItem("user_activity");
          trackUserActivity({
            type: CART_TYPE,
            tempType: TEMP_CART_TYPE,
            area_id: localStorage.getItem("area_id")!,
            source: ANDROID,
          });
          window.localStorage.setItem(
            "tracking_cache_timestamp",
            JSON.stringify(moment())
          );
        } else {
          if (user_activity) {
            if (TEMP_CART_TYPE > user_activity?.tempType) {
              trackUserActivity({
                ...user_activity,
                type: CART_TYPE,
                tempType: TEMP_CART_TYPE,
                services: getItemsId(),
              });
            }
          } else {
            trackUserActivity({
              ...user_activity,
              type: CART_TYPE,
              tempType: TEMP_CART_TYPE,
              services: getItemsId(),
              area_id: localStorage.getItem("area_id")!,
              source: ANDROID,
            });
          }
        }
      }
    }
  }, []);

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
    ReactPixel.trackCustom("AddToCart", cartItemsToSendInFacebookPixel());
    gtm.track("view_cart", cartItemsToSendInGtm());

    localStorage.removeItem("walletDiscount");
  }, []);

  useEffect(() => {
    if (homepage_sections && Object.keys(homepage_sections)?.length > 0) {
      Object.keys(homepage_sections).forEach((key) => {
        if (homepage_sections[key]?.id === 63) {
          const amount = Math.round(
            (paymentDetails?.grandTotal *
              +homepage_sections[key]?.information) /
              100
          );

          if (amount > 200) {
            setInformation(200);
          } else {
            setInformation(amount);
          }
        }
      });
    }
  }, [homepage_sections, paymentDetails]);

  return (
    <>
      <div
        className="mobile cart_page"
        style={{
          backgroundColor: "var(--bg-color2)",
          paddingBottom: "67px",
        }}
      >
        <Helmet>
          <title>{CartPage.title}</title>
          <meta name="description" content={CartPage.description} />
          <meta name="keywords" content={CartPage.keywords} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <Header label="Your Cart" navigate={() => fn.handleClickOnBack()} />

        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "calc(100vh - 48px - 67px)" }}
          >
            <SpinLoader height="62" width="62" color="#AF031A" />
          </div>
        ) : (
          <>
            {checkItemsInCart()?.length > 0 ? (
              <>
                {/* get membership */}
                {!(
                  user?.isMember ||
                  cartItemsInContext?.some((item: any) => {
                    return item?.serviceType?.id === membershipId;
                  })
                ) && (
                  <div className="get_membership_wrapper d-flex justify-content-between align-items-center gap-4">
                    <p className="mb-0 font12x600 textColor1">
                      You could have saved Rs. 200 with Getlook Gold
                    </p>
                    <button
                      type="button"
                      className="font12x400 get_membership_btn text-white"
                      onClick={() => {
                        navigate("/membership");
                        fn.removeCartDetailsFromLocal();
                      }}
                    >
                      Get Membership
                    </button>
                  </div>
                )}
                {/* special offers section */}
                <SpecialOffers />

                {/* cart items */}
                <CartItem />

                {/* recommended upgrade section */}
                <RecommendedUpgrade />

                {/* recommended add on section */}
                <RecommendedAddOn />

                {/* instruction  section */}
                <Instruction />

                {/* membership */}
                {cartItemsInContext?.some((item: any) => {
                  return item?.serviceType?.id === membershipId;
                }) && <Membership />}

                {isUserValid() && (
                  <CouponSection
                    setAppliedCoupon={setAppliedCoupon}
                    state={commonData}
                    setState={setCommonData}
                    setPaymentDetails={setPaymentDetails}
                    walletInfo={walletInfo}
                  />
                )}

                {walletInfo?.balance > 0 ? (
                  <div className="wallet_discount_container cart_wallet_container d-flex align-items-center bg-white">
                    <div>
                      <input
                        onChange={(e) => {
                          if (appliedCoupon?.couponCode) {
                            showToast(
                              "Wallet balance cannot be combined with coupons",
                              "error"
                            );
                          } else if (paymentDetails?.grandTotal < 1500) {
                            showToast(
                              "Minimum amount to reedem the wallet is Rs.1500",
                              "error"
                            );
                          } else if (isPriceDropServiceExist) {
                            showToast(
                              "Wallet balance is not applicable with special offers",
                              "error"
                            );
                          } else {
                            fn.handleOnchangeWallet(e);
                          }
                        }}
                        id="wallet"
                        type="checkbox"
                        checked={walletInfo.isApplyWallet}
                      />
                      <label htmlFor="wallet"></label>
                    </div>
                    <div>
                      <label
                        htmlFor="wallet"
                        className="mb-0 font14x400 textColor1 ms-2 apply-wallet-discount-text"
                      >
                        Apply Wallet Discount?{" "}
                        {walletInfo.isWalletBalance == true ? (
                          `(₹${
                            walletInfo?.balance < 0
                              ? "0.00"
                              : walletInfo?.balance
                          })`
                        ) : (
                          <>
                            {walletInfo.isWalletBalance == false ? (
                              <span className="mb-0 font14x400 text-black">
                                {/* (No wallet){" "} */}
                                {`(₹${
                                  walletInfo?.balance < 0
                                    ? "0.00"
                                    : walletInfo?.balance
                                })`}
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        <button className="border-0 bg-white">
                          <BsInfoCircle
                            className="textColor1 cursor_pointer ms-2"
                            onClick={() =>
                              showToast(
                                <ul className="wallet-info m-0">
                                  <li className="m-0 text-start">
                                    Minimum amount to redeem the wallet is Rs.
                                    1500
                                  </li>
                                  <li className="m-0 text-start">
                                    Both Coupon and Wallet cannot be applied
                                  </li>
                                  <li className="m-0 text-start">
                                    If membership discount is availed, 50% of
                                    the wallet amount can be used
                                  </li>
                                </ul>,
                                "success"
                              )
                            }
                          />
                        </button>
                      </label>
                      {!walletInfo?.expiresData?.isUsed &&
                      walletInfo?.expiresData?.trxn_type == 1 ? (
                        <p className="font12x700 m-0 ms-2">
                          EXPIRES IN{" "}
                          {moment(walletInfo?.expiresData?.expiry_date).diff(
                            moment(),
                            "days"
                          )}{" "}
                          DAYS
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {information ? (
                  <div className="g-cash-section">
                    <div className="g-cash-hilight font14x600">
                      Get Assured Cashback{" "}
                    </div>
                    <div className="g-cash-container d-flex align-items-center gap-2">
                      <GiTwoCoins size={20} fill="#fed703" />
                      <div>
                        <p className="font14x600 m-0 mb-1">GCash</p>
                        <p className="font12x600 m-0 textColor2">
                          Get ₹{information} GCash post this booking! Grab now,
                          offer may end soon!
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* payment details */}
                <PaymentDetails paymentDetails={paymentDetails} />

                {/* grand total */}
                <GrandTotal
                  state={commonData}
                  setState={setCommonData}
                  paymentDetails={paymentDetails}
                />
              </>
            ) : (
              // for cart empty
              <div className="empty_cart_section_container mt-5 d-flex flex-column align-items-center justify-content-center">
                <CartEmpty />
                <p className="mt-3 font18x600">
                  Your cart is <span className="textColor1">empty!</span>
                </p>
                <p className="font14x500 text-center px-4">
                  We recommend you to browse through our popular categories
                </p>
                <button
                  type="button"
                  className="cart_empty_btn font14x400"
                  onClick={() => {
                    fn.handleClickOnBrowseCategories();
                  }}
                >
                  Browse categories
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* coupon applied */}
      {commonData?.modal && commonData?.check === "coupon_applied" && (
        <BottomTopAnimation>
          <CouponApplied setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default Cart;
