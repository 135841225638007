import toast, { Toaster, ToastBar } from "react-hot-toast";
import AllRoutes from "./routes";

const App = () => {

  return (
    <>
      <AllRoutes />

      <Toaster>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== "loading" && (
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "1px solid var(--border-color1)",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                      color: "var(--text-color3)",
                    }}
                    onClick={() => toast.dismiss()}
                  >
                    {icon}
                    {message}
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  );
};

export default App;
