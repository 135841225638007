import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getImageURL,
  getParentIdBySubCategoryId,
} from "../../../utils/function/function";
import { gtm, ReactPixel } from "../GTM";
import { BackIcon, SearchIcon } from "../Icons";
import PopularSearch from "./PopularSearch";

const Search = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [searchData, setSearchData] = useState<any>([]);
  const searchRef = useRef<any>(null);
  const navigate = useNavigate();

  const fn = {
    searchData: (value: string) => {
      // setting input value into state
      setInputValue(value);

      if (value === "") {
        setSearchData([]);
      } else {
        // data after searched
        const allServices = JSON.parse(
          localStorage.getItem("homepage_categories")!
        );

        let filterSubCategory: any = [];
        // for sub categories
        Object.keys(allServices).map((key: any) => {
          allServices[key]?.map((item: any) => {
            if (
              item?.serviceType?.category?.name
                ?.toLowerCase()
                ?.includes(value?.toLowerCase())
            ) {
              let { parentId, foundIn } = getParentIdBySubCategoryId(
                item?.serviceType?.category?.id?.toString()
              );

              filterSubCategory.push({
                name: item?.serviceType?.category?.name,
                imageURL: item?.serviceType?.category?.category_image,
                parentId,
                id: item?.serviceType?.category?.id,
                link:
                  foundIn === "parent"
                    ? `/services/${parentId}/${item?.serviceType?.category?.id}`
                    : `/products/${parentId}/${item?.serviceType?.category?.id}`,
                tag: "Sub Category",
              });
            }
          });
        });

        // getting unique sub category data
        const uniqueSubCategories = filterSubCategory.filter(
          (obj: any, index: number) => {
            return (
              index ===
              filterSubCategory.findIndex((o: any) => obj.name === o.name)
            );
          }
        );
        setSearchData(uniqueSubCategories);

        // for single service
        if (uniqueSubCategories?.length === 0) {
          let filterService: any = [];
          Object.keys(allServices).map((key: any) => {
            allServices[key]?.map((item: any) => {
              // only get services which are not free
              if (item.serviceType?.isPackage !== 100) {
                if (
                  item?.serviceType?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase())
                ) {
                  let { parentId } = getParentIdBySubCategoryId(
                    item?.serviceType?.category?.id?.toString()
                  );

                  filterService.push({
                    name: `${item?.serviceType?.category?.name} - ${
                      item?.serviceType.name?.split(":")[0]
                    }`,
                    imageURL: item?.serviceType?.image_1,
                    parentId,
                    subCategoryId: item?.serviceType?.category?.id,
                    id: item?.serviceType?.id,
                    link:
                      item?.serviceType?.update_services === "0"
                        ? `/products/${parentId}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
                        : `/services/${parentId}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
                    tag:
                      item?.serviceType?.update_services === "0"
                        ? "Product"
                        : "Service",
                  });
                }
              }
            });
          });

          setSearchData(filterService);
        }
      }
    },
  };

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div className="search_wrapper_sticky py-3">
          <div className="search_input_section">
            <BackIcon
              width="17"
              height="14"
              fill="black"
              clickHandler={() => navigate(-1)}
            />
            <input
              ref={searchRef}
              type="text"
              value={inputValue}
              placeholder="Search for salon services etc"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                fn.searchData(e.target.value)
              }
            />
            <SearchIcon />
          </div>
        </div>

        <div className="searched_data">
          {searchData?.map((item: any, i: number) => {
            return (
              <Link
                key={i}
                to={item?.link}
                className="textColor3 text-decoration-none"
              >
                <div className="d-flex align-items-center gap-2 p-2">
                  {item?.imageURL ? (
                    <img
                      className="border_radius_8"
                      src={getImageURL(item?.imageURL)}
                      alt="img"
                      width={48}
                      height={48}
                    />
                  ) : (
                    <img
                      className="border_radius_8"
                      src="/static/img/no-image.png"
                      alt="img"
                      width={48}
                      height={48}
                      style={{ objectFit: "cover" }}
                    />
                  )}
                  <div>
                    <p
                      className="mb-0 font14x400"
                      dangerouslySetInnerHTML={{
                        __html: item?.name?.replaceAll(
                          inputValue,
                          `<strong>${inputValue}</strong>`
                        ),
                      }}
                    />
                    <p className="mb-0 font12x400 textColor7">{item?.tag}</p>
                  </div>
                </div>
              </Link>
            );
          })}

          {/* for search results not found */}
          {searchData?.length === 0 && inputValue?.length > 0 && (
            <div>
              <h1 className="font14x500">No Data found</h1>
            </div>
          )}
        </div>

        <PopularSearch />
      </div>
    </>
  );
};

export default Search;
