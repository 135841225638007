import Skeleton from "react-loading-skeleton";

const ServiceLoader = () => {
  return (
    <>
      <div className="service_page_loader_container d-flex justify-content-between align-items-center">
        <div>
          <Skeleton width="160px" height="16px" borderRadius="8px" />
          <Skeleton width="50px" height="16px" borderRadius="8px" />
          <Skeleton width="150px" height="16px" borderRadius="8px" />
          <Skeleton width="160px" height="16px" borderRadius="8px" />
        </div>
        <div>
          <Skeleton width="96px" height="96px" borderRadius="8px" />
        </div>
      </div>
    </>
  );
};

export default ServiceLoader;
