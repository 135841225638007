import { useState } from "react";
import { AngleDownIcon } from "../Icons";
import { membershipFAQs } from "./constant";

const FAQs = () => {
  const [show, setShow] = useState<boolean>(false);
  const [whichFAQSelected, setWhichFAQSelected] = useState<number>();

  const fn = {
    openFAQ: (id: number) => {
      if (show && whichFAQSelected === id) {
        setShow(false);
      } else {
        setWhichFAQSelected(id);
        setShow(true);
      }
    },
  };

  return (
    <>
      <div className="g_gold_faq_wrapper">
        <h2 className="mb-0 font16x600 textColor3">FAQs</h2>
        {membershipFAQs?.map((item: any, i: number) => {
          return (
            <div
              key={i}
              className="single_faq cursor_pointer"
              onClick={() => fn.openFAQ(item?.id)}
            >
              <div className="d-flex justify-content-between gap-3">
                <div className="d-flex align-items-center gap-3">
                  <p className="mb-0 font14x600 textColor3">{item?.question}</p>
                </div>
                <i>
                  <AngleDownIcon width="14" height="14" fill="#AF031A" />
                </i>
              </div>
              {show && item?.id === whichFAQSelected && (
                <div className="g_gold_faq_desc_wrapper">
                  <p className="mb-0 font14x400 textColor3">{item?.answer}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FAQs;
