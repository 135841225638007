import React from "react";
import { BackIcon } from "../Icons";

type PropType = {
  label: string;
  navigate: () => void;
};

const Header: React.FC<PropType> = (props) => {
  const { label, navigate } = props;

  return (
    <>
      <div className="header">
        <div onClick={() => navigate()} className="back_icon">
          <BackIcon width="17" height="14" fill="#0F0F0F" />
        </div>
        <div className="w-100 text-center">
          <h1 className="mb-0 font16x600 textColor3">{label}</h1>
        </div>
      </div>
    </>
  );
};

export default Header;
