const DownloadButtons = () => {
  return (
    <>
      <div className="download_btn_wrapper">
        <div className="text-end">
          <h2 className="mb-0 font44x600 textColor3">
            Download GetLook app now
          </h2>
          <p className="mb-0 font24x400 textColor3">
            Get the best salon service at your home
          </p>
          <div className="download_btns">
            <a
              href="https://apps.apple.com/in/app/getlook-salon-at-home/id1205081417"
              className="d-inline-block"
              target="_blank"
            >
              <img src="/static/img/app_store_download.png" alt="app_store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=salon.look.ui&hl=en&gl=US"
              className="d-inline-block ms-3"
              target="_blank"
            >
              <img src="/static/img/play_store_download.png" alt="play_store" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadButtons;
