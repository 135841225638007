import { useContext } from "react";
import { Link } from "react-router-dom";
import { Auth } from "../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../utils/function/function";

const PopularSearch = () => {
  const { homepage_sections } = useContext(Auth);
  const popularSearches = filterHomepageSections(
    homepage_sections,
    "search"
  )?.sort((a: any, b: any) => {
    return a.category_order - b.category_order;
  });

  return (
    <>
      {popularSearches?.length > 0 && (
        <div className="p-3">
          <h2 className="mb-0 font16x700 ">Popular Searches</h2>
          <div className="mt-3 d-flex gap-3 flex-wrap">
            {popularSearches?.map((item: any, i: number) => (
              <Link
                key={i}
                to={`/services/${item?.items_required}`}
                className="single-popular-search font12x500"
              >
                <p className="mb-0">{item?.information}</p>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PopularSearch;
