import Api from "../../../config/api";
import { showToast } from "../../toast/showToast";
import { GET_SLOT } from "../../../utils/constant/constant";

export const getSlot = async (body: any) => {
  try {
    const res = await Api(false).post(GET_SLOT, body);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};
