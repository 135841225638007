import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { WorkingSteps } from "../../../../../components/pages/MyProfile/SubPages/ReferAndEarn/constant";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import Header from "../../../../../components/shared/Header";
import { Auth } from "../../../../../utils/context/AuthContext";
import { copyToClipboard } from "../../../../../utils/function/function";
import { ReferPage } from "../../../../../utils/metaInfo/metaInfo";

const msg =
  "Hey there! Download the GetLook App Now (https://onelink.to/ev25mg) and use the Code - MYREFER to save Rs.200 on your first booking. Enjoy the convenience and pamper yourself!";

const ReferAndEarn = () => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();

  // ad tracking
  useEffect(() => {

    window.scrollTo(0, 0);

    
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Helmet>
          <title>{ReferPage.title}</title>
          <meta name="description" content={ReferPage.description} />
          <meta name="keywords" content={ReferPage.keywords} />
        </Helmet>

        <Header label="Refer & Earn" navigate={() => navigate(-1)} />

        {/* refer options */}
        <div className="refer_earn_wrapper">
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="font16x700 textColor3">
                Refer and get Rs. 200 off
              </h2>
              <p className="w-75 mb-0 font10x400 textColor3">
                Invite your friends to try GetLook services. They will get
                instant Rs. 200 off and you get Rs. 200 off once they take a
                service
              </p>
            </div>
            <img src="/static/svg/gift-open.svg" alt="gift_open" />
          </div>

          <div className="refer_separator my-3 gap-3">
            <div></div>
            <p className="mb-0 font12x500 textColor3">Refer Now</p>
            <div></div>
          </div>

          <div className="refer_options_wrapper">
            <a
              href={`https://api.whatsapp.com/send/?text=${msg}`}
              target="_blank"
              className="single_option text-decoration-none"
            >
              <div className="single_option_img">
                <img src="/static/svg/whatsapp.svg" alt="whatsapp" />
              </div>
              <p className="mb-0 mt-2 font10x400 textColor3">Whatsapp</p>
            </a>

            <div
              className="single_option text-decoration-none"
              onClick={() => copyToClipboard(msg)}
            >
              <div className="single_option_img">
                <img src="/static/svg/copy-link.svg" alt="copy_link" />
              </div>
              <p className="mb-0 mt-2 font10x400 textColor3">Copy Link</p>
            </div>
          </div>
        </div>

        {/* working info */}
        <div className="working_info_wrapper">
          <div className="working_info_section">
            <h2 className="mb-0 font16x600 textColor3">How it works?</h2>
            <div className="mt-3 d-flex flex-column gap-3">
              {WorkingSteps?.map((item: any, i: number) => {
                return (
                  <div key={i} className="d-flex gap-2">
                    <p className="mb-0 working_step_number textColor3 font12x500 lh-base">
                      {i + 1}
                    </p>
                    <p className="mb-0 textColor3 font12x500 lh-base">{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* refer and earn footer */}
        <div
          className="text-center pt-5"
          style={{
            backgroundColor: "var(--bg-color2)",
          }}
        >
          <img src="/static/svg/refer-earn.svg" alt="refer" />
        </div>
      </div>
    </>
  );
};

export default ReferAndEarn;
