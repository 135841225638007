import React from "react";
import { commonType } from "../../../pages/main/type";
import { setOverflowToBodyUnset } from "../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const PreviousDues: React.FC<propType> = (props) => {
  const { state, setState } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper">
        <div className="modal_section">
          <p className="mt-3 font14x400 textColor3">
            Previous dues have been deducted. Check your wallet for details.
          </p>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="button"
              className="w-50 btn_login font14x400"
              onClick={fn.setPositionToBody}
            >
              Okay, Got it
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousDues;
