import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ProfileMenusSection1 from "../../../components/pages/MyProfile/ProfileMenusSection1";
import ProfileMenusSection2 from "../../../components/pages/MyProfile/ProfileMenusSection2";
import UserDetails from "../../../components/pages/MyProfile/UserDetails";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import Header from "../../../components/shared/Header";
import { MyProfilePage } from "../../../utils/metaInfo/metaInfo";

const MyProfile = () => {
  const navigate = useNavigate();

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "var(--bg-color2)",
        }}
      >
        <Helmet>
          <title>{MyProfilePage.title}</title>
          <meta name="description" content={MyProfilePage.description} />
          <meta name="keywords" content={MyProfilePage.keywords} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>

        <Header label="My Profile" navigate={() => navigate("/")} />

        <UserDetails />

        <ProfileMenusSection1 />

        <ProfileMenusSection2 />

        {/* version info hidden for web */}
        <div className="version_info_wrapper text-center py-3">
          <img src="/static/svg/logo.svg" alt="svg" width={80} />
          <p className="mb-0 mt-2 font10x400 textColor2">v2.5.2</p>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
