import { useContext } from "react";
import { Auth } from "../../../../../utils/context/AuthContext";
import HeroSectionBanner from "./Banner";
import HeroSectionLocation from "./Location";

const HeroSection = () => {
  return (
    <>
      <div className="hero_section_wrapper">
        <HeroSectionBanner />

        <HeroSectionLocation />
      </div>
    </>
  );
};

export default HeroSection;
