/**
 * @author Deepanshu Tyagi
 */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import Header from "../../../components/shared/Header";
import "./index.css";

const ConfirmBooking = () => {
  const navigate = useNavigate();

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="confirm_booking_page">
          <Header label="" navigate={() => navigate(-1)} />

          <div className="confirm_booking_image_container text-center">
            <img src="/static/img/confirmbooking.png" />
          </div>

          <div className="confirm_booking_section">
            <div className="text-center">
              <h1 className="font20x700 mb-0">Booking Placed</h1>
            </div>

            <div className="confirm_booking_text text-center">
              <h1 className="font20x700 mb-0">Successfully!</h1>
            </div>

            <div className="text-center">
              <p className="font12x400 mb-0">
                Payment ID: 28473, 26 Sep, 2022 - 11:55pm
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmBooking;
