import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Auth } from "../../../utils/context/AuthContext";
import { membershipId } from "../../../utils/constant/constant";
import { getServiceById } from "../../../utils/function/function";
import { useNavigate } from "react-router-dom";

const MembershipInfo = () => {
  const navigate = useNavigate();

  const { user, cartItemsInContext, setCartItemsInContext } = useContext(Auth);
  const [membership, setMembership] = useState<any>({});
  const cartItems = JSON.parse(localStorage.getItem("cartItems")!);

  const fn = {
    getMembershipById: () => {
      const membership = getServiceById(membershipId);
      setMembership(membership);

      // check membership present or not in cart and user membered or not if not then it call fn
      // let membershipExit = cartItems?.some((cartItem: any) => {
      //   return cartItem?.serviceType?.id === membershipId;
      // });

      // if (!(user?.isMember || membershipExit)) {
      //   fn.handleClickOnAdd(membership);
      // }
    },
    handleClickOnAdd: (item: any) => {
      const paymentDetailsList =
        JSON.parse(`${localStorage.getItem("paymentDetails")}`) || [];
      const serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      if (item.id == "620") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "546") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "547") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "548") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "538") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "521") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "605") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "497") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else {
        item.count = 1;
        item.added = true;
        serviceList.push(item);
        setCartItemsInContext((prev: any) => {
          return [...prev, item];
        });
        localStorage.setItem("cartItems", JSON.stringify(serviceList));
      }
    },
    removeMembershipFromCart: (service: any, isRedirect: boolean) => {
      const cartItems =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      const filteredItems = cartItems?.filter(
        (item: any) => item?.serviceType?.id !== service?.serviceType?.id
      );
      setCartItemsInContext(filteredItems);
      localStorage.setItem("cartItems", JSON.stringify(filteredItems));

      // redirect user to cart when user click on "i will pay full price"
      if (isRedirect) {
        navigate("/cart", { state: { previousURL: "/membership" } });
      }
    },
  };

  useEffect(() => {
    fn.getMembershipById();
  }, []);

  return (
    <>
      <div className="membership_info_wrapper">
        <div className="membership_info_section">
          <div className="w-50 py-2 px-4 membership_active_inactive">
            <p className="mb-2 font14x400 textColor3">MEMBERSHIP</p>
            {user?.isMember ? (
              <p className="mb-0 font14x600 textColor11">ACTIVE</p>
            ) : (
              <p className="mb-0 font14x600 textColor1">EXPIRED</p>
            )}
          </div>
          {user?.isMember ? (
            <div className="w-50 py-2 px-4">
              <p className="mb-2 text-end font14x400 textColor3">VALID TILL</p>
              <p className="mb-0 text-end font14x600 textColor3">
                {moment(user?.membershipActivationDate)
                  .add(user?.membershipDuration, "days")
                  .format("DD MMM YYYY")}
              </p>
            </div>
          ) : (
            <div className="w-50 d-flex align-items-center justify-content-center border-0 ">
              {cartItemsInContext?.find((cartItem: any) => {
                return (
                  cartItem?.serviceType?.id === membership?.serviceType?.id
                );
              }) ? (
                <button
                  className="font14x500 remove_button textColor1 d-flex align-items-center justify-content-center"
                  onClick={() => {
                    fn.removeMembershipFromCart(membership, false);
                  }}
                >
                  Remove
                </button>
              ) : (
                <button
                  className="font14x500 remove_button textColor1 d-flex align-items-center justify-content-center"
                  onClick={() => {
                    fn.handleClickOnAdd(membership);
                  }}
                >
                  Add
                </button>
              )}
   
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MembershipInfo;
