const BeauticianImage = () => {
  return (
    <>
      <div className="beautician_wrapper">
        <img
          src="/static/img/beautician.png"
          className="img-fluid"
          alt="beautician"
        />
      </div>
    </>
  );
};

export default BeauticianImage;
