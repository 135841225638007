import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import InputError from "../../../components/shared/InputError/InputError";
import InputField from "../../../components/shared/InputField";
import InputMobile from "../../../components/shared/InputMobile";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import ProfilePhoto from "../../../components/shared/ProfilePhoto";
import useLoading from "../../../hooks/useLoading";
import { showToast } from "../../../lib/toast/showToast";
import { userSchema } from "../../../validation/validation";
import { UserData } from "../../main/myProfile/subPages/viewProfile/type";
import { useContext, useEffect } from "react";
import { Auth } from "../../../utils/context/AuthContext";
import moment from "moment";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import { USER_PROFILE } from "../../../utils/constant/constant";
import axios from "axios";

const ProfileSetUp = () => {
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const { setUser, getUserAddresses, token, setCartItemsInContext } =
    useContext(Auth);
  const { state } = useLocation();

  const initialValues: UserData = {
    name: state?.user?.first_name?.trim(),
    email: "",
    phone: state?.user?.phone,
    birth_date: state?.user?.birth_date,
    alternate_phone: state?.user?.alternate_phone,
  };

  const formik = useFormik<UserData>({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      let finalObj = {
        first_name: values.name,
        email: state?.user.email === values.email ? undefined : values.email,
        phone: state?.user.phone,
        birth_date: values.birth_date,
        alternate_phone: values.alternate_phone,
      };

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}${USER_PROFILE}`,
          finalObj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + token,
            },
          }
        );

        if (res) {
          if (res?.data?.data?.success) {
            window.localStorage.setItem("token", token);
            window.sessionStorage.removeItem("phone");
            getUserAddresses();
            setUser({ ...finalObj, ...res?.data?.data });
            window.localStorage.setItem(
              "user",
              JSON.stringify({ ...finalObj, ...res?.data?.data })
            );

            // updation of cart
            if (state?.serviceTobeAdded) {
              setCartItemsInContext((prev: any) => {
                return [...prev, state?.serviceTobeAdded[0]];
              });
              localStorage.setItem(
                "cartItems",
                JSON.stringify(state?.serviceTobeAdded)
              );
            }

            // check if previous URL exist or not
            if (state?.previousURL) {
              navigate(state?.previousURL, { replace: true });
            } else {
              navigate("/", { replace: true });
            }
            showToast(`Profile updated`, "success");
            hideLoader();
          } else {
            showToast(res?.data?.data, "error");
          }
        }
      } catch (error: any) {
        if (error.response.status >= 400 && error.response.status <= 499) {
          showToast(`${error.response.statusText}`, "error");
        } else {
          showToast(`${error.response.data.message}`, "error");
        }
      }
    },
  });

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/otp-login", { replace: true });
    }
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="profile_setup_wrapper">
          <div className="profile_setup_header">
            <h1 className="mb-0 font16x500 textColor3">Set up Profile</h1>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="profile_setup_content">
              <ProfilePhoto />

              <div className="mb-3">
                <InputField
                  id="name"
                  label="Name*"
                  type="text"
                  state={formik.values.name}
                  setState={(e) => {
                    formik.setFieldValue("name", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                />
                {formik.touched.name && formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>

              <div className="mb-3">
                <InputMobile
                  id="phone"
                  label="Mobile No.*"
                  state={formik.values.phone}
                  setState={(e) => {
                    let isString = e.target.value.match(/[A-z]/g);
                    if (isString) {
                      showToast("Only number is allowed", "error");
                    } else {
                      formik.setFieldValue("phone", e.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  isDisabled={true}
                  max={10}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <InputError error={formik.errors.phone} />
                ) : null}
              </div>

              <div className="mb-3">
                <InputField
                  id="email"
                  label="Email Id*"
                  type="text"
                  state={formik.values.email}
                  setState={(e) => {
                    formik.setFieldValue("email", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                />
                {formik.touched.email && formik.errors.email ? (
                  <InputError error={formik.errors.email} />
                ) : null}
              </div>

              <div className="mb-3">
                <InputField
                  id="birth_date"
                  label="Your Birthday"
                  type="date"
                  state={formik.values.birth_date}
                  setState={(e) => {
                    formik.setFieldValue("birth_date", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                  max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                />
                {formik.touched.birth_date && formik.errors.birth_date ? (
                  <InputError error={formik.errors.birth_date} />
                ) : null}
                <p className="mb-0 mt-3 font10x400 textColor2">
                  (Please provide your correct birthday and year. We have free
                  services and offers on Birthdays)
                </p>
              </div>

              <div className="mb-3">
                <InputMobile
                  id="alternate_phone"
                  label="Alternate No."
                  state={formik.values.alternate_phone}
                  setState={(e) => {
                    let isString = e.target.value.match(/[A-z]/g);
                    if (isString) {
                      showToast("Only number is allowed", "error");
                    } else {
                      formik.setFieldValue("alternate_phone", e.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  max={10}
                />
                {formik.touched.alternate_phone &&
                formik.errors.alternate_phone ? (
                  <InputError error={formik.errors.alternate_phone} />
                ) : null}
              </div>
            </div>

            <button type="submit" className="font14x400 btn_full_width">
              {loading ? (
                <SpinLoader height="22" width="22" color="#fff" />
              ) : (
                "Proceed"
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProfileSetUp;
