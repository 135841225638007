import { useContext, useEffect, useState } from "react";
import { showToast } from "../../../../../../../lib/toast/showToast";
import { packageIds } from "../../../../../../../utils/constant/constant";
import { Auth } from "../../../../../../../utils/context/AuthContext";
import {
  checkItemType,
  getImageURL,
  getServiceById,
  randomSortData,
  setOverflowToBodyHidden,
} from "../../../../../../../utils/function/function";
import { ClockIcon } from "../../../../../../shared/Icons";
import { commonType } from "../../../../../../../pages/main/type";
import ViewDetails from "../../../../../../shared/ViewServiceDetails";
import BottomTopAnimation from "../../../../../../shared/BottomTopAnimation";
import { useLocation } from "react-router-dom";
import { rescheduleOrder } from "../../../../../../../lib/api functions/order/order";
import SpinLoader from "../../../../../../shared/Loader/SpinLoader";

const RecommendedAddOn = ({ orderDetails }: any) => {
  const { cartItemsInContext, setCartItemsInContext } = useContext(Auth);
  const [recommendedServices, setRecommendedServices] = useState<any>([]);
  const [loading, setShowLoader] = useState<any>();
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const appliedCoupon =
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;

  const { state } = useLocation();

  const fn = {
    getRecommendedServices: () => {
      let tempService: any = [];

      orderDetails?.appointmentitem_set?.map((item: any) => {
        return (
          !item?.update &&
          item?.service?.serviceType?.update_services &&
          item?.service?.serviceType?.update_services !== "0" &&
          JSON.parse(item?.service?.serviceType?.update_services)?.map(
            (item2: any) => {
              // check add on service is active or not
              let service = getServiceById(item2?.id);
              return (
                Object.keys(service)?.length > 0 &&
                item2?.header?.split(" ")[0] === "Add" &&
                tempService.push({
                  previousServiceId: item?.service?.serviceType?.id,
                  serviceType: item2,
                  serviceImage: service?.serviceType?.image_1,
                })
              );
            }
          )
        );
      });

      // random sorting
      const randomSortTempServices = randomSortData(tempService);
      setRecommendedServices(randomSortTempServices);
    },
    upgradeCart: async (service: any) => {
      setShowLoader(service?.serviceType?.id);
      const finajObj = {
        create_type: "modify",
        id: orderDetails?.id,
        service: service?.serviceType,
      };

      try {
        const res: any = await rescheduleOrder(finajObj);
        if (res) {
          showToast(`${res?.data?.msg}`, "success");
          // navigate("/order");
          setShowLoader("");
        }
      } catch (err) {
        setShowLoader("");
      }

      // let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      // let serviceData: any = getServiceById(service?.serviceType?.id);

      // //for package

      // // checking service already present in the cart or not
      // if (
      //   cartItems?.some((item: any) => {
      //     return item?.serviceType?.id === service?.serviceType?.id;
      //   })
      // ) {
      //   showToast("This service is already present in the cart", "error");
      // } else {
      //   let itemType = checkItemType(serviceData, service);
      //   if (itemType === "") {
      //     showToast("Unknown Add On", "error");
      //   } else {
      //     serviceData[itemType] = true;
      //   }

      //   // added service in the cart
      //   serviceData.count = 1;
      //   serviceData.added = true;
      //   serviceData.update = true;
      //   serviceData.previousServiceId = service?.previousServiceId;
      //   cartItems.push(serviceData);
      //   setCartItemsInContext((prev: any) => {
      //     return [...prev, serviceData];
      //   });

      //   // for special instructions (packages)
      //   if (packageIds.includes(service?.previousServiceId)) {
      //     cartItems?.map((item: any) => {
      //       if (item?.serviceType?.id === service?.previousServiceId) {
      //         item["special_instructions"].push({
      //           id: service?.serviceType?.id,
      //           header: service?.serviceType?.header,
      //           name: service?.serviceType?.name,
      //         });
      //       }
      //       return item;
      //     });
      //   }

      //   // removing free service from cart
      //   cartItems = cartItems.filter((serviceItem: any) => {
      //     if (
      //       serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
      //       appliedCoupon?.type == "Service"
      //     ) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });

      //   localStorage.setItem("cartItems", JSON.stringify(cartItems));
      //   setCartItemsInContext(cartItems);

      //   // removing coupon on add on
      //   localStorage.removeItem("coupon_applied");
      //   localStorage.removeItem("conveyanceCharge");
      //   localStorage.removeItem("slotInfo");
      //   localStorage.removeItem("previousDues");
      // }
      // }
    },
    undoCart: (service: any) => {
      let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

      // remove added service item from cart
      let filteredItems = cartItems.filter(
        (item: any) => item?.serviceType?.id !== service?.serviceType?.id
      );

      // remove special instructions if exist
      if (packageIds.includes(service?.previousServiceId)) {
        filteredItems = filteredItems?.map((item: any) => {
          if (item?.serviceType?.id === service?.previousServiceId) {
            const filteredIns = item?.special_instructions?.filter(
              (insObj: any) => {
                return service?.serviceType?.id !== insObj.id;
              }
            );
            item.special_instructions = filteredIns;
          }
          return item;
        });
      }

      // removing free service from cart
      filteredItems = filteredItems.filter((serviceItem: any) => {
        if (
          serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
          appliedCoupon?.type == "Service"
        ) {
          return false;
        } else {
          return true;
        }
      });

      setCartItemsInContext(filteredItems);
      localStorage.setItem("cartItems", JSON.stringify(filteredItems));

      // removing coupon on add on
      localStorage.removeItem("coupon_applied");
      localStorage.removeItem("conveyanceCharge");
      localStorage.removeItem("slotInfo");
      localStorage.removeItem("previousDues");
    },
    handleClickOnImage: (serviceId: any) => {
      const serviceData = getServiceById(serviceId);
      setCommonData({
        data: serviceData,
        modal: true,
        check: "view_details",
      });
      setOverflowToBodyHidden();
    },
  };

  useEffect(() => {
    fn.getRecommendedServices();
  }, []);

  useEffect(() => {
    if (state?.divId) {
      let rating = document.getElementById(state?.divId);
      rating?.scrollIntoView({ block: "center" });
    }
  }, [recommendedServices]);

  return (
    <>
      {recommendedServices?.length > 0 && (
        <div
          id="upgrades2"
          className="recommendation_wrapper recommendation_wrapper-order-details"
        >
          <h2 className="mb-0 font16x700">Frequently added together</h2>
          <div className="recommendation_wrapper_scroll">
            <div className="recommendation_section mt-3">
              {recommendedServices?.map((item: any, i: number) => {
                return (
                  <>
                    <div className="cart-upgrade-service-container">
                      {item?.serviceImage ? (
                        <img
                          className="cart-upgrade-service-container-img"
                          width={150}
                          height={150}
                          src={getImageURL(item?.serviceImage)}
                          alt="recommend_service"
                          onClick={() => {
                            fn.handleClickOnImage(item.serviceType.id);
                          }}
                        />
                      ) : (
                        <img
                          className="cart-upgrade-service-container-img"
                          width={150}
                          height={150}
                          src="/static/img/no-image.png"
                          alt="recommend_service"
                          onClick={() => {
                            fn.handleClickOnImage(item.serviceType.id);
                          }}
                        />
                      )}
                      <div className="best-selling-package-info-container cart-upgrade-service-infor-container">
                        <div>
                          <p className="mb-1 textColor3 font12x600">
                            {item?.serviceType?.name}
                          </p>

                          <p className="font12x500 textColor7 d-flex align-items-center gap-1 mb-0 mt-2">
                            <ClockIcon />
                            {`${
                              getServiceById(item?.serviceType?.id)?.serviceType
                                ?.time
                            } mins`}
                          </p>
                          <div className="best-selling-package-price-container mt-2">
                            <div>
                              <div className="d-flex gap-2 align-items-center">
                                <p className="mb-0 font12x500 textColor3">{`₹${
                                  getServiceById(+item?.serviceType?.id)?.cost
                                }`}</p>
                                {item?.serviceType?.mrp && (
                                  <p className="mb-0 font12x400 text-decoration-line-through textColor7">
                                    {`₹${item?.serviceType?.mrp}`}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          {/* {cartItemsInContext?.find((cartItem: any) => {
                            return (
                              cartItem?.serviceType?.id ===
                                item?.serviceType?.id && cartItem?.update
                            );
                          }) ? (
                            <button
                              className="w-100 font14x600 add_button textColor1 d-flex align-items-center justify-content-center"
                              onClick={() => fn.undoCart(item)}
                            >
                              Undo
                            </button>
                          ) : ( */}
                          <button
                            className="w-100 font14x600 add_button textColor1 d-flex align-items-center justify-content-center"
                            onClick={() => fn.upgradeCart(item)}
                            disabled={loading === item?.serviceType?.id}
                          >
                            {loading === item?.serviceType?.id ? (
                              <SpinLoader
                                height="16"
                                width="16"
                                color="var(--bg-color1)"
                              />
                            ) : (
                              "ADD+"
                            )}
                          </button>
                          {/* // )} */}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* view details */}
      {commonData?.modal && commonData?.check === "view_details" && (
        <BottomTopAnimation>
          <ViewDetails state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default RecommendedAddOn;
