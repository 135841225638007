import { Link, useNavigate } from "react-router-dom";
import { Download_APP } from "../../../components/shared/Icons";
import { isUserValid } from "../../../utils/function/function";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="mobile">
        <div className="landing_page_wrapper">
          <div className="landing_page_images position-relative">
            <img
              src="/static/img/getlook-web-first.png"
              className="w-100"
              alt="landing_img"
            />
            <img
              src="/static/img/getlook-feature.png"
              className="w-100"
              alt="getlook_feature"
            />
            <div className="download_app d-flex align-items-center gap-1">
              <a
                href="https://onelink.to/ev25mg"
                target="_blank"
                className="mb-0 text-decoration-none font16x600 textColor1"
              >
                Download APP
              </a>
              <Download_APP />
            </div>
          </div>
          <div className="landing_page_footer text-center">
            <button
              onClick={() => navigate("/", { replace: true })}
              className="landing_page_btn"
            >
              Book your Services
            </button>
            {!isUserValid() && (
              <p className="font14x400 textColor3">
                Have an account?{" "}
                <Link
                  className="text-decoration-none font14x600 textColor1"
                  to="/otp-login"
                >
                  Login
                </Link>
              </p>
            )}
            <p className="mb-0 font14x400 textColor3">
              We are available in <span className="font14x600">10+ cities</span>
            </p>
          </div>
          <div className="landing_page_doodle">
            <img
              src="/static/img/doodle.png"
              className="w-100"
              alt="getlook_feature"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
