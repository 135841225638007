export const SafetyStandard = [
  {
    image: "/static/svg/hand-sanitizer.svg",
    label: "Sanitized kits & beauticians",
  },
  {
    image: "/static/svg/thermometer.svg",
    label: "Temperature check",
  },
  {
    image: "/static/svg/syringe.svg",
    label: "Vaccinated Beauticians",
  },
  {
    image: "/static/svg/skincare.svg",
    label: "One-Time use products",
  },
];
