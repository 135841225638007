import { useContext, useEffect, useState } from "react";
import { Auth } from "../../../../utils/context/AuthContext";
import {
  filterHomepageSections,
  getImageURL,
  getServiceById,
  returnGrandTotal,
  setOverflowToBodyHidden,
} from "../../../../utils/function/function";
import { BsInfoCircle } from "react-icons/bs";
import { showToast } from "../../../../lib/toast/showToast";
import { IoMdLock } from "react-icons/io";
import { IoMdUnlock } from "react-icons/io";
import { commonType } from "../../../../pages/main/type";
import BottomTopAnimation from "../../../shared/BottomTopAnimation";
import PriceDropServiceInfoModal from "./PriceDropInfo";

const SpecialOffers = () => {
  const { homepage_sections, cartItemsInContext, setCartItemsInContext } =
    useContext(Auth);
  const fetchedSpecialOffers = filterHomepageSections(
    homepage_sections,
    "special"
  );
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
    data: undefined,
  });
  const [specialOffers, setSpecialOffers] = useState<any>([]);

  const fn = {
    getSpecialOffers: () => {
      setSpecialOffers([]);
      let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

      // checking special offer service exist or not in cart for sub category and service
      const filteredSpecialOffers = fetchedSpecialOffers.filter(
        (specialOffer: any) => {
          // return false
          let check = true;
          let ids = specialOffer?.stylist_information?.split(",");
          cartItems?.forEach((cartItem: any) => {
            if (
              ids?.includes(String(cartItem?.serviceType?.category?.id)) ||
              ids?.includes(String(cartItem?.serviceType?.id))
            ) {
              check = false;
            }
          });
          return check;
        }
      );

      // add extra variables in special offers
      let tempOffers: any = [];

      filteredSpecialOffers?.forEach((specialOffer: any) => {
        return tempOffers.push({
          ...specialOffer,
          isDisabled: fn.checkSpecialOfferServiceUnlockButton(specialOffer),
        });
      });

      // sort SpecialOffers according to worth price
      tempOffers.sort((a: any, b: any) => {
        return +a?.information - +b?.information;
      });

      setSpecialOffers(tempOffers);
    },
    handleUnlockButtonClick: (offerService: any) => {
      let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      let isAddSpecialService = false;
      cartItems.forEach((specialService: any) => {
        if (specialService?.isSpecialService) {
          isAddSpecialService = true;
        }
      });

      if (isAddSpecialService) {
        showToast("You can select only one special offer at a time", "error");
      } else {
        let serviceData: any = getServiceById(+offerService?.items_required);
        let tempCartItems = [...cartItems];
        serviceData.count = 1;
        serviceData.added = true;
        serviceData.update = true;
        serviceData.isSpecialService = true;
        serviceData.type = "special_offer";
        serviceData.worthPrice = +offerService?.information;
        // serviceData.parentId = +offerService?.items_required?.split(",")[0];
        tempCartItems.push(serviceData);
        setCartItemsInContext(tempCartItems);
        localStorage.setItem("cartItems", JSON.stringify(tempCartItems));

        // removing coupon
        localStorage.removeItem("coupon_applied");
      }
    },
    handleUndoButtonClick: (offerService: any) => {
      const cartItems =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      const filteredCart = cartItems.filter(
        (item: any) => item?.serviceType?.id !== +offerService?.items_required
      );
      setCartItemsInContext(filteredCart);
      localStorage.setItem("cartItems", JSON.stringify(filteredCart));
    },
    checkSpecialOfferServiceUnlockButton: (item: any): boolean => {
      let check = false;
      let grandTotal = returnGrandTotal();
      if (+item?.information > +grandTotal) {
        check = true;
      }
      return check;
    },
    getSpecialOfferInfo: (item: any) => {
      if (+item?.information > returnGrandTotal()) {
        return (
          <p className="font12x500 mb-0 d-flex align-items-center gap-2">
            <div className="lock-container">
              <IoMdLock />
            </div>
            {`Shop for Rs.${
              item?.information - returnGrandTotal()
            } more to unlock`}
            {getServiceById(+item?.items_required)?.serviceType?.isPackage ==
              99 &&
              getServiceById(+item?.items_required)?.serviceType
                ?.cost_promotion && (
                <i>
                  <BsInfoCircle
                    className="cursor_pointer ms-1"
                    onClick={() =>
                      fn.openModal("priceDropInfo", {
                        parentId: getServiceById(+item?.items_required)
                          ?.serviceType?.cost_promotion,
                      })
                    }
                  />
                </i>
              )}
          </p>
        );
      } else {
        const diffrence =
          getServiceById(+item?.items_required)?.serviceType?.cost -
          getServiceById(+item?.items_required)?.cost;
        let percent = Math.floor(
          (diffrence /
            getServiceById(+item?.items_required)?.serviceType?.cost) *
            100
        );
        return (
          <p className="font12x500 mb-0 d-flex gap-2 align-items-center">
            <div className="lock-container">
              <IoMdUnlock />
            </div>

            {`Yay!! Get ${percent}% off on this`}
            {getServiceById(+item?.items_required)?.serviceType?.isPackage ==
              99 &&
              getServiceById(+item?.items_required)?.serviceType
                ?.cost_promotion && (
                <i>
                  <BsInfoCircle
                    className="cursor_pointer ms-1"
                    onClick={() =>
                      fn.openModal("priceDropInfo", {
                        parentId: getServiceById(+item?.items_required)
                          ?.serviceType?.cost_promotion,
                      })
                    }
                  />
                </i>
              )}
          </p>
        );
      }
    },
    openModal: (check?: string, data?: any) => {
      setCommonData({
        modal: true,
        check: check,
        data,
      });
      setOverflowToBodyHidden();
    },
  };

  useEffect(() => {
    fn.getSpecialOffers();
  }, [cartItemsInContext]);

  return (
    <>
      {fetchedSpecialOffers?.length > 0 && specialOffers?.length > 0 && (
        <div className="recommendation_wrapper special-offers-section mt-2 mb-0">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0 font14x700">Special offers for you</h2>
            <p className="mb-0 font14x500 textColor1">{`${
              specialOffers?.filter((item: any) => item?.isDisabled === false)
                ?.length
            }/${specialOffers?.length} Unlocked`}</p>
          </div>

          <div className="recommendation_wrapper_scroll">
            <div className="recommendation_section mt-3">
              {specialOffers?.map((item: any, i: number) => (
                <div className="special-offer-container">
                  <div key={i} className="single_special_offer">
                    <div className="d-flex align-items-center special_offer_details_wrapper">
                      {item?.category_image ? (
                        <img
                          src={getImageURL(item?.category_image)}
                          alt="special_offer_service"
                          width={50}
                          height={50}
                        />
                      ) : (
                        <img
                          src="/static/img/no-image.png"
                          alt="special_offer_service"
                          width={50}
                          height={50}
                        />
                      )}
                      <div className="special_offer_details">
                        <p className="mb-0 textColor3 font10x600">
                          {item?.name}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex gap-2 align-items-center">
                            {getServiceById(+item?.items_required)?.cost ===
                            0 ? (
                              <p className="mb-0 font12x500 textColor3">FREE</p>
                            ) : (
                              <p className="mb-0 font12x500 textColor3">
                                {getServiceById(+item?.items_required)?.cost}
                              </p>
                            )}

                            <p className="mb-0 font10x400 text-decoration-line-through textColor7">
                              {/* {`₹${item?.information?.split(",")[1]}`} */}
                              {
                                getServiceById(+item?.items_required)
                                  ?.serviceType?.cost
                              }
                            </p>
                          </div>
                          {cartItemsInContext?.find((cartItem: any) => {
                            return (
                              cartItem?.serviceType?.id ===
                                +item?.items_required &&
                              cartItem?.update &&
                              cartItem?.type === "special_offer"
                            );
                          }) ? (
                            <button
                              className="font12x600 special_offer_unlock_button textColor1 d-flex align-items-center justify-content-center"
                              onClick={() => {
                                fn.handleUndoButtonClick(item);
                              }}
                            >
                              Undo
                            </button>
                          ) : (
                            <button
                              className="font12x600 special_offer_unlock_button textColor1 d-flex align-items-center justify-content-center"
                              disabled={item?.isDisabled}
                              onClick={() => fn.handleUnlockButtonClick(item)}
                            >
                              {item?.isDisabled ? "Unlock" : "Add"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="special_offer_text">
                      {/* <p className="font12x500 mb-0">
                      {+item?.information?.split(",")[2] > returnGrandTotal()
                        ? `Shop for Rs.${
                            item?.information?.split(",")[2] -
                            returnGrandTotal()
                          } to unclock special price`
                        : "Yay!! You just unlocked the offer"}
                    </p> */}
                      {fn.getSpecialOfferInfo(item)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* safety & hygiene fee modal */}
      {commonData?.modal && commonData?.check === "priceDropInfo" && (
        <BottomTopAnimation>
          <PriceDropServiceInfoModal
            state={commonData}
            setState={setCommonData}
          />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default SpecialOffers;
