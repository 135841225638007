import { useNavigate } from "react-router-dom";
import { commonType } from "../../../pages/main/type";
import {
  getServiceById,
  setOverflowToBodyUnset,
} from "../../../utils/function/function";
import { CloseIcon } from "../Icons";
import { useContext } from "react";
import { Auth } from "../../../utils/context/AuthContext";
import { showToast } from "../../../lib/toast/showToast";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const PriceDropServiceWeekendModal: React.FC<propType> = (props) => {
  const { state, setState } = props;
  const { cartItemsInContext, setCartItemsInContext } = useContext(Auth);
  const navigate = useNavigate();

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
    handleGoBackToCart: () => {
      navigate("/cart", { replace: true });
      fn.setPositionToBody();
    },
    handleUpdateThePrice: () => {
      let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      try {
        // fetch all original service ids of price drop services
        const priceDropServicesParentIds: number[] = [];
        cartItems?.map((item: any) => {
          if (item?.serviceType?.isPackage === 99) {
            priceDropServicesParentIds.push(item?.serviceType?.cost_promotion);
          }
        });

        // fetch original services data from above ids
        let tempOriginalServiceData: any = [];
        priceDropServicesParentIds?.map((item: number) =>
          tempOriginalServiceData.push({
            count: 1,
            added: true,
            isService: true,
            ...getServiceById(item),
          })
        );

        // removed price drop service from cart
        const filteredCart = cartItems?.filter(
          (item: any) => item?.serviceType?.isPackage !== 99
        );

        // update the cart with original services
        const finalCart = [...filteredCart, ...tempOriginalServiceData];
        setCartItemsInContext(finalCart);
        localStorage.setItem("cartItems", JSON.stringify(finalCart));

        fn.setPositionToBody();
        showToast("All price drop services price updated", "success");
      } catch (error) {
        showToast("Something went wrong", "error");
      }
    },
  };

  return (
    <>
      <div
        className="view_details_page"
        style={{
          gridTemplateRows: "auto max-content",
        }}
      >
        {/* cancel */}
        <div className="cancel-container">
          <button
            onClick={fn.setPositionToBody}
            className="d-flex align-items-center justify-content-center bg-black text-white border-0"
          >
            <CloseIcon width="20" height="20" fill="#fff" />
          </button>
        </div>

        <div className="payment_methods_wrapper">
          <div className="payment_methods_container bg-white">
            <div className="pb-3">
              <p className="font12x600">
                <span className="textColor1">Note: </span>The prices of selected
                services are valid only for weekdays
              </p>
            </div>
            <h2 className="payment_methods_text mb-4 font16x700">
              All service/s you have added
            </h2>
            {/* <div className="d-flex flex-column gap-3">
              <div className="d-flex gap-3 justify-content-end">
                <p className="mb-2 font12x600 textColor3">Weekdays</p>
                <p className="mb-2 font12x600 textColor3">Weekends</p>
              </div>
              {cartItemsInContext?.map((item: any, i: number) => {
                if (item?.serviceType?.isPackage === 99) {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-between gap-3"
                    >
                      <p className="mb-0 font14x600 textColor3">
                        {item?.serviceType?.name}
                      </p>
                      <div className="d-flex gap-2">
                        <p className="mb-0 font14x400 text-decoration-line-through textColor7">
                          ₹
                          {
                            getServiceById(item?.serviceType?.cost_promotion)
                              ?.serviceType?.cost
                          }
                        </p>

                        <p className="mb-0 font16x700 textColor3">
                          ₹
                          {
                            getServiceById(item?.serviceType?.cost_promotion)
                              ?.cost
                          }
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div> */}
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" className="font12x600 textColor3">
                    Weekdays
                  </th>
                  <th scope="col" className="font12x600 textColor3">
                    Weekends
                  </th>
                </tr>
              </thead>
              <tbody>
                {cartItemsInContext?.map((item: any, i: number) => {
                  if (item?.serviceType?.isPackage === 99) {
                    return (
                      <tr key={i}>
                        <th>
                          <p className="mb-0 font14x600 textColor3">
                            {item?.serviceType?.name}
                          </p>
                        </th>
                        <td>
                          <p className="mb-0 font14x500">₹{item?.cost}</p>
                        </td>
                        <td>
                          <p className="mb-0 font14x500">
                            ₹
                            {
                              getServiceById(item?.serviceType?.cost_promotion)
                                ?.cost
                            }
                          </p>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="submit"
              className="btn_login font14x500"
              onClick={fn.handleUpdateThePrice}
            >
              Update the price
            </button>
            <button
              type="submit"
              className="btn_login font14x500"
              onClick={fn.handleGoBackToCart}
            >
              Go back to Cart
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceDropServiceWeekendModal;
