import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../utils/function/function";
import { Carousel } from "react-responsive-carousel";

const Banner = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);

  const [bgImage, setBgImage] = useState<any>("");
  const banner = filterHomepageSections(homepage_sections, "cover")?.sort(
    (a: any, b: any) => {
      return a.category_order - b.category_order;
    }
  );

  const new_cover = filterHomepageSections(homepage_sections, "new_cover");

  useEffect(() => {
    new_cover?.forEach((coverItem: any) => {
      if (coverItem?.name?.includes("new_cover_bg")) {
        setBgImage(coverItem?.category_image);
      }
    });
  }, [new_cover]);

  return (
    <>
      {banner?.length > 0 && (
        <div
          style={{ backgroundImage: `url(${bgImage})` }}
          className="banner_wrapper"
        >
          <div className="banner_section">
            {mainLoading ? (
              <div className="d-flex gap-3">
                <Skeleton width="322px" height="161px" borderRadius="8px" />
                <Skeleton width="322px" height="161px" borderRadius="8px" />
              </div>
            ) : (
              // <Carousel
              //   autoPlay={true}
              //   centerSlidePercentage={70}
              //   infiniteLoop={true}
              //   centerMode={true}
              //   showArrows={false}
              //   showIndicators={false}
              //     showThumbs={false}
              //     showStatus={false}
              // >
              <>
                {new_cover?.map((coverItem: any) => {
                  return (
                    <>
                      {coverItem?.name?.includes("new_cover_left") ? (
                        <>
                          {coverItem?.items_required?.includes("http") ? (
                            <a
                              className="banner-link"
                              target="_blank"
                              href={coverItem?.items_required}
                            >
                              <img
                                className="border_radius_8 banner-image"
                                src={coverItem?.category_image}
                                alt="banner"
                                width={322}
                                height={161}
                              />
                            </a>
                          ) : (
                            <Link
                              className="banner-link"
                              to={`/services/${coverItem?.items_required}`}
                            >
                              <img
                                className="border_radius_8 banner-image"
                                src={coverItem?.category_image}
                                alt="banner"
                                width={322}
                                height={161}
                              />
                            </Link>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}

                {new_cover?.map((coverItem: any) => {
                  return (
                    <>
                      {coverItem?.name?.includes("new_cover_right") ? (
                        <>
                          {coverItem?.items_required?.includes("http") ? (
                            <a
                              className="banner-link"
                              target="_blank"
                              href={coverItem?.items_required}
                            >
                              <img
                                className="border_radius_8 banner-image"
                                src={coverItem?.category_image}
                                alt="banner"
                                width={322}
                                height={161}
                              />
                            </a>
                          ) : (
                            <Link
                              className="banner-link"
                              to={`/services/${coverItem?.items_required}`}
                            >
                              <img
                                className="border_radius_8 banner-image"
                                src={coverItem?.category_image}
                                alt="banner"
                                width={322}
                                height={161}
                              />
                            </Link>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </>
              // </Carousel>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
