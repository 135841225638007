import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Auth } from "../../../utils/context/AuthContext";
import { BottomBarMenus } from "./constant";

const BottomBar = () => {
  const { pathname } = useLocation();
  const { cartItemsInContext } = useContext(Auth);

  return (
    <>
      <div className="bottom_bar_wrapper">
        <div className="d-flex justify-content-between">
          {BottomBarMenus?.map((item: any, i: number) => {
            return (
              <Link
                key={i}
                to={item?.link}
                state={{ comingFrom: pathname }}
                className="text-decoration-none"
                target={item?.label == "Whatsapp" ? "_blank" : "_self"}
              >
                <div className="text-center">
                  <img
                    src={
                      pathname === item?.check ? item?.colorIcon : item?.icon
                    }
                    alt="menu"
                  />
                  <p
                    className={`mb-0 mt-2 font10x400 ${
                      pathname === item?.check ? "textColor1" : "textColor7"
                    }`}
                  >
                    {item?.label}
                  </p>
                </div>
              </Link>
            );
          })}

          {/* cart */}
          <Link to={"/cart"} className="position-relative text-decoration-none">
            <div className="text-center">
              <img
                src={
                  pathname === "/cart"
                    ? "/static/svg/cart-color.svg"
                    : "/static/svg/cart.svg"
                }
                alt="menu"
              />
              <p
                className={`mb-0 mt-2 font10x400 ${
                  pathname === "/cart" ? "textColor1" : "textColor7"
                }`}
              >
                Cart
              </p>
            </div>

            {/* cart length */}
            <div className="cart_item_numbers">
              <p className="mb-0 font10x600 text-white">
                {
                  cartItemsInContext?.filter((item: any) => {
                    return item.added === true;
                  }).length
                }
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BottomBar;
