import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import {
  calculateDiscountInPercent,
  getChosenService,
  getImageURL,
  isUserValid,
} from "../../../../utils/function/function";
import { ClockIcon, StarIcon } from "../../../shared/Icons";
import "./index.css";
import { BiSolidOffer } from "react-icons/bi";

type PropType = {
  services: any;
  handleClickOnMinusPlus: (val1: any, val2: string) => void;
  setPaymentDetails: (val: any) => void;
  handleScrolledServices: (e: any) => void;
  showServicePageSpecialOffers: boolean;
};

const Packages: React.FC<PropType> = (props) => {
  const {
    services,
    handleClickOnMinusPlus,
    setPaymentDetails,
    handleScrolledServices,
    showServicePageSpecialOffers
  } = props;
  const { setCartItemsInContext, cartItemsInContext, setNewURLAfterReplace } =
    useContext(Auth);
  const navigate = useNavigate();
  const { homepageCategory } = useParams();

  const fn = {
    renderServiceNameList: (service: any) => {
      const splitedNames = service?.name?.split(":");
      if (splitedNames[1]?.includes(" OR ")) {
        return splitedNames[1]?.split(" OR ")?.map((info: any, k: any) => {
          return (
            <li className="mb-0 font12x400 textColor3" key={k}>
              {info}
            </li>
          );
        });
      } else if (splitedNames[1]?.includes(" , ")) {
        return splitedNames[1]?.split(" , ")?.map((info: any, k: any) => {
          return (
            <li className="mb-0 font12x400 textColor3" key={k}>
              {info}
            </li>
          );
        });
      } else if (splitedNames[1]?.includes(" + ")) {
        return splitedNames[1]?.split(" + ")?.map((info: any, k: any) => {
          return (
            <li className="mb-0 font12x400 textColor3" key={k}>
              {info}
            </li>
          );
        });
      } else {
        <></>;
      }
    },
    handleClickOnAdd: (item: any) => {
      const paymentDetailsList =
        JSON.parse(`${localStorage.getItem("paymentDetails")}`) || [];
      const serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      if (item.id == "620") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "546") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "547") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "548") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "538") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "521") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "605") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "497") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else {
        item.count = 1;
        item.added = true;
        item.isPackage = true;
        item.special_instructions = [];
        item.serviceType.chosenService = getChosenService(item);
        serviceList.push(item);

        if (isUserValid()) {
          // original cart updation
          setCartItemsInContext((prev: any) => {
            return [...prev, item];
          });
          localStorage.setItem("cartItems", JSON.stringify(serviceList));

          // updating current location in history
          setNewURLAfterReplace(
            `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
          );
          navigate(
            `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
            { replace: true }
          );
        } else {
          // temporary cart updation
          fn.navigateToLogin(item, serviceList);
        }
      }
    },
    navigateToLogin: (item: any, serviceTobeAdded?: any) => {
      // updating current location in history
      navigate(
        `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        { replace: true }
      );

      // navigate to login
      navigate("/otp-login", {
        state: {
          previousURL: `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
          serviceTobeAdded,
        },
      });
    },
  };

  return (
    <>
      <div
        style={{
          paddingBottom: showServicePageSpecialOffers ? "30px" : "0px",
        }}
        onScroll={handleScrolledServices}
        id="all_sub_category"
      >
        {/* rendering all services */}
        {services?.map((item: any, i: number) => {
          return (
            <div
              id={`category_${item?.subCategoryId}`}
              key={i}
              className="single_sub_category mb-3"
            >
              {/* category_name_container */}
              <div className="book-category_name_container">
                <h1 className="mb-0 font14x600 textColor3">
                  {item?.subCategoryName}
                </h1>
              </div>

              {/* services */}
              {item?.services?.map((serviceItem: any, index: number) => {
                return (
                  <div
                    id={`service_${serviceItem?.serviceType?.id}`}
                    key={index}
                    className="services_list"
                  >
                    <div className="service_section">
                      <div className="services_image_wrapper pt-3">
                        <div className="services_image_section d-flex">
                          {serviceItem?.serviceType?.image_1 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_1
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                          {serviceItem?.serviceType?.image_2 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_2
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                          {serviceItem?.serviceType?.image_3 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_3
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                          {serviceItem?.serviceType?.image_4 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_4
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                        </div>

                        <div className="category_info_and_add_container d-grid justify-content-between">
                          <div>
                            <div className="category_name_container mt-2">
                              {serviceItem?.serviceType?.recommended && (
                                <p className="mb-2 service_tag font10x600">
                                  {serviceItem?.serviceType?.recommended}
                                </p>
                              )}
                              <h1 className="mb-0 font14x600 textColor3">
                                {serviceItem?.serviceType?.name?.split(":")[0]}
                              </h1>
                              <div className="d-flex align-items-center mt-2 gap-1">
                                <ClockIcon />
                                <p className="mb-0 font12x500 textColor7">
                                  {`${serviceItem?.serviceType?.time} mins`}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex mt-2 align-items-center">
                              <p className="mb-0 font12x700 textColor3">
                                {`₹${serviceItem?.cost}`}
                              </p>
                              {serviceItem?.cost !==
                                serviceItem?.serviceType?.cost && (
                                <p className="mb-0 ms-2 font12x700 text-decoration-line-through textColor7">
                                  {`₹${serviceItem?.serviceType?.cost}`}
                                </p>
                              )}

                              {serviceItem?.cost !==
                                serviceItem?.serviceType?.cost && (
                                <p className="ms-1 mb-0 service_discount_tag gap-1 font11x600 d-flex align-items-center">
                                  | <BiSolidOffer width={14} height={14} />
                                  {calculateDiscountInPercent(
                                    serviceItem?.cost,
                                    serviceItem?.serviceType?.cost
                                  )}
                                  % OFF
                                </p>
                              )}
                            </div>
                            <div className="rating_review_container d-flex align-items-center gap-1 pb-0">
                              {serviceItem?.serviceType?.commission > 0 ? (
                                <div className="rating_container d-flex align-items-center gap-1">
                                  {/* <i>
                                    <StarIcon
                                      width="12"
                                      height="11"
                                      fill="#838383"
                                    />
                                  </i> */}
                                  <p className="mb-0 textColor7 booked-in-days mt-0">
                                    {/* {serviceItem?.serviceType?.commission / 10} */}
                                    {serviceItem?.serviceType?.commission/10}k+
                                    women booked in last 7 days
                                  </p>
                                </div>
                              ):""}
                            </div>
                          </div>

                          {/** @author Deepanshu Tyagi */}
                          {cartItemsInContext.find((cartItem: any) => {
                            return (
                              cartItem?.serviceType?.id ==
                              serviceItem?.serviceType?.id
                            );
                          }) ? (
                            <div className="mt-3 d-flex minus_plus_button_container align-items-center justify-content-between">
                              <button
                                onClick={() => {
                                  handleClickOnMinusPlus(serviceItem, "-");
                                }}
                                className="font14x600 textColor1 d-flex border-0"
                              >
                                -
                              </button>
                              <p className="font14x600 textColor1 mb-0">
                                {
                                  cartItemsInContext.find((cartItem: any) => {
                                    return (
                                      cartItem?.serviceType?.id ==
                                      serviceItem?.serviceType?.id
                                    );
                                  })?.count
                                }
                              </p>
                              <button
                                onClick={() => {
                                  handleClickOnMinusPlus(serviceItem, "+");
                                }}
                                className="font14x600 textColor1 border-0"
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                fn.handleClickOnAdd(serviceItem);
                              }}
                              className="font14x600 add_button mt-3 textColor1 d-flex align-items-center justify-content-center"
                            >
                              ADD+
                            </button>
                          )}
                          {/** */}
                        </div>

                        <div className="services_container">
                          <ul className="m-0 p-0 ps-3 d-flex flex-column gap-2">
                            {fn.renderServiceNameList(serviceItem?.serviceType)}
                          </ul>
                          {/* <div className="description_container"> */}
                          <ul className="package-benefits-container mb-0 ps-4 mt-2">
                            {serviceItem?.serviceType?.benefit
                              ?.split("+")
                              ?.slice(0, 2)
                              ?.map((item: string, i: number) => {
                                return (
                                  <li
                                    key={i}
                                    className="mb-1 font12x400 textColor7"
                                  >
                                    {item?.trim()}
                                  </li>
                                );
                              })}
                          </ul>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Packages;
