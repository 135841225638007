export const BenefitPoints = [
  "ZERO Setup cost",
  "Profits within 3 months",
  "Tech Enabled Platform",
  "Inventory Management",
  "Auto Assign tasks",
  "Training for Beauticians",
  "Marketing Support",
  "App for Customer and Service provider",
];

export const FranchiseSetupCost = [
  {
    title: "Technology Fee ( Including GST)",
    cost: "₹2,36,000",
  },
  {
    title: "4 Kits+ Initial stock+ Training Material (Including GST)",
    cost: "₹1,25,000",
  },
  {
    title: "Online Marketing Expense",
    description: "Facebook, Instagram & Google Ads ( for Lead Generation)",
    cost: "₹3,72,000",
  },
  {
    title: "Offline Marketing",
    description: "Hoardings, Auto banner, Canopy & Phamphlets, Theatre Ads",
    cost: "₹50,000",
  },
  {
    title: "Lodging Cost + Food",
    description: "Trainer & Executive for Initial Set up",
    cost: "₹30,000",
  },
  {
    title: "Cost",
    cost: "₹8,13,000",
  },
];

export const MonthlyProjections = [
  {
    title: "Number of Beauty Professionals",
    cost: "4",
  },
  {
    title: "Number of Bookings",
    cost: "200",
  },
  {
    title: "Average Ticket Size",
    cost: "₹1500",
  },
  {
    title: "Expected Revenue",
    cost: "₹3,00,000",
  },
];

export const Expenses = [
  {
    title: "Staff salary",
    description: "(Beauty Professionals + Riders + Petrol)",
    cost: "₹1,00,000",
  },
  {
    title: "Rental",
    cost: "₹0",
  },
  {
    title: "Products",
    cost: "₹75,000",
  },
  {
    title: "Royalty + GST",
    cost: "₹18,000",
  },
  {
    title: "Misc. Expenses",
    cost: "₹15,000",
  },
  {
    title: "Marketing",
    cost: "₹25,000",
  },
  {
    title: "Total Expenses",
    cost: "₹2,33,000",
  },
];

export const SupportFromGetlook = [
  {
    imagePath: "/static/img/web_&_mobile_platform.png",
    alt: "web & mobile platform",
    heading: "Web & Mobile Platform",
    bgColor: "#EFF7F2",
  },
  {
    imagePath: "/static/img/tech_enabled.png",
    alt: "tech enabled",
    heading: "Tech Enabled Platform",
    bgColor: "#F2F0FE",
  },
  {
    imagePath: "/static/img/digital_marketing.png",
    alt: "digital marketing",
    heading: "Digital Marketing",
    bgColor: "#FEF7D9",
  },
  {
    imagePath: "/static/img/beauty_professional.png",
    alt: "Beauty Professional Training",
    heading: "Beauty Professional Training",
    bgColor: "#FBF3F2",
  },
  {
    imagePath: "/static/img/customer_support.png",
    alt: "Customer Support",
    heading: "Customer Support",
    bgColor: "#F2F0FE",
  },
];

export const BookingStats = [
  {
    count: "15 Lakhs+",
    description: "Bookings served till date",
  },
  {
    count: "15000+",
    description: "Bookings every month",
  },
  {
    count: "800+",
    description: "Women Service Professionals",
  },
];

export const FranchiseCities = [
  {
    city: "Bangalore",
  },
  {
    city: "Pune",
  },
  {
    city: "Jodhpur",
  },
  {
    city: "Kolkata",
  },
  {
    city: "Mumbai",
  },
  {
    city: "Hyderabad",
  },
  {
    city: "Siliguri",
  },
  {
    city: "Gorakhpur",
  },
  {
    city: "Nagpur",
  },
  {
    city: "Chennai",
  },
  {
    city: "Noida",
  },
];
