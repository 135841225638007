import { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { commonType } from "../../../../pages/main/type";
import { setOverflowToBodyHidden } from "../../../../utils/function/function";
import BottomTopAnimation from "../../../shared/BottomTopAnimation";
import ConvenienceFee from "../../../shared/PopupModal/ConvenienceFee";
import Separator from "../../../shared/Separator";
import PreviousDues from "../../../shared/PopupModal/PreviousDues";

type propType = {
  paymentDetails: any;
};

const PaymentDetails: React.FC<propType> = (props) => {
  const { paymentDetails } = props;
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });

  const fn = {
    openModal: (check?: string) => {
      setCommonData({
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
  };

  return (
    <>
      <div className="cart-payment_details_wrapper payment_details_wrapper">
        <div className="payment_details_section">
          <h3 className="font14x600 textColor3">Payment Details</h3>
          <Separator />
          <div className="d-flex flex-column gap-1 py-3">
            <div className="d-flex justify-content-between">
              <p className="mb-1 font14x500 textColor7">Subtotal:</p>
              <p className="mb-0 font14x400 textColor3">
                {`₹${paymentDetails?.subTotal}`}
              </p>
            </div>
            {paymentDetails?.discount > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">Discount:</p>
                <p className="mb-0 font14x400 textColor11">
                  {`- ₹${paymentDetails?.discount}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.couponDiscount > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">Coupon Discount:</p>
                <p className="mb-0 font14x400 textColor11">
                  {`- ₹${+paymentDetails?.couponDiscount}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.walletDiscount ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">Wallet Discount:</p>
                <p className="mb-0 font14x400 textColor11">
                  {`- ₹${paymentDetails?.walletDiscount}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.membershipDiscount ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">
                  Membership Discount:
                </p>
                <p className="mb-0 font14x400 textColor11">
                  {`- ₹${paymentDetails?.membershipDiscount}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.surgeFee < 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">Surge Discount:</p>
                <p className="mb-0 font14x400 textColor11">
                  {`-₹${paymentDetails?.surgeFee}`}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <p className="mb-1 font14x500 textColor7">
                  Safety & Hygiene fee:
                </p>
                <BsInfoCircle
                  className="textColor1"
                  onClick={() => fn.openModal("safety&hygiene_fee")}
                />
              </div>
              <p className="mb-0 font14x400 textColor3">
                {`₹${paymentDetails?.safetyAndHygieneCharge}`}
              </p>
            </div>
            {paymentDetails?.conveyanceCharge > 0 && (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">Conveyance Charge:</p>
                <p className="mb-0 font14x400 textColor3">
                  {`₹${paymentDetails?.conveyanceCharge}`}
                </p>
              </div>
            )}
            {paymentDetails?.premiumCharge ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">
                  Premium Beautician:
                </p>
                <p className="mb-0 font14x400 textColor3">
                  {`₹${paymentDetails?.premiumCharge}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.surgeFee > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">Surge Fee:</p>
                <p className="mb-0 font14x400 textColor3">
                  {`₹${paymentDetails?.surgeFee}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.upgradedServiceCharge > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font14x500 textColor7">
                  Upgraded Service Charge:
                </p>
                <p className="mb-0 font14x400 textColor3">
                  {`₹${paymentDetails?.upgradedServiceCharge}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.previousDues > 0 ? (
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <p className="mb-1 font14x500 textColor7">Previous Dues:</p>
                  <BsInfoCircle
                    className="textColor1"
                    onClick={() => fn.openModal("previousDues")}
                  />
                </div>
                <p className="mb-0 font14x400 textColor3">
                  {`₹${paymentDetails?.previousDues}`}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <Separator />
          <div className="pt-2 d-flex justify-content-between">
            <p className="mb-0 font16x700 textColor3">Grand Total:</p>
            <p className="mb-0 font16x700 textColor3">
              {`₹${paymentDetails?.grandTotal}`}
            </p>
          </div>
        </div>
      </div>

      {/* safety & hygiene fee modal */}
      {commonData?.modal && commonData?.check === "safety&hygiene_fee" && (
        <BottomTopAnimation>
          <ConvenienceFee state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}

      {/* previuos dues fee modal */}
      {commonData?.modal && commonData?.check === "previousDues" && (
        <BottomTopAnimation>
          <PreviousDues state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default PaymentDetails;
