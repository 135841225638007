import { Helmet } from "react-helmet";
import { ContactPage } from "../../../../../utils/metaInfo/metaInfo";
import Header from "../../../../../components/shared/Header";
import { useNavigate } from "react-router-dom";
import "./contact.css";
import { CONTACT_US_CITY } from "../../../../../utils/constant/constant";
import {
  ContactEmail,
  ContactLocation,
  ContactPhone,
} from "../../../../../components/shared/Icons";
import { useEffect } from "react";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";

const ContactUs = () => {
  const navigate = useNavigate();

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Helmet>
          <title>{ContactPage.title}</title>
          <meta name="description" content={ContactPage.description} />
          <meta name="keywords" content={ContactPage.keywords} />
        </Helmet>

        <div className="contact_us_page">
          <Header
            label="Contact us"
            navigate={() => {
              navigate(-1);
            }}
          />

          <div className="contact_image">
            <img src="/static/svg/contact-us-pana.svg" />
          </div>

          <div className="cities_container">
            <div className="city_info_container mb-2">
              {/* <div className="title_container">
                <p className="font14x600 mb-0 textColor10">Bangalore</p>
              </div> */}
              <div className="city_info d-flex align-items-center justify-content-between bg-white mb-2">
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    <ContactPhone />
                  </div>
                  <div>
                    <p className="font12x400 mb-0 ms-3">Call us now</p>
                  </div>
                </div>
                <div>
                  <a
                    href="tel:6366370336"
                    className="font14x500 text-decoration-none mb-0 textColor1"
                  >
                    6366370336
                  </a>
                </div>
              </div>

              <div className="city_info d-flex align-items-center justify-content-between bg-white mb-2">
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    <ContactEmail />
                  </div>
                  <div>
                    <p className="font12x400 mb-0 ms-3">Mail us now</p>
                  </div>
                </div>
                <div>
                  <a
                    href="mailto:hi@getlook.in"
                    className="font14x500 text-decoration-none mb-0 textColor1"
                  >
                    hi@getlook.in
                  </a>
                </div>
              </div>

              <div className="city_info d-flex justify-content-between bg-white">
                <div className="office_image_title d-flex align-itemx-center">
                  <div className="d-flex">
                    <ContactLocation />
                  </div>
                  <div>
                    <p className="font12x400 mb-0 ms-3">Office</p>
                  </div>
                </div>
                <div className="address_container">
                  <p className="font14x500 textColor1 mb-1 text-end">
                    L142, 4th Floor, 5th Main,
                  </p>
                  <p className="font14x500 mb-1 textColor1 text-end">
                    6, HSR Layout 5th Sector,
                  </p>
                  <p className="font14x500 mb-0 textColor1 text-end">
                    Bengaluru, Karnataka 560102
                  </p>
                </div>
              </div>
            </div>

            {/* {CONTACT_US_CITY.map((item: any, i: any) => {
              return (
                <div
                  key={i}
                  className="single_city_info d-flex align-items-center justify-content-between mb-2"
                >
                  <div>
                    <p className="font14x500 mb-0 textColor10">{item?.city}</p>
                  </div>
                  <div>
                    <a
                      href={`tel:${item?.phone}`}
                      className="font14x500 text-decoration-none mb-0 textColor1"
                    >
                      {item?.phone}
                    </a>
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
