import { GoldNumbers } from "../../pages/MyProfile/SubPages/GetlookGold/constant";

const GoldInNumber = () => {
  return (
    <>
      <div className="g_gold_numbers">
        <h2 className="font16x600 textColor3">Gold in numbers</h2>
        <div className="g_gold_numbers_section mt-4">
          {GoldNumbers?.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className="g_gold_single_stats"
                style={{
                  backgroundColor: item?.bgColor,
                }}
              >
                <img src={item?.imagePath} alt={item?.alt} />
                <div className="mt-3">
                  <p className="mb-1 font14x600 textColor3">{item?.heading}</p>
                  <p className="mb-0 font14x400 textColor7">
                    {item?.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GoldInNumber;
