const ServicesLinks = () => {
  return (
    <>
      <div>
        <h3 className="mb-0 text-white font16x600">SERVICES WE PROVIDE</h3>
        <div className="mt-3 d-flex flex-column gap-2">
          <p className="mb-0 text-white font14x500">Waxing</p>
          <p className="mb-0 text-white font14x500">Skin/Face</p>
          <p className="mb-0 text-white font14x500">Pedi-Mani</p>
          <p className="mb-0 text-white font14x500">Massage</p>
          <p className="mb-0 text-white font14x500">Pre Bridal</p>
        </div>
      </div>
    </>
  );
};

export default ServicesLinks;
