import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../utils/function/function";

const DealOfTheDay = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const deal = filterHomepageSections(homepage_sections, "dod");
  const navigate = useNavigate();

  return (
    <>
      {mainLoading ? (
        <>
          <div className="w-100 p-3 mt-2">
            <Skeleton width="122px" height="18px" borderRadius="8px" />
            <Skeleton
              className="mt-2"
              width="200px"
              height="18px"
              borderRadius="8px"
            />
            <Skeleton
              className="mt-2"
              width="100px"
              height="30px"
              borderRadius="8px"
            />
            <Skeleton
              className="w-100 mt-3"
              height="171px"
              borderRadius="8px"
            />
          </div>
        </>
      ) : (
        deal?.map((item: any, i: number) => {
          return (
            <div key={i} className="deal_of_day_wrapper">
              {/* <h2 className="mb-0 font16x700 text-center deal_heading">
                Deal of the Day
              </h2> */}
              {item?.items_required?.includes("http") ? (
                <a target="_blank" href={item?.items_required}>
                  <img
                    src={item?.category_image}
                    className="w-100"
                    alt="service_ad"
                    onClick={() =>
                      navigate(`/services/${item?.items_required}`)
                    }
                  />
                </a>
              ) : (
                <Link to={`/services/${item?.items_required}`}>
                  <img
                    src={item?.category_image}
                    className="w-100"
                    alt="service_ad"
                    onClick={() =>
                      navigate(`/services/${item?.items_required}`)
                    }
                  />
                </Link>
              )}
              {/* <div className="service_ad_wrapper mt-2">
                <h2 className="text-white font16x700">{item?.name}</h2>
                <p className="text-white font12x400">{item?.information}</p>
                <button
                  type="button"
                  className="textColor8 book_now_btn font14x600"
                  onClick={() => navigate(`/services/${item?.items_required}`)}
                >
                  Book Now
                </button>
                <div className="position-relative mt-4">
                  <img
                    src={item?.category_image}
                    className="w-100"
                    alt="service_ad"
                    onClick={() =>
                      navigate(`/services/${item?.items_required}`)
                    }
                  />
                  <div className="safer_msg">
                    <img src="/static/svg/safer.svg" alt="safer" />
                  </div>
                </div>
              </div> */}
            </div>
          );
        })
      )}
    </>
  );
};

export default DealOfTheDay;
