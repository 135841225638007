import {
  ANDROID_WEB,
  IOS_WEB,
  MOBILE_WEB,
  WEB,
} from "../../../../utils/constant/constant";
import { getDeviceSource } from "../../../../utils/function/function";
import { CloseIcon } from "../../../shared/Icons";
import { useRef } from "react";

const AppBanner = () => {
  const appBanner = useRef<any>(null);
  const isAppBannerClose = window.sessionStorage.getItem(
    "is_app_banner_close"
  )!;

  const fn = {
    handleCloseAppBanner: () => {
      window.sessionStorage.setItem("is_app_banner_close", "true");
      appBanner.current.style.display = "none";
    },
    handleNavigateToStore: () => {
      const deviceSource = getDeviceSource();
      const anchor = document.createElement("a");
      if (deviceSource === WEB || deviceSource === MOBILE_WEB) {
        return;
      } else if (deviceSource === ANDROID_WEB) {
        anchor.href =
          "https://play.google.com/store/apps/details?id=salon.look.ui&hl=en_IN";
        anchor.click();
      } else if (deviceSource === IOS_WEB) {
        anchor.href =
          "https://apps.apple.com/in/app/getlook-salon-at-home/id1205081417";
        anchor.click();
      }
    },
  };

  return (
    <>
      {!isAppBannerClose &&
        (getDeviceSource() === ANDROID_WEB ||
          getDeviceSource() === IOS_WEB) && (
          <div ref={appBanner} className="getAppBannerWrapper">
            <div>
              <button
                onClick={fn.handleCloseAppBanner}
                className="d-flex align-items-center justify-content-center  border-0"
                style={{
                  borderRadius: "100%",
                }}
              >
                <CloseIcon width="10" height="10" fill="var(--text-color1)" />
              </button>
            </div>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <img src="/static/img/app-image.png" width={65} alt="appImage" />
              <div>
                <p className="mb-1 font12x600">Download the GetLook App &</p>
                <p className="mb-0 font10x500">
                  Get Upto 50% off on first order
                </p>
              </div>
              <button
                className="font12x500 text-white getAppBtn"
                onClick={fn.handleNavigateToStore}
              >
                Get App
              </button>
            </div>
          </div>
        )}
    </>
  );
};

export default AppBanner;
