import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import "./style.css";

type propType = {
  latLng: any;
  handleOnDrag: (val: any) => void;
};

const AddressMap: React.FC<propType> = (props) => {
  const { latLng, handleOnDrag } = props;
  const defaultProps = {
    center: {
      lat: latLng?.lat,
      lng: latLng?.long,
    },
    zoom: 17,
  };
  const fn = {
    handleOnDragMap: (res: any) => {
      handleOnDrag(res);
    },
    createMapOptions: () => {
      return {
        gestureHandling: "greedy", // Will capture all touch events on the map towards map panning
      };
    },
  };
  useEffect(() => {
    if (latLng) {
      defaultProps.center = {
        lat: latLng?.lat,
        lng: latLng?.long,
      };
    }
  }, [latLng]);

  return (
    // Important! Always set the container height explicitly
    <div className="address_map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: localStorage.getItem("map_key")! }}
        // defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onDragEnd={(res) => fn.handleOnDragMap(res)}
        center={defaultProps.center}
        options={fn.createMapOptions}
      ></GoogleMapReact>
      {/* marker */}
      <div className="marker_container">
        <img src="/static/svg/location.svg" />
      </div>
    </div>
  );
};
export default AddressMap;
