/**
 * @author Deepanshu Tyagi
 */

import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gtm, ReactPixel } from "../../../../components/shared/GTM";
import Header from "../../../../components/shared/Header";
import "./index.css";

const PaymentFailed = () => {
  const navigate = useNavigate();
  const [queryData, setQueryData] = useSearchParams();

  const paymentMethodApis = {
    payu: process.env.REACT_APP_PAYU_LINK,
  };

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    localStorage.removeItem("orderId");
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="payment_failed_page">
          <Header label="" navigate={() => navigate("/")} />
          <div className="payment_failed_image_container text-center">
            <img src="/static/img/paymentfailed.png" />
          </div>
          <div className="payment_failed_section">
            <div className="payment_rupees_image_container text-center">
              <img src="/static/svg/paymentfailedrupees.svg" />
            </div>
            <div className="text-center">
              <h1 className="font20x700 mb-0 textColor3">
                Oops! Something went
              </h1>
            </div>
            <div className="payment_failed_text text-center">
              <h1 className="font20x700 mb-0 textColor3">Payment Failed !</h1>
            </div>
            <div className="text-center">
              <p className="font12x400 mb-0 textColor3">
                Order ID: {queryData.get("order_id")}
              </p>
            </div>
          </div>
          <div className="view_details_done_container">
            <button
              type="button"
              onClick={() => {
                navigate(`/myorder?order_id=${queryData.get("order_id")}`, {
                  replace: true,
                });
              }}
              className="view_details font14x400"
            >
              View Details
            </button>
            <button
              type="button"
              onClick={() => {
                window.open(
                  `${paymentMethodApis.payu}?id=${queryData.get("order_id")}`,
                  "_self"
                );
              }}
              className="view_details font14x400 mt-2"
            >
              Retry Payment
            </button>
            <button
              type="button"
              onClick={() => {
                navigate("/", {
                  replace: true,
                });
              }}
              className="done font14x400"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
