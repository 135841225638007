import React from "react";
import { commonType } from "../../../pages/main/type";
import { setOverflowToBodyUnset } from "../../../utils/function/function";
import { CloseIcon } from "../../shared/Icons";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const OfferDetails: React.FC<propType> = (props) => {
  const { state, setState } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="details_modal_wrapper">
        {/* cancel */}
        <div className="cancel_modal_wrapper">
          <button
            onClick={fn.setPositionToBody}
            className="d-flex align-items-center justify-content-center bg-black text-white border-0"
          >
            <CloseIcon width="20" height="20" fill="#fff" />
          </button>
        </div>

        <div className="details_container">
          <h1 className="mb-1 font16x600 textColor3">Offer Details</h1>
          <div className="mt-2">
            <ul className="mb-0 ps-3">
              {state?.data?.description
                ?.split("|")
                ?.map((item: any, i: number) => {
                  return (
                    <li key={i} className="mb-0 font12x500 textColor3">
                      {item?.trim()}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferDetails;
