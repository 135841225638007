import { useNavigate } from "react-router-dom";
import FAQs from "../../../components/shared/FAQ";
import Benefits from "../../../components/shared/GetlookGold/Benefits";
import GoldInNumber from "../../../components/shared/GetlookGold/GoldInNumber";
import MemberReviews from "../../../components/shared/GetlookGold/MemberReviews";
import SavingsInfo from "../../../components/shared/GetlookGold/SavingsInfo";
import { BsArrowRight } from "react-icons/bs";
import { BackIcon } from "../../../components/shared/Icons";
import { getServiceById } from "../../../utils/function/function";
import { useContext, useEffect, useState } from "react";
import { Auth } from "../../../utils/context/AuthContext";
import { membershipId } from "../../../utils/constant/constant";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import moment from "moment";

const Membership = () => {
  const { user, cartItemsInContext, setCartItemsInContext } = useContext(Auth);
  const navigate = useNavigate();
  const [membership, setMembership] = useState<any>({});
  const cartItems = JSON.parse(localStorage.getItem("cartItems")!);

  const fn = {
    getMembershipById: () => {
      const membership = getServiceById(membershipId);
      setMembership(membership);

      // check membership present or not in cart and user membered or not if not then it call fn
      let membershipExit = cartItems?.some((cartItem: any) => {
        return cartItem?.serviceType?.id === membershipId;
      });

      if (!(user?.isMember || membershipExit)) {
        fn.handleClickOnAdd(membership);
      }
    },
    handleClickOnAdd: (item: any) => {
      const paymentDetailsList =
        JSON.parse(`${localStorage.getItem("paymentDetails")}`) || [];
      const serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      if (item.id == "620") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "546") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "547") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "548") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "538") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "521") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "605") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "497") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else {
        item.count = 1;
        item.added = true;
        serviceList.push(item);
        setCartItemsInContext((prev: any) => {
          return [...prev, item];
        });
        localStorage.setItem("cartItems", JSON.stringify(serviceList));
      }
    },
    removeMembershipFromCart: (service: any, isRedirect: boolean) => {
      const cartItems =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      const filteredItems = cartItems?.filter(
        (item: any) => item?.serviceType?.id !== service?.serviceType?.id
      );
      setCartItemsInContext(filteredItems);
      localStorage.setItem("cartItems", JSON.stringify(filteredItems));

      // redirect user to cart when user click on "i will pay full price"
      if (isRedirect) {
        navigate("/cart", { state: { previousURL: "/membership" } });
      }
    },
  };

  useEffect(() => {
    fn.getMembershipById();
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "var(--bg-color2)",
          paddingBottom: "54px",
        }}
      >
        {/* header */}
        <div className="membership_header_wrapper">
          <div className="membership_back">
            <BackIcon
              width="17"
              height="14"
              fill="#0F0F0F"
              clickHandler={() => navigate(-1)}
            />
          </div>

          <div className="membership_content d-flex flex-column justify-content-between align-items-center">
            <img
              style={{ maxWidth: "215px" }}
              src="/static/img/g-gold-logo.png"
              alt="membership_logo"
            />
            <p className="mb-4 textColor1 font16x600">
              Save upto Rs. 200 on every order with GetLook Gold
            </p>
          </div>

          {/* checking current user is member or not */}
          {user?.isMember ? (
            <>
              <div className="membership_info_wrapper">
                <div className="membership_info_section">
                  <div className="w-50 py-2 px-4 membership_active_inactive">
                    <p className="mb-2 font14x400 textColor3">MEMBERSHIP</p>
                    {user?.isMember ? (
                      <p className="mb-0 font14x600 textColor11">ACTIVE</p>
                    ) : (
                      <p className="mb-0 font14x600 textColor1">EXPIRED</p>
                    )}
                  </div>
                  <div className="w-50 py-2 px-4">
                    <p className="mb-2 text-end font14x400 textColor3">
                      VALID TILL
                    </p>
                    <p className="mb-0 text-end font14x600 textColor3">
                      {moment(user?.membershipActivationDate)
                        .add(user?.membershipDuration, "days")
                        .format("DD MMM YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <div className="mt-4 d-flex justify-content-center align-items-center gap-3">
                <img src="/static/svg/coin.svg" alt="coin" />
                <p className="mb-0 font14x500 textColor4">
                  You will earn Rs. 150 on this order
                </p>
              </div> */}
              <div className="membership_details">
                <div className="membership_section d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-2 font12x600 textColor4">
                      1 Lakh + members
                    </p>
                    <p className="mb-2 font18x600 textColor3">
                      {`${membership?.serviceType?.name?.split(" ")[1]} ${
                        membership?.serviceType?.name?.split(" ")[2]
                      }`}
                    </p>
                    <div className="d-flex gap-2 align-items-center">
                      <p className="mb-0 font14x500 textColor3">{`Rs. ${membership?.cost}`}</p>
                      <p className="mb-0 font12x400 text-decoration-line-through textColor7    ">
                        {`Rs. ${membership?.serviceType?.cost}`}
                      </p>
                    </div>
                  </div>
                  <div>
                    {cartItemsInContext?.find((cartItem: any) => {
                      return (
                        cartItem?.serviceType?.id ===
                        membership?.serviceType?.id
                      );
                    }) ? (
                      <button
                        className="font14x500 remove_button textColor1 d-flex align-items-center justify-content-center"
                        onClick={() => {
                          fn.removeMembershipFromCart(membership, false);
                        }}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="font14x500 remove_button textColor1 d-flex align-items-center justify-content-center"
                        onClick={() => {
                          fn.handleClickOnAdd(membership);
                        }}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="pb-3 d-flex justify-content-center align-items-center gap-2"
                onClick={() => fn.removeMembershipFromCart(membership, true)}
              >
                <p className="mb-0 textColor1 font14x600">
                  No, I will pay full price
                </p>
                <BsArrowRight
                  style={{
                    color: "var(--text-color1)",
                  }}
                />
              </div>
            </>
          )}
        </div>

        {/* getlook gold benefits */}
        <div className="mt-2">
          <Benefits />
        </div>

        {/* savings info */}
        <SavingsInfo />

        {/* gold in numbers */}
        <GoldInNumber />

        {/* faqs */}
        <FAQs />

        {/* member reviews */}
        {/* <MemberReviews /> */}

        {/* proceed btn */}
        <div className="fix_section_at_bottom">
          <button
            type="button"
            className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
            onClick={() =>
              navigate("/cart", { state: { previousURL: "/membership" } })
            }
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default Membership;
