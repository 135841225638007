import { useState } from "react";
import { showToast } from "../../../../lib/toast/showToast";
import { DeleteIcon, EditIcon, NotePadIcon } from "../../../shared/Icons";
import TextareaInput from "../../../shared/TextareaInput";

const Instruction = () => {
  const [extraInsClicked, setExtraInsClicked] = useState(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [extraInstruction, setExtraInstruction] = useState<any>(
    localStorage.getItem("extra_instruction") || ""
  );

  const fn = {
    putInsIntoLocal: () => {
      if (inputValue?.length > 0) {
        window.localStorage.setItem("extra_instruction", inputValue);
        setExtraInstruction(inputValue);
        setExtraInsClicked(false);
        setInputValue("");
      } else {
        showToast("No extra instruction provided", "error");
      }
    },
    removeInsIntoLocal: () => {
      window.localStorage.removeItem("extra_instruction");
      // setExtraInsClicked(true);
      setExtraInstruction("");
    },
    editInsIntoLocal: () => {
      setInputValue(localStorage.getItem("extra_instruction"));
      setExtraInsClicked(true);
    },
    closeInstructionInput: () => {
      setExtraInsClicked(false);
    },
  };

  return (
    <>
      <div className="instruction_wrapper">
        <div className="d-flex justify-content-between align-items-center gap-2 cursor_pointer">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className="notpad_instruction_container d-grid align-items-center gap-2">
              <NotePadIcon />
              <p className="mb-0 font12x400 textColor7">
                {extraInstruction
                  ? extraInstruction
                  : "Do you want to add any instructions?"}
              </p>
            </div>
            {extraInsClicked || extraInstruction ? (
              ""
            ) : (
              <button
                onClick={() => setExtraInsClicked(true)}
                className="mb-0 font14x600 textColor1 bg-white border-0"
              >
                Add
              </button>
            )}
          </div>
          {extraInsClicked ? (
            <div className="d-flex align-items-center gap-2">
              <button
                className="font14x600 bg-white border-0 textColor1"
                onClick={fn.putInsIntoLocal}
              >
                Add
              </button>
              <button
                className="font14x600 bg-white border-0 textColor1"
                onClick={fn.closeInstructionInput}
              >
                Close
              </button>
            </div>
          ) : (
            ""
          )}
          {extraInstruction && !extraInsClicked ? (
            <div className="d-flex gap-2">
              <i className="address_action_options">
                <EditIcon
                  width="16"
                  height="16"
                  fill="#AF031A"
                  clickHandler={() => fn.editInsIntoLocal()}
                />
              </i>
              <i className="address_action_options">
                <DeleteIcon
                  width="16"
                  height="16"
                  fill="#AF031A"
                  clickHandler={() => fn.removeInsIntoLocal()}
                />
              </i>
            </div>
          ) : (
            ""
          )}
        </div>
        {extraInsClicked && (
          <div className="mt-3">
            <TextareaInput
              id="extra_ins"
              placeholder="enter your extra instructions here..."
              state={inputValue}
              setState={(e: any) => {
                setInputValue(e.target.value);
              }}
              border="1px solid var(--border-color1)"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Instruction;
