import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  conveyanceId,
  couponId,
  handlingFeeId,
  membershipDiscountId,
  membershipId,
  ORDER_DETAILS_STATUS,
  premiumId,
  previousDuesId,
  rescheduleChargeId,
  safetyId,
  surgeId,
  upgradeId,
  walletId,
} from "../../../../../../../utils/constant/constant";
import { getDataFromSIns } from "../../../../../../../utils/function/function";
import { ClockIcon, CloseIcon } from "../../../../../../shared/Icons";
import Separator from "../../../../../../shared/Separator";
import StylistInfo from "../StylistInfo";
import { Link } from "react-router-dom";
import { commonType } from "../../../../../../../pages/main/type";
import BottomTopAnimation from "../../../../../../shared/BottomTopAnimation";
import { IoIosArrowDown } from "react-icons/io";
import { Auth } from "../../../../../../../utils/context/AuthContext";

type PropType = {
  singleOrder: any;
};

const Attention: React.FC<PropType> = (props) => {
  const { singleOrder } = props;

  const { homepage_sections } = useContext(Auth);

  const [showContent, setShowContent] = useState(false);
  const [attentionInfo, setAttentionInfo] = useState<any>();

  useEffect(() => {
    if (homepage_sections && Object.keys(homepage_sections)?.length > 0) {
      Object.keys(homepage_sections).forEach((key) => {
        if (homepage_sections[key]?.id === 63) {
          setAttentionInfo(homepage_sections[key]?.stylist_information);
        }
      });
    }
  }, [homepage_sections]);

  return (
    <>
      <div className="order_details_wrapper">
        <div>
          <div
            onClick={() => {
              setShowContent(!showContent);
            }}
            className="d-flex align-items-center justify-content-between"
          >
            <h3 className="mb-0 font14x700 textColor3">Important 🔔</h3>

            <IoIosArrowDown
              style={{
                transform: showContent ? "rotate(180deg)" : "",
                transition: "transform .2s",
              }}
            />
          </div>
          {showContent ? (
            <ul className="p-0 ps-3 m-0 mt-2">
              {attentionInfo?.split("+")?.map((item: any) => {
                return <li className="mb-2 font12x400 textColor3">{item}</li>;
              })}
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Attention;
