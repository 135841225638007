import { Navigate, Outlet } from "react-router-dom";

const IsDesktop = () => {
  return window.innerWidth > 1100 &&
    !window.sessionStorage.getItem("isDesktop") ? (
    <Navigate to="/home" replace />
  ) : (
    <Outlet />
  );
};
export default IsDesktop;
