import React, { useContext, useEffect, useState } from "react";
import { setOverflowToBodyHidden } from "../../../../utils/function/function";
import { OffersDeals, RightChevronIcon } from "../../../shared/Icons";
import Coupon from "./Coupon";
import { showToast } from "../../../../lib/toast/showToast";
import { Auth } from "../../../../utils/context/AuthContext";

type propType = {
  state: any;
  setState: (val: any) => void;
  setPaymentDetails: (val: any) => void;
  setAppliedCoupon: (val: any) => void;
  walletInfo: any;
};

const CouponSection: React.FC<propType> = (props) => {
  const { cartItemsInContext } = useContext(Auth);
  const { state, setState, setPaymentDetails, setAppliedCoupon, walletInfo } =
    props;
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
  const [isPriceDropServiceExist, setIsPriceDropServiceExist] =
    useState<boolean>(false);

  const [isPackage, setIsPackage] = useState<boolean>(false);

  const appliedCoupon =
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;

  const fn = {
    openModal: (check?: string) => {
      if (isPriceDropServiceExist) {
        showToast("Coupons are not applicable on price drop", "error");
      } else if (isPackage) {
        showToast("Coupons are not applicable on packages", "error");
      } else {
        setState({
          modal: true,
          check: check,
        });
        setOverflowToBodyHidden();
      }
    },
  };

  useEffect(() => {
    const isPriceDropServiceExist = cartItems?.some((item: any) => {
      if (item?.serviceType?.isPackage === 99) {
        return item;
      }
    });

    const isPackageExist = cartItems?.some((item: any) => {
      if (
        item?.serviceType?.isPackage === 1 ||
        item?.serviceType?.isPackage === 9
      ) {
        return item;
      }
    });
    if (isPriceDropServiceExist) {
      // removing coupon when price drop service added is cart
      localStorage.removeItem("coupon_applied");
      setIsPriceDropServiceExist(true);
    } else if (isPackageExist) {
      setIsPackage(true);
      localStorage.removeItem("coupon_applied");
    } else {
      setIsPriceDropServiceExist(false);
    }
  }, [cartItemsInContext]);

  return (
    <>
      <div className="coupon_wrapper">
        {/* coupon after applied */}
        {appliedCoupon ? (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <OffersDeals width="20" height="20" fill="#AF031A" />
                <p className="mb-0 font14x700 textColor3">Used Coupon</p>
              </div>
              <p
                className="mb-0 textColor1 font14x400 cursor_pointer"
                onClick={() => fn.openModal("coupon")}
              >
                Change offer
              </p>
            </div>
            <div className="mt-2 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <img src="/static/svg/check.svg" alt="check" />
                <p className="mb-0 font14x400 textColor3">
                  Code {appliedCoupon?.couponCode} applied!
                </p>
              </div>
              <p className="mb-0 textColor3 font16x400 cursor_pointer">
                {appliedCoupon?.type == "Service"
                  ? "Free Service"
                  : `- ₹${appliedCoupon?.discountAmount}`}
              </p>
            </div>
          </div>
        ) : (
          //  coupon before applied
          <div
            className="d-flex justify-content-between align-items-center cursor_pointer"
            onClick={() => {
              if (walletInfo?.isApplyWallet) {
                showToast(
                  "Coupons cannot be combined with wallet balance",
                  "error"
                );
              } else {
                fn.openModal("coupon");
              }
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <img
                src="/static/svg/offer-and-deal.svg"
                alt="coupon"
                width={20}
              />
              <p className="mb-0 font14x700 textColor3">Use Coupon</p>
            </div>
            <RightChevronIcon width="14" height="12" fill="#AF031A" />
          </div>
        )}
      </div>

      {/* coupon modal */}
      {state?.modal && state?.check === "coupon" && (
        <Coupon
          state={state}
          setState={setState}
          setPaymentDetails={setPaymentDetails}
          setAppliedCoupon={setAppliedCoupon}
        />
      )}
    </>
  );
};
export default CouponSection;
