import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import SavedAddress from "../../../../../components/shared/Address/SavedAddress";
import Header from "../../../../../components/shared/Header";
import Separator from "../../../../../components/shared/Separator";
import { AddressBookPage } from "../../../../../utils/metaInfo/metaInfo";
import { useContext, useEffect, useState } from "react";
import { commonType } from "../../../type";
import MapView from "../../../../../components/shared/Address/ChooseLocation";
import { Auth } from "../../../../../utils/context/AuthContext";
import { Plus } from "../../../../../components/shared/Icons";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import SpinLoader from "../../../../../components/shared/Loader/SpinLoader";
import { setOverflowToBodyUnset } from "../../../../../utils/function/function";

const AddressBook = () => {
  const navigate = useNavigate();
  const { addresses, getUserAddresses } = useContext(Auth);
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });

  const { addressLoading } = useContext(Auth);

  const fn = {
    handleClickOnUseCurrentLocation: () => {
      setCommonData?.({
        modal: true,
        check: "choose_location",
      });
      setOverflowToBodyUnset();
    },
    handleClickOnEditIcon: (add: string) => {
      setCommonData?.({
        data: add,
        modal: true,
        check: "choose_location",
      });
      setOverflowToBodyUnset();
    },
  };

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Helmet>
          <title>{AddressBookPage.title}</title>
          <meta name="description" content={AddressBookPage.description} />
          <meta name="keywords" content={AddressBookPage.keywords} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>

        <Header label="Address Book" navigate={() => navigate(-1)} />

        {/* add address */}
        <div
          className="add_address_wrapper py-3"
          onClick={() => {
            fn.handleClickOnUseCurrentLocation();
          }}
        >
          <div className="d-flex align-items-center gap-3 add_address_link_section">
            <Plus width="16" height="16" />
            <p className="mb-0 font14x500 textColor3">Add Address</p>
          </div>
        </div>
        <Separator />

        {/* saved address */}
        {addressLoading ? (
          <div className="mt-5">
            <SpinLoader height="60" width="60" color="#AF031A" />
          </div>
        ) : (
          <SavedAddress
            handleClickOnEditIcon={fn.handleClickOnEditIcon}
            getUserAddresses={getUserAddresses}
            addresses={addresses}
            isSelectAddress={false}
          />
        )}
      </div>

      {commonData?.modal && commonData?.check === "choose_location" && (
        <MapView
          getUserAddresses={getUserAddresses}
          state={commonData}
          setState={setCommonData}
          addresses={addresses}
        />
      )}
    </>
  );
};

export default AddressBook;
