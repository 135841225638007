import { Link } from "react-router-dom";

const CompanyLinks = () => {
  return (
    <>
      <div>
        <h3 className="mb-0 text-white font16x600">COMPANY</h3>
        <div className="mt-3 d-flex flex-column gap-2">
          <p className="mb-0 font14x500">
            <Link to="/about-us" className="text-white text-decoration-none">
              About us
            </Link>
          </p>
          <p className="mb-0 font14x500">
            <Link
              to="https://getlook.in/custtnc/"
              className="text-white text-decoration-none"
            >
              Terms & Conditions
            </Link>
          </p>
          <p className="mb-0 font14x500">
            <Link
              to="/privacy-policy"
              className="text-white text-decoration-none"
            >
              Privacy Policy
            </Link>
          </p>
          <p className="mb-0 font14x500">
            <Link to="/faqs" className="text-white text-decoration-none">
              FAQs
            </Link>
          </p>
          <p className="mb-0 font14x500">
            <Link
              to="/franchise-getlook"
              className="text-white text-decoration-none"
            >
              Franchise
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default CompanyLinks;
