import React, { useContext } from "react";
import { commonType } from "../../../../pages/main/type";
import { postAddress } from "../../../../lib/api functions/address/address";
import { showToast } from "../../../../lib/toast/showToast";
import { Auth } from "../../../../utils/context/AuthContext";
import useLoading from "../../../../hooks/useLoading";
import SpinLoader from "../../Loader/SpinLoader";
import { setOverflowToBodyUnset } from "../../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const DeleteAddress: React.FC<propType> = (props) => {
  const { state, setState } = props;
  const { getUserAddresses } = useContext(Auth);
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
    deleteAddress: async () => {
      showLoader();
      const values = {
        type: "delete",
        id: state?.data,
      };
      const res = await postAddress(values);
      if (res) {
        if (res?.data?.success) {
          getUserAddresses();
          showToast("Address deleted successfully", "success");
          localStorage.removeItem("userAddress");
          fn.setPositionToBody();
          hideLoader();
        } else {
          showToast(res?.data?.msg, "error");
        }
      }
    },
  };

  return (
    <>
      <div className="modal_wrapper" onClick={fn.setPositionToBody}>
        <div className="modal_section">
          <p className="mt-3 text-center font16x400 textColor3">
            Are you sure you want to delete this address?
          </p>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="button"
              className="btn_outline font14x400 textColor1"
              onClick={fn.setPositionToBody}
            >
              No
            </button>
            <button
              onClick={fn.deleteAddress}
              type="submit"
              className="btn_login font14x400"
            >
              {loading ? (
                <SpinLoader height="22" width="22" color="#fff" />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAddress;
