import { commonType } from "../../../../pages/main/type";
import { setOverflowToBodyUnset } from "../../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  handleClickOnYesOnTravellingModal: () => void;
};

const TravellingModal: React.FC<propType> = (props) => {
  const { state, setState, handleClickOnYesOnTravellingModal } = props;
  const fn = {
    handleClickOnNo: () => {
      setState({
        modal: true,
        check: "select_address",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper">
        <div className="modal_section">
          <p className="mt-3 text-center font14x400 textColor3">
            Rs. {state?.data?.charges} travelling charges will apply on this
            location. Are you sure want to apply?
          </p>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="button"
              className="btn_outline font14x400 textColor1"
              onClick={() => {
                fn.handleClickOnNo();
              }}
            >
              No
            </button>
            <button
              type="submit"
              className="btn_login font14x400"
              onClick={() => {
                handleClickOnYesOnTravellingModal();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravellingModal;
