import { commonType } from "../../../pages/main/type";
import { handlingFeeId } from "../../../utils/constant/constant";
import {
  getServiceById,
  setOverflowToBodyUnset,
} from "../../../utils/function/function";
import { CloseIcon } from "../Icons";
import SpinLoader from "../Loader/SpinLoader";
import RadioButton from "../RadioButton";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  paymentDetails: any;
  setPaymentDetails: (paymentDetails: any) => void;
  proceedPayment: () => void;
  loading: boolean;
};

const ProceedToPay: React.FC<propType> = (props) => {
  const {
    state,
    setState,
    paymentDetails,
    setPaymentDetails,
    proceedPayment,
    loading,
  } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div
        className="view_details_page"
        style={{
          gridTemplateRows: "auto max-content",
        }}
      >
        {/* cancel */}
        <div className="cancel-container">
          <button
            onClick={fn.setPositionToBody}
            className="d-flex align-items-center justify-content-center bg-black text-white border-0"
          >
            <CloseIcon width="20" height="20" fill="#fff" />
          </button>
        </div>

        <div className="payment_methods_wrapper">
          <div className="payment_methods_container bg-white">
            <h1 className="payment_methods_text mb-4 font16x700">
              Payment methods
            </h1>
            <label
              htmlFor="Cash"
              className="mb-4 cod-payment_method_container payment_method_container d-flex align-items-center justify-content-between"
            >
              <div className="d-flex align-items-center gap-2">
                <img src="/static/svg/cod.svg" alt="cash" />
                <div>
                  <p className="mb-0 font14x400 textColor3 mb-1">Cash</p>
                  {getServiceById(handlingFeeId)?.cost ? (
                    <p className="mb-0 font10x400 textColor3">
                      Handling fee of ₹{getServiceById(handlingFeeId)?.cost} is
                      applicable
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                <RadioButton
                  label={""}
                  htmlFor={""}
                  name={"method"}
                  value={"Cash"}
                  state={paymentDetails?.paymentMethod == "Cash" ? true : false}
                  setState={(e) => {
                    setPaymentDetails((prev: any) => {
                      return {
                        ...prev,
                        paymentMethod: e.target.value,
                        handlingFee: getServiceById(handlingFeeId)?.cost
                          ? getServiceById(handlingFeeId)?.cost
                          : 0,
                      };
                    });
                  }}
                  isDisabled={false}
                  onBlur={""}
                  id="Cash"
                />
              </div>
            </label>
            <label
              htmlFor="payOnlineAfterService"
              className="cod-payment_method_container payment_method_container d-flex align-items-center justify-content-between"
            >
              <div className="d-flex align-items-center gap-2">
                <div className="pay-online-after-service">
                  <img src="/static/img/qr-code.png" alt="cod" />
                </div>
                <div>
                  <p className="mb-0 font14x400 textColor3 mb-1">
                    Pay Online After Service
                  </p>
                  {getServiceById(handlingFeeId)?.cost ? (
                    <p className="mb-0 font10x400 textColor3">
                      Handling fee of ₹{getServiceById(handlingFeeId)?.cost} is
                      applicable
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                <RadioButton
                  label={""}
                  htmlFor={""}
                  name={"method"}
                  value={"Pay Online After Service"}
                  state={
                    paymentDetails?.paymentMethod == "Pay Online After Service"
                      ? true
                      : false
                  }
                  setState={(e) => {
                    setPaymentDetails((prev: any) => {
                      return {
                        ...prev,
                        paymentMethod: e.target.value,
                        handlingFee: getServiceById(handlingFeeId)?.cost
                          ? getServiceById(handlingFeeId)?.cost
                          : 0,
                      };
                    });
                  }}
                  isDisabled={false}
                  onBlur={""}
                  id="payOnlineAfterService"
                />
              </div>
            </label>

            <label
              htmlFor="Online"
              className="payment_method_container d-flex align-items-center justify-content-between mt-4"
            >
              <div className="d-flex align-items-center gap-2">
                <img src="/static/svg/payu.svg" alt="payu" />
                <div>
                  <p className="mb-0 font14x400 textColor3 mb-1">Online</p>
                  <p className="mb-0 font9x400 textColor3">
                    No fee on UPI. Upto 10% cashback on online payment
                  </p>
                </div>
              </div>
              <div>
                <RadioButton
                  label={""}
                  htmlFor={""}
                  name={"method"}
                  value={"Online"}
                  state={
                    paymentDetails?.paymentMethod == "Online" ? true : false
                  }
                  setState={(e) => {
                    setPaymentDetails((prev: any) => {
                      return {
                        ...prev,
                        paymentMethod: e.target.value,
                        handlingFee: 0,
                      };
                    });
                  }}
                  isDisabled={false}
                  onBlur={""}
                  id="Online"
                />
              </div>
            </label>
          </div>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="submit"
              className="btn_login font14x500"
              onClick={proceedPayment}
              disabled={loading ? true : false}
            >
              {loading ? (
                <SpinLoader height="22" width="22" color="#fff" />
              ) : (
                `Pay ₹${state?.data?.grandTotal}`
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProceedToPay;
