import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IoIosCopy } from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import OfferDetails from "../../../components/pages/Offer/OfferDetails";
import BottomTopAnimation from "../../../components/shared/BottomTopAnimation";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import Header from "../../../components/shared/Header";
import useLoading from "../../../hooks/useLoading";
import { getOffer } from "../../../lib/api functions/offer/offer";
import {
  copyToClipboard,
  filterOfferData,
  getImageURL,
  setOverflowToBodyHidden,
} from "../../../utils/function/function";
import { OffersPage } from "../../../utils/metaInfo/metaInfo";
import { commonType } from "../type";

const OfferAndDeal = () => {
  const navigate = useNavigate();
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [offers, setOffers] = useState<any>([]);

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    // activeCategory: () => {
    //   const category: any = document.getElementsByClassName(
    //     "single_discount_option"
    //   );

    //   category[0].click();
    // },
    getOfferDataFromAPI: async (params: any) => {
      showLoader();
      const res = await getOffer(params);
      if (res) {
        const dataRes = filterOfferData(res.data?.objects);
        if (dataRes) {
          setOffers(dataRes);
          hideLoader();
        }
      }
    },
  };

  useEffect(() => {
    fn.getOfferDataFromAPI({});
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  // useEffect(() => {
  //   if (offers.length > 0) {
  //     fn.activeCategory();
  //   }
  // }, [offers]);

  return (
    <>
      <div className="padding_bottom offers_deals_page">
        <Helmet>
          <title>{OffersPage.title}</title>
          <meta name="description" content={OffersPage.description} />
          <meta name="keywords" content={OffersPage.keywords} />
        </Helmet>

        <Header label="Offers & Deals" navigate={() => navigate("/")} />

        {/* discount options */}
        <div className="discount_options_wrapper">
          <div className="discount_section">
            {loading ? (
              <></>
            ) : (
              offers?.length &&
              offers?.map((item: any, i: number) => {
                return (
                  <a
                    href={`#${item?.title?.split(" ").join("_")}`}
                    key={i}
                    className="single_discount_option text-center text-decoration-none"
                  >
                    <img
                      src={`${
                        item?.image
                          ? getImageURL(item?.image)
                          : "https://s3.ap-south-1.amazonaws.com/getlook.in/category/coupon.jpg"
                      }`}
                      alt="option"
                      className={`${
                        window.location.hash ==
                        `#${item?.title?.split(" ").join("_")}`
                          ? "active_category"
                          : "category_image"
                      }`}
                      style={{ objectFit: "cover" }}
                    />
                    <p className="mb-0 mt-2 font10x400 textColor3">
                      {item?.title}
                    </p>
                  </a>
                );
              })
            )}
          </div>
        </div>

        {/* discount items */}
        <div className="offers_deals_container">
          {offers?.map((item: any, i: number) => {
            return (
              <div
                key={i}
                id={item?.title?.split(" ").join("_")}
                className="discount_items_wrapper"
              >
                <h2 className="mb-0 font16x700">{item?.title}</h2>
                <div className="discount_items_section d-flex flex-column gap-3 mt-2">
                  {item?.values?.map((singleOffer: any, index: number) => {
                    return (
                      <div key={index} className="single_discount_item">
                        <img
                          src={getImageURL(singleOffer?.image)}
                          className="w-100 border_radius_8"
                          alt="discount"
                        />
                        <div className="py-2 d-flex justify-content-between align-items-center">
                          <div className="d-flex gap-2 align-items-center">
                            <p className="mb-0 font12x500 textColor3">
                              Coupon Code:
                            </p>
                            <p className="mb-0 font16x700 textColor1">
                              {singleOffer?.amount}
                            </p>
                            <i
                              className="cursor_pointer textColor7"
                              onClick={() =>
                                copyToClipboard(singleOffer?.amount)
                              }
                            >
                              <IoIosCopy />
                            </i>
                          </div>
                          <i
                            className="cursor_pointer textColor1"
                            onClick={() => {
                              fn.openModal(singleOffer, "offer_details");
                            }}
                          >
                            <MdInfoOutline
                              style={{
                                fontSize: 20,
                              }}
                            />
                          </i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* offer details */}
      {commonData?.modal && commonData?.check === "offer_details" && (
        <BottomTopAnimation>
          <OfferDetails state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default OfferAndDeal;
