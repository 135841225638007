import moment from "moment";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../../utils/context/AuthContext";
import { isUserValid } from "../../../utils/function/function";
import { UserDefault } from "../../shared/Icons";
import ShowInitialImage from "../../shared/InitialImage";

const UserDetails = () => {
  const navigate = useNavigate();
  const { user } = useContext(Auth);

  return (
    <>
      <div className="user_details_wrapper">
        {isUserValid() ? (
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-6 user_details_container">
                <p className="mb-0 font16x500 textColor3">
                  {user?.first_name?.trim() ?? "--"}
                </p>
                {user?.isMember ? (
                  <p className="mb-0 font12x400 textColor3">
                    Member Since:{" "}
                    {moment(user?.membershipActivationDate).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                ) : (
                  <p className="mb-0 font12x400 textColor3">Not a member</p>
                )}
                <p className="mb-0 font14x400 textColor1 d-flex align-items-center">
                  <span className="me-1">
                    <img src="/static/svg/view-number.svg" alt="view_number" />
                  </span>
                  {user?.phone ?? "--"}
                </p>
              </div>
              <div className="col-6 d-flex justify-content-end">
                {user?.profile_image?.length > 0 ? (
                  <UserDefault width="78" height="78" />
                ) : (
                  <ShowInitialImage
                    width="64px"
                    height="64px"
                    data={user?.first_name?.trim()}
                    fontSize="30px"
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="font14x400 textColor3">Log in to view your profile</p>
            <button
              type="button"
              className="btn_outline font14x400 textColor1"
              onClick={() => navigate("/otp-login")}
            >
              Login
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default UserDetails;
