import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderAddress from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/OrderAddress";
import OrderPaymentDetails from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/OrderPaymentDetails";
import OrderPricePaymentDetails from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/OrderPricePaymentDetails";
import OrderRating from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/OrderRating";
import ServiceOrderDetails from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/ServiceOrder/ServiceOrderDetails";
import Header from "../../../../../../../components/shared/Header";
import useLoading from "../../../../../../../hooks/useLoading";
import { getAppointments } from "../../../../../../../lib/api functions/order/order";
import OrderDetailsLoader from "./loader";
import { calculateOrderPaymentDetails } from "../../../../../../../utils/function/function";
import { gtm, ReactPixel } from "../../../../../../../components/shared/GTM";
import BeauticianDetails from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/BeauticianDetails/BeauticianDetails";
import CancellationFee from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/CancellationFee/CancellationFee";
import Attention from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/Attention/Attention";
import RecommendedUpgrade from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/Recommended Upgrade";
import RecommendedAddOn from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/Recommended AddOn";

const OrderDetails = () => {
  const navigate = useNavigate();
  const [queryData, setQueryData] = useSearchParams();
  const [loading, showLoader, hideLoader] = useLoading();
  const [singleOrder, setSingleOrder] = useState<any>([]);
  const [paymentDetails, setPaymentDetails] = useState<any>({});

  const fn = {
    getSingleOrderFn: async () => {
      showLoader();
      const params = {
        id: queryData.get("order_id"),
      };
      const res = await getAppointments(params);
      if (res) {
        const data1 = res.data.find((ord: any) => ord.id == params.id);
        setSingleOrder(data1);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    if (singleOrder) {
      setPaymentDetails(calculateOrderPaymentDetails(singleOrder));
    }
  }, [singleOrder]);
  useEffect(() => {
    window.scrollTo(0, 0);

    queryData.get("order_id") && fn.getSingleOrderFn();
  }, []);
  // redirect to orders page if it gets null in query string
  useEffect(() => {
    !queryData.get("order_id") && navigate("/order");
  }, [queryData]);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, [singleOrder]);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "var(--bg-color2)",
        }}
      >
        <Header label="Order Details" navigate={() => navigate("/order")} />
        {loading ? (
          <OrderDetailsLoader />
        ) : (
          <>
            {/* order price payment status details */}
            <div className="order_id_price d-flex justify-content-between">
              <p className="mb-0 text-white font14x600">{`Order#${singleOrder?.id}`}</p>
              <p className="mb-0 text-white font14x600">
                ₹{paymentDetails?.grandTotal}
              </p>
            </div>

            <OrderPricePaymentDetails singleOrder={singleOrder} />

            {/* beautician details */}
            <BeauticianDetails singleOrder={singleOrder} />

            {/* order details */}
            <ServiceOrderDetails singleOrder={singleOrder} />

            {singleOrder?.specialInstructions?.includes("|") &&
            singleOrder?.specialInstructions?.split("|")[0] ? (
              <div className="special_instructions_container d-flex">
                <p className="mb-0 text-black font14x500">Instructions:- </p>
                <p className="mb-0 text-black font14x500 ms-3">
                  {singleOrder?.specialInstructions?.split("|")[0]}
                </p>
              </div>
            ) : (
              ""
            )}

            {/* recommended upgrade section */}
            {singleOrder?.status == 0 ||
            singleOrder?.status == 1 ||
            singleOrder?.status == 13 ||
            singleOrder?.status == 3 ||
            singleOrder?.status == 4 ? (
              <RecommendedUpgrade orderDetails={singleOrder} />
            ) : (
              ""
            )}

            {/* recommended add on section */}
            {singleOrder?.status == 0 ||
            singleOrder?.status == 1 ||
            singleOrder?.status == 13 ||
            singleOrder?.status == 3 ||
            singleOrder?.status == 4 ? (
              <RecommendedAddOn orderDetails={singleOrder} />
            ) : (
              ""
            )}

            <CancellationFee singleOrder={singleOrder} />

            <Attention singleOrder={singleOrder} />

            {/* payment details */}
            <OrderPaymentDetails
              loading={loading}
              paymentDetails={paymentDetails}
              singleOrder={singleOrder}
            />

            {/* order rating */}
            <OrderRating
              singleOrder={singleOrder}
              getSingleOrderFn={fn.getSingleOrderFn}
            />

            {/* address section */}
            <OrderAddress singleOrder={singleOrder} />
          </>
        )}
      </div>
    </>
  );
};

export default OrderDetails;
