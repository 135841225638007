/**
 * @author Deepanshu Tyagi
 */

import { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import "./index.css";

type PropType = {
  allCategories: any;
  mainLoading: boolean;
  linkFor: string;
};

const Categories: React.FC<PropType> = (props) => {
  const { allCategories, mainLoading, linkFor } = props;
  const { homepageCategory } = useParams();
  const { setNewURLAfterReplace } = useContext(Auth);
  const navigate = useNavigate();

  const fn = {
    scrollToCategory: (id?: string) => {
      const all_single_category_link = document.getElementsByClassName(
        "single_category_link"
      );

      let loop = 0;
      for (loop = 0; loop <= all_single_category_link?.length - 1; loop++) {
        if (all_single_category_link[loop].id.split("_")[1] === id) {
          all_single_category_link[loop].scrollIntoView(true);
        }
      }
    },
    handleClickOnCategory: (item: any) => {
      const all_sub_category = document.getElementById("all_sub_category");
      setNewURLAfterReplace(`/${linkFor}/${item?.id}`);
      navigate(`/${linkFor}/${item?.id}`, { replace: true });
      all_sub_category?.scrollTo(0, 0);
    },
  };

  useEffect(() => {
    !mainLoading && fn.scrollToCategory(homepageCategory);
  }, [mainLoading]);

  return (
    <div className="categories_wrapper">
      <div className="categories_section" id="all_categories">
        {mainLoading ? (
          <div className="service_page_categories_loader d-flex">
            <div className="single_category">
              <Skeleton width="52px" height="52px" borderRadius="50%" />
            </div>
            <div className="single_category">
              <Skeleton width="52px" height="52px" borderRadius="50%" />
            </div>
            <div className="single_category">
              <Skeleton width="52px" height="52px" borderRadius="50%" />
            </div>
            <div className="single_category">
              <Skeleton width="52px" height="52px" borderRadius="50%" />
            </div>
            <div className="single_category">
              <Skeleton width="52px" height="52px" borderRadius="50%" />
            </div>
          </div>
        ) : (
          allCategories?.map((item: any, i: number) => {
            return (
              <Link
                key={i}
                id={`parent_${item?.id}`}
                // to={`/${linkFor}/${item?.id}`}
                to=""
                className="single_category_link text-decoration-none"
                replace
                onClick={() => {
                  // updating current location in history
                  fn.handleClickOnCategory(item);
                }}
              >
                <div className="single_category text-center">
                  {item?.category_image && (
                    <img
                      src={item?.category_image}
                      alt="category"
                      style={{
                        border:
                          homepageCategory === item?.id?.toString()
                            ? "2px solid var(--border-color1)"
                            : "none",
                        borderRadius: "8px",
                      }}
                      width={64}
                      height={63}
                    />
                  )}
                  <p className="mb-0 mt-2 font10x500 textColor3">
                    {item?.name}
                  </p>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Categories;
