import moment from "moment";
import { showToast } from "../../lib/toast/showToast";
import {
  ANDROID_WEB,
  Caching_Time,
  conveyanceId,
  couponId,
  handlingFeeId,
  IOS_WEB,
  membershipDiscountId,
  membershipId,
  MOBILE_WEB,
  Popup_Banner_Cache_Time,
  premiumId,
  previousDuesId,
  rescheduleChargeId,
  safetyId,
  Stylist_Assigned_Time,
  surgeId,
  Tracking_Cache_Time,
  upgradeId,
  walletId,
  WEB,
} from "../constant/constant";

//check user is valid or not
export const isUserValid = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return true;
  }
  return false;
};

//handle OTP time
export const handleTime = (expireTime: number): string => {
  let s = expireTime % 60 > 9 ? expireTime % 60 : "0" + (expireTime % 60);
  let m =
    Math.floor(expireTime / 60) > 9
      ? Math.floor(expireTime / 60)
      : "0" + Math.floor(expireTime / 60);
  return `${m}:${s}`;
};

//get default initial of user
export const getDefaultInitial = (str: string): string => {
  let arr = str?.split(" ");
  let finalStr = "";
  if (arr?.length === 1) {
    finalStr = `${arr[0]?.charAt(0)}`;
  } else {
    finalStr = `${arr[0]?.charAt(0)}${arr[arr?.length - 1]?.charAt(0)}`;
  }
  return finalStr?.toUpperCase();
};

//checking time diffrence acc. to caching time
export const checkingTimeDifference = (): boolean => {
  const api_cache_timestamp = JSON.parse(
    localStorage.getItem("api_cache_timestamp")!
  );
  const convert_initialTimestampToDate = moment(api_cache_timestamp);
  const latest_timestamp = moment();
  const timeDiff = latest_timestamp.diff(
    convert_initialTimestampToDate,
    "minutes"
  );

  if (timeDiff > Caching_Time) {
    return true;
  } else {
    return false;
  }
};

//checking tracking time diffrence acc. to tracking caching time
export const checkingTrackingTimeDifference = (): boolean => {
  let tracking_cache_timestamp = window.localStorage.getItem(
    "tracking_cache_timestamp"
  );

  if (!tracking_cache_timestamp) {
    window.localStorage.setItem(
      "tracking_cache_timestamp",
      JSON.stringify(moment())
    );

    return false;
  }

  tracking_cache_timestamp = JSON.parse(tracking_cache_timestamp!);
  const convert_initialTimestampToDate = moment(tracking_cache_timestamp);
  const latest_timestamp = moment();
  const timeDiff = latest_timestamp.diff(
    convert_initialTimestampToDate,
    "minutes"
  );

  if (timeDiff > Tracking_Cache_Time) {
    return true;
  } else {
    return false;
  }
};

// filter homepage sections data
export const filterHomepageSections = (allData: any, check: string) => {
  const filterData = allData?.filter((item: any) => {
    return item?.brand === check;
  });
  return filterData;
};

// filter sub categories data
export const filterSubCategories = (allData: any, ids: string[]): any => {
  let arr: any = [];

  try {
    for (let j = 0; j < ids?.length; j++) {
      if (allData[Number(ids[j])]) {
        arr.push({
          subCategoryId: allData[Number(ids[j])][0]?.serviceType?.category?.id,
          subCategoryName:
            allData[Number(ids[j])][0]?.serviceType?.category?.name,
          subCategoryImage:
            allData[Number(ids[j])][0]?.serviceType?.category?.category_image,
          services: allData[Number(ids[j])]?.sort((a: any, b: any) => {
            return a.serviceType?.gender - b.serviceType?.gender;
          }),
        });
      }
    }
  } catch (error) {
    showToast("Something went wrong", "error");
  }
  return arr;
};

// filter service by id
export const getServiceById = (serviceId: number): any => {
  const allServices =
    JSON.parse(`${localStorage.getItem("homepage_categories")}`) || null;
  let service: any = {};

  if (allServices) {
    Object.keys(allServices)?.map((key: any) => {
      allServices[key]?.map((singleService: any) => {
        if (singleService?.serviceType?.id === serviceId) {
          service = singleService;
        }
      });
    });
  }

  return service;
};

/**@author Deepanshu Tyagi */
// increasing and decreasing the cart items
export const increaseDecreaseItem = (
  item: any,
  button: string,
  cartItemsInContext: any
) => {
  let serviceList = cartItemsInContext;
  const appliedCoupon =
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;
  serviceList = serviceList.filter((serviceItem: any) => {
    if (serviceItem?.serviceType?.id == item?.serviceType?.id) {
      // adding items
      if (button == "+") {
        serviceItem.count = serviceItem.count + 1;
        return serviceItem;
      }
      // decreasing items
      else if (button == "-") {
        if (serviceItem.count == 1) {
          return;
        } else {
          serviceItem.count = serviceItem.count - 1;
          return serviceItem;
        }
      }
    } else {
      return serviceItem;
    }
  });
  serviceList = serviceList.filter((serviceItem: any) => {
    if (
      serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
      appliedCoupon?.type == "Service"
    ) {
      return false;
    } else {
      return true;
    }
  });
  localStorage.removeItem("coupon_applied");
  localStorage.removeItem("conveyanceCharge");
  localStorage.removeItem("slotInfo");
  localStorage.setItem("cartItems", JSON.stringify(serviceList));
  return serviceList;
};
/* */

// getting charge from categories
export const getChargeFromHomeCategories = (id: string): any => {
  const homeCategories = JSON.parse(
    `${localStorage.getItem("homepage_categories")}`
  );
  const service = homeCategories[129].find((item: any) => {
    return item.serviceType.id == id;
  });
  return service;
};

// checking user address
export const checkUserAddress = (data: any): any => {
  if (data.length == 0) {
    return false;
  } else if (JSON.parse(localStorage.getItem("userAddress")!)) {
    return true;
  }
  return false;
};

// filter offer data
export const filterOfferData = (data: any): any => {
  const uniqueOfferTitles = new Set();
  let filteredOfferData: any = [];

  // getting unique offer titles
  for (let i = 0; i < data.length; i++) {
    if (data[i].terms_conditions?.length > 0) {
      uniqueOfferTitles.add(data[i].terms_conditions);
    }
  }

  // filtering offer acc. to unique offer titles
  uniqueOfferTitles.forEach((value: any) => {
    type SingleOfferType = {
      title: string;
      image: string | null;
      values: any;
    };
    const singleOffer: SingleOfferType = {
      title: "",
      image: null,
      values: [],
    };

    for (let i = 0; i < data.length; i++) {
      if (
        data[i].amount?.length > 0 &&
        data[i].terms_conditions?.length > 0 &&
        data[i].terms_conditions === value
      ) {
        singleOffer.title = value;
        singleOffer.image = null;
        singleOffer.values.push(data[i]);
      }
    }
    filteredOfferData.push(singleOffer);
  });

  return filteredOfferData;
};

// copy text to clipboard
export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  showToast("Copied", "success");
};

// calculate Payment Details
export const calculatePaymentDetails = () => {
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
  const conveyanceCharge = localStorage.getItem("conveyanceCharge") || 0;
  const walletDiscount = Number(localStorage.getItem("walletDiscount")) || 0;
  const previousDues = localStorage.getItem("previousDues") || 0;
  const user = JSON.parse(`${localStorage.getItem("user")}`);
  const safetyAndHygieneService: any = getServiceById(safetyId);
  const appliedCoupon = JSON.parse(`${localStorage.getItem("coupon_applied")}`);
  const slot = JSON.parse(`${localStorage.getItem("slotInfo")}`);
  let couponDiscount = 0;
  if (appliedCoupon?.type != "Service") {
    couponDiscount = Math.round(appliedCoupon?.discountAmount) || 0;
  }

  if (appliedCoupon?.type != "Service") {
    couponDiscount = Math.round(appliedCoupon?.discountAmount) || 0;
  }

  if (slot?.date) {
    if (
      appliedCoupon?.weekend == 1 &&
      (moment(slot?.date).format("ddd") == "Sun" ||
        moment(slot?.date).format("ddd") == "Sat" ||
        moment(slot?.date).format("ddd") == "Fri")
    ) {
      couponDiscount = appliedCoupon?.discountAmount / 2;
    }
  }

  let paymentDetails = {
    subTotal: 0,
    discount: 0,
    couponDiscount: couponDiscount,
    grandTotal: 0,
    safetyAndHygieneCharge: safetyAndHygieneService?.cost,
    conveyanceCharge: Math.round(Number(conveyanceCharge)),
    premiumCharge: 0,
    surgeFee: 0,
    membershipDiscount: 0,
    upgradedServiceCharge: 0,
    previousDues: 0,
    walletDiscount: walletDiscount,
  };
  // previous dues
  if (previousDues) {
    paymentDetails.previousDues = +previousDues;
  }
  // premium fee
  if (slot?.beautician == "premium") {
    paymentDetails.premiumCharge = Math.round(slot?.premiumBeauticianCharge);
  }
  // check membership service in cartitems
  const isMembershipService = cartItems?.some((item: any) => {
    return item?.serviceType?.id == membershipId;
  });
  // surge fee
  if (slot?.surge) {
    paymentDetails.surgeFee = Math.round(slot?.surge);
  }
  cartItems?.forEach((item: any) => {
    if (item.added) {
      // subtotal

      if (item?.serviceType?.cost > item.cost) {
        paymentDetails.subTotal += Math.round(
          item?.serviceType?.cost * item.count
        );
      } else {
        paymentDetails.subTotal += Math.round(item?.cost * item.count);
      }

      // discount
      if (item?.serviceType?.cost > item.cost) {
        paymentDetails.discount += Math.round(
          item?.serviceType?.cost * item?.count - item.cost * item?.count
        );
      }
      // upgraded service charge
      if (item?.special_instructions?.length > 0) {
        item?.special_instructions.forEach((upgradedServiceItem: any) => {
          if (upgradedServiceItem?.header?.includes("Upgrade")) {
            paymentDetails.upgradedServiceCharge += Math.round(
              calculateUpgradeCost(
                upgradedServiceItem?.id,
                upgradedServiceItem?.previousServiceId
              )
            );
          }
        });
      }
    }
  });
  // membership discount
  let packageDiscount = 0;
  let serviceDiscount = 0;
  let productDiscount = 0;
  if (user?.isMember || (isMembershipService && cartItems?.length != 1)) {
    cartItems?.forEach((item: any) => {
      const packageDiscountPercent = 5;
      const serviceProductDiscountPercent = 10;
      if (item.added) {
        if (item?.isPackage) {
          packageDiscount +=
            (packageDiscountPercent * item.cost * item.count) / 100;
        } else if (item?.isProduct) {
          productDiscount +=
            (serviceProductDiscountPercent * item.cost * item.count) / 100;
        } else if (appliedCoupon?.type == "Service" && item?.isService) {
          if (+appliedCoupon?.discount != item?.serviceType?.id) {
            serviceDiscount +=
              (serviceProductDiscountPercent * item.cost * item.count) / 100;
          }
        } else if (item?.isService && appliedCoupon?.type != "Service") {
          serviceDiscount +=
            (serviceProductDiscountPercent * item.cost * item.count) / 100;
        }
      }
    });
  }
  //membership discount max 200
  let membershipDiscount = Math.round(
    packageDiscount + serviceDiscount + productDiscount
  );
  if (membershipDiscount > 200) {
    paymentDetails.membershipDiscount = 200;
  } else {
    paymentDetails.membershipDiscount = membershipDiscount;
  }
  // grandtotal
  let discountedGrandtotal;

  if (window?.location?.pathname == "/checkout") {
    discountedGrandtotal =
      paymentDetails.subTotal -
      (paymentDetails.discount +
        Math.round(Number(paymentDetails.couponDiscount)) +
        paymentDetails.membershipDiscount +
        paymentDetails?.walletDiscount);
  } else {
    discountedGrandtotal =
      paymentDetails.subTotal -
      (paymentDetails.discount +
        Math.round(Number(paymentDetails.couponDiscount)) +
        paymentDetails.membershipDiscount);
  }
  const chargedGrandtotal =
    discountedGrandtotal +
    paymentDetails?.premiumCharge +
    Number(paymentDetails?.conveyanceCharge) +
    paymentDetails?.safetyAndHygieneCharge +
    paymentDetails?.surgeFee +
    paymentDetails?.upgradedServiceCharge +
    Number(paymentDetails?.previousDues);
  paymentDetails.grandTotal = chargedGrandtotal;
  return paymentDetails;
};

// calculatePaymentDetailsExceptSpecialOffers
export const calculatePaymentDetailsExceptSpecialOffers = () => {
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
  const conveyanceCharge = localStorage.getItem("conveyanceCharge") || 0;
  const previousDues = localStorage.getItem("previousDues") || 0;
  const user = JSON.parse(`${localStorage.getItem("user")}`);
  const safetyAndHygieneService: any = getServiceById(safetyId);
  const appliedCoupon = JSON.parse(`${localStorage.getItem("coupon_applied")}`);
  const slot = JSON.parse(`${localStorage.getItem("slotInfo")}`);
  let couponDiscount = 0;
  if (appliedCoupon?.type != "Service") {
    couponDiscount = Math.round(appliedCoupon?.discountAmount) || 0;
  }

  if (appliedCoupon?.type != "Service") {
    couponDiscount = Math.round(appliedCoupon?.discountAmount) || 0;
  }

  if (slot?.date) {
    if (
      appliedCoupon?.weekend == 1 &&
      (moment(slot?.date).format("ddd") == "Sun" ||
        moment(slot?.date).format("ddd") == "Sat" ||
        moment(slot?.date).format("ddd") == "Fri")
    ) {
      couponDiscount = appliedCoupon?.discountAmount / 2;
    }
  }

  let paymentDetails = {
    subTotal: 0,
    discount: 0,
    couponDiscount: couponDiscount,
    grandTotal: 0,
    safetyAndHygieneCharge: safetyAndHygieneService?.cost,
    conveyanceCharge: Math.round(Number(conveyanceCharge)),
    premiumCharge: 0,
    surgeFee: 0,
    membershipDiscount: 0,
    upgradedServiceCharge: 0,
    previousDues: 0,
  };
  // previous dues
  if (previousDues) {
    paymentDetails.previousDues = +previousDues;
  }
  // premium fee
  if (slot?.beautician == "premium") {
    paymentDetails.premiumCharge = Math.round(slot?.premiumBeauticianCharge);
  }
  // check membership service in cartitems
  const isMembershipService = cartItems?.some((item: any) => {
    return item?.serviceType?.id == membershipId;
  });
  // surge fee
  if (slot?.surge) {
    paymentDetails.surgeFee = Math.round(slot?.surge);
  }
  cartItems?.forEach((item: any) => {
    if (item.added && item?.type !== "special_offer") {
      // subtotal

      if (item.serviceType.cost > item.cost) {
        paymentDetails.subTotal += Math.round(
          item?.serviceType?.cost * item.count
        );
      } else {
        paymentDetails.subTotal += Math.round(item?.cost * item.count);
      }

      // discount
      if (item.serviceType.cost > item.cost) {
        paymentDetails.discount += Math.round(
          item?.serviceType?.cost * item?.count - item.cost * item?.count
        );
      }
      // upgraded service charge
      if (item?.special_instructions?.length > 0) {
        item?.special_instructions.forEach((upgradedServiceItem: any) => {
          if (upgradedServiceItem?.header?.includes("Upgrade")) {
            paymentDetails.upgradedServiceCharge += Math.round(
              calculateUpgradeCost(
                upgradedServiceItem?.id,
                upgradedServiceItem?.previousServiceId
              )
            );
          }
        });
      }
    }
  });
  // membership discount
  let packageDiscount = 0;
  let serviceDiscount = 0;
  let productDiscount = 0;
  if (user?.isMember || (isMembershipService && cartItems?.length != 1)) {
    cartItems?.forEach((item: any) => {
      const packageDiscountPercent = 5;
      const serviceProductDiscountPercent = 10;
      if (item.added && item?.type !== "special_offer") {
        if (item?.isPackage) {
          packageDiscount +=
            (packageDiscountPercent * item.cost * item.count) / 100;
        } else if (item?.isProduct) {
          productDiscount +=
            (serviceProductDiscountPercent * item.cost * item.count) / 100;
        } else if (appliedCoupon?.type == "Service" && item?.isService) {
          if (+appliedCoupon?.discount != item?.serviceType?.id) {
            serviceDiscount +=
              (serviceProductDiscountPercent * item.cost * item.count) / 100;
          }
        } else if (item?.isService && appliedCoupon?.type != "Service") {
          serviceDiscount +=
            (serviceProductDiscountPercent * item.cost * item.count) / 100;
        }
      }
    });
  }
  //membership discount max 200
  let membershipDiscount = Math.round(
    packageDiscount + serviceDiscount + productDiscount
  );
  if (membershipDiscount > 200) {
    paymentDetails.membershipDiscount = 200;
  } else {
    paymentDetails.membershipDiscount = membershipDiscount;
  }
  // grandtotal
  const discountedGrandtotal =
    paymentDetails.subTotal -
    (paymentDetails.discount +
      Math.round(Number(paymentDetails.couponDiscount)) +
      paymentDetails.membershipDiscount);
  const chargedGrandtotal =
    discountedGrandtotal +
    paymentDetails?.premiumCharge +
    Number(paymentDetails?.conveyanceCharge) +
    paymentDetails?.safetyAndHygieneCharge +
    paymentDetails?.surgeFee +
    paymentDetails?.upgradedServiceCharge +
    Number(paymentDetails?.previousDues);
  paymentDetails.grandTotal = chargedGrandtotal;
  return paymentDetails;
};

// calculate order Payment Details
export const calculateOrderPaymentDetails = (singleOrder: any) => {
  const paymentDetails = {
    subTotal: 0,
    discount: 0,
    couponDiscount: singleOrder?.coupoun_discount,
    walletDiscount: 0,
    grandTotal: 0,
    safetyAndHygieneCharge: 0,
    conveyanceCharge: 0,
    premiumCharge: 0,
    surgeFee: 0,
    membershipDiscount: 0,
    upgradedServiceCharge: 0,
    rescheduleCharge: 0,
    previousDues: 0,
    handlingFee: 0,
  };

  singleOrder?.appointmentitem_set?.forEach((appointmentItem: any) => {
    if (appointmentItem?.service?.serviceType?.id == walletId) {
      paymentDetails.walletDiscount = -appointmentItem?.cost;
    } else if (appointmentItem?.service?.serviceType?.id == surgeId) {
      paymentDetails.surgeFee = appointmentItem?.cost;
    } else if (appointmentItem?.service?.serviceType?.id == premiumId) {
      paymentDetails.premiumCharge = appointmentItem?.cost;
    } else if (
      appointmentItem?.service?.serviceType?.id == membershipDiscountId
    ) {
      paymentDetails.membershipDiscount = -appointmentItem?.cost;
    } else if (appointmentItem?.service?.serviceType?.id == conveyanceId) {
      paymentDetails.conveyanceCharge = appointmentItem?.cost;
    } else if (appointmentItem?.service?.serviceType?.id == couponId) {
      paymentDetails.couponDiscount = -appointmentItem?.cost;
    } else if (appointmentItem?.service?.serviceType?.id == safetyId) {
      paymentDetails.safetyAndHygieneCharge =
        appointmentItem?.cost * appointmentItem?.count;
    } else if (appointmentItem?.service?.serviceType?.id == upgradeId) {
      paymentDetails.upgradedServiceCharge = appointmentItem?.cost;
    } else if (
      appointmentItem?.service?.serviceType?.id == rescheduleChargeId
    ) {
      paymentDetails.rescheduleCharge += appointmentItem?.cost;
    } else if (appointmentItem?.service?.serviceType?.id == previousDuesId) {
      paymentDetails.previousDues += appointmentItem?.cost;
    } else if (appointmentItem?.service?.serviceType?.id == handlingFeeId) {
      paymentDetails.handlingFee += appointmentItem?.cost;
    }
    // subtotal and discount
    if (
      appointmentItem?.service?.serviceType?.id != conveyanceId &&
      appointmentItem?.service?.serviceType?.id != premiumId &&
      appointmentItem?.service?.serviceType?.id != surgeId &&
      appointmentItem?.service?.serviceType?.id != walletId &&
      appointmentItem?.service?.serviceType?.id != membershipDiscountId &&
      appointmentItem?.service?.serviceType?.id != couponId &&
      appointmentItem?.service?.serviceType?.id != safetyId &&
      appointmentItem?.service?.serviceType?.id != upgradeId &&
      appointmentItem?.service?.serviceType?.id != rescheduleChargeId &&
      appointmentItem?.service?.serviceType?.id != previousDuesId
    ) {
      // subtotal
      if (appointmentItem?.service?.serviceType?.cost > appointmentItem?.cost) {
        paymentDetails.subTotal +=
          appointmentItem?.service?.serviceType?.cost * appointmentItem?.count;
      } else {
        paymentDetails.subTotal +=
          appointmentItem?.cost * appointmentItem?.count;
      }

      // discount
      if (appointmentItem?.service?.serviceType?.cost > appointmentItem?.cost) {
        paymentDetails.discount +=
          appointmentItem?.service?.serviceType?.cost * appointmentItem?.count -
          appointmentItem?.cost * appointmentItem?.count;
      }
    }
  });

  // all discount
  const discountedGrandtotal =
    paymentDetails.subTotal -
    (paymentDetails.discount +
      paymentDetails.couponDiscount +
      paymentDetails.membershipDiscount +
      paymentDetails.walletDiscount);

  // all extra charges
  const chargedGrandtotal =
    discountedGrandtotal +
    paymentDetails?.premiumCharge +
    paymentDetails?.conveyanceCharge +
    paymentDetails?.safetyAndHygieneCharge +
    paymentDetails?.surgeFee +
    paymentDetails?.upgradedServiceCharge +
    paymentDetails?.rescheduleCharge +
    paymentDetails?.previousDues;
  paymentDetails.grandTotal = chargedGrandtotal;

  return paymentDetails;
};

// checking order date timing with current timing
export const isSlotAboveGivenTime = (
  date: string,
  timing: string,
  timeInSeconds: number
): boolean => {
  let check = false;
  const slotTiming = timing;
  const slotStartTime = slotTiming?.split(" - ")[0];

  const slotStartTimeIn24Hours = moment(slotStartTime, ["h:mm A"]).format(
    "HH:mm"
  );

  if (
    moment(date + " " + slotStartTimeIn24Hours).unix() - moment().unix() >
    timeInSeconds
  ) {
    check = true;
    return check;
  } else {
    return check;
  }
};

// order rating acc. to API rating variable
export const getRating = (rating: number): number => {
  switch (rating) {
    case -1:
      return 1;
    case 0:
      return 3;
    case 1:
      return 4;
    case 7:
      return 5;
    default:
      return -1;
  }
};

// isService/ isPackage/ isProduct
export const checkItemType = (cachedItemData: any, addOnItemData: any) => {
  if (cachedItemData?.update_services === "0") {
    return "isProduct";
  } else if (addOnItemData?.serviceType?.description?.length === 0) {
    return "isService";
  } else if (addOnItemData?.serviceType?.description?.length > 0) {
    return "isPackage";
  } else {
    return "";
  }
};

// return all cart items ids as a string
export const getItemsId = (): string => {
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`);
  const ids: string[] = [];

  cartItems?.forEach((item: any) => {
    if (item.added == true) {
      for (let count = 1; count <= item.count; count++) {
        ids.push(item.serviceType.id);
      }
    }
  });

  return ids?.join(",");
};

// get parent id by sub category id
export const getParentIdBySubCategoryId = (id: string): any => {
  const homepage_sections = JSON.parse(
    localStorage.getItem("homepage_sections")!
  );
  const products = filterHomepageSections(homepage_sections, "products");
  const service = filterHomepageSections(homepage_sections, "parent");

  let finalArr = [...service, ...products];

  let finalObj: any = {
    parentId: "",
    foundIn: "",
  };
  // let parentId = "";
  finalArr?.map((item: any) => {
    if (item?.items_required?.split(",").includes(id)) {
      finalObj.parentId = item?.id;
      finalObj.foundIn = item?.brand;
      // parentId = item?.id;
    }
  });

  return finalObj;
};

// get device source
export const getDeviceSource = (): string => {
  let source = "";
  let userAgent = navigator.userAgent;
  let windowWidth = window.innerWidth;

  if (userAgent.includes("Android")) {
    source = ANDROID_WEB;
  } else if (userAgent.includes("iPhone")) {
    source = IOS_WEB;
  } else if (windowWidth < 992) {
    source = MOBILE_WEB;
  } else {
    source = WEB;
  }

  return source;
};

// calculate upgrade cost
export const calculateUpgradeCost = (
  upgradeServiceId: number,
  serviceId: number
): number => {
  let upgradeServiceCost = getServiceById(upgradeServiceId)?.cost;
  let serviceCost = getServiceById(serviceId)?.cost;
  // let serviceCost = getServiceById(409)?.cost;

  let finalUpgradeCost = upgradeServiceCost - serviceCost;

  return finalUpgradeCost;
};

// getting data from speacial instructions
export const getDataFromSIns = (str: string, serviceId: string): string[] => {
  const arrayOfChoosedService = str
    ?.split(serviceId)[1]
    ?.split("-")[1]
    ?.split(",");

  if (str?.split(serviceId)?.length >= 2) {
    return arrayOfChoosedService;
  } else {
    return [];
  }
};

// making image URL for services
export const getImageURL = (str: string): string => {
  let imageURL = "";

  if (str?.includes("home")) {
    // imageURL = str?.replaceAll(
    //   "/home/ubuntu/look_data/",
    //   "https://getlook.in/"
    // );
    imageURL = "https://getlook.in/" + str.split("look_data/")[1];
  } else {
    imageURL = str;
  }

  return imageURL;
};

// making chosen service
export const getChosenService = (service: any): any => {
  let chosenService: any = [];

  const splitedNames = service?.serviceType?.name?.split(":");
  if (splitedNames[1]?.includes(" OR ")) {
    splitedNames[1]?.split(" OR ")?.map((info: any) =>
      chosenService.push({
        id: service?.serviceType?.id,
        label: info,
        header: null,
      })
    );
  } else if (splitedNames[1]?.includes(" , ")) {
    splitedNames[1]?.split(" , ")?.map((info: any) =>
      chosenService.push({
        id: service?.serviceType?.id,
        label: info,
        header: null,
      })
    );
  } else if (splitedNames[1]?.includes(" + ")) {
    splitedNames[1]?.split(" + ")?.map((info: any) =>
      chosenService.push({
        id: service?.serviceType?.id,
        label: info,
        header: null,
      })
    );
  }

  return chosenService;
};

// checking items in cart or not
export const checkItemsInCart = () => {
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || null;
  let itemsInCart = [];
  itemsInCart = cartItems?.filter((item: any) => {
    if (item?.added) {
      return true;
    }
  });
  return itemsInCart;
};

// calculate discount in percent
export const calculateDiscountInPercent = (
  originalPrice: number,
  cutPrice: number
): number => {
  let discount: number = 0;

  let diff = cutPrice - originalPrice;
  discount = Math.floor((diff / cutPrice) * 100);

  return discount;
};

// sent cart items in local for fb pixel
export const cartItemsToSendInFacebookPixel = () => {
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
  const content_id: any = [];
  const content_name: any = [];
  let items = 0;
  let value = calculatePaymentDetails()?.grandTotal;
  cartItems?.forEach((item: any) => {
    if (item?.added) {
      // value += item.cost * item.count;
      content_id.push(item?.serviceType?.id);
      content_name.push(item?.serviceType?.name);
      items += 1;
    }
  });
  const data = {
    content_id: content_id.join(","),
    content_name: content_name.join(","),
    num_items: items,
    currency: "INR",
    value: value,
  };
  return data;
};

// sent cart items in local for gtm
export const cartItemsToSendInGtm = () => {
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
  const appliedCoupon = JSON.parse(`${localStorage.getItem("coupon_applied")}`);
  let coupon = "";
  if (appliedCoupon?.couponCode) {
    coupon = appliedCoupon?.couponCode;
  }

  const content_id: any = [];
  const content_name: any = [];
  let num_items = 0;

  cartItems?.forEach((item: any, i: any) => {
    if (item?.added) {
      content_id.push(item?.serviceType?.id);
      content_name.push(item?.serviceType?.name);
      num_items += 1;
    }
  });

  const data: any = {
    currency: "INR",
    value: calculatePaymentDetails()?.grandTotal,
    coupon: coupon,
    content_id: content_id.join(","),
    content_name: content_name.join(","),
    num_items: num_items,
    items: [],
  };
  cartItems?.forEach((item: any, i: any) => {
    if (item?.added) {
      // data.value += item.cost * item.count;

      data.items.push({
        item_id: item?.serviceType?.id,
        item_name: item?.serviceType?.name,
        affiliation: "",
        coupon: coupon,
        discount:
          item?.serviceType?.cost * item?.count - item.cost * item?.count,
        index: i,
        item_brand: item?.serviceType?.brand,
        item_category: item?.serviceType?.category?.name,
        item_category2: "",
        item_category3: "",
        item_category4: "",
        item_category5: "",
        item_list_id: "",
        item_list_name: "",
        item_variant: "",
        location_id: "",
        price: item.cost * item?.count,
        quantity: item?.count,
      });
    }
  });
  return data;
};

export const deg2rad = (deg: any) => {
  return deg * (Math.PI / 180);
};

// It will return index, If direct distance is less than euqal to 0.1 otherwise return null
export const getNearestLocationIndex = (
  latLongCache: any,
  lat: any,
  long: any
) => {
  let index = -1,
    maxDistance = 1000,
    distance = 0;
  latLongCache.forEach((obj: any, i: any) => {
    distance = getDistanceFromLatLonInKm(obj.lat, obj.long, lat, long);

    if (distance <= maxDistance) {
      index = i;
      maxDistance = distance;
    }
  });

  if (maxDistance <= 0.15) {
    return index;
  } else {
    return null;
  }
};

export const getFarthestLocationIndex = (
  latLongCache: any,
  lat: any,
  long: any
) => {
  let index = -1,
    minDistance = -1,
    distance = -1;
  latLongCache.forEach((obj: any, i: any) => {
    distance = getDistanceFromLatLonInKm(obj.lat, obj.long, lat, long);

    if (distance >= minDistance) {
      index = i;
      minDistance = distance;
    }
  });

  if (index != -1) {
    return index;
  } else {
    return null;
  }
};

export const getDistanceFromLatLonInKm = (
  lat1: any,
  lon1: any,
  lat2: any,
  lon2: any
) => {
  var R = 6378; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d >= 0 ? d : -d;
};

export const cartUpdateAfterCacheExpire = () => {
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

  const updatedItems: any = [];

  cartItems.forEach((item: any) => {
    if (item.added) {
      const service = getServiceById(item?.serviceType?.id);

      if (service?.serviceType?.active) {
        const updatedItem = {
          ...item,
          ...service,
          serviceType: { ...item.serviceType, ...service.serviceType },
        };

        updatedItems.push(updatedItem);
      }
    }
  });

  return updatedItems;
};

export const isShowStylistInfo = (order: any): boolean => {
  if (isSlotAboveGivenTime(order?.date, order?.timing, Stylist_Assigned_Time)) {
    // show msg
    return false;
  } else {
    if ([1, 13, 4, 3, 2, 5].includes(order?.status)) {
      // confirmed, in transit, start,accepted, ended status
      // hide msg, show stylist info
      return true;
    }
  }

  return false;
};

export const checkUserExpiry = (
  activationDate: string,
  duration: string
): boolean => {
  let validTill = moment(activationDate)
    .add(duration, "days")
    .format("DD MMM YYYY");

  let currentDate = moment().format("DD MMM YYYY");

  let isExpired = moment(validTill, "DD MMM YYYY").isBefore(
    moment(currentDate, "DD MMM YYYY")
  );

  return isExpired;
};

export const setOverflowToBodyHidden = () => {
  document.body.style.overflow = "hidden";
};

export const setOverflowToBodyUnset = () => {
  document.body.style.overflow = "unset";
};

//checking popup time diffrence acc. to caching time
export const checkingPopupTimeDifference = (): boolean => {
  const popup_banner_close_time = JSON.parse(
    localStorage.getItem("popup_banner_close_time")!
  );
  const convert_initialTimestampToDate = moment(popup_banner_close_time);
  const latest_timestamp = moment();

  const timeDiff = latest_timestamp.diff(
    convert_initialTimestampToDate,
    "minutes"
  );

  if (timeDiff > Popup_Banner_Cache_Time) {
    return true;
  } else {
    return false;
  }
};

export const fetchMapKey = (obj: any) => {
  let keys: string[] = [];
  let finalKey: string = "";
  Object.keys(obj).forEach((key) => keys.push(key));
  if (keys.includes("order_id")) {
    finalKey = obj.order_id.split("gt_")[1].split("_lk")[0];
  } else if (keys.includes("order_id_android")) {
    finalKey = obj.order_id_android.split("gt_")[1].split("_lk")[0];
  } else if (keys.includes("order_id_ios")) {
    finalKey = obj.order_id_ios.split("gt_")[1].split("_lk")[0];
  } else {
    finalKey = process.env.REACT_APP_MAP_APIKEY!;
  }
  return finalKey;
};

export const calculateSubTotal = (): number => {
  let subTotal = 0;
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
  cartItems?.forEach((item: any) => {
    if (item.added) {
      // subtotal
      if (item?.serviceType?.cost > item?.cost) {
        subTotal += Math.round(item?.serviceType?.cost * item?.count);
      } else {
        subTotal += Math.round(item?.cost * item.count);
      }
    }
  });
  return subTotal;
};

export const randomSortData = (data: any) => {
  data?.sort(() => 0.5 - Math.random());
  return data;
};

export const returnGrandTotal = () => {
  const grandTotal = calculatePaymentDetails()?.grandTotal;
  return grandTotal;
};
