import React, { useContext } from "react";
import { commonType } from "../../../../pages/main/type";
import { Auth } from "../../../../utils/context/AuthContext";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../../lib/toast/showToast";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  paymentDetails: any;
};

const GrandTotal: React.FC<propType> = (props) => {
  const { state, setState, paymentDetails } = props;
  const { user } = useContext(Auth);
  let token = localStorage.getItem("token")!;
  const navigate = useNavigate();

  const fn = {
    handleClickOnProceed: () => {
      if (user && token) {
        // check for @99 package
        // 1. grand total should be > 999, if @99 package in add in cart
        // 2. @99 package not applicable with packages
        let check = fn.checkForAt99Package();
        if (!check) {
          // setState({
          //   modal: true,
          //   check: "select_address",
          // });
          //   setOverflowToBodyHidden();
          navigate("/select-address");
        }
      } else {
        navigate("/otp-login", {
          replace: true,
          state: {
            previousURL: "/cart",
          },
        });
      }
    },
    checkForAt99Package: (): boolean => {
      const cartItems =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      let check = false;
      let is99PackageExistInCart = cartItems?.some((item: any) => {
        // 276 is id for @99 package service
        return item?.serviceType?.id === 276;
      });
      // for checking : @99 package not applicable with packages
      let isPackage: boolean[] = [];
      cartItems?.map((item: any) => {
        if (item?.serviceType?.id !== 276 && item?.isPackage) {
          return isPackage.push(item?.isPackage);
        }
      });
      if (is99PackageExistInCart && paymentDetails?.grandTotal < 999) {
        showToast("Please add more services", "error");
        check = true;
      } else if (
        is99PackageExistInCart &&
        isPackage?.length > 0 &&
        isPackage?.includes(true)
      ) {
        showToast("@99 package not applicable with packages", "error");
        check = true;
      }
      return check;
    },
  };

  return (
    <>
      <div className="fix_section_at_bottom">
        <div className="grand_total_section d-flex justify-content-between align-items-center">
          <div>
            <p className="mb-0 textColor1 font12x500">Grand Total</p>
            <p className="mb-0 textColor1 font16x500">
              {paymentDetails?.grandTotal
                ? `₹${paymentDetails?.grandTotal}`
                : ""}
            </p>
          </div>
          <button
            type="button"
            className="font16x500 text-white"
            onClick={() => {
              fn.handleClickOnProceed();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default GrandTotal;
