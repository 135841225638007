export const ProfileSection1Menus = [
  {
    imagePath: "/static/svg/user.svg",
    alt: "user",
    label: "View Profile",
    link: "/view-profile",
  },
  {
    imagePath: "/static/svg/offer-and-deal.svg",
    alt: "offer_and_deal",
    label: "Offers & Deals",
    // link: "/offers-deals",
    link: "/offers-deals#Coupon_Discount",
  },
  {
    imagePath: "/static/svg/gift.svg",
    alt: "gift",
    label: "Refer & Earn",
    link: "/refer-and-earn",
  },
  {
    imagePath: "/static/svg/wallet.svg",
    alt: "wallet",
    label: "Wallet",
    link: "/mywallet",
  },
];

export const ProfileSection2Menus = [
  {
    imagePath: "/static/svg/booking.svg",
    alt: "booking",
    label: "Bookings & Orders",
    link: "/order",
  },
  {
    imagePath: "/static/svg/address-book.svg",
    alt: "address_book",
    label: "Address Book",
    link: "/address-book",
  },
  {
    imagePath: "/static/svg/getlook-gold.svg",
    alt: "getlook_gold",
    label: "GetLook Gold",
    link: "/getlook-gold",
  },
  {
    imagePath: "/static/svg/franchise.svg",
    alt: "franchise",
    label: "Franchise",
    link: "/franchise-getlook",
  },
  {
    imagePath: "/static/svg/about-us.svg",
    alt: "about_us",
    label: "About Us",
    link: "/about-us",
  },
  {
    imagePath: "/static/svg/terms.svg",
    alt: "Terms_of_Service",
    label: "Terms of Service",
    link: "https://getlook.in/custtnc/",
  },
  {
    imagePath: "/static/svg/privacy-policy.svg",
    alt: "privacy_policy",
    label: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    imagePath: "/static/svg/faq.svg",
    alt: "faq",
    label: "FAQs",
    link: "/faqs",
  },
  {
    imagePath: "/static/svg/contact-us.svg",
    alt: "contact",
    label: "Contact Us",
    link: "/contact-us",
  },
];
