/**
 * @author Deepanshu Tyagi
 */

import "./index.css";
import { commonType } from "../../../../../pages/main/type";
import { CloseIcon } from "../../../../shared/Icons";
import { setOverflowToBodyUnset } from "../../../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const FacialGuide: React.FC<propType> = (props) => {
  const { state, setState } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <div className="facial_guide_page">
      {/* cancel */}
      <div className="cancel-container">
        <button
          onClick={fn.setPositionToBody}
          className="d-flex align-items-center justify-content-center bg-black text-white border-0"
        >
          <CloseIcon width="20" height="20" fill="#fff" />
        </button>
      </div>

      <div className="facial_guide_container">
        <div className="facial_guide_title_container">
          <h1 className="mb-0 font16x600 textColor3">
            Choose facial that suits you
          </h1>
        </div>

        <div className="red_facial_guide_container">
          <div className="facial_guide_image_container">
            <img src="/static/img/redfacialguide.png" />
          </div>

          <div className="recommendations_container">
            <div className="recommendation_title_container">
              <h1 className="mb-0 font20x600 textColor1">
                Our recommendations
              </h1>
            </div>

            <div className="recommendation_container">
              <div className="mb-3">
                <h1 className="mb-0 font16x600 textColor3">Instant glow</h1>
              </div>

              <div className="d-flex align-items-center mb-2">
                <div>
                  <img src="/static/svg/loreal.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    Cheryl’s by Loreal facial
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <img src="/static/svg/vlcc.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    VLCC shine and glow facial
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="mb-3">
                <h1 className="mb-0 font16x600 textColor3">
                  Hydration & oil control
                </h1>
              </div>

              <div className="d-flex align-items-center mb-2">
                <div>
                  <img src="/static/svg/loreal.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    Cheryl’s by Loreal facial
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <img src="/static/svg/vlcc.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    VLCC shine and glow facial
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="blue_facial_guide_container">
          <div className="facial_guide_image_container">
            <img src="/static/img/bluefacialguide.png" />
          </div>

          <div className="recommendations_container">
            <div className="recommendation_title_container">
              <h1 className="mb-0 font20x600 textColor12">
                Our recommendations
              </h1>
            </div>

            <div className="recommendation_container">
              <div className="mb-3">
                <h1 className="mb-0 font16x600 textColor3">Instant glow</h1>
              </div>

              <div className="d-flex align-items-center mb-2">
                <div>
                  <img src="/static/svg/loreal.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    Cheryl’s by Loreal facial
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <img src="/static/svg/vlcc.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    VLCC shine and glow facial
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="mb-3">
                <h1 className="mb-0 font16x600 textColor3">
                  Hydration & oil control
                </h1>
              </div>

              <div className="d-flex align-items-center mb-2">
                <div>
                  <img src="/static/svg/loreal.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    Cheryl’s by Loreal facial
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <img src="/static/svg/vlcc.svg" />
                </div>
                <div>
                  <p className="mb-0 font14x500 ms-2 textColor3">
                    VLCC shine and glow facial
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacialGuide;
