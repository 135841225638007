import { useContext } from "react";
import { Auth } from "../../../../../utils/context/AuthContext";
import BeauticianImage from "./Beautician";
import DownloadButtons from "./DownloadButtons";

const DownloadApp = () => {
  const { servingOnLocation } = useContext(Auth);

  return (
    <>
      <div
        className="download_app_wrapper"
        style={{
          marginTop: servingOnLocation?.isServing ? 0 : 48,
        }}
      >
        <BeauticianImage />
        <DownloadButtons />
      </div>
    </>
  );
};

export default DownloadApp;
