import { CameraIcon, UserDefault } from "../Icons";

const ProfilePhoto = () => {
  return (
    <>
      <div className="has_not_profile_photo mb-3">
        <UserDefault width="78" height="78" />
        {/* <div className="upload_wrapper">
          <CameraIcon />
        </div> */}
      </div>
    </>
  );
};

export default ProfilePhoto;
