import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import FAQs from "../../../../../components/shared/FAQ";
import Benefits from "../../../../../components/shared/GetlookGold/Benefits";
import GoldInNumber from "../../../../../components/shared/GetlookGold/GoldInNumber";
import MemberReviews from "../../../../../components/shared/GetlookGold/MemberReviews";
import MembershipInfo from "../../../../../components/shared/GetlookGold/MembershipInfo";
import SavingsInfo from "../../../../../components/shared/GetlookGold/SavingsInfo";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import { BackIcon } from "../../../../../components/shared/Icons";
import { Auth } from "../../../../../utils/context/AuthContext";
import { GetlookGoldPage } from "../../../../../utils/metaInfo/metaInfo";
import { getServiceById } from "../../../../../utils/function/function";
import { membershipId } from "../../../../../utils/constant/constant";

const GetlookGold = () => {
  const navigate = useNavigate();
  const { user } = useContext(Auth);

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "var(--bg-color2)",
        }}
      >
        <Helmet>
          <title>{GetlookGoldPage.title}</title>
          <meta name="description" content={GetlookGoldPage.description} />
          <meta name="keywords" content={GetlookGoldPage.keywords} />
        </Helmet>

        {/* header */}
        <div className="g_gold_header_wrapper">
          <div className="g_gold_back">
            <BackIcon
              width="17"
              height="14"
              fill="#0F0F0F"
              clickHandler={() => navigate(-1)}
            />
          </div>
          <div className="g_gold_content d-flex flex-column justify-content-between align-items-center">
            <img
              style={{ maxWidth: "215px" }}
              src="/static/img/g-gold-logo.png"
              alt="g_gold_logo"
            />
            <p className="mb-4 textColor1 font16x600">
              Save upto Rs. 200 on every order
            </p>
          </div>
        </div>

        {/* membership info */}
        {user?.membershipStartDate && user?.membershipActivationDate && (
          <MembershipInfo />
        )}

        {/* getlook gold benefits */}
        <Benefits />

        {/* savings info */}
        <SavingsInfo />

        {/* gold in numbers */}
        <GoldInNumber />

        {/* faqs */}
        <FAQs />

        {/* member reviews */}
        {/* <MemberReviews /> */}
      </div>
    </>
  );
};

export default GetlookGold;
