import { useContext, useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChooseFour from "../../../components/pages/Services/ChooseFour";
import Packages from "../../../components/pages/Services/Packages";
import Service from "../../../components/pages/Services/Service";
import BottomTopAnimation from "../../../components/shared/BottomTopAnimation";
import Categories from "../../../components/shared/Category/categories";
import Menu from "../../../components/shared/Category/menu";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import { BackIcon, CloseIcon } from "../../../components/shared/Icons";
import {
  membershipId,
  SERVICE_TYPE,
  TEMP_SERVICE_TYPE,
} from "../../../utils/constant/constant";
import { Auth } from "../../../utils/context/AuthContext";
import {
  checkingTrackingTimeDifference,
  checkItemsInCart,
  checkUserExpiry,
  filterHomepageSections,
  filterSubCategories,
  getDeviceSource,
  getImageURL,
  getServiceById,
  increaseDecreaseItem,
  isUserValid,
  returnGrandTotal,
  setOverflowToBodyHidden,
} from "../../../utils/function/function";
import { commonType } from "../type";
import "./index.css";
import moment from "moment";
import ServiceLoader from "../../../components/pages/Services/loader";
import { Helmet } from "react-helmet";
import { ServicePage } from "../../../utils/metaInfo/metaInfo";
import useLoading from "../../../hooks/useLoading";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import AppBanner from "../../../components/pages/Home/App Banner";
import { BsInfoCircle } from "react-icons/bs";
import { showToast } from "../../../lib/toast/showToast";
import { Carousel } from "react-responsive-carousel";
import { BsUnlock } from "react-icons/bs";
import Header from "../../../components/shared/Header";

const packagesId = ["140", "150"];
const chooseServiceId = ["151", "174"];

const Services = () => {
  const navigate = useNavigate();
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [paymentDetails, setPaymentDetails] = useState<any>({
    subTotal: 0,
    total: 0,
  });
  const {
    user,
    homepage_sections,
    homepage_categories,
    itemsPageLoading,
    setCartItemsInContext,
    cartItemsInContext,
    trackUserActivity,
    newURLAfterReplace,
    setNewURLAfterReplace,
    updateUserData,
    setUser,
  } = useContext(Auth);
  const allCategories = filterHomepageSections(
    homepage_sections,
    "parent"
  )?.sort((a: any, b: any) => {
    return a.category_order - b.category_order;
  });
  const { homepageCategory, subCategory, serviceId } = useParams();
  const [services, setServices] = useState<any>([]);
  const [categoryName, setCategoryName] = useState<string>("");
  const [loading, showLoader, hideLoader] = useLoading();

  const [showServicePageSpecialOffers, setShowServicePageSpecialOffers] =
    useState(true);

  const [specialOffers, setSpecialOffers] = useState<any>([]);
  const [isServiceScrolled, setIsServiceScrolled] = useState(false);

  const banner = filterHomepageSections(homepage_sections, "cover")?.sort(
    (a: any, b: any) => {
      return a.category_order - b.category_order;
    }
  );

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    getItemsFromHomepageCategory: () => {
      let itemsId = "";

      // getting items required
      allCategories?.map((item: any) => {
        if (item?.id?.toString() === homepageCategory) {
          itemsId = item?.items_required;
          setCategoryName(item?.name);
        }
      });

      // calling sub categories function
      setServices(
        filterSubCategories(homepage_categories, itemsId?.split(","))
      );
    },
    scrollToSubCategory: (id?: string) => {
      const all_single_sub_category = document.getElementsByClassName(
        "single_sub_category"
      );

      let loop = 0;
      for (loop = 0; loop <= all_single_sub_category?.length - 1; loop++) {
        if (all_single_sub_category[loop].id.split("_")[1] === id) {
          all_single_sub_category[loop].scrollIntoView(true);
          break;
        }
      }
    },
    scrollToService: (serviceId?: string, subCategoryId?: string) => {
      const all_single_sub_category = document.getElementsByClassName(
        "single_sub_category"
      );

      let loop = 0;
      for (loop = 0; loop <= all_single_sub_category?.length - 1; loop++) {
        if (all_single_sub_category[loop].id.split("_")[1] === subCategoryId) {
          const all_single_service_list =
            all_single_sub_category[loop].getElementsByClassName(
              "services_list"
            );

          for (let j = 0; j <= all_single_service_list?.length - 1; j++) {
            if (all_single_service_list[j].id.split("_")[1] === serviceId) {
              all_single_service_list[j].scrollIntoView({ block: "center" });
              break;
            }
          }
        }
      }
    },
    handleClickOnAdd: (item: any) => {
      const paymentDetailsList =
        JSON.parse(`${localStorage.getItem("paymentDetails")}`) || [];
      const serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

      // please add service info
      if (item.id == "620") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "546") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "547") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "548") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "538") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "521") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "605") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "497") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else {
        item.count = 1;
        item.added = true;
        item.isService = true;
        serviceList.push(item);

        if (isUserValid()) {
          // checking special offer service is exist or not in cart
          const filteredCart = serviceList?.filter((cartItem: any) => {
            return cartItem?.parentId !== item?.serviceType?.id;
          });

          // original cart updation
          setCartItemsInContext(filteredCart);
          localStorage.setItem("cartItems", JSON.stringify(filteredCart));

          // updating current location in history
          setNewURLAfterReplace(
            `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
          );
          navigate(
            `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
            { replace: true }
          );
        } else {
          // temporary cart updation
          fn.navigateToLogin(item, serviceList);
        }
      }
    },
    handleClickOnMinusPlus: (item: any, button: string) => {
      // handling increase and descrease of items
      setCartItemsInContext(
        increaseDecreaseItem(item, button, cartItemsInContext)
      );

      // updating current location in history
      setNewURLAfterReplace(
        `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
      );
      navigate(
        `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        { replace: true }
      );
    },
    handleClickOnProceed: async () => {
      showLoader();
      // updating current location in history
      navigate(newURLAfterReplace, { replace: true });

      let isMembershipExistInCart = cartItemsInContext?.some((item: any) => {
        return item?.serviceType?.id === membershipId;
      });

      if (user?.isMember) {
        if (
          checkUserExpiry(
            user?.membershipActivationDate,
            user?.membershipDuration
          )
        ) {
          const latestUserData = await updateUserData();
          if (latestUserData?.isMember) {
            // check membership expired or not
            if (
              checkUserExpiry(
                latestUserData?.membershipActivationDate,
                latestUserData?.membershipDuration
              )
            ) {
              window.localStorage.setItem(
                "user",
                JSON.stringify({
                  ...user,
                  isMember: false,
                })
              );
              setUser({
                ...user,
                isMember: false,
              });
              if (isMembershipExistInCart) {
                hideLoader();
                navigate("/cart", { state: { previousURL: "/services" } });
              } else {
                hideLoader();
                navigate("/membership");
              }
            } else {
              hideLoader();
              navigate("/cart", { state: { previousURL: "/services" } });
            }
          } else {
            if (isMembershipExistInCart) {
              hideLoader();
              navigate("/cart", { state: { previousURL: "/services" } });
            } else {
              hideLoader();
              navigate("/membership");
            }
          }
        } else {
          hideLoader();
          navigate("/cart", { state: { previousURL: "/services" } });
        }
      } else {
        const latestUserData = await updateUserData();
        if (latestUserData?.isMember) {
          // check membership expired or not
          if (
            checkUserExpiry(
              latestUserData?.membershipActivationDate,
              latestUserData?.membershipDuration
            )
          ) {
            window.localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                isMember: false,
              })
            );
            setUser({
              ...user,
              isMember: false,
            });
            if (isMembershipExistInCart) {
              hideLoader();
              navigate("/cart", { state: { previousURL: "/services" } });
            } else {
              hideLoader();
              navigate("/membership");
            }
          } else {
            hideLoader();
            navigate("/cart", { state: { previousURL: "/services" } });
          }
        } else {
          if (isMembershipExistInCart) {
            hideLoader();
            navigate("/cart", { state: { previousURL: "/services" } });
          } else {
            hideLoader();
            navigate("/membership");
          }
        }
      }
    },
    calculatePaymentDetails: () => {
      const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`);
      let paymentDetails = {
        subTotal: 0,
        total: 0,
      };
      cartItems.forEach((item: any) => {
        if (item.added) {
          // subtotal
          paymentDetails.subTotal += item?.cost * item.count;
          paymentDetails.total += item?.serviceType?.cost * item.count;
        }
      });
      return paymentDetails;
    },
    navigateToLogin: (item: any, serviceTobeAdded?: any) => {
      // updating current location in history
      navigate(
        `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        { replace: true }
      );

      // navigate to login
      navigate("/otp-login", {
        state: {
          previousURL: `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
          serviceTobeAdded,
        },
      });
    },
    getSpecialOffers: () => {
      setSpecialOffers([]);
      let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

      // checking special offer service exist or not in cart for sub category and service
      const filteredSpecialOffers = fetchedSpecialOffers.filter(
        (specialOffer: any) => {
          // return false
          let check = true;
          let ids = specialOffer?.stylist_information?.split(",");
          cartItems?.forEach((cartItem: any) => {
            if (
              ids?.includes(String(cartItem?.serviceType?.category?.id)) ||
              ids?.includes(String(cartItem?.serviceType?.id))
            ) {
              check = false;
            }
          });
          return check;
        }
      );

      // add extra variables in special offers
      let tempOffers: any = [];

      filteredSpecialOffers?.forEach((specialOffer: any) => {
        return tempOffers.push({
          ...specialOffer,
          isDisabled: fn.checkSpecialOfferServiceUnlockButton(specialOffer),
        });
      });

      // sort SpecialOffers according to worth price
      tempOffers.sort((a: any, b: any) => {
        return +a?.information - +b?.information;
      });

      setSpecialOffers(tempOffers);
    },
    handleUnlockButtonClick: (offerService: any) => {
      let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      let serviceData: any = getServiceById(+offerService?.items_required);
      let tempCartItems = [...cartItems];
      serviceData.count = 1;
      serviceData.added = true;
      serviceData.update = true;
      serviceData.type = "special_offer";
      serviceData.worthPrice = +offerService?.information;
      // serviceData.parentId = +offerService?.items_required?.split(",")[0];
      tempCartItems.push(serviceData);
      setCartItemsInContext(tempCartItems);
      localStorage.setItem("cartItems", JSON.stringify(tempCartItems));

      // removing coupon
      localStorage.removeItem("coupon_applied");
    },
    handleUndoButtonClick: (offerService: any) => {
      const cartItems =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      const filteredCart = cartItems.filter(
        (item: any) => item?.serviceType?.id !== +offerService?.items_required
      );
      setCartItemsInContext(filteredCart);
      localStorage.setItem("cartItems", JSON.stringify(filteredCart));
    },
    checkSpecialOfferServiceUnlockButton: (item: any): boolean => {
      let check = false;
      let grandTotal = returnGrandTotal();
      if (+item?.information > +grandTotal) {
        check = true;
      }
      return check;
    },
    getSpecialOfferInfo: (item: any) => {
      if (+item?.information > returnGrandTotal()) {
        return (
          <div>
            <p className="service-page-special-title mb-0 textColor1 font11x500 text-left">
              Get {item?.name} at ₹{getServiceById(+item?.items_required)?.cost}{" "}
              to unlocked
            </p>
            <div className="service-page-special-desc textColor7">
              <p className="font10x400 mb-0 p-0 text-left">
                {`Add service worth Rs.${
                  item?.information - returnGrandTotal()
                } to unlock`}
                {/* {item?.stylist_information?.length > 0 && (
                  <i>
                    <BsInfoCircle
                      className="textColor1 cursor_pointer ms-2"
                      onClick={() =>
                        showToast(item?.stylist_information, "success")
                      }
                    />
                  </i>
                )} */}
              </p>
            </div>
          </div>
        );
      } else {
        const diffrence =
          getServiceById(+item?.items_required)?.serviceType?.cost -
          getServiceById(+item?.items_required)?.cost;
        let percent = Math.floor(
          (diffrence /
            getServiceById(+item?.items_required)?.serviceType?.cost) *
            100
        );
        return (
          <div>
            <p className="service-page-special-title mb-0 textColor1 font11x500 text-left">
              Yay!! {item?.name} at ₹
              {getServiceById(+item?.items_required)?.cost} is unlocked
            </p>
            <div className="service-page-special-desc textColor11">
              <p className="font10x400 mb-0 text-left">
                You can add it from cart page
              </p>
            </div>
          </div>
        );
      }
    },
    handleScrolledServices: (e: any) => {
      if (e?.target?.scrollTop > 0) {
        setIsServiceScrolled(true);
      } else {
        setIsServiceScrolled(false);
      }
    },
  };

  useEffect(() => {
    if (cartItemsInContext.length > 0) {
      setPaymentDetails(fn.calculatePaymentDetails());
    } else {
      localStorage.removeItem("coupon_applied");
      localStorage.removeItem("conveyanceCharge");
      localStorage.removeItem("slotInfo");
      localStorage.removeItem("previousDues");
      localStorage.removeItem("extra_instruction");
    }
  }, [cartItemsInContext]);

  useEffect(() => {
    !itemsPageLoading && fn.getItemsFromHomepageCategory();
  }, [homepageCategory, itemsPageLoading]);

  useEffect(() => {
    !itemsPageLoading &&
      subCategory &&
      services?.length > 0 &&
      fn.scrollToSubCategory(subCategory);
  }, [subCategory, services, itemsPageLoading]);

  useEffect(() => {
    !itemsPageLoading &&
      serviceId &&
      services?.length > 0 &&
      fn.scrollToService(serviceId, subCategory);
  }, [serviceId, services, itemsPageLoading]);

  // user tracking
  useEffect(() => {
    const user_activity = JSON.parse(localStorage.getItem("user_activity")!);

    if (isUserValid()) {
      const trackingTimeDiff = checkingTrackingTimeDifference();
      if (trackingTimeDiff) {
        // removing user activity after 6 hours
        window.localStorage.removeItem("user_activity");

        trackUserActivity({
          type: SERVICE_TYPE,
          tempType: TEMP_SERVICE_TYPE,
          area_id: localStorage.getItem("area_id")!,
          source: getDeviceSource(),
        });

        window.localStorage.setItem(
          "tracking_cache_timestamp",
          JSON.stringify(moment())
        );
      } else {
        if (user_activity) {
          if (TEMP_SERVICE_TYPE > user_activity?.tempType) {
            trackUserActivity({
              ...user_activity,
              type: SERVICE_TYPE,
              tempType: TEMP_SERVICE_TYPE,
            });
          }
        } else {
          trackUserActivity({
            ...user_activity,
            type: SERVICE_TYPE,
            tempType: TEMP_SERVICE_TYPE,
            area_id: localStorage.getItem("area_id")!,
            source: getDeviceSource(),
          });
        }
      }
    }
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
    ReactPixel.trackCustom("ViewContent", "");
    gtm.track("view_content", "");
  }, []);

  const fetchedSpecialOffers = filterHomepageSections(
    homepage_sections,
    "special"
  );

  const handleClickOnChildCategory = (childCategoryLink: any) => {
    navigate(childCategoryLink);
  };

  useEffect(() => {
    fn.getSpecialOffers();
  }, [cartItemsInContext]);

  // useEffect(() => {
  //   return () => {
  //     sessionStorage.setItem("showServicePageSpecialOffers", "true");
  //   };
  // }, []);

  useEffect(() => {
    if (services?.length > 0) {
      handleClickOnChildCategory(
        `/services/${homepageCategory}/${services[0]?.subCategoryId}`
      );
    }
  }, [services]);

  return (
    <>
      <div className="services_page">
        <AppBanner />
        <div
          style={{ width: `${window.innerWidth}px` }}
          className="header_categories_container"
        >
          <Helmet>
            <title>{ServicePage.title}</title>
            <meta name="description" content={ServicePage.description} />
            <meta name="keywords" content={ServicePage.keywords} />
          </Helmet>

          {isServiceScrolled ? (
            <div className="header">
              <div onClick={() => navigate("/")} className="back_icon">
                <BackIcon width="17" height="14" fill="#0F0F0F" />
              </div>
              <div className="w-100 text-center">
                <h1 className="mb-0 font16x600 textColor3 text-start ps-2">
                  {categoryName}
                </h1>
              </div>
            </div>
          ) : (
            // <Header label={categoryName} navigate={() => navigate(-1)} />
            <div className="service-page-banner-container">
              <Carousel
                autoPlay={true}
                centerSlidePercentage={100}
                infiniteLoop={true}
                centerMode={true}
                showArrows={false}
                showIndicators={true}
                showThumbs={false}
                showStatus={false}
              >
                {banner?.map((item: any) => {
                  return (
                    <div>
                      <img src={item?.category_image} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}

          <div
            style={{
              padding: "16px",
              display: "grid",
              // gridTemplateColumns: "max-content auto",
              alignItems: "center",
              columnGap: "10px",
              paddingBottom: "4px",
            }}
            className="service_page_header_container"
          >
            {/* <div onClick={() => navigate("/")} className="back_icon">
              <BackIcon width="17" height="14" fill="#0F0F0F" />
            </div> */}

            {/*Categories */}

            <Categories
              allCategories={allCategories}
              mainLoading={itemsPageLoading}
              linkFor="services"
            />
          </div>

          <div className="child-categories-container">
            {services?.map((item: any) => {
              return (
                <div>
                  <button
                    onClick={() => {
                      handleClickOnChildCategory(
                        `/services/${homepageCategory}/${item?.subCategoryId}`
                      );
                    }}
                    className={
                      subCategory == item?.subCategoryId
                        ? "child-category-container active-child-category"
                        : "child-category-container"
                    }
                  >
                    {item?.subCategoryName}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        {itemsPageLoading ? (
          <div>
            <ServiceLoader />
            <ServiceLoader />
            <ServiceLoader />
          </div>
        ) : (
          <>
            {/* render ChooseFour component */}
            {chooseServiceId?.includes(homepageCategory!) ? (
              <ChooseFour
                showServicePageSpecialOffers={showServicePageSpecialOffers}
                handleScrolledServices={fn.handleScrolledServices}
                services={services}
                handleClickOnMinusPlus={fn.handleClickOnMinusPlus}
                setPaymentDetails={setPaymentDetails}
              />
            ) : packagesId?.includes(homepageCategory!) ? (
              <Packages
                showServicePageSpecialOffers={showServicePageSpecialOffers}
                handleScrolledServices={fn.handleScrolledServices}
                services={services}
                handleClickOnMinusPlus={fn.handleClickOnMinusPlus}
                setPaymentDetails={setPaymentDetails}
              />
            ) : (
              <Service
                showServicePageSpecialOffers={showServicePageSpecialOffers}
                handleScrolledServices={fn.handleScrolledServices}
                services={services}
                cartItemsInContext={cartItemsInContext}
                handleClickOnMinusPlus={fn.handleClickOnMinusPlus}
                handleClickOnAdd={fn.handleClickOnAdd}
              />
            )}
          </>
        )}
      </div>

      {/* sub total button */}
      {checkItemsInCart()?.length > 0 && (
        <div className="fix_section_at_bottom cart_alert_section">
          {!showServicePageSpecialOffers ? (
            ""
          ) : (
            <div className="service-page-specials-container">
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showIndicators={true}
                showThumbs={false}
                showStatus={false}
                swipeable={true}
                swipeScrollTolerance={1}
              >
                {specialOffers?.map((item: any, i: number) => (
                  <div className="d-flex align-items-center service-page-special-offer-container">
                    {item?.category_image ? (
                      <img
                        className="rounded-circle"
                        src={getImageURL(item?.category_image)}
                        alt="special_offer_service"
                        width={"30px"}
                        height={"30px"}
                      />
                    ) : (
                      <img
                        className="rounded-circle"
                        src="/static/img/no-image.png"
                        alt="special_offer_service"
                        width={"30px"}
                        height={"30px"}
                      />
                    )}
                    <div className="service-page-special-offer-info">
                      {fn.getSpecialOfferInfo(item)}

                      <div className="d-flex align-items-center gap-1">
                        <button
                          onClick={() => {
                            setShowServicePageSpecialOffers(false);
                          }}
                          className="service-page-special-close"
                        >
                          <CloseIcon height="10" width="10" fill="black" />
                        </button>
                        {/* <div>
                        <p className="mb-0">
                          <BsUnlock />
                          {specialOffers?.length}
                        </p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          )}

          <div className="grand_total_section d-flex justify-content-between align-items-center">
            <div>
              {/* <p className="mb-0 textColor1 font12x500">Sub Total</p> */}
              <div className="d-flex align-items-center gap-2">
                <p className="mb-0 textColor1 font16x500">
                  {paymentDetails?.subTotal
                    ? `₹${paymentDetails?.subTotal}`
                    : ""}
                </p>
                <p className="mb-0 textColor2 font12x500 text-decoration-line-through">
                  {paymentDetails?.total ? `₹${paymentDetails?.total}` : ""}
                </p>
              </div>
              <p className="mb-0 textColor11 font12x500 cut-price">
                Saved ₹{paymentDetails?.total - paymentDetails?.subTotal}
              </p>
            </div>
            <button
              type="button"
              className="font16x500 text-white"
              onClick={() => {
                fn.handleClickOnProceed();
              }}
            >
              {loading ? (
                <SpinLoader height="22" width="22" color="#fff" />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      )}

      {/* menu button */}
      {/* <div className="menu_button_container d-flex align-items-center justify-content-center">
        <button
          onClick={() => fn.openModal(services, "menu")}
          className="d-flex align-items-center text-white border-0"
        >
          <GiHamburgerMenu />
          <p className="mb-0 font14x400">Menu</p>
        </button>
      </div> */}

      {/* menu */}
      {/* {commonData?.modal && commonData?.check === "menu" && (
        <BottomTopAnimation>
          <Menu
            state={commonData}
            setState={setCommonData}
            linkFor="services"
          />
        </BottomTopAnimation>
      )} */}
    </>
  );
};

export default Services;
