import React from "react";
import Separator from "../../../../../shared/Separator";

type PropType = {
  singleOrder: any;
  loading: boolean;
  paymentDetails: any;
};

const OrderPaymentDetails: React.FC<PropType> = (props) => {
  const { singleOrder, loading, paymentDetails } = props;

  return (
    <>
      <div className="payment_details_wrapper">
        <div className="payment_details_section">
          <h3 className="font14x700 text-center textColor3">Payment Details</h3>
          <Separator />
          <div className="d-flex flex-column gap-1 pt-3">
            <div className="d-flex justify-content-between">
              <p className="mb-1 font12x500 textColor3">Subtotal:</p>
              <p className="mb-0 font12x400 textColor3">
                ₹{paymentDetails?.subTotal}
              </p>
            </div>
            {paymentDetails?.discount ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Discount:</p>
                <p className="mb-0 font12x400 textColor11">
                  -₹{paymentDetails?.discount}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.couponDiscount > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Coupon Discount:</p>
                <p className="mb-0 font12x400 textColor11">
                  - ₹{paymentDetails?.couponDiscount}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.walletDiscount > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Wallet Discount:</p>
                <p className="mb-0 font12x400 textColor11">
                  - ₹{paymentDetails?.walletDiscount}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.membershipDiscount > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">
                  Membership Discount:
                </p>
                <p className="mb-0 font12x400 textColor11">
                  - ₹{paymentDetails?.membershipDiscount}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.surgeFee < 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Surge Discount:</p>
                <p className="mb-0 font12x500 textColor11">
                  {`-₹${paymentDetails?.surgeFee}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.safetyAndHygieneCharge > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">
                  Safety & Hygiene fee:
                </p>
                <p className="mb-0 font12x400 textColor3">
                  +₹{paymentDetails?.safetyAndHygieneCharge}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.conveyanceCharge > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Conveyance Charge:</p>
                <p className="mb-0 font12x400 textColor3">
                  +₹{paymentDetails?.conveyanceCharge}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.surgeFee > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Surge Fee:</p>
                <p className="mb-0 font12x400 textColor3">
                  {`+₹${paymentDetails?.surgeFee}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.premiumCharge > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">
                  Premium Beautician:
                </p>
                <p className="mb-0 font12x400 textColor3">
                  {`+₹${paymentDetails?.premiumCharge}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.rescheduleCharge > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">
                  Rescheduling Charge:
                </p>
                <p className="mb-0 font12x400 textColor3">
                  {`+₹${paymentDetails?.rescheduleCharge}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.previousDues > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Previous Dues:</p>
                <p className="mb-0 font12x400 textColor3">
                  {`+₹${paymentDetails?.previousDues}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {paymentDetails?.handlingFee > 0 ? (
              <div className="d-flex justify-content-between">
                <p className="mb-1 font12x500 textColor3">Handlign Fee:</p>
                <p className="mb-0 font12x400 textColor3">
                  {`+₹${paymentDetails?.handlingFee}`}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between">
              <p className="mb-1 font12x500 textColor3">Grand Total:</p>
              <p className="mb-0 font12x400 textColor3">
                ₹{paymentDetails?.grandTotal}
              </p>
            </div>
          </div>
        </div>
        <div className="payment_mode d-flex justify-content-between align-items-center">
          <p className="mb-0 textColor3 font12x400">Payment Mode</p>
          {singleOrder?.customer_discount < 10 ? (
            <p className="mb-0 textColor3 font12x400">Online</p>
          ) : (
            <p className="mb-0 textColor3 font12x400">CASH</p>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderPaymentDetails;
