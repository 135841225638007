import { Link, useNavigate } from "react-router-dom";
import { ProfileSection1Menus } from "./constant";

const ProfileMenusSection1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="profile_menus_section1_wrapper">
        <div className="profile_menus_section1_section">
          {ProfileSection1Menus?.map((item: any, i: number) => {
            return (
              <Link key={i} to={item?.link} className="text-decoration-none">
                <div className="section1_individual_menu">
                  <img src={item?.imagePath} alt={item?.alt} />
                  <p className="mb-0 font10x400">{item?.label}</p>
                </div>
              </Link>
            );
          })}
        </div>

        {/* getlook gold banner */}
        <div
          className="g_gold_banner mt-4 px-4 d-flex justify-content-between align-items-center"
          onClick={() => navigate("/getlook-gold")}
        >
          <img
            src="/static/img/g-gold-logo.png"
            className="mt-2"
            alt="membership"
            width={120}
          />
          <button type="button" className="textColor1 g_gold_btn font12x400">
            View
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileMenusSection1;
