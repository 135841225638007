import moment from "moment";
import React from "react";
import {
  conveyanceId,
  couponId,
  handlingFeeId,
  membershipDiscountId,
  membershipId,
  ORDER_DETAILS_STATUS,
  premiumId,
  previousDuesId,
  rescheduleChargeId,
  safetyId,
  surgeId,
  upgradeId,
  walletId,
} from "../../../../../../../utils/constant/constant";
import { getDataFromSIns } from "../../../../../../../utils/function/function";
import { ClockIcon } from "../../../../../../shared/Icons";
import Separator from "../../../../../../shared/Separator";
import StylistInfo from "../StylistInfo";

type PropType = {
  singleOrder: any;
};

const BeauticianDetails: React.FC<PropType> = (props) => {
  const { singleOrder } = props;

  return (
    <>
      <div className="order_details_wrapper">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="mb-0 font14x700 textColor3">Order Details</h2>
          <span
            style={{
              backgroundColor:
                ORDER_DETAILS_STATUS[singleOrder?.status?.toString()]
                  ?.backgroundColor,
              color:
                ORDER_DETAILS_STATUS[singleOrder?.status?.toString()]?.color,
            }}
            className="status_detail font12x400"
          >
            {ORDER_DETAILS_STATUS[singleOrder?.status?.toString()]?.status}
          </span>
        </div>
        <Separator />
        <div className="py-3 d-flex justify-content-between">
          <div>
            <p className="mb-1 font12x400 textColor7">Appointment On</p>
          </div>
          <div>
            <p className="mb-0 font13x400 textColor3">
              {moment(singleOrder?.date).format("ddd, MMM D")},{" "}
              {singleOrder?.timing}
            </p>
          </div>
        </div>

        {/* don't show stylist div on these status */}
        {![0, 6, 7, 10, -1, 11].includes(singleOrder?.status) && (
          <>
            <Separator />

            <StylistInfo singleOrder={singleOrder} />

            <Separator />
          </>
        )}

        
      </div>
    </>
  );
};

export default BeauticianDetails;
