import { useNavigate } from "react-router-dom";
import { Error_404 } from "../../../components/shared/Icons";
import "./index.css";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <div className="not_found_page mobile">
      <div className="d-flex align-items-center justify-content-center flex-column">
        <Error_404 />
        <div className="mt-4 text-center">
          <p className="mb-2 font16x600 textColor3">Page Not Found!</p>
          <p className="mb-0 font14x400 textColor7">
            We're sorry, the page you requested
          </p>
          <p className="font14x400 textColor7">could not be found!</p>
          <button
            onClick={() => navigate("/", { replace: true })}
            className="btn_404 font14x600"
          >
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Error404;
