import React, { useState } from "react";
import { commonType } from "../../../../pages/main/type";
import {
  calculateDiscountInPercent,
  getImageURL,
  setOverflowToBodyHidden,
} from "../../../../utils/function/function";
import BottomTopAnimation from "../../../shared/BottomTopAnimation";
import { ClockIcon, StarIcon } from "../../../shared/Icons";
import Separator from "../../../shared/Separator";
import ViewDetails from "../../../shared/ViewServiceDetails";
import FacialGuide from "./FacialGuide";
import { BiSolidOffer } from "react-icons/bi";

type PropType = {
  services: any;
  cartItemsInContext: any;
  handleClickOnMinusPlus: (val1: any, val2: string) => void;
  handleClickOnAdd: (val1: any) => void;
  handleScrolledServices: (e: any) => void;
  showServicePageSpecialOffers: boolean;
};

const Service: React.FC<PropType> = (props) => {
  const {
    services,
    cartItemsInContext,
    handleClickOnMinusPlus,
    handleClickOnAdd,
    handleScrolledServices,
    showServicePageSpecialOffers,
  } = props;
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
  };

  return (
    <>
      <div
        style={{
          paddingBottom: showServicePageSpecialOffers ? "50px" : "0px",
        }}
        onScroll={handleScrolledServices}
        id="all_sub_category"
      >
        {/* rendering all services */}
        {services?.map((item: any, i: number) => {
          // return item?.subCategoryId === 8 || item?.subCategoryId === 76 ? (
          // UI for facial category only
          return (
            <div
              id={`category_${item?.subCategoryId}`}
              key={i}
              className="single_sub_category mb-0"
            >
              {/* category_name_container */}
              <div className="book-category_name_container d-flex align-items-center justify-content-between">
                <div>
                  <h1 className="mb-0 font14x600 textColor3">
                    {item?.subCategoryName}
                  </h1>
                </div>

                {/* <div
                  onClick={() => fn.openModal(undefined, "facial_guide")}
                  className="d-flex align-items-center"
                >
                  <img src="/static/svg/book.svg" />
                  <h1 className="mb-0 font16x600 textColor1 ms-2">
                    Facial Guide
                  </h1>
                </div> */}
              </div>

              {/* services */}
              {item?.services?.map((serviceItem: any, index: number) => {
                return (
                  <>
                    {serviceItem?.serviceType?.recommended != null &&
                    serviceItem?.serviceType?.recommended != "" ? (
                      <div
                        key={index}
                        style={{
                          margin: "10px",
                          borderRadius: "12px",
                        }}
                      >
                        <div
                          id={`service_${serviceItem?.serviceType?.id}`}
                          className="services_list p-0"
                          style={{
                            borderRadius: "12px",
                          }}
                        >
                          <div className="service_image_facial position-relative overflow-hidden mt-3">
                            {serviceItem?.serviceType?.items_required ? (
                              <div className="service_vedio_wrapper pb-2">
                                <video
                                  poster={serviceItem?.serviceType?.image_4}
                                  controls={true}
                                  autoPlay
                                  muted={true}
                                >
                                  <source
                                    src={
                                      serviceItem?.serviceType?.items_required
                                    }
                                  />
                                </video>
                              </div>
                            ) : (
                              <>
                                {serviceItem?.serviceType?.image_1 && (
                                  <img
                                    style={{
                                      borderRadius: "12px 12px 0px 0px",
                                    }}
                                    src={getImageURL(
                                      serviceItem?.serviceType?.image_1
                                    )}
                                    alt="service"
                                  />
                                )}
                              </>
                            )}
                          </div>
                          <div className="service_container border-0 pb-0 p-3">
                            <div className="service_info_and_add_container">
                              {serviceItem?.serviceType?.recommended && (
                                <p className="mb-2 service_tag font10x600">
                                  {serviceItem?.serviceType?.recommended}
                                </p>
                              )}
                              <h1 className="mb-0 font14x600 textColor3">
                                {serviceItem?.serviceType?.name?.split(":")[0]}
                              </h1>

                              <div className="d-flex align-items-center gap-1 mb-2 mt-2">
                                <ClockIcon />
                                <p className="mb-0 font12x500 textColor7">
                                  {`${serviceItem?.serviceType?.time} mins`}
                                </p>
                              </div>
                              <div className="w-100 price_and_time_container d-flex align-items-center pb-1">
                                <p className="mb-0 font12x700 textColor3">
                                  {`₹${serviceItem?.cost}`}
                                </p>
                                {serviceItem?.cost !==
                                  serviceItem?.serviceType?.cost && (
                                  <p className="mb-0 ms-2 font12x700 text-decoration-line-through textColor7">
                                    {`₹${serviceItem?.serviceType?.cost}`}
                                  </p>
                                )}
                                {serviceItem?.cost !==
                                  serviceItem?.serviceType?.cost && (
                                  <p className="ms-1 mb-0 service_discount_tag gap-1 font10x600 d-flex align-items-center">
                                    | <BiSolidOffer />
                                    {calculateDiscountInPercent(
                                      serviceItem?.cost,
                                      serviceItem?.serviceType?.cost
                                    )}
                                    % OFF
                                  </p>
                                )}
                              </div>
                              <div className="rating_review_container pt-1 d-flex align-items-center gap-1">
                                {serviceItem?.serviceType?.commission > 0 ? (
                                  <div className="rating_container d-flex align-items-center gap-1">
                                    {/* <i>
                                  <StarIcon
                                    width="12"
                                    height="11"
                                    fill="#838383"
                                  />
                                </i> */}
                                    <p className="mb-0 mt-0 textColor7 booked-in-days mb-0">
                                      {/* {serviceItem?.serviceType?.commission / 10} */}
                                      {serviceItem?.serviceType?.commission/10}k+
                                      women booked in last 7 days
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="ms-4">
                              {/** @author Deepanshu Tyagi */}
                              {cartItemsInContext.find((cartItem: any) => {
                                return (
                                  cartItem?.serviceType?.id ===
                                  serviceItem?.serviceType?.id
                                );
                              }) ? (
                                <div className="d-flex minus_plus_button_container align-items-center justify-content-between">
                                  <button
                                    onClick={() => {
                                      handleClickOnMinusPlus(serviceItem, "-");
                                    }}
                                    className="font14x600 textColor1 d-flex border-0"
                                  >
                                    -
                                  </button>
                                  <p className="font14x600 textColor1 mb-0">
                                    {
                                      cartItemsInContext.find(
                                        (cartItem: any) => {
                                          return (
                                            cartItem?.serviceType?.id ==
                                            serviceItem?.serviceType?.id
                                          );
                                        }
                                      )?.count
                                    }
                                  </p>
                                  <button
                                    onClick={() => {
                                      handleClickOnMinusPlus(serviceItem, "+");
                                    }}
                                    className="font14x600 textColor1 border-0"
                                  >
                                    +
                                  </button>
                                </div>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleClickOnAdd(serviceItem);
                                  }}
                                  className="font14x600 add_button textColor1 d-flex align-items-center justify-content-center"
                                >
                                  ADD+
                                </button>
                              )}
                              {/** */}
                            </div>
                          </div>
                          <div className="pt-1 pb-2 p-3">
                            <div className="description_container">
                              <ul className="mb-0 ps-3">
                                {serviceItem?.serviceType?.benefit
                                  ?.split("+")
                                  ?.slice(0, 2)
                                  ?.map((item: string, i: number) => {
                                    return (
                                      <li
                                        key={i}
                                        className="mb-1 font12x400 textColor7"
                                      >
                                        {item?.trim()}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <div className="view_details_container">
                              <button
                                onClick={() =>
                                  fn.openModal(serviceItem, "view_details")
                                }
                                className="font14x600 bg-white border-0 textColor1"
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </div>
                        <Separator />
                      </div>
                    ) : (
                      <div key={index}>
                        <div
                          id={`service_${serviceItem?.serviceType?.id}`}
                          className="services_list"
                        >
                          <div className="service_container">
                            <div className="service_info_and_add_container">
                              {serviceItem?.serviceType?.recommended && (
                                <p className="mb-2 service_tag font10x600">
                                  {serviceItem?.serviceType?.recommended}
                                </p>
                              )}
                              <h1 className="mb-0 font14x600 textColor3">
                                {serviceItem?.serviceType?.name?.split(":")[0]}
                              </h1>
                              <div className="d-flex align-items-center mt-2 mb-2 gap-1">
                                <ClockIcon />
                                <p className="mb-0 font12x500 textColor7">
                                  {`${serviceItem?.serviceType?.time} mins`}
                                </p>
                              </div>
                              <div className="w-100 price_and_time_container d-flex align-items-center pb-0 mb-0">
                                <p className="mb-0 font12x700 textColor3">
                                  {`₹${serviceItem?.cost}`}
                                </p>
                                {serviceItem?.cost !==
                                  serviceItem?.serviceType?.cost && (
                                  <p className="mb-0 ms-2 font12x700 text-decoration-line-through textColor7">
                                    {`₹${serviceItem?.serviceType?.cost}`}
                                  </p>
                                )}
                                {serviceItem?.cost !==
                                  serviceItem?.serviceType?.cost && (
                                  <p className="ms-1 mb-0 service_discount_tag gap-1 font10x600 d-flex align-items-center">
                                    | <BiSolidOffer />
                                    {calculateDiscountInPercent(
                                      serviceItem?.cost,
                                      serviceItem?.serviceType?.cost
                                    )}
                                    % OFF
                                  </p>
                                )}
                              </div>
                              <div className="rating_review_container d-flex align-items-center gap-1 mt-1 pt-0 pb-0">
                                {serviceItem?.serviceType?.commission > 0 ? (
                                  <div className="rating_container d-flex align-items-center gap-1">
                                    {/* <i>
                                  <StarIcon
                                    width="12"
                                    height="11"
                                    fill="#838383"
                                  />
                                </i> */}
                                    <p className="mb-0 textColor7 booked-in-days mb-0">
                                      {/* {serviceItem?.serviceType?.commission / 10} */}
                                      {serviceItem?.serviceType?.commission/10}k+
                                      women booked in last 7 days
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div className="description_container">
                                <ul className="mb-0 ps-3">
                                  {serviceItem?.serviceType?.benefit
                                    ?.split("+")
                                    ?.slice(0, 2)
                                    ?.map((item: string, i: number) => {
                                      return (
                                        <li
                                          key={i}
                                          className="mb-1 font12x400 textColor7"
                                        >
                                          {item?.trim()}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div> */}
                              <div className="view_details_container">
                                <button
                                  onClick={() =>
                                    fn.openModal(serviceItem, "view_details")
                                  }
                                  className="font14x600 bg-white border-0 textColor1"
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                            <div className="ms-4">
                              <div className="service_image position-relative overflow-hidden">
                                {serviceItem?.serviceType?.image_1 && (
                                  <img
                                    className="border_radius_8"
                                    src={getImageURL(
                                      serviceItem?.serviceType?.image_1
                                    )}
                                    alt="service"
                                  />
                                )}
                              </div>
                              {/** @author Deepanshu Tyagi */}
                              {cartItemsInContext.find((cartItem: any) => {
                                return (
                                  cartItem?.serviceType?.id ===
                                  serviceItem?.serviceType?.id
                                );
                              }) ? (
                                <div className="d-flex mt-2 minus_plus_button_container align-items-center justify-content-between">
                                  <button
                                    onClick={() => {
                                      handleClickOnMinusPlus(serviceItem, "-");
                                    }}
                                    className="font14x600 textColor1 d-flex border-0"
                                  >
                                    -
                                  </button>
                                  <p className="font14x600 textColor1 mb-0">
                                    {
                                      cartItemsInContext.find(
                                        (cartItem: any) => {
                                          return (
                                            cartItem?.serviceType?.id ==
                                            serviceItem?.serviceType?.id
                                          );
                                        }
                                      )?.count
                                    }
                                  </p>
                                  <button
                                    onClick={() => {
                                      handleClickOnMinusPlus(serviceItem, "+");
                                    }}
                                    className="font14x600 textColor1 border-0"
                                  >
                                    +
                                  </button>
                                </div>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleClickOnAdd(serviceItem);
                                  }}
                                  className="font14x600 mt-2 add_button textColor1 d-flex align-items-center justify-content-center"
                                >
                                  ADD+
                                </button>
                              )}
                              {/** */}
                            </div>
                          </div>
                        </div>
                        <Separator />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          );
          // ) : (
          //   // UI for other category
          //   <div
          //     id={`category_${item?.subCategoryId}`}
          //     key={i}
          //     className="single_sub_category mb-0"
          //   >
          //     {/* category_name_container */}
          //     <div className="book-category_name_container d-flex align-items-center justify-content-between">
          //       <div>
          //         <h1 className="mb-0 font16x600 textColor3">
          //           {item?.subCategoryName}
          //         </h1>
          //       </div>

          //       {/* <div
          //         onClick={() => fn.openModal(undefined, "facial_guide")}
          //         className="d-flex align-items-center"
          //       >
          //         <img src="/static/svg/book.svg" />
          //         <h1 className="mb-0 font16x600 textColor1 ms-2">
          //           Facial Guide
          //         </h1>
          //       </div> */}
          //     </div>

          //     {/* services */}
          //     {item?.services?.map((serviceItem: any, index: number) => {
          //       return (
          //         <div key={index}>
          //           <div
          //             id={`service_${serviceItem?.serviceType?.id}`}
          //             className="services_list"
          //           >
          //             <div className="service_container">
          //               <div className="service_info_and_add_container">
          //                 {serviceItem?.serviceType?.recommended && (
          //                   <p className="mb-2 service_tag font10x600">
          //                     {serviceItem?.serviceType?.recommended}
          //                   </p>
          //                 )}
          //                 <h1 className="mb-0 font14x600 textColor3">
          //                   {serviceItem?.serviceType?.name?.split(":")[0]}
          //                 </h1>
          //                 <div className="rating_review_container d-flex align-items-center gap-1">
          //                   {serviceItem?.serviceType?.commission !== -1 && (
          //                     <div className="rating_container d-flex align-items-center gap-1">
          //                       {/* <i>
          //                         <StarIcon
          //                           width="12"
          //                           height="11"
          //                           fill="#838383"
          //                         />
          //                       </i> */}
          //                       <p className="mb-0 textColor7 booked-in-days">
          //                         {/* {serviceItem?.serviceType?.commission / 10} */}
          //                         {serviceItem?.serviceType?.commission}k+ women
          //                         booked in last 7 days
          //                       </p>
          //                     </div>
          //                   )}
          //                 </div>
          //                 <div className="w-100 price_and_time_container d-flex align-items-center">
          //                   <p className="mb-0 font12x700 textColor3">
          //                     {`₹${serviceItem?.cost}`}
          //                   </p>
          //                   {serviceItem?.cost !==
          //                     serviceItem?.serviceType?.cost && (
          //                     <p className="mb-0 ms-2 font12x700 text-decoration-line-through textColor7">
          //                       {`₹${serviceItem?.serviceType?.cost}`}
          //                     </p>
          //                   )}
          //                   <div className="d-flex align-items-center ms-3 gap-1">
          //                     <ClockIcon />
          //                     <p className="mb-0 font12x500 textColor7">
          //                       {`${serviceItem?.serviceType?.time} mins`}
          //                     </p>
          //                   </div>
          //                 </div>
          //                 <div className="description_container">
          //                   <ul className="mb-0 ps-3">
          //                     {serviceItem?.serviceType?.benefit
          //                       ?.split("+")
          //                       ?.slice(0, 2)
          //                       ?.map((item: string, i: number) => {
          //                         return (
          //                           <li
          //                             key={i}
          //                             className="mb-1 font12x400 textColor7"
          //                           >
          //                             {item?.trim()}
          //                           </li>
          //                         );
          //                       })}
          //                   </ul>
          //                 </div>
          //                 <div className="view_details_container">
          //                   <button
          //                     onClick={() =>
          //                       fn.openModal(serviceItem, "view_details")
          //                     }
          //                     className="font14x600 bg-white border-0 textColor1"
          //                   >
          //                     View Details
          //                   </button>
          //                 </div>
          //               </div>
          //               <div className="ms-4">
          //                 <div className="service_image position-relative overflow-hidden">
          //                   {serviceItem?.serviceType?.image_1 && (
          //                     <img
          //                       className="border_radius_8"
          //                       src={getImageURL(
          //                         serviceItem?.serviceType?.image_1
          //                       )}
          //                       alt="service"
          //                     />
          //                   )}
          //                   {serviceItem?.cost !==
          //                     serviceItem?.serviceType?.cost && (
          //                     <p className="mb-1 service_discount_tag font10x600">
          //                       {calculateDiscountInPercent(
          //                         serviceItem?.cost,
          //                         serviceItem?.serviceType?.cost
          //                       )}
          //                       % <br />
          //                       OFF
          //                     </p>
          //                   )}
          //                 </div>
          //                 {/** @author Deepanshu Tyagi */}
          //                 {cartItemsInContext.find((cartItem: any) => {
          //                   return (
          //                     cartItem?.serviceType?.id ===
          //                     serviceItem?.serviceType?.id
          //                   );
          //                 }) ? (
          //                   <div className="d-flex mt-2 minus_plus_button_container align-items-center justify-content-between">
          //                     <button
          //                       onClick={() => {
          //                         handleClickOnMinusPlus(serviceItem, "-");
          //                       }}
          //                       className="font14x600 textColor1 d-flex border-0"
          //                     >
          //                       -
          //                     </button>
          //                     <p className="font14x600 textColor1 mb-0">
          //                       {
          //                         cartItemsInContext.find((cartItem: any) => {
          //                           return (
          //                             cartItem?.serviceType?.id ==
          //                             serviceItem?.serviceType?.id
          //                           );
          //                         })?.count
          //                       }
          //                     </p>
          //                     <button
          //                       onClick={() => {
          //                         handleClickOnMinusPlus(serviceItem, "+");
          //                       }}
          //                       className="font14x600 textColor1 border-0"
          //                     >
          //                       +
          //                     </button>
          //                   </div>
          //                 ) : (
          //                   <button
          //                     onClick={() => {
          //                       handleClickOnAdd(serviceItem);
          //                     }}
          //                     className="font14x600 mt-2 add_button textColor1 d-flex align-items-center justify-content-center"
          //                   >
          //                     ADD+
          //                   </button>
          //                 )}
          //                 {/** */}
          //               </div>
          //             </div>
          //             {serviceItem?.serviceType?.items_required && (
          //               <div className="service_vedio_wrapper pb-2">
          //                 <video
          //                   poster={serviceItem?.serviceType?.image_4}
          //                   controls
          //                 >
          //                   <source
          //                     src={serviceItem?.serviceType?.items_required}
          //                   />
          //                 </video>
          //               </div>
          //             )}
          //           </div>
          //           <Separator />
          //         </div>
          //       );
          //     })}
          //   </div>
          // );
        })}
      </div>

      {/* view details */}
      {commonData?.modal && commonData?.check === "view_details" && (
        <BottomTopAnimation>
          <ViewDetails state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}

      {/* facial guide */}
      {commonData?.modal && commonData?.check === "facial_guide" && (
        <BottomTopAnimation>
          <FacialGuide state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default Service;
