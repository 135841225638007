import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { BiCheck } from "react-icons/bi";
import Location from "../../../components/pages/Home/Location";
import { SafetyStandard } from "../../../components/pages/Home/SafetyStandard/constant";
import { WhyPoints } from "../../../components/pages/Home/WhyGetlook/constant";
import { HomePage } from "../../../utils/metaInfo/metaInfo";
import moment from "moment";
import { Auth } from "../../../utils/context/AuthContext";
import {
  checkingTimeDifference,
  checkingTrackingTimeDifference,
  filterHomepageSections,
  getDeviceSource,
  isUserValid,
  setOverflowToBodyUnset,
} from "../../../utils/function/function";
import Banner from "../../../components/pages/Home/Banner";
import ShopByService from "../../../components/pages/Home/ShopByService";
import BestSellingPackages from "../../../components/pages/Home/Best Selling Packages";
import MostBookedServices from "../../../components/pages/Home/Most Booked Services";
import DealOfTheDay from "../../../components/pages/Home/Deal of the day";
import Offers from "../../../components/pages/Home/Offers";
import Products from "../../../components/pages/Home/Products";
import Videos from "../../../components/pages/Home/Videos";
import {
  HOME_TYPE,
  reviewTitles,
  TEMP_HOME_TYPE,
} from "../../../utils/constant/constant";
import {
  ClockIcon,
  CloseIcon,
  SearchIcon,
  ServiceNotFoundAtLocation,
  StarIcon,
} from "../../../components/shared/Icons";
import NewLaunchServices from "../../../components/pages/Home/New Launch Services";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import Brands from "../../../components/pages/Home/Brands";
import PopupBanner from "../../../components/shared/PopupBanner";
import BottomTopAnimation from "../../../components/shared/BottomTopAnimation";
import { commonType } from "../type";
import OrderRating from "../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/OrderRating";
import { useFormik } from "formik";
import useLoading from "../../../hooks/useLoading";
import { reviewSchema } from "../../../validation/validation";
import {
  getAppointments,
  review,
} from "../../../lib/api functions/order/order";
import { showToast } from "../../../lib/toast/showToast";
import InputError from "../../../components/shared/InputError/InputError";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import Separator from "../../../components/shared/Separator";
import { CiStar } from "react-icons/ci";
import { IoIosStarOutline } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import { IoMdAlarm } from "react-icons/io";
import { getWallet } from "../../../lib/api functions/wallet/wallet";

type PropType = {
  singleOrder: any;
  getSingleOrderFn: () => void;
};

type ReviewType = {
  rating: number;
  description: string;
  title: string[];
};

const Home = () => {
  const {
    getHomepageDataFn,
    latitude,
    longitude,
    trackUserActivity,
    servingOnLocation,
    mainLoading
  } = useContext(Auth);
  const navigate = useNavigate();

  const [showRatingPopUp, setShowRatingPopUp] = useState<boolean>(false);

  const initialValues: ReviewType = {
    rating: 0,
    description: "",
    title: [],
  };

  const { homepage_sections } = useContext(Auth);

  const homePopupBanner = filterHomepageSections(homepage_sections, "popup");
  const new_cover = filterHomepageSections(homepage_sections, "new_cover");

  const offers = filterHomepageSections(homepage_sections, "offers")?.sort(
    (a: any, b: any) => {
      return a.category_order - b.category_order;
    }
  );

  const [showPopUpVideoPage, setShowPopUpVideoPage] = useState(false);
  const [showPopUpVideoButton, setShowPopUpVideoButton] = useState(true);

  const [placeholder, setPlaceholder] = useState("");
  const [walletInfo, setWalletInfo] = useState<any>("");
  const [singleOrder, setSingleOrder] = useState<any>("");
  const [singleOffer, setSingleOffer] = useState<any>("");
  const [singleImage, setSingleImage] = useState<any>("");

  const [loading, showLoader, hideLoader] = useLoading();
  const { state } = useLocation();

  useEffect(() => {
    setOverflowToBodyUnset();
    const timeDiff = checkingTimeDifference();
    if (timeDiff) {
      getHomepageDataFn(latitude, longitude);
    }
  }, []);

  const words = ["Waxing", "Facial", "Makeup", "Hair Treatment"];
  let i = 0;
  let timer;

  function typingEffect() {
    const placeholderElement = document.getElementById("placeholder");
    let word = words[i].split("");
    var loopTyping = function () {
      if (word.length > 0) {
        if (placeholderElement) {
          placeholderElement.innerHTML += word.shift();
        }
      } else {
        deletingEffect();
        return false;
      }
      timer = setTimeout(loopTyping, 300);
    };
    loopTyping();
  }

  function deletingEffect() {
    const placeholderElement = document.getElementById("placeholder");
    let word = words[i].split("");
    var loopDeleting = function () {
      if (word.length > 0) {
        word.pop();
        if (placeholderElement) {
          placeholderElement.innerHTML = word.join("");
        }
      } else {
        if (words.length > i + 1) {
          i++;
        } else {
          i = 0;
        }
        typingEffect();
        return false;
      }
      timer = setTimeout(loopDeleting, 200);
    };
    loopDeleting();
  }

  useEffect(() => {
    if (offers && offers?.length > 0) {
      offers?.forEach((offer: any) => {
        if (offer?.id === 157) {
          setSingleOffer(offer);
        }
      });
    }
  }, [offers]);

  // user tracking
  useEffect(() => {
    typingEffect();
    const user_activity = JSON.parse(localStorage.getItem("user_activity")!);

    if (isUserValid()) {
      const trackingTimeDiff = checkingTrackingTimeDifference();
      if (trackingTimeDiff) {
        // removing user activity after 6 hours
        window.localStorage.removeItem("user_activity");

        trackUserActivity({
          type: HOME_TYPE,
          tempType: TEMP_HOME_TYPE,
          area_id: localStorage.getItem("area_id")!,
          source: getDeviceSource(),
        });

        window.localStorage.setItem(
          "tracking_cache_timestamp",
          JSON.stringify(moment())
        );
      } else {
        if (!user_activity) {
          trackUserActivity({
            type: HOME_TYPE,
            tempType: TEMP_HOME_TYPE,
            area_id: localStorage.getItem("area_id")!,
            source: getDeviceSource(),
          });
        }
      }
    }
  }, []);

  // ad tracking
  var lastScrollTop = 0;

  // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.

  useEffect(() => {
    const headerSearchContainer: any = document.getElementsByClassName(
      "home-header-search-container"
    );
    const heroSection: any = document.getElementsByClassName("hero-section");
    document.addEventListener("scroll", (e: any) => {
      if (
        e?.target?.scrollingElement?.scrollTop >
        heroSection[0]?.offsetHeight / 2 - 137
      ) {
        if (headerSearchContainer[0]) {
          headerSearchContainer[0].style.backgroundColor = "white";
        }
      } else {
        if (headerSearchContainer[0]) {
          headerSearchContainer[0].style.backgroundColor = "transparent";
        }
      }
      // console.log(heroSection[0]?.offsetHeight);

      // var st = window.pageYOffset || document.documentElement.scrollTop;
      // if (st > lastScrollTop) {
      //   // downscroll code
      //   console.log(true);
      // } else if (st < lastScrollTop) {
      //   console.log(false);
      //   // upscroll code
      // } // else was horizontal scroll
      // lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

      // rgb(255 255 255 / 0%)
    });
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    return () => {
      if (!sessionStorage.getItem("showVideoPopButton")) {
        sessionStorage.setItem("showVideoPopButton", "true");
      }
    };
  }, []);

  const fn = {
    updateReviewTitles: (value: string) => {
      if (formik.values.title?.includes(value)) {
        const filteredTitle = formik.values.title?.filter((item: any) => {
          return item !== value;
        });
        formik.setFieldValue("title", filteredTitle);
      } else {
        formik.setFieldValue("title", [...formik.values.title, value]);
      }
    },
    getSingleOrderFn: async () => {
      showLoader();

      const res = await getAppointments({});
      if (res) {
        const data1 = res.data[0];
        if (data1?.rating === -2 && data1?.status === 5) {
          setShowRatingPopUp(true);
        } else {
          setShowRatingPopUp(false);
        }
        setSingleOrder(data1);
        hideLoader();
      }
    },
    getWalletData: async () => {
      const params = "";
      const res = await getWallet(params);
      if (res) {
        const walletList = res?.data;

        let expiresData: any;
        const daysDifference: any = [];
        const walletDataWithDayDiff: any = {};
        walletList.forEach((walletItem: any) => {
          if (!walletItem?.isUsed && walletItem?.trxn_type === 1) {
            // days diff
            const dayDiff = moment(walletItem?.expiry_date).diff(
              moment(),
              "days"
            );
            daysDifference.push(dayDiff);
            walletDataWithDayDiff[dayDiff] = walletItem;
          }
        });

        expiresData = walletDataWithDayDiff[Math.min(...daysDifference)];

        if (
          res?.data[0]?.customer?.wallet_balance == 0 ||
          res?.data[0]?.customer?.wallet_balance > 0
        ) {
          setWalletInfo((prev: any) => {
            return {
              expiresData: expiresData,
              ...prev,
              balance:
                res?.data?.length > 0
                  ? res?.data[0]?.customer?.wallet_balance
                  : "0.00",
            };
          });
        } else if (res?.data[0]?.customer?.wallet_balance < 0) {
          setWalletInfo((prev: any) => {
            return {
              expiresData: expiresData,
              ...prev,
              balance: res?.data[0]?.customer?.wallet_balance,
            };
          });
        } else {
          setWalletInfo((prev: any) => {
            return {
              expiresData: expiresData,
              ...prev,
              balance:
                res?.data?.length > 0
                  ? res?.data[0]?.customer?.wallet_balance
                  : "0.00",
            };
          });
        }
      }
    },
  };

  const formik = useFormik<ReviewType>({
    initialValues,
    validationSchema: reviewSchema,
    onSubmit: async (values) => {
      showLoader();
      let finalObj = {
        id: singleOrder?.id,
        title: formik.values.title.join(","),
        description: values.description,
        rating: values.rating,
        stylist_username: singleOrder.stylist.user.username,
        source: getDeviceSource(),
      };

      const res = await review(finalObj);
      if (res) {
        showToast(`Order reviewed`, "success");
        fn.getSingleOrderFn();
        hideLoader();
      }
    },
  });

  // scroll to rating section
  useEffect(() => {
    if (isUserValid()) {
      fn.getWalletData();
    }
    if (state?.divId) {
      let rating = document.getElementById(state?.divId);
      rating?.scrollIntoView(true);
    }

    if (localStorage.getItem("token")) {
      fn.getSingleOrderFn();
    }
  }, []);

  console.log("popupBanner", homePopupBanner);

  return (
    <>
      <div className="padding_bottom">
        <Helmet>
          <title>{HomePage.title}</title>
          <meta name="description" content={HomePage.description} />
          <meta name="keywords" content={HomePage.keywords} />
        </Helmet>

        {servingOnLocation?.isServing ? (
          <>
            <div className="hero-section">
              <div className="home-header-search-container">
                {/* location */}
                <Location />
                {/* search */}
                <div
                  className="search_wrapper"
                  onClick={() => navigate("/search")}
                >
                  <div className="search_input_section">
                    <input type="text" />
                    <p className="search-placeholder">
                      Search for <span id="placeholder">{placeholder}</span>
                    </p>
                    <SearchIcon />
                  </div>
                </div>
              </div>
              {new_cover?.map((coverItem: any) => {
                return (
                  <>
                    {coverItem?.name?.includes("new_cover_top") ? (
                      <>
                        {coverItem?.items_required?.includes("http") ? (
                          <a href={coverItem?.items_required}>
                            <img
                              className="hero-section-image"
                              src={coverItem?.category_image}
                            />
                          </a>
                        ) : (
                          <Link to={`/services/${coverItem?.items_required}`}>
                            <img
                              className="hero-section-image"
                              src={coverItem?.category_image}
                            />
                          </Link>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </div>

            {/* banner */}
            <Banner />

            {/* offers */}
            {/* <Offers /> */}

            {new_cover?.map((coverItem: any) => {
              return (
                <>
                  {coverItem?.name?.includes("gif") ? (
                    <>
                      {coverItem?.items_required?.includes("http") ? (
                        <a
                          className="home-page-single-image"
                          href={coverItem?.items_required}
                        >
                          <img width={"100%"} src={coverItem?.category_image} />
                        </a>
                      ) : (
                        <Link
                          className="home-page-single-image"
                          to={`/services/${coverItem?.items_required}`}
                        >
                          <img width={"100%"} src={coverItem?.category_image} />
                        </Link>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            })}

            {walletInfo?.balance > 0 ? (
              <Link
                to="/mywallet"
                className="wallet-expiring-alert d-flex align-items-center justify-content-between gap-2"
              >
                <div>
                  {!walletInfo?.expiresData?.isUsed &&
                  walletInfo?.expiresData?.trxn_type == 1 ? (
                    <p className="mb-0 font10x500 text-start textColor1 d-flex align-items-center gap-1">
                      <IoMdAlarm />
                      EXPIRES IN{" "}
                      {moment(walletInfo?.expiresData?.expiry_date).diff(
                        moment(),
                        "days"
                      )}{" "}
                      DAYS
                    </p>
                  ) : (
                    ""
                  )}

                  <p className="mt-1 mb-1 font12x700 text-start textColor3">
                    Hurry! Use Your Rs.
                    {walletInfo?.balance > 200 ? 200 : walletInfo?.balance} FREE
                    Gcash
                  </p>
                  {singleOffer?.items_required ? (
                    <p className="mb-0 font11x500 text-start textColor2">
                      On your next order above ₹{singleOffer?.items_required}
                    </p>
                  ) : (
                    <p className="mb-0 font11x500 text-start textColor2">
                      On your next order
                    </p>
                  )}
                </div>
                <img
                  width={50}
                  height={50}
                  src="/static/svg/wallet-balance.svg"
                  alt="wallet_balance"
                ></img>
              </Link>
            ) : (
              ""
            )}

            {/* services */}
            <ShopByService />

            {/* booked services */}
            <MostBookedServices />

            {/* products */}
            <Products />

            {/* service ad */}
            <DealOfTheDay />

            {/* packages */}
            <BestSellingPackages />

            {/* new launch services */}
            <NewLaunchServices />

            {/* getlook videos */}
            <Videos />

            {/* why getlook */}
            <div className="why_getlook_wrapper">
              <div className="why_getlook_section">
                <h2 className="mb-0 font14x700 textColor1">Why GetLook?</h2>
                <div className="mt-3 d-flex flex-column gap-3">
                  {WhyPoints?.map((item: any, i: number) => {
                    return (
                      <div key={i} className="d-flex gap-2">
                        <p className="mb-0 why_getlook_steps textColor1 font12x500">
                          <BiCheck />
                        </p>
                        <p className="mb-0 textColor3 font12x500">{item}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* brands used */}
            <Brands />

            {/* safety standards */}
            <div className="safety_standard_wrapper">
              <h2 className="mb-0 textColor3 font14x700">
                GetLook's Safety Standards
              </h2>
              <div className="standard_section mt-4">
                {SafetyStandard?.map((item: any, i: number) => {
                  return (
                    <div key={i} className="single_standard">
                      <div className="standard_image text-center">
                        <img src={item?.image} alt="standard" />
                      </div>
                      <p className="mb-0 px-2 mt-2 text-center textColor3 font8x500">
                        {item?.label}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* popup */}
            <PopupBanner />

            {/* refer (hidden for sometime) */}
            {/* <div className="refer_wrapper">
              <img className="w-100" src="/static/img/refer.png" alt="refer" />
            </div> */}
          </>
        ) : (
          <>
            {/* location */}
            <Location />
            {/* serving not */}
            <div className="mt-2 py-4 bg-white">
              <div className="d-flex flex-column align-items-center px-3">
                <ServiceNotFoundAtLocation />
                <h1 className="mb-0 mt-3 text-center font16x500">
                  {servingOnLocation?.data?.msg}
                </h1>
              </div>

              <div className="cities_wrapper mt-5 d-flex flex-column align-items-center">
                <h2 className="mb-3 font14x600">
                  We are currently serving in these cities
                </h2>
                <div className="cities_section">
                  {servingOnLocation?.data?.city?.map(
                    (item: string, i: number) => {
                      return (
                        <p key={i} className="mb-0 serving_city font14x400">
                          {item}
                        </p>
                      );
                    }
                  )}
                </div>
              </div>
            </div>

            <Link to={`http://wa.me/919513862350`} target="_blank">
              <button
                type="button"
                className="fix_section_at_bottom font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
              >
                Connect to chat
              </button>
            </Link>
          </>
        )}
      </div>

      {!showPopUpVideoButton || sessionStorage.getItem("showVideoPopButton") ? (
        ""
      ) : (
        <>
          {homePopupBanner?.map((item: any, i: number) => {
            return (
              <>
                {item?.name.includes(`video_popup`) ? (
                  <div className="video-popup-button">
                    <button
                      onClick={() => {
                        setShowPopUpVideoButton(false);
                        sessionStorage.setItem("showVideoPopButton", "true");
                      }}
                      className="video-pop-button-close"
                    >
                      <CloseIcon fill="black" height="8" width="8" />
                    </button>
                    <video
                      onClick={() => {
                        setShowPopUpVideoPage(true);
                      }}
                      autoPlay={true}
                      controls={false}
                      muted={true}
                      loop={true}
                      poster={item?.category_image}
                    >
                      <source src={item?.items_required} />
                    </video>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </>
      )}

      {showPopUpVideoPage ? (
        <div className="pop-up-video-page">
          <div className="cancel-container">
            <button
              onClick={() => {
                setShowPopUpVideoPage(false);
              }}
              className="d-flex align-items-center justify-content-center text-white border-0"
            >
              <CloseIcon width="10" height="10" fill="#fff" />
            </button>
          </div>
          <div className="pop-up-video-page-video-container">
            {homePopupBanner?.map((item: any, i: number) => {
              return (
                <>
                  {item?.name.includes(`video_popup`) ? (
                    <video
                      autoPlay={true}
                      controls={false}
                      loop={true}
                      poster={item?.category_image}
                    >
                      <source src={item?.items_required} />
                    </video>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* coupon applied */}
      {showRatingPopUp && (
        <BottomTopAnimation>
          <div className="home-page-rating-review-page">
            <div className="d-flex align-items-end justify-content-end">
              <button
                onClick={() => {
                  setShowRatingPopUp(false);
                }}
                className="home-page-rating-popup-close-button"
              >
                <CloseIcon width="16" height="16" fill="white" />
              </button>
            </div>
            <div
              className="rating_wrapper home-page-rating-wrapper"
              id="rating"
            >
              <div className="before_rated">
                <h2 className="font14x700 textColor3 text-center mb-0">
                  Rate your Experience
                </h2>

                <form onSubmit={formik.handleSubmit}>
                  {/* no. of rating */}
                  <div className="d-flex gap-2 mt-3 mb-4 align-items-center justify-content-center">
                    {[...Array(5)].map((_, i: number) => {
                      return (
                        <div
                          key={i}
                          className={`${
                            formik.values.rating <= i
                              ? "home-unselected"
                              : "home-ratings"
                          } cursor_pointer`}
                          onClick={() => {
                            formik.setFieldValue("rating", i + 1);
                          }}
                        >
                          {formik.values.rating <= i ? (
                            <IoIosStarOutline fill="#ececec" />
                          ) : (
                            <IoStar fill="#AF031A" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <Separator />
                  {/* review title */}
                  <div className="mt-4 review_titles_wrapper">
                    {reviewTitles?.map((item: string, i: number) => {
                      return (
                        <button
                          type="button"
                          key={i}
                          className={`${
                            formik.values.title?.includes(item)
                              ? "single_review_title_selected"
                              : "single_review_title"
                          } font12x400`}
                          onClick={() => fn.updateReviewTitles(item)}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>

                  {/* review description */}
                  <div className="mt-3 rating_text">
                    <textarea
                      rows={3}
                      className="w-100"
                      placeholder="Write a review for this order."
                      value={formik.values.description}
                      onChange={(e) =>
                        formik.setFieldValue("description", e.target.value)
                      }
                    ></textarea>
                    {formik.errors.description ? (
                      <InputError error={formik.errors.description} />
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn_2 font14x400 submit_review"
                      style={{
                        height: "32px",
                        borderRadius: 8,
                      }}
                    >
                      {loading ? (
                        <SpinLoader height="22" width="22" color="#fff" />
                      ) : (
                        " Submit Review"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </BottomTopAnimation>
      )}
    </>
  );
};

export default Home;
