import React from "react";
import { getDefaultInitial } from "../../../utils/function/function";

type PropType = {
  width: string;
  height: string;
  data: string;
  fontSize: string;
};

const ShowInitialImage: React.FC<PropType> = (props) => {
  const { width, height, data, fontSize } = props;
  return (
    <span
      className="initial_image_section"
      style={{
        width,
        height,
        fontSize,
      }}
    >
      {data && getDefaultInitial(data)}
    </span>
  );
};

export default ShowInitialImage;
