import axios from "axios";
import { showToast } from "../lib/toast/showToast";

const logoutUser = (msg: string) => {
  showToast(msg, "error");
  window.localStorage.clear();
  window.location.href = "/";
};

export default function Api(isFormData = false) {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  if (isFormData) {
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  } else {
    api.defaults.headers.common["Content-Type"] = "application/json";
  }

  const token = localStorage.getItem("token");
  if (token) {
    api.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    api.defaults.headers.common["Authorization"] = null;
  }

  api.interceptors.response.use(undefined, (err) => {
    if (
      err?.response?.status === 401 ||
      err?.response?.data?.message === "401 Unauthorized"
    ) {
      logoutUser("Unauthorized, Please log in again.");
    }
    return Promise.reject(err);
  });
  return api;
}
