import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/shared/Header";
import "./index.css";
import { LuClock8 } from "react-icons/lu";
import { useContext, useEffect, useState } from "react";
import { getSlot } from "../../../lib/api functions/slot/slot";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../../lib/toast/showToast";
import useLoading from "../../../hooks/useLoading";
import { rescheduleOrder } from "../../../lib/api functions/order/order";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import { Auth } from "../../../utils/context/AuthContext";
import { calculatePaymentDetails } from "../../../utils/function/function";

const RescheduleSlot = () => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, showLoader, hideLoader] = useLoading();
  const [slotLoading, showLoader2, hideLoader2] = useLoading();
  const [slotData, setSlotData] = useState<any>({
    date: moment().format("YYYY-MM-DD"),
    time: "",
  });
  const [times, setTimes] = useState<any>({});

  const fn = {
    handleClickOnDate: async (date: any) => {
      showLoader2();
      setTimes({});
      const body = {
        lat: state?.order?.latitude,
        long: state?.order?.longitude,
        city: state?.order?.area?.city,
        date: date,
        phone: user?.phone ?? "",
        total: calculatePaymentDetails()?.grandTotal,
      };
      const res = await getSlot(body);
      if (res) {
        hideLoader2();
        setTimes(res?.data);
        fn.isSlotsBlockOfActiveDate(res?.data, date);
      }
    },
    getDateOfNextDays: (nextDays: number): any => {
      const dates = [];
      dates.push(moment());
      for (let day = 1; day <= nextDays; day++) {
        dates.push(moment().add(day, "days"));
      }
      return dates;
    },
    handleClickOnProceed: async () => {
      if (!slotData?.date) {
        showToast("Please select date", "error");
      } else if (!slotData?.time) {
        showToast("Please select time", "error");
      } else {
        localStorage.setItem("slotInfo", `${JSON.stringify(slotData)}`);

        // calling rescheduling API
        showLoader();
        const finajObj = {
          create_type: "reschedule",
          id: state?.order?.id,
          slot: slotData?.slotNumber,
          date: slotData?.date,
          timing: slotData?.time,
          fee: state?.fee ? state?.fee : undefined,
          surge: slotData?.surge === 0 ? undefined : slotData?.surge,
        };

        const res = await rescheduleOrder(finajObj);
        if (res) {
          showToast("Your appointment has been rescheduled", "success");
          navigate("/order");
          hideLoader();
        }
      }
    },
    handleClickOnTime: (item: any, surge: any, i: any) => {
      let slotNumber = 0;
      if (i == 0 || i == 1) {
        slotNumber = 1;
      }
      if (i == 2 || i == 3) {
        slotNumber = 2;
      }
      if (i == 4 || i == 5) {
        slotNumber = 3;
      }
      setSlotData((prev: any) => {
        return {
          ...prev,
          time: item,
          surge: surge,
          slotNumber: slotNumber,
        };
      });
    },
    isSlotsBlockOfActiveDate: (slotsToBeCheck: any, date: any) => {
      const blockSlot: any = [];
      const slots = Object.keys(slotsToBeCheck);
      slots.forEach((item: any) => {
        if (
          slotsToBeCheck[item][0] == 0 ||
          (moment().isSame(date, "date") &&
            moment(moment(item.split("-")[0], "LT")).isBefore(moment()))
        ) {
          blockSlot.push(item);
        }
      });
      if (blockSlot.length == slots.length) {
        if (date == moment(fn.getDateOfNextDays(6)[6]).format("YYYY-MM-DD")) {
          showToast("There is no slots avalable", "error");
        } else {
          setSlotData((prev: any) => {
            return {
              ...prev,
              date: moment(date).add(1, "days").format("YYYY-MM-DD"),
            };
          });
          fn.handleClickOnDate(
            moment(date).add(1, "days").format("YYYY-MM-DD")
          );
        }
      }
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fn.handleClickOnDate(moment().format("YYYY-MM-DD"));
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="slot_page">
          <Header label="Reschedule Slot" navigate={() => navigate("/order")} />

          <div className="slot_section bg-white">
            <div className="time_slot_text_container d-flex align-items-center">
              <div className="textColor1 d-flex">
                <LuClock8 />
              </div>
              <h1 className="mb-0 font16x600 ms-2">Time Slot</h1>
            </div>
            <div className="slot_dates_container d-flex align-items-center">
              {fn.getDateOfNextDays(6).map((item: any, i: number) => {
                return (
                  <button
                    onClick={() => {
                      setSlotData((prev: any) => {
                        return {
                          ...prev,
                          date: moment(item).format("YYYY-MM-DD"),
                        };
                      });
                      fn.handleClickOnDate(item.format("YYYY-MM-DD"));
                    }}
                    key={i}
                    className={`slot_date_container ${
                      slotData.date == moment(item).format("YYYY-MM-DD")
                        ? "slot_date_selected"
                        : ""
                    }`}
                  >
                    <p className="mb-1 font16x400 textColor1">
                      {moment(item).format("ddd")}
                    </p>
                    <p className="mb-0 font16x400 textColor3">
                      {moment(item).format("MMM DD")}
                    </p>
                  </button>
                );
              })}
            </div>
            <div className="slots">
              <div className="mt-3">
                <p className="mb-2 font16x600 textColor3">
                  Select start time of service
                </p>
                <p className="mb-0 font12x400 textColor7">
                  Beautician will arrive within this time
                </p>
              </div>
              {slotLoading ? (
                <div className="slot_times_loaders_container d-grid">
                  <Skeleton className="time_loader" />
                  <Skeleton className="time_loader" />
                  <Skeleton className="time_loader" />
                  <Skeleton className="time_loader" />
                </div>
              ) : (
                <>
                  {Object.keys(times).length > 0 ? (
                    <div className="slot_times_container d-grid">
                      {Object.keys(times).map((item: any, i: any) => {
                        return (
                          <div
                            key={i}
                            className="slot_time_container position-relative"
                          >
                            <button
                              className={`font14x400 textColor7 ${
                                slotData.time == item
                                  ? "slot_time_selected"
                                  : ""
                              }`}
                              onClick={() => {
                                fn.handleClickOnTime(item, times[item][1], i);
                              }}
                              style={{
                                backgroundColor: `${
                                  times[item][0] ? "" : "#EBEBEB"
                                }`,
                              }}
                              disabled={
                                times[item][0] == 0 ||
                                (moment().isSame(slotData?.date, "date") &&
                                  moment(
                                    moment(item.split("-")[0], "LT")
                                  ).isBefore(moment()))
                                  ? true
                                  : false
                              }
                            >
                              {item}
                            </button>
                            {times[item][1] > 0 ? (
                              <div className="time_prime_price_container red_time_prime_price_container">
                                <p className="font10x600 textColor1 d-flex align-items-center justify-content-center mb-0">
                                  +₹{times[item][1]}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {times[item][1] < 0 ? (
                              <div className="time_prime_price_container green_time_prime_price_container">
                                <p className="font10x600 textColor1 d-flex align-items-center justify-content-center mb-0">
                                  -₹{times[item][1]}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="mb-0 font16x400 mt-3">
                      No slots available for this date
                    </p>
                  )}
                </>
              )}
            </div>
            <div
              className="py-2 slot_alert"
              style={{ backgroundColor: "var(--bg-color9)" }}
            >
              <p className="mb-0 font12x500 textColor1">
                We will confirm the exact service start time well in advance
              </p>
            </div>
          </div>
        </div>

        {/* proceed btn */}
        <div className="fix_section_at_bottom">
          <button
            type="button"
            className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
            onClick={() => {
              fn.handleClickOnProceed();
            }}
          >
            {loading ? (
              <SpinLoader height="22" width="22" color="#fff" />
            ) : (
              "Save & Proceed"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default RescheduleSlot;
