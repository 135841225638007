import Skeleton from "react-loading-skeleton";

const OrdersLoader = () => {
  return (
    <>
      <div className="px-3 py-2">
        <div className="d-flex justify-content-between">
          <div>
            <Skeleton width="140px" height="18px" borderRadius="8px" />
            <Skeleton
              className="mt-2"
              width="122px"
              height="18px"
              borderRadius="8px"
            />
            <Skeleton
              className="mt-2"
              width="100px"
              height="18px"
              borderRadius="8px"
            />
          </div>
          <Skeleton
            className="mt-2"
            width="100px"
            height="18px"
            borderRadius="8px"
          />
        </div>
        <div className="d-flex mt-2 justify-content-between">
          <Skeleton width="100px" height="18px" borderRadius="8px" />
          <Skeleton width="100px" height="18px" borderRadius="8px" />
        </div>
        <div className="d-flex mt-2 justify-content-between">
          <Skeleton width="140px" height="35px" borderRadius="8px" />
          <Skeleton width="140px" height="35px" borderRadius="8px" />
        </div>
      </div>
      <div className="px-3 py-2">
        <div className="d-flex justify-content-between">
          <div>
            <Skeleton width="140px" height="18px" borderRadius="8px" />
            <Skeleton
              className="mt-2"
              width="122px"
              height="18px"
              borderRadius="8px"
            />
            <Skeleton
              className="mt-2"
              width="100px"
              height="18px"
              borderRadius="8px"
            />
          </div>
          <Skeleton
            className="mt-2"
            width="100px"
            height="18px"
            borderRadius="8px"
          />
        </div>
        <div className="d-flex mt-2 justify-content-between">
          <Skeleton width="100px" height="18px" borderRadius="8px" />
          <Skeleton width="100px" height="18px" borderRadius="8px" />
        </div>
        <div className="d-flex mt-2 justify-content-between">
          <Skeleton width="140px" height="35px" borderRadius="8px" />
          <Skeleton width="140px" height="35px" borderRadius="8px" />
        </div>
      </div>
      <div className="px-3 py-2">
        <div className="d-flex justify-content-between">
          <div>
            <Skeleton width="140px" height="18px" borderRadius="8px" />
            <Skeleton
              className="mt-2"
              width="122px"
              height="18px"
              borderRadius="8px"
            />
            <Skeleton
              className="mt-2"
              width="100px"
              height="18px"
              borderRadius="8px"
            />
          </div>
          <Skeleton
            className="mt-2"
            width="100px"
            height="18px"
            borderRadius="8px"
          />
        </div>
        <div className="d-flex mt-2 justify-content-between">
          <Skeleton width="100px" height="18px" borderRadius="8px" />
          <Skeleton width="100px" height="18px" borderRadius="8px" />
        </div>
        <div className="d-flex mt-2 justify-content-between">
          <Skeleton width="140px" height="35px" borderRadius="8px" />
          <Skeleton width="140px" height="35px" borderRadius="8px" />
        </div>
      </div>
    </>
  );
};

export default OrdersLoader;
