import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../utils/function/function";

const Products = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const products = filterHomepageSections(homepage_sections, "products")?.sort(
    (a: any, b: any) => {
      return a.category_order - b.category_order;
    }
  );

  return (
    <>
      {products?.length > 0 && (
        <div className="products_wrapper shop_by_service_section mt-2">
          <h2 className="mb-0 p-0 font14x700 text-center">Shop by Products</h2>
          <div className="services_section mt-3">
            {mainLoading ? (
              <>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
              </>
            ) : (
              products?.map((item: any, i: number) => {
                return (
                  <Link
                    key={i}
                    to={`/products/${item?.id}`}
                    className="text-decoration-none"
                  >
                    <div className="single_service text-center">
                      <img
                        src={item?.category_image}
                        alt="category"
                        style={{
                          borderRadius: "100%",
                          width: "100%",
                          height: "auto",
                        }}
                      />
                      <p className="mb-0 mt-2 px-2 font12x500 textColor3 service_name">
                        {item?.name}
                      </p>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Products;
