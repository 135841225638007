import { useEffect, useState } from "react";
import { postAddress } from "../../../../lib/api functions/address/address";
import { commonType } from "../../../../pages/main/type";
import { AngleDownIcon, DeleteIcon, EditIcon, HomeIcon } from "../../Icons";
import RadioButton from "../../RadioButton";
import DeleteAddress from "../DeleteAddressModal";
import { showToast } from "../../../../lib/toast/showToast";
import CityNotMatched from "../../PopupModal/CityNotMatched";
import BottomTopAnimation from "../../BottomTopAnimation";

type propType = {
  handleClickOnEditIcon: (val: string) => void;
  getUserAddresses: () => void;
  addresses: any;
  isSelectAddress: boolean;
};

const SavedAddress: React.FC<propType> = (props) => {
  const {
    handleClickOnEditIcon,
    getUserAddresses,
    addresses,
    isSelectAddress,
  } = props;
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [isSeeMore, setIsSeeMore] = useState(false);

  const [address, setAddress] = useState<any>(
    JSON.parse(`${localStorage.getItem("userAddress")}`)
  );

  const city = JSON.parse(`${localStorage.getItem("city")}`);

  const fn = {
    openModal: (check?: string) => {
      setCommonData((prev) => {
        return {
          ...prev,
          modal: true,
          check: check,
        };
      });
    },
    onChangeOfRadio: async (item: any) => {
      const body = {
        address: item?.address,
        address1: item?.address1,
        name: item?.name,
        phone: item?.phone,
        title: item?.title,
        area: item?.area.id,
        latitude: item?.latitude,
        longitude: item?.longitude,
        landmark: item?.landmark,
        isDefault: true,
        type: "update",
        id: item?.id,
      };

      if (isSelectAddress) {
        if (city == item.area.city) {
          localStorage.setItem("userAddress", JSON.stringify(item));
          setAddress(item);
        } else {
          fn.openModal("city_not_matched");
        }
      } else {
        const res = await postAddress(body);
        if (res) {
          if (res?.data?.success) {
            getUserAddresses();
            showToast("Default Address added successfully", "success");
            setAddress(JSON.parse(`${localStorage.getItem("userAddress")}`));
          } else {
            showToast(res?.data?.msg, "error");
          }
        }
      }
    },
    renderAddress: (item: any) => {
      return (
        <div className="single_address align-items-start justify-content-between gap-2">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <div>
                {isSelectAddress ? (
                  <div>
                    <RadioButton
                      name="default"
                      value={item}
                      state={
                        isSelectAddress
                          ? item?.id == address?.id
                            ? true
                            : false
                          : false
                      }
                      setState={() => fn.onChangeOfRadio(item)}
                      id={item?.id}
                    />
                  </div>
                ) : (
                  <HomeIcon />
                )}
              </div>
              <div>
                <div className="mb-1 d-flex align-items-center gap-1">
                  <p className="mb-0 font14x500 textColor3">{item?.title}</p>
                  {item?.isDefault && (
                    <p className="mb-0 font14x500 textColor1">(Default)</p>
                  )}
                </div>
                <p className="font14x400 textColor7 mb-1">{item?.address}</p>
                {item?.landmark ? (
                  <p className="mb-1 font14x400 textColor7">
                    Landmark :- {item?.landmark}
                  </p>
                ) : (
                  ""
                )}

                <p className="mb-0 font14x400 textColor7">
                  {item?.area?.name}, {item?.area?.city}
                </p>
              </div>
            </div>
            <div className="d-flex gap-3">
              <i className="address_action_options">
                <EditIcon
                  width="18"
                  height="18"
                  fill="#AF031A"
                  clickHandler={() => handleClickOnEditIcon(item)}
                />
              </i>
              <i className="address_action_options">
                <DeleteIcon
                  width="18"
                  height="18"
                  fill="#AF031A"
                  clickHandler={() => fn.handleClickOnDeleteIcon(item?.id)}
                />
              </i>
            </div>
          </div>
        </div>
      );
    },
    handleClickOnDeleteIcon: (id: any) => {
      setCommonData((prev) => {
        return {
          ...prev,
          data: id,
        };
      });
      fn.openModal("delete_address");
    },
  };

  useEffect(() => {
    if (addresses) {
      const defaultAddress = addresses?.filter((item: any) => {
        return item.isDefault == true && item?.active;
      });
      if (defaultAddress.length > 0 && !address) {
        setAddress(defaultAddress[0]);
        localStorage.setItem("userAddress", JSON.stringify(defaultAddress[0]));
      }
    }
  }, [addresses]);

  return (
    <>
      <div className="saved_address_wrapper">
        <h2 className="mb-0 font16x700 textColor3">Saved addresses</h2>
        {addresses?.length > 0 ? (
          <>
            {addresses?.length > 5 ? (
              <>
                {isSeeMore ? (
                  <>
                    <div className="saved_address_section d-flex flex-column gap-3 my-4">
                      {addresses?.map((item: any) => {
                        return fn.renderAddress(item);
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="saved_address_section d-flex flex-column gap-3 my-4">
                      {addresses?.slice(0, 5).map((item: any) => {
                        return fn.renderAddress(item);
                      })}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="saved_address_section d-flex flex-column gap-3 my-4">
                  {addresses?.map((item: any) => {
                    return fn.renderAddress(item);
                  })}
                </div>
              </>
            )}

            {/* see more button */}
            {addresses?.length > 5 && (
              <div className="">
                <button
                  onClick={() => {
                    setIsSeeMore(!isSeeMore);
                  }}
                  className="btn_outline textColor1 font14x400 proceed_button"
                >
                  {isSeeMore ? "See less" : "See more"}{" "}
                  {isSeeMore ? (
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      src="/static/svg/down_arrow.svg"
                    />
                  ) : (
                    <AngleDownIcon width="12" height="12" fill="#AF031A" />
                  )}
                </button>
              </div>
            )}
          </>
        ) : (
          <p className="mb-0 mt-3 font14x500 textColor3">No addresses found</p>
        )}
      </div>

      {/* delete address */}
      {commonData?.modal && commonData?.check === "delete_address" && (
        <BottomTopAnimation>
          <DeleteAddress state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}

      {/* City Not Matched modal */}
      {commonData?.modal && commonData?.check === "city_not_matched" && (
        <BottomTopAnimation>
          <CityNotMatched state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default SavedAddress;
