import Api from "../../../config/api";
import { showToast } from "../../toast/showToast";
import {
  CHECK_SERVICE_IN_LOCATION,
  GET_ADDRESS,
  POST_ADDRESS,
} from "../../../utils/constant/constant";
import axios from "axios";
import {
  getFarthestLocationIndex,
  getNearestLocationIndex,
} from "../../../utils/function/function";

export const getAddress = async (params: any) => {
  try {
    const res = await Api(false).get(GET_ADDRESS, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

// post address
export const postAddress = async (body: any) => {
  try {
    const res = await Api(false).post(POST_ADDRESS, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};

// checking service in location
export const checkServiceInLocation = async (body: any) => {
  try {
    const res = await Api(false).post(CHECK_SERVICE_IN_LOCATION, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};

// getting Address From LatLng
export const getAddressFromLatLng = async (latlng: any) => {
  const locationAddressRes =
    JSON.parse(`${localStorage.getItem("locationAddressRes")}`) || null;

  if (locationAddressRes) {
    if (
      locationAddressRes.lat === latlng.lat &&
      locationAddressRes.long === latlng.long
    ) {
      return locationAddressRes.res;
    }
  }

  const latLongCache =
    JSON.parse(`${localStorage.getItem("latLongCache")}`) || [];

  if (latLongCache.length != 0) {
    const nearestLocationIndex: any = getNearestLocationIndex(
      latLongCache,
      latlng.lat,
      latlng.long
    );

    if (nearestLocationIndex != null) {
      return latLongCache[nearestLocationIndex]?.res;
    }
  }

  // const latLongCache = []

  try {
    let res;

    res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${
        latlng.lat
      },${latlng.long}&key=${localStorage.getItem("map_key")!}`,
      {}
    );

    latLongCache.push({ res, lat: latlng.lat, long: latlng.long });
    localStorage.setItem("latLongCache", JSON.stringify(latLongCache));

    if (latLongCache.length > 50) {
      const farthestLocationIndex = getFarthestLocationIndex(
        latLongCache,
        latlng.lat,
        latlng.long
      );

      latLongCache.splice(farthestLocationIndex, 1);

      // latLongCache.push({ res, lat: latlng.lat, long: latlng.long })
      localStorage.setItem("latLongCache", JSON.stringify(latLongCache));
    }

    localStorage.setItem(
      "locationAddressRes",
      JSON.stringify({ res, lat: latlng.lat, long: latlng.long })
    );

    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};
