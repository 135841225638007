import { useContext, useEffect, useState } from "react";
import { showToast } from "../../../../../../../lib/toast/showToast";
import { Auth } from "../../../../../../../utils/context/AuthContext";
import {
  calculateUpgradeCost,
  checkItemType,
  getImageURL,
  getServiceById,
  randomSortData,
  setOverflowToBodyHidden,
} from "../../../../../../../utils/function/function";
import { ClockIcon } from "../../../../../../shared/Icons";
import { commonType } from "../../../../../../../pages/main/type";
import ViewDetails from "../../../../../../shared/ViewServiceDetails";
import BottomTopAnimation from "../../../../../../shared/BottomTopAnimation";
import { useLocation } from "react-router-dom";
import { rescheduleOrder } from "../../../../../../../lib/api functions/order/order";
import SpinLoader from "../../../../../../shared/Loader/SpinLoader";
import useLoading from "../../../../../../../hooks/useLoading";

const RecommendedUpgrade = ({ orderDetails }: any) => {
  const { cartItemsInContext, setCartItemsInContext } = useContext(Auth);
  const [loading, setShowLoader] = useState<any>();
  const [recommendedServices, setRecommendedServices] = useState<any>([]);
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const appliedCoupon =
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;

  const { state } = useLocation();

  const fn = {
    getRecommendedServices: () => {
      let tempService: any = [];

      orderDetails?.appointmentitem_set?.map((item: any) => {
        return (
          !item?.update &&
          item?.service?.serviceType?.update_services &&
          item?.service?.serviceType?.update_services !== "0" &&
          JSON.parse(item?.service?.serviceType?.update_services)?.map(
            (item2: any) => {
              // check upgrade service is active or not
              let service = getServiceById(item2?.id);

              return (
                Object.keys(service)?.length > 0 &&
                calculateUpgradeCost(item2?.id, item2?.id_p) >= 0 &&
                item2?.header?.split(" ")[0] === "Upgrade" &&
                tempService.push({
                  previousServiceId: item?.serviceType?.id,
                  serviceType: item2,
                  serviceImage: service?.serviceType?.image_1,
                })
              );
            }
          )
        );
      });

      // random sorting
      const randomSortTempServices = randomSortData(tempService);
      setRecommendedServices(randomSortTempServices);
    },
    upgradeCart: async (service: any) => {
      setShowLoader(service?.serviceType?.id);
      const finajObj = {
        create_type: "modify",
        id: orderDetails?.id,
        service: service?.serviceType,
      };

      try {
        const res: any = await rescheduleOrder(finajObj);
        if (res) {
          showToast(`${res?.data?.msg}`, "success");
          // navigate("/order");
          setShowLoader("");
        }
      } catch (err) {
        setShowLoader("");
      }

      // let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      // let serviceData: any = getServiceById(service?.serviceType?.id);

      // if (service?.serviceType?.description?.length === 0) {
      //   //for service
      //   // upgrade recommended item into cart
      //   // disable same id service into cart
      //   let upgradedItems = cartItems?.map((item: any) => {
      //     if (item?.serviceType?.id === service?.previousServiceId) {
      //       item.added = false;
      //       return item;
      //     } else {
      //       return item;
      //     }
      //   });

      //   // check upgrade item type
      //   let itemType = checkItemType(serviceData, service);
      //   if (itemType === "") {
      //     showToast("Unknown upgrade", "error");
      //   } else {
      //     serviceData[itemType] = true;
      //   }

      //   // upgraded service
      //   serviceData.count = 1;
      //   serviceData.added = true;
      //   serviceData.update = true;
      //   serviceData.previousServiceId = service?.previousServiceId;
      //   upgradedItems.push(serviceData);

      //   // removing free service from cart
      //   upgradedItems = upgradedItems.filter((serviceItem: any) => {
      //     if (
      //       serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
      //       appliedCoupon?.type == "Service"
      //     ) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });

      //   setCartItemsInContext(upgradedItems);
      //   localStorage.setItem("cartItems", JSON.stringify(upgradedItems));

      //   // removing coupon on upgrades
      //   localStorage.removeItem("coupon_applied");
      //   localStorage.removeItem("conveyanceCharge");
      //   localStorage.removeItem("slotInfo");
      //   localStorage.removeItem("previousDues");
      // } else {
      //   //for package

      //   // getting item from cart using selected service id
      //   let serviceFromCart = cartItems?.find((item: any) => {
      //     return item?.serviceType?.id === service?.previousServiceId;
      //   });

      //   let isServiceExist = false;
      //   let changedData = serviceFromCart?.serviceType?.chosenService?.map(
      //     (item: any) => {
      //       if (item.label?.trim() == service?.serviceType?.description) {
      //         isServiceExist = true;
      //         return {
      //           ...item,
      //           newLabel: service?.serviceType?.name,
      //           header: service?.serviceType?.header,
      //         };
      //       } else {
      //         return item;
      //       }
      //     }
      //   );

      //   if (!isServiceExist) {
      //     showToast(
      //       "This upgradation is not possible. Please add service related to it first",
      //       "error"
      //     );
      //   } else {
      //     serviceFromCart.serviceType.chosenService = changedData;

      //     let upgradedItems = cartItems?.map((item: any) => {
      //       if (item?.serviceType?.id === serviceFromCart?.serviceType?.id) {
      //         return item;
      //       } else {
      //         return item;
      //       }
      //     });

      //     // for special instructions
      //     upgradedItems = cartItems?.map((item: any) => {
      //       if (item?.serviceType?.id === service?.previousServiceId) {
      //         item["special_instructions"].push({
      //           id: service?.serviceType?.id,
      //           previousServiceId: service?.serviceType?.id_p,
      //           header: service?.serviceType?.header,
      //           name: service?.serviceType?.name,
      //         });
      //       }
      //       return item;
      //     });

      //     // added selected service into cart, it will not show in cart
      //     service.added = false;
      //     service.update = true;
      //     upgradedItems.push(service);

      //     // removing free service from cart
      //     upgradedItems = upgradedItems.filter((serviceItem: any) => {
      //       if (
      //         serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
      //         appliedCoupon?.type == "Service"
      //       ) {
      //         return false;
      //       } else {
      //         return true;
      //       }
      //     });

      //     console.log("upgradedItems", upgradedItems);

      //     setCartItemsInContext(upgradedItems);
      //     localStorage.setItem("cartItems", JSON.stringify(upgradedItems));

      //     // removing coupon on upgrades
      //     localStorage.removeItem("coupon_applied");
      //     localStorage.removeItem("conveyanceCharge");
      //     localStorage.removeItem("slotInfo");
      //     localStorage.removeItem("previousDues");
      //   }
      // }
    },
    undoCart: (service: any) => {
      const cartItems =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

      if (service?.serviceType?.description?.length === 0) {
        //for service
        // enable and remove same id service into cart
        let upgradedItems = cartItems
          ?.map((item: any) => {
            if (item?.serviceType?.id === service?.previousServiceId) {
              item.added = true;
              return item;
            } else {
              return item;
            }
          })
          .filter(
            (item: any) => item?.serviceType?.id !== service?.serviceType?.id
          );

        // removing free service from cart
        upgradedItems = upgradedItems.filter((serviceItem: any) => {
          if (
            serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
            appliedCoupon?.type == "Service"
          ) {
            return false;
          } else {
            return true;
          }
        });

        setCartItemsInContext(upgradedItems);
        localStorage.setItem("cartItems", JSON.stringify(upgradedItems));

        // removing coupon on upgrades
        localStorage.removeItem("coupon_applied");
        localStorage.removeItem("conveyanceCharge");
        localStorage.removeItem("slotInfo");
        localStorage.removeItem("previousDues");
      } else {
        //for package
        // getting item from cart using selected service id
        let serviceFromCart = cartItems?.find((item: any) => {
          return item?.serviceType?.id === service?.previousServiceId;
        });

        let changedData = serviceFromCart?.serviceType?.chosenService?.map(
          (item: any) => {
            return item.newLabel?.trim() == service?.serviceType?.name
              ? {
                  ...item,
                  newLabel: undefined,
                  header: null,
                }
              : item;
          }
        );
        serviceFromCart.serviceType.chosenService = changedData;

        let upgradedItems = cartItems
          ?.map((item: any) => {
            if (item?.serviceType?.id === serviceFromCart?.serviceType?.id) {
              return item;
            } else {
              return item;
            }
          })
          .filter(
            (item: any) => item?.serviceType?.id !== service?.serviceType?.id
          );

        // remove special instructions
        const filteredIns = serviceFromCart?.special_instructions?.filter(
          (insObj: any) => {
            return service?.serviceType?.id !== insObj.id;
          }
        );
        serviceFromCart.special_instructions = filteredIns;

        // removing free service from cart
        upgradedItems = upgradedItems.filter((serviceItem: any) => {
          if (
            serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
            appliedCoupon?.type == "Service"
          ) {
            return false;
          } else {
            return true;
          }
        });

        setCartItemsInContext(upgradedItems);
        localStorage.setItem("cartItems", JSON.stringify(upgradedItems));

        // removing coupon on upgrades
        localStorage.removeItem("coupon_applied");
        localStorage.removeItem("conveyanceCharge");
        localStorage.removeItem("slotInfo");
        localStorage.removeItem("previousDues");
      }
    },
    handleClickOnImage: (serviceId: any) => {
      const serviceData = getServiceById(serviceId);
      setCommonData({
        data: serviceData,
        modal: true,
        check: "view_details",
      });
      setOverflowToBodyHidden();
    },
  };

  useEffect(() => {
    fn.getRecommendedServices();
  }, []);

  useEffect(() => {
    if (state?.divId) {
      let rating = document.getElementById(state?.divId);
      rating?.scrollIntoView({ block: "center" });
    }
  }, [recommendedServices]);

  return (
    <>
      {recommendedServices?.length > 0 && (
        <div
          id="upgrades"
          className="recommendation_wrapper recommendation_wrapper-order-details"
        >
          <h2 className="mb-0 font16x700">Upgrades for you</h2>
          <div className="recommendation_wrapper_scroll">
            <div className="recommendation_section mt-3">
              {recommendedServices?.map((item: any, i: number) => {
                return (
                  <>
                    <div className="cart-upgrade-service-container">
                      {item?.serviceImage ? (
                        <img
                          className="cart-upgrade-service-container-img"
                          width={150}
                          height={150}
                          src={getImageURL(item?.serviceImage)}
                          alt="recommend_service"
                          onClick={() => {
                            fn.handleClickOnImage(item.serviceType.id);
                          }}
                        />
                      ) : (
                        <img
                          className="cart-upgrade-service-container-img"
                          width={150}
                          height={150}
                          src="/static/img/no-image.png"
                          alt="recommend_service"
                          onClick={() => {
                            fn.handleClickOnImage(item.serviceType.id);
                          }}
                        />
                      )}
                      <div className="best-selling-package-info-container cart-upgrade-service-infor-container">
                        <div>
                          <p className="mb-1 textColor3 font12x600">
                            {item?.serviceType?.name}
                          </p>

                          <p className="font12x500 textColor7 d-flex align-items-center gap-1 mb-0 mt-2">
                            <ClockIcon />
                            {`${
                              getServiceById(item?.serviceType?.id)?.serviceType
                                ?.time
                            } mins`}
                          </p>
                          <div className="best-selling-package-price-container mt-2">
                            <div>
                              <div className="d-flex gap-2 align-items-center">
                                {item?.serviceType?.text ? (
                                  <p className="mb-0 font12x500 textColor3">{`₹${calculateUpgradeCost(
                                    item?.serviceType?.id,
                                    item?.serviceType?.id_p
                                  )}`}</p>
                                ) : item?.serviceType?.cost ? (
                                  <p className="mb-0 font12x500 textColor3">{`₹${item?.serviceType?.cost}`}</p>
                                ) : (
                                  <p className="mb-0 font12x500 textColor3">{`₹${calculateUpgradeCost(
                                    item?.serviceType?.id,
                                    item?.serviceType?.id_p
                                  )}`}</p>
                                )}
                                {item?.serviceType?.mrp && (
                                  <p className="mb-0 font12x400 text-decoration-line-through textColor7">
                                    {`₹${item?.serviceType?.mrp}`}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          {/* *
                          {cartItemsInContext?.find((cartItem: any) => {
                            return (
                              cartItem?.serviceType?.id ===
                                item?.serviceType?.id && cartItem?.update
                            );
                          }) ? (
                            <button
                              className="w-100 font14x600 add_button textColor1 d-flex align-items-center justify-content-center"
                              onClick={() => fn.undoCart(item)}
                            >
                              Undo
                            </button>
                          ) : ( */}
                          <button
                            className="w-100 font14x600 add_button textColor1 d-flex align-items-center justify-content-center"
                            onClick={() => fn.upgradeCart(item)}
                            disabled={loading === item?.serviceType?.id}
                          >
                            {loading === item?.serviceType?.id ? (
                              <SpinLoader
                                height="16"
                                width="16"
                                color="var(--bg-color1)"
                              />
                            ) : (
                              "Upgrade"
                            )}
                          </button>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* view details */}
      {commonData?.modal && commonData?.check === "view_details" && (
        <BottomTopAnimation>
          <ViewDetails state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default RecommendedUpgrade;
