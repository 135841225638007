import moment from "moment";

type PropType = {
  singleOrder: any;
};

const OrderPricePaymentDetails: React.FC<PropType> = (props) => {
  const { singleOrder } = props;

  const paymentMethodApis = {
    payu: process.env.REACT_APP_PAYU_LINK,
  };

  return (
    <>
      <div>
        <div className="order_date_paymentstatus d-flex justify-content-between align-items-center">
          <div>
            <p className="mb-1 font12x400 textColor7">Order Placed</p>
            <p className="mb-0 font13x400 textColor3">
              {moment(singleOrder?.requestTimestamp).format(
                "ddd, MMM D, h.mm A"
              )}
            </p>
          </div>
          {singleOrder?.customer_discount > 10 &&
            [0, 6, 7, 10, 1, 4, 13].includes(singleOrder?.status) && (
              <div>
                <button
                  className="pay_online textColor1 font14x400"
                  onClick={() => {
                    window.open(
                      `${paymentMethodApis.payu}?id=${singleOrder?.id}`,
                      "_self"
                    );
                  }}
                >
                  Pay Online
                </button>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default OrderPricePaymentDetails;
