import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isUserValid } from "../../utils/function/function";

const PrivateRoutes = () => {
  const location = useLocation();

  return isUserValid() ? (
    <Outlet />
  ) : (
    <Navigate
      to={"/otp-login"}
      state={{
        previousURL: location?.pathname,
      }}
      replace
    />
  );
};

export default PrivateRoutes;
