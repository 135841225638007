import { useContext } from "react";
import { membershipId } from "../../../../utils/constant/constant";
import { Auth } from "../../../../utils/context/AuthContext";

const Membership = () => {
  const { cartItemsInContext, setCartItemsInContext } = useContext(Auth);
  const appliedCoupon =
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;

  const fn = {
    handleClickOnCross: () => {
      // remove membership from cart
      let serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      serviceList = serviceList.filter((serviceItem: any) => {
        if (
          serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
          appliedCoupon?.type == "Service"
        ) {
          return false;
        } else {
          return true;
        }
      });
      // removing coupon
      localStorage.removeItem("coupon_applied");
      serviceList = serviceList.filter((serviceItem: any) => {
        return serviceItem?.serviceType?.id !== membershipId;
      });
      localStorage.setItem("cartItems", JSON.stringify(serviceList));
      setCartItemsInContext(serviceList);
    },
  };

  return (
    <>
      {cartItemsInContext?.map((item: any, i: number) => {
        return (
          item?.serviceType?.id === membershipId && (
            <div key={i} className="cart_items_membership_wrapper me-2 ms-2 mt-2 pt-3">
              <div className="single_cart_item d-flex justify-content-between align-items-start gap-4">
                <div>
                  <p className="mb-0 font14x700 textColor3">
                    {item?.serviceType?.name?.split(":")[0]}
                  </p>
                  <div className="d-flex mt-2 gap-2 align-items-center">
                    {item?.serviceType?.cost > item?.cost && (
                      <p className="mb-0 font14x400 text-decoration-line-through textColor7">
                        {`₹${item?.serviceType?.cost * item?.count}`}
                      </p>
                    )}
                    <p className="mb-0 font16x700 textColor3">
                      {`₹${item?.cost * item?.count}`}
                    </p>
                  </div>
                </div>
                <img
                  onClick={() => {
                    fn.handleClickOnCross();
                  }}
                  className="cursor_pointer"
                  src="/static/svg/remove-cart-item.svg"
                  alt="remove_cart_item"
                />
              </div>
            </div>
          )
        );
      })}
    </>
  );
};

export default Membership;
