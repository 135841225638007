import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../../../../utils/context/AuthContext";
import { AngleDownIcon, HomeIcon } from "../../../../shared/Icons";

type propType = {
  setPositionToBody: () => void;
  addresses: any;
};

const SavedAddresses: React.FC<propType> = (props) => {
  const { addresses, setPositionToBody } = props;
  const { getLocationId, setCartItemsInContext } = useContext(Auth);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const navigate = useNavigate();

  const fn = {
    changeLocation: async (item: any) => {
      window.localStorage.setItem("latitude", JSON.stringify(item?.latitude));
      window.localStorage.setItem("longitude", JSON.stringify(item?.longitude));

      // close modal
      setPositionToBody();

      // calling location slot API for getting new location id
      getLocationId(item?.latitude, item?.longitude);

      // when location is changed flush cart from local storage
      window.localStorage.removeItem("cartItems");
      setCartItemsInContext([]);

      // navigate to homepage whenever location is changed
      navigate("/");
    },
    renderAddress: (item: any, index: number) => {
      return (
        <div
          key={index}
          className="single_address d-flex align-items-start justify-content-between gap-2"
          onClick={() => fn.changeLocation(item)}
        >
          <div className="d-flex gap-2">
            <div>
              <HomeIcon />
            </div>
            <div>
              <div className="mb-1 d-flex align-items-center gap-1">
                <p className="mb-0 font14x500 textColor3">{item?.title}</p>
                {item?.isDefault && (
                  <p className="mb-0 font14x500 textColor1">(Default)</p>
                )}
              </div>
              <p className="font14x400 textColor7 mb-1">{item?.address}</p>
              <p className="mb-1 font14x400 textColor7">
                Landmark :- {item?.landmark}
              </p>
              <p className="mb-0 font14x400 textColor7">
                {item?.area?.name}, {item?.area?.city}
              </p>
            </div>
          </div>
        </div>
      );
    },
  };

  return (
    <>
      <div className="saved_address_wrapper pt-0 mb-0">
        <h2 className="mb-0 font16x600 textColor3">Saved addresses</h2>
        {addresses.length > 5 ? (
          <>
            {isSeeMore ? (
              <>
                <div className="saved_address_section d-flex flex-column gap-3 my-4">
                  {addresses.map((item: any, i: number) => {
                    return fn.renderAddress(item, i);
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="saved_address_section d-flex flex-column gap-3 my-4">
                  {addresses.slice(0, 5).map((item: any, i: number) => {
                    return fn.renderAddress(item, i);
                  })}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="saved_address_section d-flex flex-column gap-3 my-4">
              {addresses.map((item: any, i: number) => {
                return fn.renderAddress(item, i);
              })}
            </div>
          </>
        )}

        {/* see more button */}
        {addresses.length > 5 && (
          <div className="">
            <button
              onClick={() => {
                setIsSeeMore(!isSeeMore);
              }}
              className="btn_outline textColor1 font14x400"
            >
              {isSeeMore ? "See less" : "See more"}{" "}
              {isSeeMore ? (
                <img
                  style={{ transform: "rotate(180deg)" }}
                  src="/static/svg/down_arrow.svg"
                />
              ) : (
                <AngleDownIcon width="12" height="12" fill="#AF031A" />
              )}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SavedAddresses;
