import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Layout from "../components/shared/Layout";
import Error404 from "../pages/common/404Error";
import OtpLogin from "../pages/common/otpLogin";
import ProfileSetUp from "../pages/common/profileSetUp";
import VerifyOtp from "../pages/common/verifyOtp";
import Categories from "../pages/main/category";
import Home from "../pages/main/home";
import MyProfile from "../pages/main/myProfile";
import AddressBook from "../pages/main/myProfile/subPages/addressBook";
import BookingAndOrders from "../pages/main/myProfile/subPages/bookingAndOrders";
import FAQ from "../pages/main/myProfile/subPages/faq";
import Franchise from "../pages/main/myProfile/subPages/franchise";
import GetlookGold from "../pages/main/myProfile/subPages/getlookGold";
import ReferAndEarn from "../pages/main/myProfile/subPages/referAndEarn";
import ViewProfile from "../pages/main/myProfile/subPages/viewProfile";
import Wallet from "../pages/main/myProfile/subPages/wallet";
import OfferAndDeal from "../pages/main/offers";
import PaymentSuccess from "../pages/main/payment/paymentsuccess";
import PaymentFailed from "../pages/main/payment/paymentfailed";
import ConfirmBooking from "../pages/main/confirmbooking";
import Cart from "../pages/main/cart";
import Checkout from "../pages/main/payment/checkout";
import OrderDetails from "../pages/main/myProfile/subPages/bookingAndOrders/subPages/orderDetails";
import CancelOrder from "../pages/main/myProfile/subPages/bookingAndOrders/subPages/cancelOrder";
import Membership from "../pages/main/membership";
import Slot from "../pages/main/slot";
import PrivateRoutes from "./privateRoutes/PrivateRoutes";
import Services from "../pages/main/services";
import RescheduleSlot from "../pages/main/rescheduleSlot";
import Products from "../pages/main/products";
import Search from "../components/shared/Search";
import OrderSuccess from "../pages/main/payment/ordersuccess";
import PrivacyPolicy from "../pages/main/myProfile/subPages/privacyPolicy";
import AboutUs from "../pages/main/myProfile/subPages/aboutUs";
import RouterAuth from "../components/shared/RouterAuth";
import IsLogin from "./IsLogin";
import SelectAddress from "../pages/main/select address";
import LandingPage from "../pages/common/landingPage";
import ContactUs from "../pages/main/myProfile/subPages/contactUs";
import DesktopHome from "../pages/desktop/home";
import IsDesktop from "./isDesktop";

const AllRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route element={<RouterAuth />}>
        <Route path="*" element={<Error404 />} />

        <Route element={<IsLogin />}>
          <Route
            path="/otp-login"
            element={<OtpLogin />}
            errorElement={<Error404 />}
          />
          <Route
            path="/verify-otp"
            element={<VerifyOtp />}
            errorElement={<Error404 />}
          />
          <Route
            path="/profile-setup"
            element={<ProfileSetUp />}
            errorElement={<Error404 />}
          />
        </Route>

        <Route
          path="/salon-at-home"
          element={<LandingPage />}
          errorElement={<Error404 />}
        />

        {/* my profile routes */}
        <Route
          path="/profile"
          element={<MyProfile />}
          errorElement={<Error404 />}
        />

        <Route
          path="/contact-us"
          element={<ContactUs />}
          errorElement={<Error404 />}
        />

        <Route path="/faqs" element={<FAQ />} errorElement={<Error404 />} />
        <Route
          path="/franchise-getlook"
          element={<Franchise />}
          errorElement={<Error404 />}
        />
        <Route
          path="/getlook-gold"
          element={<GetlookGold />}
          errorElement={<Error404 />}
        />
        {/* privacy policy routes */}
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
          errorElement={<Error404 />}
        />
        {/* about us */}
        <Route
          path="/about-us"
          element={<AboutUs />}
          errorElement={<Error404 />}
        />
        {/* search route */}
        <Route
          path="/search"
          element={<Search />}
          errorElement={<Error404 />}
        />

        {/* home route */}
        <Route element={<IsDesktop />}>
          <Route
            path="/"
            element={
              <Layout showBottomBar backgroundColor="var(--bg-color2)">
                <Home />
              </Layout>
            }
            errorElement={<Error404 />}
          />
        </Route>

        {/* desktop home route */}
        <Route
          path="/home"
          element={<DesktopHome />}
          errorElement={<Error404 />}
        />

        {/* offer and deal route */}
        <Route
          path="/category-new"
          element={
            <Layout showBottomBar backgroundColor="#fff">
              <Categories />
            </Layout>
          }
          errorElement={<Error404 />}
        />
        {/* service routes */}
        <Route
          path="/services/:homepageCategory"
          element={
            <Layout showBottomBar backgroundColor="#fff">
              <Services />
            </Layout>
          }
          errorElement={<Error404 />}
        />
        <Route
          path="/services/:homepageCategory/:subCategory"
          element={
            <Layout showBottomBar backgroundColor="#fff">
              <Services />
            </Layout>
          }
          errorElement={<Error404 />}
        />
        <Route
          path="/services/:homepageCategory/:subCategory/:serviceId"
          element={
            <Layout showBottomBar backgroundColor="#fff">
              <Services />
            </Layout>
          }
          errorElement={<Error404 />}
        />
        {/* products routes */}
        <Route
          path="/products/:homepageCategory"
          element={
            <Layout showBottomBar backgroundColor="#fff">
              <Products />
            </Layout>
          }
          errorElement={<Error404 />}
        />
        <Route
          path="/products/:homepageCategory/:subCategory"
          element={
            <Layout showBottomBar backgroundColor="#fff">
              <Products />
            </Layout>
          }
          errorElement={<Error404 />}
        />
        <Route
          path="/products/:homepageCategory/:subCategory/:productId"
          element={
            <Layout showBottomBar backgroundColor="#fff">
              <Products />
            </Layout>
          }
          errorElement={<Error404 />}
        />

        <Route element={<PrivateRoutes />}>
          <Route
            path="/view-profile"
            element={<ViewProfile />}
            errorElement={<Error404 />}
          />
          <Route
            path="/mywallet"
            element={<Wallet />}
            errorElement={<Error404 />}
          />

          <Route
            path="/refer-and-earn"
            element={<ReferAndEarn />}
            errorElement={<Error404 />}
          />

          {/* offer and deal route */}
          <Route
            path="/offers-deals"
            element={
              <Layout showBottomBar backgroundColor="var(--bg-color2)">
                <OfferAndDeal />
              </Layout>
            }
            errorElement={<Error404 />}
          />

          {/* order routes */}
          <Route
            path="/order"
            element={<BookingAndOrders />}
            errorElement={<Error404 />}
          />
          <Route
            path="/myorder"
            element={<OrderDetails />}
            errorElement={<Error404 />}
          />
          <Route
            path="/cancel"
            element={<CancelOrder />}
            errorElement={<Error404 />}
          />

          {/* address book routes */}
          <Route
            path="/address-book"
            element={<AddressBook />}
            errorElement={<Error404 />}
          />

          {/* select address */}
          <Route
            path="/select-address"
            element={<SelectAddress />}
            errorElement={<Error404 />}
          />

          {/* membership */}
          <Route
            path="/membership"
            element={<Membership />}
            errorElement={<Error404 />}
          />

          {/* cart routes */}
          <Route path="/cart" element={<Cart />} errorElement={<Error404 />} />

          {/* slot routes */}
          <Route path="/slot" element={<Slot />} errorElement={<Error404 />} />

          {/* reschedule slot routes */}
          <Route
            path="/reschedule-slot"
            element={<RescheduleSlot />}
            errorElement={<Error404 />}
          />

          {/* checkout routes */}
          <Route
            path="/checkout"
            element={<Checkout />}
            errorElement={<Error404 />}
          />

          {/* payment */}
          <Route
            path="/payment-success"
            element={<PaymentSuccess />}
            errorElement={<Error404 />}
          />
          <Route
            path="/order-success"
            element={<OrderSuccess />}
            errorElement={<Error404 />}
          />
          <Route
            path="/payment-failed"
            element={<PaymentFailed />}
            errorElement={<Error404 />}
          />
          <Route
            path="/confirm-booking"
            element={<ConfirmBooking />}
            errorElement={<Error404 />}
          />
        </Route>
      </Route>,
    ])
  );
  return <RouterProvider router={router} />;
};

export default AllRoutes;
