// order status
export const ORDER_STATUS: any = {
  "-1": "Cancelled",
  0: "Pending",
  1: "Confirmed",
  2: "Ended",
  3: "Accepted",
  4: "Start",
  5: "Ended",
  6: "Pending",
  7: "Pending",
  10: "Pending",
  11: "Cancellation Requested",
  12: "Not able to Connect",
  13: "On the way",
};

// order status colors
export const ORDER_DETAILS_STATUS: any = {
  "-1": {
    status: "CANCELLED",
    backgroundColor: "#434343",
    color: "white",
  },
  0: {
    status: "BOOKED",
    backgroundColor: "#F7B948",
    color: "white",
  },
  1: {
    status: "CONFIRMED",
    backgroundColor: "#13BF6D",
    color: "white",
  },
  2: {
    status: "SERVICE COMPLETED",
    backgroundColor: "black",
    color: "white",
  },
  3: {
    status: "ACCEPETED",
    backgroundColor: "black",
    color: "white",
  },
  4: {
    status: "START",
    backgroundColor: "#90cbaa",
    color: "white",
  },
  5: {
    status: "SERVICE COMPLETED",
    backgroundColor: "#c5f5c5",
    color: "green",
  },
  6: {
    status: "BOOKED",
    backgroundColor: "#F7B948",
    color: "white",
  },
  7: {
    status: "BOOKED",
    backgroundColor: "#F7B948",
    color: "white",
  },
  10: {
    status: "BOOKED",
    backgroundColor: "#F7B948",
    color: "white",
  },
  11: {
    status: "CANCELLATION REQUESTED",
    backgroundColor: "black",
    color: "white",
  },
  12: {
    status: "NOT ABLE TO CONNECT",
    backgroundColor: "black",
    color: "white",
  },
  13: {
    status: "ON THE WAY",
    backgroundColor: "#cbaacb",
    color: "white",
  },
};

// cancel order status
export const CANCEL_ORDER_STATUS = [0, 6, 7];

// cancel not order status
export const CANCEL_NOT_ORDER_STATUS = [-1, 4, 5, 13, 11];

// all package ids
export const packageIds = [525, 526, 527, 237, 276, 539, 540];

//homepage data caching time in minutes
// 0.5h
export const Caching_Time = 30;

//homepage popup caching time in minutes
// 1h
export const Popup_Banner_Cache_Time = 60;

//tracking caching time in minutes
// 6h
export const Tracking_Cache_Time = 720;

// @99 package sub category ids
export const package99Ids = [46, 106];

// PLACES_API_CHARACTER_HITS
export const PLACES_API_CHARACTER_HITS = 2;

// tracking type
export const HOME_TYPE = -1;
export const SERVICE_TYPE = 0;
export const PRODUCT_TYPE = 1;
export const CART_TYPE = 2;
export const COUPON_APPLIED_TYPE = 11;
export const ADDRESS_TYPE = 3;
export const SLOT_TYPE = 5;
export const PAYMENT_TYPE = 4;
export const BOOKED_TYPE = 6;

export const TEMP_HOME_TYPE = 1;
export const TEMP_SERVICE_TYPE = 2;
export const TEMP_PRODUCT_TYPE = 3;
export const TEMP_CART_TYPE = 4;
export const TEMP_COUPON_APPLIED_TYPE = 5;
export const TEMP_ADDRESS_TYPE = 6;
export const TEMP_SLOT_TYPE = 7;
export const TEMP_PAYMENT_TYPE = 8;
export const TEMP_BOOKED_TYPE = 9;

// device sources
export const MOBILE_WEB = "mobile";
export const WEB = "web";
export const IOS = "ios";
export const ANDROID = "android";
export const IOS_WEB = "ios_web";
export const ANDROID_WEB = "android_web";

// review titles
export const reviewTitles = [
  "Service Quality",
  "Products",
  "Customer Care",
  "Hygiene",
  "Punctual",
  "Beautician",
];

// cancel order time in sec.
// 24h
export const Cancel_Order_Time_24H = 86400;
// 1h
export const Cancel_Order_Time_1H = 3600;

// reschedule order time in sec.
// 3h
export const Reschedule_Order_Time = 10800;
// 24h
export const Reschedule_Order_Time_24H = 86400;
// 1h
export const Reschedule_Order_Time_1H = 3600;

// stylist assigneds time in sec.
// 2h
export const Stylist_Assigned_Time = 7200;

// charges id
export const premiumId = 620;
export const safetyId = 546;
export const surgeId = 547;
export const upgradeId = 548;
export const conveyanceId = 538;
export const membershipDiscountId = 521;
export const membershipId = 519;
export const walletId = 605;
export const couponId = 497;
export const rescheduleChargeId = 643;
export const previousDuesId = 698;
export const handlingFeeId = 284;

//API URL's
export const SEND_OTP = `/login/v1/api`;
export const VERIFY_OTP = `/verifylogin/v1/api`;
export const GET_CATEGORIES = `/getCategory`;
export const GET_APPOINTMENTS = `/appointments/v1/api`;
export const REVIEW = `/review/v1/api`;
export const GET_HOMEPAGE_SECTIONS = `/categoriesmain/v1/api`;
export const GET_HOMEPAGE_CATEGORIES = `/categoriesall/v1/api`;
export const GET_ADDRESS = `/address/v1/api`;
export const POST_ADDRESS = `/address/v1/api`;
export const CHECK_SERVICE_IN_LOCATION = `/locationslot/v1/api`;
export const GET_SLOT = `/slotview/v1/api`;
export const USER_PROFILE = `/customer/v1/api`;
export const APPLY_COUPON = `/coupoun/v1/api`;
export const GET_WALLET = `/wallet/v1/api`;
export const GET_OFFERS = `/api/appv1/offer/`;
export const PLACE_ORDER = `/newappointment/v1/api`;
export const BOOKING_TRACKER = "trackernew/v1/api";
export const FRANCHISE = "signupfranchise/";
export const CHANGESTYLIST = "changestylist/v1/api";

export const CONTACT_US_CITY = [
  {
    city: "Noida",
    phone: "0987654321",
  },
  {
    city: "Aurangabad",
    phone: "0987654321",
  },
  {
    city: "Mumbai",
    phone: "0987654321",
  },
  {
    city: "Chennai",
    phone: "0987654321",
  },
  {
    city: "Jodhpur",
    phone: "0987654321",
  },
  {
    city: "Kolkata",
    phone: "0987654321",
  },
  {
    city: "Silguri",
    phone: "0987654321",
  },
  {
    city: "Pune",
    phone: "0987654321",
  },
  {
    city: "Gorakhpur",
    phone: "0987654321",
  },
  {
    city: "Hyderabad",
    phone: "0987654321",
  },
];
