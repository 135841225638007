import React from "react";

type PropType = {
  id: string;
  label?: string;
  type: string;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  state?: string | number;
  setState?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  width?: string;
  bgColor?: string;
  border?: string;
  labelColor?: string;
  max?: string;
};

const numberInputOnWheelPreventChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current
  // function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

const InputField: React.FC<PropType> = (props) => {
  const {
    id,
    label,
    type,
    placeholder,
    name,
    isDisabled,
    state,
    setState,
    width,
    onBlur,
    isReadOnly,
    bgColor,
    border,
    labelColor,
    max,
  } = props;

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className={`form-label font12x400 ${labelColor} mb-1`}
        >
          {label}
        </label>
      )}

      <div
        className="input_wrapper d-flex align-items-center"
        style={{
          width,
          border,
          backgroundColor: bgColor,
        }}
      >
        <input
          type={type}
          className="form-control shadow-none input_field"
          id={id}
          placeholder={placeholder}
          autoComplete={`new-${type}`}
          name={name ?? ""}
          disabled={isDisabled}
          style={{
            cursor: isDisabled ? "no-drop" : "unset",
            backgroundColor: isDisabled ? "#fff" : bgColor,
            color: isDisabled ? "var(--text-color2)" : "var(--text-color3)",
          }}
          value={state}
          onChange={setState}
          onBlur={onBlur}
          readOnly={isReadOnly}
          onWheel={
            type === "number"
              ? (numberInputOnWheelPreventChange as any)
              : undefined
          }
          max={max}
        />
      </div>
    </>
  );
};

export default InputField;
