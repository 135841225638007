import { useEffect, useState } from "react";
import Header from "../../../../../components/shared/Header";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import { showToast } from "../../../../../lib/toast/showToast";
import { franchiseData } from "../../../../../lib/api functions/franchise/franchise";
import { commonType } from "../../../type";
import useLoading from "../../../../../hooks/useLoading";
import { FranchiseData } from "./type";
import { setOverflowToBodyHidden } from "../../../../../utils/function/function";
import { useFormik } from "formik";
import { franchiseSchema } from "../../../../../validation/validation";
import BottomTopAnimation from "../../../../../components/shared/BottomTopAnimation";
import SetupCostModal from "../../../../../components/pages/MyProfile/SubPages/Franchise/setupCostModal";
import SpinLoader from "../../../../../components/shared/Loader/SpinLoader";
import InputError from "../../../../../components/shared/InputError/InputError";
import InputField from "../../../../../components/shared/InputField";
import InputMobile from "../../../../../components/shared/InputMobile";
import {
  BookingStats,
  FranchiseCities,
  SupportFromGetlook,
} from "../../../../../components/pages/MyProfile/SubPages/Franchise/constant";
import ThankYouModal from "../../../../../components/pages/MyProfile/SubPages/Franchise/ThankYouModal";
import { useNavigate } from "react-router-dom";

const Franchise = () => {
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();

  const initialValues: FranchiseData = {
    name: "",
    mail: "",
    mobile: "",
    comment: "",
    city: "",
  };

  const navigate = useNavigate();

  const fn = {
    openModal: (check?: string) => {
      setCommonData({
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
  };

  const formik = useFormik<FranchiseData>({
    initialValues,
    validationSchema: franchiseSchema,
    onSubmit: async (values) => {
      showLoader();
      let finalObj = {
        name: values.name,
        mail: values.mail,
        mob: values.mobile,
        comment: values.city,
      };

      const res = await franchiseData(finalObj);
      if (res) {
        showToast(`Request for franchise created`, "success");
        fn.openModal("thankYou");
        formik.setFieldValue("name", "");
        formik.setFieldValue("mail", "");
        formik.setFieldValue("mobile", "");
        formik.setFieldValue("city", "");
        hideLoader();
      }
    },
  });

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <div
      className="mobile about_us_page"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Header
        label="Getlook Franchise"
        navigate={() => {
          navigate("/profile");
        }}
      />
      <div>
        <div className="franchise_details_container">
          <p className="mt-3 mb-3 font16x700 text-center">
            India's #1 Salon At Home App
          </p>
          <h2 className="font20x700 text-center">Become Your OWN BOSS</h2>
          <p className="mb-0 font12x500 text-center">
            OWN GETLOOK FRANCHISE IN YOUR CITY TODAY
          </p>
          <div className="upto_price_container mt-4">
            <p className="mb-0 font16x600 text-center">Earn upto 1 Crore*</p>
          </div>
          <div className="single_franchise_benefit_wrapper">
            <div className="single_franchise_benefit">
              <div className="benefit_text">
                <p className="mb-0 font12x500">Initial Investment</p>
              </div>
              <div className="benefit_secondary_text">
                <p className="mb-0 font12x600">05-08 lakhs</p>
              </div>
            </div>
            <div className="single_franchise_benefit">
              <div className="benefit_text">
                <p className="mb-0 font12x500">Payback Period</p>
              </div>
              <div className="benefit_secondary_text">
                <p className="mb-0 font12x600">08-15 months</p>
              </div>
            </div>
            <div className="single_franchise_benefit">
              <div className="benefit_text">
                <p className="mb-0 font12x500">Gross Margin</p>
              </div>
              <div className="benefit_secondary_text">
                <p className="mb-0 font12x600">70%-80%</p>
              </div>
            </div>
            <div className="single_franchise_benefit">
              <div className="benefit_text">
                <p className="mb-0 font12x500">Total Carpet Area</p>
              </div>
              <div className="benefit_secondary_text">
                <p className="mb-0 font12x600">100-200 sqft</p>
              </div>
            </div>
          </div>
          <div className="text-center franchise_enquire_button_container">
            <a
              href="#enquire-now"
              className="font14x500 franchise_enquire_button"
            >
              Enquire Now
            </a>
          </div>
          <div className="mt-2">
            <p className="text-end mb-0 font10x600">*T&C Apply</p>
          </div>
        </div>

        <div className="franchise_setup_cost_wrapper">
          <p
            className="mb-0 font12x500 cursor_pointer"
            onClick={() => fn.openModal("setupCost")}
          >
            Know More About Franchise Setup Cost & Returns
          </p>
        </div>

        <div className="my-5">
          <h2 className="font20x700 text-center">Support From Getlook</h2>
          <div className="support_section mt-4 px-3">
            {SupportFromGetlook?.map((item: any, i: number) => {
              return (
                <div
                  key={i}
                  className="single_support_section"
                  style={{
                    backgroundColor: item?.bgColor,
                  }}
                >
                  <img src={item?.imagePath} alt={item?.alt} />
                  <div className="mt-3">
                    <p className="mb-0 font14x600 textColor3 text-center lh-base">
                      {item?.heading}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="city_live_section mt-5 px-3">
          <div className="cities-header">
            <h2 className="font20x700 text-center">We are live in</h2>
            <p className="mb-0 font28x700 textColor1 text-center">10+ Cities</p>
          </div>
          <div className="cities-conatiner">
            {FranchiseCities.map((item: any, i: any) => {
              return (
                <p className="m-0" key={i}>
                  {item?.city}{" "}
                </p>
              );
            })}
          </div>
        </div>

        <div className="booking_stats_container py-5 px-3">
          {BookingStats.map((item: any, i: number) => (
            <div key={i} className="single_stats text-center">
              <p className="mb-2 font18x600" style={{ color: "#f7eab0" }}>
                {item?.count}
              </p>
              <p className="mb-0 font18x600 text-white">{item?.description}</p>
            </div>
          ))}
        </div>

        {/* brochure form */}
        <div id="enquire-now" className="brochure_form_wrapper">
          <h2 className="font20x700 pt-0">
            Interested? Fill this form to get Brochure!
          </h2>
          <form className="mt-3" onSubmit={formik.handleSubmit}>
            <div className="brochure_form">
              <div className="mb-3">
                <InputField
                  id="name"
                  label="Name*"
                  type="text"
                  placeholder="your name"
                  state={formik.values.name}
                  setState={(e) => {
                    formik.setFieldValue("name", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                  bgColor="var(--bg-color2)"
                />
                {formik.touched.name && formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>
              <div className="mb-3">
                <InputMobile
                  id="mobile"
                  label="Mobile No.*"
                  state={formik.values.mobile}
                  setState={(e) => {
                    let isString = e.target.value.match(/[A-z]/g);
                    if (isString) {
                      showToast("Only number is allowed", "error");
                    } else {
                      formik.setFieldValue("mobile", e.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  bgColor="var(--bg-color2)"
                  max={10}
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <InputError error={formik.errors.mobile} />
                ) : null}
              </div>
              <div className="mb-3">
                <InputField
                  id="mail"
                  label="Email Id*"
                  type="text"
                  placeholder="email id"
                  state={formik.values.mail}
                  setState={(e) => {
                    formik.setFieldValue("mail", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                  bgColor="var(--bg-color2)"
                />
                {formik.touched.mail && formik.errors.mail ? (
                  <InputError error={formik.errors.mail} />
                ) : null}
              </div>
              <div className="mb-3">
                <InputField
                  id="city"
                  label="City*"
                  type="text"
                  placeholder="city name"
                  state={formik.values.city}
                  setState={(e) => {
                    formik.setFieldValue("city", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                  bgColor="var(--bg-color2)"
                />
                {formik.touched.city && formik.errors.city ? (
                  <InputError error={formik.errors.city} />
                ) : null}
              </div>
            </div>

            <div className="submit-button-container">
              <button
                type="submit"
                className="font14x400 btn_full_width submit_button"
              >
                {loading ? (
                  <SpinLoader height="22" width="22" color="#fff" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Setup cost modal */}
      {commonData?.modal && commonData?.check === "setupCost" && (
        <BottomTopAnimation>
          <SetupCostModal state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}

      {/* thanku modal */}
      {commonData?.modal && commonData?.check === "thankYou" && (
        <BottomTopAnimation>
          <ThankYouModal state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </div>
  );
};

export default Franchise;
