/**
 * @author Deepanshu Tyagi
 */

import { useNavigate } from "react-router-dom";
import Header from "../../../../components/shared/Header";
import "./index.css";
import RadioButton from "../../../../components/shared/RadioButton";
import { LuClock8 } from "react-icons/lu";
import PaymentDetails from "../../../../components/pages/Cart/PaymentDetails";
import { HomeIcon } from "../../../../components/shared/Icons";
import { useContext, useEffect, useState } from "react";
import { commonType } from "../../type";
import { Auth } from "../../../../utils/context/AuthContext";
import moment from "moment";
import {
  calculatePaymentDetails,
  cartItemsToSendInFacebookPixel,
  cartItemsToSendInGtm,
  checkingTrackingTimeDifference,
  checkUserAddress,
  getDeviceSource,
  getServiceById,
  isUserValid,
  setOverflowToBodyHidden,
  setOverflowToBodyUnset,
} from "../../../../utils/function/function";
import { checkServiceInLocation } from "../../../../lib/api functions/address/address";
import { showToast } from "../../../../lib/toast/showToast";
import {
  changeStylist,
  placeOrder,
} from "../../../../lib/api functions/checkout/checkout";
import { getWallet } from "../../../../lib/api functions/wallet/wallet";
import {
  BOOKED_TYPE,
  conveyanceId,
  couponId,
  handlingFeeId,
  membershipDiscountId,
  membershipId,
  PAYMENT_TYPE,
  premiumId,
  previousDuesId,
  safetyId,
  surgeId,
  TEMP_BOOKED_TYPE,
  TEMP_PAYMENT_TYPE,
  upgradeId,
  walletId,
} from "../../../../utils/constant/constant";
import useLoading from "../../../../hooks/useLoading";
import { gtm, ReactPixel } from "../../../../components/shared/GTM";
import FullPageLoader from "../../../../components/shared/FullPageLoader";
import CityNotMatched from "../../../../components/shared/PopupModal/CityNotMatched";
import BottomTopAnimation from "../../../../components/shared/BottomTopAnimation";
import CheckoutServices from "../../../../components/pages/Checkout/CheckoutServices";
import ProceedToPay from "../../../../components/shared/PopupModal/ProceedToPay";

const Checkout = () => {
  const navigate = useNavigate();
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const { user, addresses, trackUserActivity, setCartItemsInContext } =
    useContext(Auth);
  const [slotInfo, setSlotInfo] = useState<any>();
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const [walletInfo, setWalletInfo] = useState<any>({
    balance: "",
    isApplyWallet: false,
    isWalletBalance: null,
  });

  const paymentMethodApis = {
    payu: process.env.REACT_APP_PAYU_LINK,
  };
  const user_activity = JSON.parse(localStorage.getItem("user_activity")!);
  const [address, setAddress] = useState<any>(
    JSON.parse(`${localStorage.getItem("userAddress")}`)
  );
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`);
  const [appliedCoupon, setAppliedCoupon] = useState<any>(
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null
  );
  const [loading, showLoader, hideLoader] = useLoading();
  const orderId = localStorage.getItem("orderId") || null;

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    handleCheckServiceInLocation: async (latLng: any) => {
      const res = await checkServiceInLocation({
        ...latLng,
        phone: user?.phone ?? "",
      });
      if (res) {
        if (res?.data?.msg) {
          showToast(res?.data?.msg, "error");
          return false;
        } else {
          if (res?.data?.minServiceValue > paymentDetails?.grandTotal) {
            showToast("Please add more services in the cart", "error");
          } else if (
            paymentDetails?.grandTotal < res?.data?.minCharge &&
            paymentDetails?.grandTotal < res?.data?.minServiceValue
          ) {
            showToast(
              `Travelling charges ${res?.data?.charges} is applied`,
              "error"
            );
            return true;
          } else {
            return true;
          }
        }
      }
    },
    handleClickOnAddressSelectProceed: async () => {
      if (checkUserAddress(addresses)) {
        const address = JSON.parse(`${localStorage.getItem("userAddress")}`);
        const latLng = {
          lat: address.latitude,
          long: address.longitude,
        };
        setAddress(address);
        if (await fn.handleCheckServiceInLocation(latLng)) {
          setCommonData?.({
            modal: false,
            check: "",
          });
          setOverflowToBodyUnset();
        }
      }
    },
    getWalletData: async () => {
      const params = "";
      const res = await getWallet(params);
      if (res) {
        if (res?.data[0]?.customer?.wallet_balance < 0) {
          setPaymentDetails((prev: any) => {
            return {
              ...prev,
              previousDues: Math.abs(res?.data[0]?.customer?.wallet_balance),
              ...paymentDetails,
            };
          });
          window.localStorage.setItem(
            "previousDues",
            Math.abs(res?.data[0]?.customer?.wallet_balance).toString()
          );
          setPaymentDetails(calculatePaymentDetails());
        }
      }
    },

    handleClickOnProceed() {
      //   requestDel = request.data.get('requestDel', '')
      //   membership_discount = int(float(request.data.get('membership_discount', '0')))
      //   upgrade_cost = int(float(request.data.get('upgradeServiceCost', '0')))
      //   timing_sel = request.data.get('timing', '0')
      //   tracker_id = request.data.get('tracker_id', '0')
      //   usePastDetails = request.data.get('usepastdetails', '')
      const city = JSON.parse(`${localStorage.getItem("city")}`);
      if (address?.area?.city == city) {
        if (paymentDetails.paymentMethod) {
          const slot = JSON.parse(`${localStorage.getItem("slotInfo")}`);
          const cartInstructions =
            localStorage.getItem("extra_instruction") || null;
          const coupon = appliedCoupon?.couponCode || "";
          let couponDiscount = 0;

          if (
            appliedCoupon?.weekend == 1 &&
            (moment(slotInfo.date).format("ddd") == "Sun" ||
              moment(slotInfo.date).format("ddd") == "Sat" ||
              moment(slotInfo.date).format("ddd") == "Fri")
          ) {
            couponDiscount = appliedCoupon?.discountAmount / 2;
          } else {
            couponDiscount =
              appliedCoupon?.discountAmount && appliedCoupon?.type != "Service"
                ? appliedCoupon?.discountAmount
                : 0;
          }

          const first_name = user?.first_name?.trim();
          const last_name = user?.last_name;
          const name = user?.first_name?.trim()
            ? first_name
            : "" + " " + last_name
            ? last_name
            : "";
          const source = getDeviceSource();
          const total = paymentDetails?.grandTotal;
          const city = address?.area?.city;
          let specialInstructions = "";

          if (cartInstructions) {
            specialInstructions += `${cartInstructions} |`;
          }

          const servicesName: any = [];
          const ids: any = [];
          // adding send at
          // cartItems.forEach((item: any) => {
          //   if (item.added == true) {
          //     // special instructions
          //     if (item?.special_instructions) {
          //       // adding send at
          //       specialInstructions = `Send at ${moment().format("H A")}`;
          //     }
          //   }
          // });
          //
          // ids services special instructions
          cartItems.forEach((item: any) => {
            if (item.added == true) {
              // ids
              for (let count = 1; count <= item.count; count++) {
                if (item.serviceType.id != membershipId) {
                  ids.push(item.serviceType.id);
                }
              }
              // services
              servicesName.push(item.serviceType.name);
              // special instructions
              if (item?.isChoosePackage) {
                let addedServices = "";
                let upgradedServices = "";
                // adding services of packages
                specialInstructions += `${specialInstructions ? " - " : ""}${
                  item?.serviceType?.id
                } - `;
                item?.serviceType?.chosenService?.forEach(
                  (chosenService: any, i: any) => {
                    specialInstructions += `${
                      chosenService?.newLabel
                        ? chosenService?.newLabel
                        : chosenService?.label
                    }${
                      item?.serviceType?.chosenService.length - 1 == i
                        ? ""
                        : ","
                    }`;
                  }
                );
                if (item?.special_instructions.length > 0) {
                  // adding add
                  item?.special_instructions.forEach(
                    (addedServiceItem: any) => {
                      if (addedServiceItem?.header.includes("Add")) {
                        addedServices = `-${addedServiceItem?.header}:`;
                      }
                    }
                  );
                  // adding upgrade
                  item?.special_instructions.forEach(
                    (upgradedServiceItem: any) => {
                      if (upgradedServiceItem?.header.includes("Upgrade")) {
                        upgradedServices = `-${upgradedServiceItem?.header}:`;
                      }
                    }
                  );
                  // adding upgraded name and added name
                  item?.special_instructions?.forEach(
                    (specInstruction: any, i: any) => {
                      if (specInstruction?.header.includes("Add")) {
                        addedServices += `${specInstruction?.name}${
                          item?.special_instructions.length - 1 == i ? "" : ","
                        }`;
                      } else if (specInstruction?.header.includes("Upgrade")) {
                        upgradedServices += `${specInstruction?.name}${
                          item?.special_instructions.length - 1 == i ? "" : ","
                        }`;
                      }
                    }
                  );
                }
                specialInstructions += addedServices + upgradedServices;
              }
            }
          });
          // membership service
          const isMembershipService = cartItems.some((item: any) => {
            return item?.serviceType?.id == membershipId;
          });

          // surge id
          if (slot?.surge < 0 || slot?.surge > 0) {
            ids.push(surgeId);
          }
          // safetyAndHygieneService
          ids.push(safetyId);
          // conveyanceService
          if (paymentDetails.conveyanceCharge > 0) {
            ids.push(conveyanceId);
          }
          // membership discount id
          if (user?.isMember && !isMembershipService) {
            ids.push(membershipDiscountId);
          }

          // membership id
          if (isMembershipService && cartItems.length == 1) {
            ids.push(membershipId);
          } else if (isMembershipService && cartItems.length != 1) {
            ids.push(membershipId);
            ids.push(membershipDiscountId);
          }

          // premium id
          if (slot?.beautician == "premium") {
            ids.push(premiumId);
          }
          // upgradeCostService
          if (paymentDetails.upgradedServiceCharge > 0) {
            ids.push(upgradeId);
          }
          // couponCostService
          if (paymentDetails.couponDiscount > 0) {
            ids.push(couponId);
          }
          // wallet discount
          let walletDiscount = paymentDetails?.walletDiscount;
          if (walletInfo?.isApplyWallet) {
            ids.push(walletId);
          }
          // if wallet balance is less than 0
          if (walletInfo?.balance < 0) {
            ids.push(previousDuesId);
          }

          // if wallet balance is less than 0
          if (paymentDetails?.handlingFee > 0) {
            ids.push(handlingFeeId);
          }

          const body = {
            phone: user.phone,
            locationId: address?.area?.id,
            city: city,
            name: name,
            date: slot?.date,
            mail: user.email,
            services: servicesName.join(","),
            total: total,
            ins: specialInstructions,
            // "get-ins": cartInstructions,
            serviceIds: ids.join(","),
            coupon: coupon,
            slot: Number(slot?.slotNumber),
            source: source,
            // adsource: "",
            // admedium: "",
            // adcampaign: "",
            address1: address?.address1,
            address: `${address?.address}${
              address?.landmark ? `, ${address?.landmark}` : ""
            }`,
            lat: address?.latitude,
            long: address?.longitude,
            // comments: "",
            surge_fee: paymentDetails?.surgeFee,
            handling_fee: paymentDetails?.handlingFee,
            // membership_discount: 90,
            membership_discount: paymentDetails?.membershipDiscount,
            coupon_discount: couponDiscount,
            wallet_discount: walletDiscount,
            // upgradeServiceCost: "",2
            timing: slotInfo.time,
            getlook_safety_cost: paymentDetails?.safetyAndHygieneCharge,
            // tracker_id: "",
            conveyanceCharges: paymentDetails?.conveyanceCharge,
            beautician_premium: paymentDetails?.premiumCharge,
            upgradeServiceCost: paymentDetails?.upgradedServiceCharge,
            previous_dues: paymentDetails?.previousDues,
            comment: paymentDetails?.paymentMethod,
          };
          showLoader();

          placeOrder(body).then((res) => {
            if (res) {
              // update stylist
              const appointmentid = res?.data?.appointmentId;
              const bodyForChangesStylist = {
                id: appointmentid,
                source: "app",
              };
              changeStylist(bodyForChangesStylist).then((res) => {});

              // set fb pixel data for order success
              localStorage.setItem(
                "itemsForOrderSuccessForPixel",
                JSON.stringify({
                  trans_id: appointmentid,
                  city: address?.area?.city,
                  ...cartItemsToSendInFacebookPixel(),
                })
              );

              // set gtm data for order success
              localStorage.setItem(
                "itemsForOrderSuccessForGtm",
                JSON.stringify({
                  transaction_id: appointmentid,
                  city: address?.area?.city,
                  ...cartItemsToSendInGtm(),
                })
              );

              // set order id in localStorage
              localStorage.setItem("orderId", appointmentid);
              localStorage.removeItem("cartItems");
              localStorage.removeItem("coupon_applied");
              localStorage.removeItem("slotInfo");
              localStorage.removeItem("extra_instruction");
              localStorage.removeItem("conveyanceCharge");
              localStorage.removeItem("userAddress");
              localStorage.removeItem("previousDues");
              setCartItemsInContext([]);

              const txnid = res?.data?.appointmentId;
              if (paymentDetails.paymentMethod == "Online") {
                window.open(`${paymentMethodApis.payu}?id=${txnid}`, "_self");
              } else {
                navigate(
                  {
                    pathname: "/order-success",
                    search: `?order_id=${txnid}`,
                  },
                  { replace: true }
                );
              }

              // user tracking
              fn.userTracking();
            }
          });
        } else {
          showToast("Please select the payment method", "error");
        }
      } else {
        fn.openModal(undefined, "city_not_matched");
      }
    },
    handleOpenModalForPayment: (check?: string) => {
      setCommonData({
        modal: true,
        check: check,
        data: { grandTotal: paymentDetails?.grandTotal },
      });
      setOverflowToBodyHidden();
    },
    userTracking: () => {
      if (isUserValid()) {
        const trackingTimeDiff = checkingTrackingTimeDifference();
        if (trackingTimeDiff) {
          // removing user activity after 6 hours
          window.localStorage.removeItem("user_activity");

          trackUserActivity({
            type: BOOKED_TYPE,
            tempType: TEMP_BOOKED_TYPE,
            area_id: localStorage.getItem("area_id")!,
            source: getDeviceSource(),
          });

          window.localStorage.setItem(
            "tracking_cache_timestamp",
            JSON.stringify(moment())
          );
        } else {
          if (user_activity) {
            if (TEMP_BOOKED_TYPE > user_activity?.tempType) {
              trackUserActivity({
                ...user_activity,
                type: BOOKED_TYPE,
                tempType: TEMP_BOOKED_TYPE,
              });
            }
          }
        }
      }
    },
    handleClickOnBack: () => {
      localStorage.removeItem("previousDues");
      navigate(-1);
    },
  };

  useEffect(() => {
    setSlotInfo(JSON.parse(`${localStorage.getItem("slotInfo")}`));
  }, []);
  useEffect(() => {
    setPaymentDetails(calculatePaymentDetails());
  }, []);
  useEffect(() => {
    const paymentDetails = calculatePaymentDetails();
    setPaymentDetails((prev: any) => {
      return {
        ...prev,
        ...paymentDetails,
      };
    });
  }, []);

  // user tracking
  useEffect(() => {
    if (isUserValid()) {
      const trackingTimeDiff = checkingTrackingTimeDifference();
      if (trackingTimeDiff) {
        // removing user activity after 6 hours
        window.localStorage.removeItem("user_activity");

        trackUserActivity({
          type: PAYMENT_TYPE,
          tempType: TEMP_PAYMENT_TYPE,
          area_id: localStorage.getItem("area_id")!,
          source: getDeviceSource(),
        });

        window.localStorage.setItem(
          "tracking_cache_timestamp",
          JSON.stringify(moment())
        );
      } else {
        if (user_activity) {
          if (TEMP_PAYMENT_TYPE > user_activity?.tempType) {
            trackUserActivity({
              ...user_activity,
              type: PAYMENT_TYPE,
              tempType: TEMP_PAYMENT_TYPE,
            });
          }
        }
      }
    }
  }, []);
  // ad tracking
  useEffect(() => {
    fn?.getWalletData();
    gtm.page();
    ReactPixel.pageView();
    ReactPixel.trackCustom(
      "InitiateCheckout",
      cartItemsToSendInFacebookPixel()
    );
    gtm.track("begin_checkout", cartItemsToSendInGtm());
  }, []);

  // checks for payment failed
  useEffect(() => {
    if (!cartItems && orderId) {
      navigate(
        {
          pathname: "/payment-failed",
          search: `?order_id=${orderId}`,
        },
        { replace: true }
      );
    } else if (!cartItems && !orderId) {
      navigate(
        {
          pathname: "/",
        },
        { replace: true }
      );
    }
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "var(--bg-color2)",
          paddingBottom: "54px",
        }}
      >
        <div className="checkout_page">
          <Header label="Checkout" navigate={() => fn.handleClickOnBack()} />

          <PaymentDetails paymentDetails={paymentDetails} />

          {/* <div className="wallet_discount_container d-flex align-items-center bg-white">
            <div>
              <input
                onChange={(e) => fn.handleOnchangeWallet(e)}
                id="wallet"
                type="checkbox"
                checked={walletInfo.isApplyWallet}
              />
              <label htmlFor="wallet"></label>
            </div>
            <div>
              <label
                htmlFor="wallet"
                className="mb-0 font14x400 textColor1 ms-2 apply-wallet-discount-text"
              >
                Apply Wallet Discount?{" "}
                {walletInfo.isWalletBalance == true ? (
                  `(₹${walletInfo?.balance < 0 ? "0.00" : walletInfo?.balance})`
                ) : (
                  <>
                    {walletInfo.isWalletBalance == false ? (
                      <span className="mb-0 font14x400 text-black">
                        {`(₹${
                          walletInfo?.balance < 0 ? "0.00" : walletInfo?.balance
                        })`}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </label>
            </div>
          </div> */}

          <CheckoutServices
            setPaymentDetails={setPaymentDetails}
            setWalletInfo={setWalletInfo}
          />

          <div className="appointment_details_section bg-white">
            <div className="appointment_details_text_container">
              <h1 className="mb-0 font16x700">Appointment Details</h1>
            </div>
            <div className="appointment_details_container d-flex align-items-center">
              <div className="textColor1 d-flex">
                <LuClock8 />
              </div>
              <p className="mb-0 font14x400 textColor3">
                {slotInfo?.time},{" "}
                {moment(slotInfo?.date).format("MMM DD, YYYY")}
              </p>
            </div>
          </div>

          <div className="address_section bg-white">
            <div className="d-flex type_and_address_container">
              <div className="textColor1 d-flex">
                <HomeIcon />
              </div>
              <div className="address_container">
                <p className="mb-1 font14x500">
                  {address?.title}{" "}
                  {address?.isDefault && (
                    <span className="textColor1">(Default)</span>
                  )}
                </p>
                <p className="mt-1 mb-1 font14x400 textColor7">
                  {address?.address}
                </p>
                {address?.landmark ? (
                  <p className="font14x400 textColor7 mb-1">
                    Landmark :- {address?.landmark}
                  </p>
                ) : (
                  ""
                )}

                <p className="mb-0 font14x400 textColor7">
                  {address?.area?.name}, {address?.area?.city}
                </p>
              </div>
            </div>
          </div>

          <div className="fix_section_at_bottom">
            <div className="grand_total_section d-flex justify-content-between align-items-center">
              <div>
                <p className="mb-0 textColor1 font12x400">Grand Total</p>
                <p className="mb-0 textColor1 font16x500">
                  {`₹${paymentDetails?.grandTotal}`}
                </p>
              </div>
              <button
                type="button"
                className="font16x500 text-white"
                onClick={() => {
                  // fn.handleClickOnProceed();
                  fn.handleOpenModalForPayment("proceedToPay");
                }}
                disabled={loading ? true : false}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>

        {loading ? <FullPageLoader /> : ""}
      </div>

      {commonData?.modal && commonData?.check === "city_not_matched" && (
        <BottomTopAnimation>
          <CityNotMatched state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
      {commonData?.modal && commonData?.check === "proceedToPay" && (
        <BottomTopAnimation>
          <ProceedToPay
            state={commonData}
            setState={setCommonData}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
            proceedPayment={fn.handleClickOnProceed}
            loading={loading}
          />
        </BottomTopAnimation>
      )}
    </>
  );
};
export default Checkout;
