export const AllLocation = [
  {
    name: "Bangalore",
  },
  {
    name: "Noida",
  },
  {
    name: "Aurangabad",
  },
  {
    name: "Mumbai",
  },
  {
    name: "Chennai",
  },
  {
    name: "Jodhpur",
  },
  {
    name: "Kolkata",
  },
  {
    name: "Silguri",
  },
  {
    name: "Pune",
  },
  {
    name: "Gorakhpur",
  },
  {
    name: "Hyderabad",
  },
];
