import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import Header from "../../../../../components/shared/Header";
import { AboutUsPage } from "../../../../../utils/metaInfo/metaInfo";
import "./style.css";

const AboutUs = () => {
  const navigate = useNavigate();

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile about_us_page"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Helmet>
          <title>{AboutUsPage.title}</title>
          <meta name="description" content={AboutUsPage.description} />
          <meta name="keywords" content={AboutUsPage.keywords} />
        </Helmet>

        <Header label="About Us" navigate={() => navigate(-1)} />

        {/* page container */}
        <div className="about_us_container">
          <p className="mb-3 lh-sm font14x400 textColor3">
            GetLook is a leading salon-at-home service in India, established in
            2016 and currently operating in more than 12+ cities across the
            country. The core commitment is to provide exceptional salon and
            wellness services in the comfort of your home, delivered by top-tier
            professionals and using high-quality products.
          </p>
          <p className="mb-3 lh-sm font14x400 textColor3">
            Clients can easily explore and book services through the GetLook
            website or app, which features a user-friendly interface for
            convenient scheduling of parlour appointments. The objective is to
            provide affordable yet top-notch salon services, aiming for client
            satisfaction.
          </p>
          <p className="mb-3 lh-sm font14x400 textColor3">
            The team at GetLook, comprising intuitive stylists, therapists, and
            skilled professionals, goes beyond traditional salon offerings.
            Services extend to therapies, massages, spa treatments, facial
            services, keratin hair spa, and a diverse range of beauty services.
          </p>
          <p className="mb-3 lh-sm font14x400 textColor3">
            At GetLook, accessibility is a key focus, ensuring that salon
            services are exclusive to women. Recently, the introduction of
            Eyebrow Lamination and Eyes Lash Lifting Services adds to the array
            of offerings available in the home setting. Safety takes precedence
            at GetLook, with beauticians following a thorough multiple Steps
            Safety Protocol.
          </p>
          <p className="mb-0 lh-sm font14x400 textColor3">
            Clients can trust in the safety of the services, which are not only
            hygienic but also utilize top-leading brand products. All products
            are sealed and provided in one-time-use sachet packets, ensuring
            complete transparency. Discover the convenience, safety, and
            excellence of GetLook's salon-at-home services—where quality meets
            comfort.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
