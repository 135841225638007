import React from "react";
import { commonType } from "../../../../pages/main/type";
import { setOverflowToBodyUnset } from "../../../../utils/function/function";

type propType = {
  setState: (val: commonType) => void;
};

const CouponApplied: React.FC<propType> = (props) => {
  const { setState } = props;
  const appliedCoupon = JSON.parse(`${localStorage.getItem("coupon_applied")}`);

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper" onClick={fn.setPositionToBody}>
        <div className="modal_section py-5 text-center">
          <img src="/static/svg/check.svg" alt="check" width={64} />
          <p className="mt-3 font16x400 textColor3">
            {appliedCoupon?.couponCode} applied
          </p>
          {appliedCoupon?.type == "Service" ? (
            <p className="mt-3 mb-0 font22x700 textColor3">
              You got a Free service
            </p>
          ) : (
            <p className="mt-3 mb-0 font22x700 textColor3">
              You saved ₹{appliedCoupon?.discountAmount}
            </p>
          )}
          <p className="mb-0 mt-1 font14x400 textColor3">
            with this coupon code
          </p>
          <p className="mt-4 mb-0 font14x400 textColor6">HURRAYYY!</p>
          <p className="mt-1 font14x400 textColor3">
            {appliedCoupon?.weekend_message}
          </p>
        </div>
      </div>
    </>
  );
};

export default CouponApplied;
