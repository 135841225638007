import Skeleton from "react-loading-skeleton";

const OrderDetailsLoader = () => {
  return (
    <>
      <div className="p-3">
        <Skeleton width="122px" height="18px" borderRadius="8px" />
        <Skeleton
          className="mt-2"
          width="200px"
          height="18px"
          borderRadius="8px"
        />
        <Skeleton
          className="mt-2"
          width="100px"
          height="30px"
          borderRadius="8px"
        />
        <div className="d-flex mt-3 gap-3">
          <Skeleton width="72px" height="72px" borderRadius="8px" />
          <div>
            <Skeleton width="122px" height="15px" borderRadius="8px" />
            <Skeleton width="60px" height="15px" borderRadius="8px" />
            <Skeleton width="80px" height="15px" borderRadius="8px" />
          </div>
        </div>
        <div className="d-flex mt-3 gap-3">
          <Skeleton width="72px" height="72px" borderRadius="8px" />
          <div>
            <Skeleton width="122px" height="15px" borderRadius="8px" />
            <Skeleton width="60px" height="15px" borderRadius="8px" />
            <Skeleton width="80px" height="15px" borderRadius="8px" />
          </div>
        </div>
        <div className="d-flex mt-3 gap-3">
          <Skeleton width="72px" height="72px" borderRadius="8px" />
          <div>
            <Skeleton width="122px" height="15px" borderRadius="8px" />
            <Skeleton width="60px" height="15px" borderRadius="8px" />
            <Skeleton width="80px" height="15px" borderRadius="8px" />
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-between">
          <Skeleton width="122px" height="15px" borderRadius="8px" />
          <Skeleton width="60px" height="15px" borderRadius="8px" />
        </div>
        <div className="d-flex mt-3 justify-content-between">
          <Skeleton width="122px" height="15px" borderRadius="8px" />
          <Skeleton width="60px" height="15px" borderRadius="8px" />
        </div>
        <div className="d-flex mt-3 justify-content-between">
          <Skeleton width="122px" height="15px" borderRadius="8px" />
          <Skeleton width="60px" height="15px" borderRadius="8px" />
        </div>
        <div className="d-flex mt-3 justify-content-between">
          <Skeleton width="122px" height="15px" borderRadius="8px" />
          <Skeleton width="60px" height="15px" borderRadius="8px" />
        </div>
      </div>
    </>
  );
};

export default OrderDetailsLoader;
