/**
 * @author Deepanshu Tyagi
 */

import "./index.css";
import { commonType } from "../../../pages/main/type";
import { ClockIcon, CloseIcon, StarIcon } from "../Icons";
import {
  calculateDiscountInPercent,
  getImageURL,
  setOverflowToBodyUnset,
} from "../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const ViewDetails: React.FC<propType> = (props) => {
  const { state, setState } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
    renderServiceNameList: (service: any) => {
      const splitedNames = service?.name?.split(":");
      if (splitedNames[1]?.includes(" OR ")) {
        return splitedNames[1]?.split(" OR ")?.map((info: any, k: any) => {
          return (
            <li className="mb-0 font12x500 textColor3" key={k}>
              {info}
            </li>
          );
        });
      } else if (splitedNames[1]?.includes(" , ")) {
        return splitedNames[1]?.split(" , ")?.map((info: any, k: any) => {
          return (
            <li className="mb-0 font12x500 textColor3" key={k}>
              {info}
            </li>
          );
        });
      } else if (splitedNames[1]?.includes(" + ")) {
        return splitedNames[1]?.split(" + ")?.map((info: any, k: any) => {
          return (
            <li className="mb-0 font12x500 textColor3" key={k}>
              {info}
            </li>
          );
        });
      } else {
        <></>;
      }
    },
    getHowItWorksData: (): any => {
      let splitData = state?.data?.serviceType?.note?.split("+");
      let tempArr: any = [];
      // split data again for title and description
      splitData?.map((item: any, i: number) => {
        let splitDataAgain = item?.split(":");
        tempArr.push({
          title: splitDataAgain[0],
          description: splitDataAgain[1],
        });
      });
      return tempArr;
    },
  };

  return (
    <div className="view_details_page">
      {/* cancel */}
      <div className="cancel-container">
        <button
          onClick={fn.setPositionToBody}
          className="d-flex align-items-center justify-content-center bg-black text-white border-0"
        >
          <CloseIcon width="20" height="20" fill="#fff" />
        </button>
      </div>
      <div className="view_details_container">
        {/* service-information */}
        <div className="service-image-container bg-white">
          {state?.data?.serviceType?.image_1 && (
            <img
              src={getImageURL(state?.data?.serviceType?.image_1)}
              alt="service_image"
              height={256}
            />
          )}
          {state?.data?.serviceType?.image_2 && (
            <img
              src={getImageURL(state?.data?.serviceType?.image_2)}
              alt="service_image"
              height={256}
            />
          )}
          {state?.data?.serviceType?.image_3 && (
            <img
              src={getImageURL(state?.data?.serviceType?.image_3)}
              alt="service_image"
              height={256}
            />
          )}
          {state?.data?.serviceType?.image_4 && (
            <img
              src={getImageURL(state?.data?.serviceType?.image_4)}
              alt="service_image"
              height={256}
            />
          )}
        </div>
        <div className="service_info_container bg-white">
          <div>
            {state?.data?.serviceType?.recommended && (
              <p className="mb-2 service_tag font10x600">
                {state?.data?.serviceType?.recommended}
              </p>
            )}
            <h1 className="mb-1 font16x600 textColor3">
              {state?.data?.serviceType?.name?.split(":")[0]}
            </h1>
            <ul className="m-0 p-0 ps-3 d-flex flex-column gap-1">
              {fn.renderServiceNameList(state?.data?.serviceType)}
            </ul>
          </div>
          <div className="rating_review_container d-flex align-items-center gap-1">
            {state?.data?.serviceType?.commission !== -1 && (
              <div className="rating_container d-flex align-items-center gap-1">
                <i>
                  <StarIcon width="12" height="11" fill="#838383" />
                </i>
                <p className="mb-0 font12x400 textColor7">
                  {state?.data?.serviceType?.commission / 10}
                </p>
              </div>
            )}
          </div>
          <div className="price_and_time_container d-flex align-items-center">
            <p className="mb-0 font12x700">{`₹${state?.data?.cost}`}</p>
            {state?.data?.cost !== state?.data?.serviceType?.cost && (
              <p className="mb-0 ms-2 font12x700 text-decoration-line-through textColor7">
                {`₹${state?.data?.serviceType?.cost}`}
              </p>
            )}
            <div className="d-flex align-items-center ms-3 gap-1">
              <ClockIcon />
              <p className="mb-0 font12x500 textColor7">
                {`${state?.data?.serviceType?.time} mins`}
              </p>
            </div>
            {state?.data?.cost !== state?.data?.serviceType?.cost && (
              <div className="ms-3">
                <p className="mb-1 package_discount_tag font10x600">
                  {calculateDiscountInPercent(
                    state?.data?.cost,
                    state?.data?.serviceType?.cost
                  )}
                  % OFF
                </p>
              </div>
            )}
          </div>
          <div className="description_container">
            <ul className="mb-0 ps-3">
              {state?.data?.serviceType?.benefit
                ?.split("+")
                ?.map((item: string, i: number) => {
                  return (
                    <li key={i} className="mb-1 font12x400 textColor7">
                      {item?.trim()}
                    </li>
                  );
                })}
            </ul>
          </div>

          {/* brand */}
          {state?.data?.serviceType?.brand && (
            <div className="mt-3">
              <div>
                <h1 className="mb-1 font16x600 textColor3">Brand</h1>
                <p className="mb-0 font12x400 textColor3">
                  {state?.data?.serviceType?.brand}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* overview */}
        <div className="overviews_section bg-white">
          <div className="title_container">
            <h1 className="mb-0 font16x600 textColor3">Overview</h1>
          </div>
          <div className="overviews_container">
            {state?.data?.serviceType?.images
              ?.split(",")
              ?.map((item: string, i: number) => {
                return (
                  <div className="overview_container text-center">
                    <img
                      src={item}
                      alt={`overview_${i + 1}`}
                      className="img-fluid"
                    />
                  </div>
                );
              })}
          </div>
        </div>

        {/* how it works? */}
        {fn.getHowItWorksData()?.length > 0 && (
          <div className="steps_container bg-white pb-0">
            <div className="title_container">
              <h1 className="mb-3 font16x600 textColor3">How it works?</h1>
            </div>
            <div className="d-flex flex-column">
              {fn.getHowItWorksData()?.map((item: any, i: number) => {
                return (
                  <div key={i} className="step_container">
                    <div
                      className="position-relative"
                      style={{
                        width: 28,
                      }}
                    >
                      <div className="number_container">
                        <p className="mb-0 font14x600 textColor1">{i + 1}</p>
                      </div>
                      {fn.getHowItWorksData()?.length - 1 > i && (
                        <div className="step_line"></div>
                      )}
                    </div>

                    <div className="pt-1 pb-2">
                      <p className="mb-1 font14x500 textColor3">
                        {item?.title}
                      </p>
                      <p className="mb-0 font12x400 textColor3">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* things to know */}
        {state?.data?.serviceType?.information && (
          <div className="steps_container bg-white">
            <div className="title_container">
              <h1 className="font16x600 textColor3">Things to know</h1>
            </div>
            <div>
              <ul className="mb-0 ps-3">
                {state?.data?.serviceType?.information
                  ?.split("+")
                  ?.map((item: string, i: number) => {
                    return (
                      <li key={i} className="mb-1 font12x400 textColor7">
                        {item?.trim()}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ViewDetails;
