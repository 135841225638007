import * as Yup from "yup";

// otp schema
export const otpLoginSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required")
    .matches(/^[0-9]{10}$/, "Invalid number")
    .test("Invalid number", "Invalid number", (value: any) => {
      return value?.trim()?.length >= 10;
    }),
});

// address schema
export const addressSchema = Yup.object().shape({
  name: Yup.string()
    .test("Invalid name", "Invalid name", (value: any) => {
      return value?.trim()?.length >= 3;
    })
    .required("Required"),
  phone: Yup.string()
    .required("Required")
    .matches(/^[0-9]{10}$/, "Invalid number")
    .test("Invalid number", "Invalid number", (value: any) => {
      return value?.trim()?.length >= 10;
    }),
  address: Yup.string()
    .test("Invalid address", "Invalid address", (value: any) => {
      return value?.trim()?.length >= 1;
    })
    .required("Required"),
  apartmentName: Yup.string()
    .required("Required")
    .test("Invalid apartment name", "Invalid apartment name", (value: any) => {
      return value?.trim()?.length >= 3;
    }),
  landmark: Yup.string().optional(),
});

// user schema
export const userSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .test("Invalid name", "Invalid name", (value: any) => {
      return value?.trim()?.length >= 3;
    }),
  email: Yup.string()
    .test("Invalid email", "Invalid email", (value: any) => {
      return value?.trim()?.length >= 3;
    })
    .required("Required")
    .test("Invalid email", "Invalid email", (value: any) => {
      let emailRgx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/;
      return emailRgx.test(value?.trim());
    }),
  // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email"),
  phone: Yup.string()
    .required("Required")
    .matches(/^[0-9]{10}$/, "Invalid number")
    .test("Invalid number", "Invalid number", (value: any) => {
      return value?.trim()?.length >= 10;
    }),
  birth_date: Yup.string().nullable().optional(),
  alternate_phone: Yup.string()
    .nullable()
    .matches(/^[0-9]{10}$/, "Invalid number"),
});

// franchise schema
export const franchiseSchema = Yup.object().shape({
  name: Yup.string()
    .test("Invalid name", "Invalid name", (value: any) => {
      return value?.trim()?.length >= 3;
    })
    .required("Required"),
  mail: Yup.string()
    .test("Invalid email", "Invalid email", (value: any) => {
      return value?.trim()?.length >= 3;
    })
    .required("Required")
    .test("Invalid mail", "Invalid mail", (value: any) => {
      let emailRgx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/;
      return emailRgx.test(value?.trim());
    }),
  mobile: Yup.string()
    .required("Required")
    .matches(/^[0-9]{10}$/, "Invalid number")
    .test("Invalid number", "Invalid number", (value: any) => {
      return value?.trim()?.length >= 10;
    }),
  city: Yup.string()
    .test("Invalid city", "Invalid city", (value: any) => {
      return value?.trim()?.length >= 3;
    })
    .required("Required"),
});

// cancel order schema
export const cancelOrderSchema = Yup.object().shape({
  reason: Yup.string()
    .test("Invalid reason", "Invalid reason", (value: any) => {
      return value?.trim()?.length >= 3;
    })
    .required("Reason is required"),
});

// review schema
export const reviewSchema = Yup.object().shape({});
