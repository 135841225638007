import { Link } from "react-router-dom";

const ContactUsLinks = () => {
  return (
    <>
      <div>
        <h3 className="mb-0 text-white font16x600">CONTACT US</h3>
        <div className="mt-3 d-flex flex-column gap-2">
          <p className="mb-0 text-white font14x500">
            Email id-{" "}
            <Link
              to="mailto: hi@getlook.in"
              className="text-white text-decoration-none"
            >
              hi@getlook.in
            </Link>
          </p>
          <p className="mb-0 text-white font14x500">
            Contact Number-{" "}
            <Link
              to="tel:8884877662"
              className="text-white text-decoration-none"
            >
              8884877662
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ContactUsLinks;
