import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import { BackIcon, EditIcon } from "../../../components/shared/Icons";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import useLoading from "../../../hooks/useLoading";
import { otpLogin, verifyOtp } from "../../../lib/api functions/common/common";
import { showToast } from "../../../lib/toast/showToast";
import { Auth } from "../../../utils/context/AuthContext";
import { checkUserExpiry, handleTime } from "../../../utils/function/function";

const VerifyOtp = () => {
  const { state } = useLocation();
  const [loading, showLoader, hideLoader] = useLoading();
  const [inputOtp, setInputOtp] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const navigate = useNavigate();
  const { setUser, getUserAddresses, setToken, setCartItemsInContext } =
    useContext(Auth);

  //otp expireTime in seconds
  const [expireTime, setExpireTime] = useState<number>(100);
  const [time, setTime] = useState<string>("00:00");
  useEffect(() => {
    if (expireTime !== 0) {
      setTime(handleTime(expireTime));
      setTimeout(() => {
        setExpireTime(expireTime - 1);
      }, 1000);
    }
  }, [expireTime]);

  const fn = {
    checkEnterOTPIsNumber: (e: any) => {
      const value = e.target.value;
      if (isNaN(value)) {
        // show error
        setInputError("Please enter only numbers");
      } else {
        setInputOtp(e.target.value);
        setInputError("");
      }
    },
    verifyOtp: async (e: any) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("otp_number", inputOtp);
      formData.append("otp_phone", state.data?.phone);
      // phoneCode: state.phoneCode,
      showLoader();
      const res = await verifyOtp(formData, hideLoader);
      if (res) {
        if (res.data?.data?.is_taken) {
          if (
            !res?.data?.data?.first_name ||
            res?.data?.data?.email?.includes("@getlook.in")
          ) {
            // for new user
            navigate("/profile-setup", {
              replace: true,
              state: {
                ...state,
                user: { ...res.data.data, ...state?.data },
              },
            });
            setToken(res.data.token);
          } else {
            // for old user
            window.localStorage.setItem("token", res.data.token);
            window.sessionStorage.removeItem("phone");

            // check user expiry
            if (
              res?.data?.data?.isMember &&
              res.data.data?.membershipActivationDate &&
              res.data.data?.membershipStartDate
            ) {
              let isMember = res?.data?.data?.isMember;

              if (
                checkUserExpiry(
                  res.data.data?.membershipActivationDate,
                  res.data.data?.membershipDuration
                )
              ) {
                isMember = false;
              }
              window.localStorage.setItem(
                "user",
                JSON.stringify({
                  ...res.data.data,
                  isMember,
                  ...state?.data,
                })
              );
              setUser({
                ...res.data.data,
                isMember,
                ...state?.data,
              });
            } else {
              window.localStorage.setItem(
                "user",
                JSON.stringify({ ...res.data.data, ...state?.data })
              );
              setUser({ ...res.data.data, ...state?.data });
            }

            getUserAddresses();

            // updation of cart
            if (state?.serviceTobeAdded) {
              setCartItemsInContext((prev: any) => {
                return [...prev, state?.serviceTobeAdded[0]];
              });
              localStorage.setItem(
                "cartItems",
                JSON.stringify(state?.serviceTobeAdded)
              );
            }

            if (state?.previousURL) {
              // check if previous URL exist or not
              // navigate("/cart", { replace: true });
              navigate(state?.previousURL, { replace: true });
            } else {
              navigate("/", { replace: true });
            }
          }
        } else {
          showToast("OTP is incorrect", "error");
          hideLoader();
        }
      }
    },
    resendOTP: async () => {
      const body = { phone: state?.data?.phone };
      const res = await otpLogin(body);
      if (res?.data) {
        showToast("OTP sent successfully", "success");
        setExpireTime(100);
        navigate("/verify-otp", { state: { ...res.data } });
      }
    },
  };

  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="verify_header">
          <BackIcon
            width="17"
            height="14"
            fill="#0F0F0F"
            clickHandler={() => navigate(-1)}
          />
        </div>
        <div className="verify_otp_wrapper">
          <p className="mt-4 font12x400 textColor3">
            OTP has been sent on your mobile number
          </p>
          <div className="mb-5 d-flex align-items-center gap-2">
            <p className="mb-0 font20x500 textColor3">
              {`+91 ${state?.data?.phone}`}
            </p>
            <EditIcon
              width="16"
              height="16"
              fill="#AF031A"
              clickHandler={() => navigate("/otp-login", { replace: true })}
            />
          </div>
          <form className="mt-5" onSubmit={fn.verifyOtp}>
            <div className="mb-4 mt-4">
              <div
                className="input_wrapper d-flex align-items-center"
                style={{ border: "1px solid var(--border-color1)" }}
              >
                <input
                  type="tel"
                  className="form-control shadow-none input_field"
                  id="otp"
                  placeholder="Enter 4 digit OTP"
                  autoComplete="one-time-code"
                  name="otp"
                  value={inputOtp}
                  onChange={(e: any) => fn.checkEnterOTPIsNumber(e)}
                  maxLength={4}
                />
              </div>
              {inputError?.length > 0 && (
                <p className="errorDanger mt-1 mb-0">{inputError}</p>
              )}
            </div>
            <button
              type="submit"
              className="mt-2 btn_login font14x500"
              style={{ width: 290 }}
              disabled={inputOtp?.length != 4 ? true : false}
            >
              {loading ? (
                <SpinLoader height="22" width="22" color="#fff" />
              ) : (
                "Verify"
              )}
            </button>
          </form>

          <div className="font14x400 text-center mt-4">
            {expireTime === 0 ? (
              <p
                className="anchorTag cursor_pointer d-inline-block"
                onClick={fn.resendOTP}
              >
                Resend OTP
              </p>
            ) : (
              <p className="mb-0 font14x400 textColor1">Resend OTP in {time}</p>
            )}
          </div>
          <div className="unable-to-login-whatsapp-container mt-5">
            <p>Unable to Login</p>
            <Link
              className="unable-to-login-whatsapp"
              to="https://api.whatsapp.com/send/?phone=919513862350&text&type=phone_number&app_absent=0"
              target="_blank"
            >
              <img
                src="/static/svg/whatsapp-menu.svg"
                alt="whatsapp-logo"
                width="22"
                height="22"
              />
              WhatsApp{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
