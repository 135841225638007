import React, { useContext } from "react";
import { Auth } from "../../../utils/context/AuthContext";
import BottomBar from "../BottomBar";

type PropType = {
  children: any;
  showBottomBar: boolean;
  backgroundColor: string;
};

const Layout: React.FC<PropType> = (props) => {
  const { servingOnLocation } = useContext(Auth);
  const { children, showBottomBar, backgroundColor } = props;

  return (
    <>
      {/* mobile */}
      <div
        className="mobile"
        style={{
          backgroundColor,
        }}
      >
        {children}

        {servingOnLocation?.isServing && showBottomBar && <BottomBar />}
      </div>
    </>
  );
};

export default Layout;
