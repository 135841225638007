import Api from "../../../config/api";
import { CHANGESTYLIST, PLACE_ORDER } from "../../../utils/constant/constant";
import { showToast } from "../../toast/showToast";

export const placeOrder = async (body: any) => {
  try {
    const res = await Api(false).post(PLACE_ORDER, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};

export const changeStylist = async (body: any) => {
  try {
    const res = await Api(false).post(CHANGESTYLIST, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};
