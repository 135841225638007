import { useState } from "react";
import { commonType } from "../../../../../../pages/main/type";
import {
  getImageURL,
  isShowStylistInfo,
  setOverflowToBodyHidden,
} from "../../../../../../utils/function/function";
import BottomTopAnimation from "../../../../../shared/BottomTopAnimation";
import StylistPhoto from "./Stylist/StylistPhoto";

type PropType = {
  singleOrder: any;
};

const StylistInfo: React.FC<PropType> = (props) => {
  const { singleOrder } = props;
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    returnStylistImage: () => {
      if (singleOrder?.stylist?.profile_picture) {
        // return stylist image
        return (
          <img
            className="border_radius_8"
            src={getImageURL(singleOrder?.stylist?.profile_picture)}
            alt="stylist"
            width={53}
            height={53}
            onClick={() => {
              fn.openModal(
                { profile_picture: singleOrder?.stylist?.profile_picture },
                "stylist_photo"
              );
            }}
          />
        );
      } else {
        // return regular or premium stylist demo image
        if (singleOrder?.stylist?.work_experience === 100) {
          return (
            <img src="/static/img/premium_stylist.png" alt="premium_stylist" />
          );
        } else {
          return (
            <img src="/static/img/regular_stylist.png" alt="regular_stylist" />
          );
        }
      }
    },
  };

  return (
    <>
      {singleOrder?.appointmentitem_set?.some((item: any) => {
        if (
          item?.service?.serviceType?.id == 620 ||
          singleOrder?.stylist?.work_experience == 100
        ) {
          return item;
        }
      }) ? (
        <div className="py-3 d-flex justify-content-between premiumn-stylist-data">
          {isShowStylistInfo(singleOrder) ? (
            <>
              <div>
                <p className="mb-1 font12x400 textColor7">By Premium Stylist</p>
                <p className="mb-0 mt-1 font14x400 textColor3">
                  {singleOrder?.stylist?.user?.first_name?.trim()}
                </p>
              </div>
              <div className="text-center position-relative">
                {fn.returnStylistImage()}
                <div className="stylist-star">
                  <img src="/static/svg/yellowstar.svg" />
                </div>
              </div>
            </>
          ) : (
            <p className="mb-0 font14x400 textColor1">
              Beautician will be assigned 2 hours prior to the booking
            </p>
          )}
        </div>
      ) : (
        <div className="py-3 d-flex justify-content-between">
          {isShowStylistInfo(singleOrder) ? (
            <>
              <div>
                <p className="mb-1 font12x400 textColor7">By Stylist</p>
                <p className="mb-0 mt-1 font14x400 textColor3">
                  {singleOrder?.stylist?.user?.first_name?.trim()}
                </p>
              </div>
              <div className="text-center">{fn.returnStylistImage()}</div>
            </>
          ) : (
            <p className="mb-0 font14x400 textColor1">
              Beautician will be assigned 2 hours prior to the booking
            </p>
          )}
        </div>
      )}

      <div className="stylist-otp">
        OTP <strong> {` : ${singleOrder?.id}`}</strong>
      </div>
      <p className="font12x400">
        Please provide this OTP to the Stylist only after your service is Completed.
      </p>

      {/* stylist photo modal */}
      {commonData?.modal && commonData?.check === "stylist_photo" && (
        <BottomTopAnimation>
          <StylistPhoto state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default StylistInfo;
