import { useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";
import { useEffect } from "react";
import { gtm, ReactPixel } from "../../../../components/shared/GTM";
import Header from "../../../../components/shared/Header";
import { OrderSuccessImage } from "../../../../components/shared/Icons";

const OrderSuccess = () => {
  const navigate = useNavigate();
  const [queryData, setQueryData] = useSearchParams();

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
    const itemsForOrderSuccessForPixel = JSON.parse(
      `${localStorage.getItem("itemsForOrderSuccessForPixel") || null}`
    );
    const itemsForOrderSuccessForGtm = JSON.parse(
      `${localStorage.getItem("itemsForOrderSuccessForGtm") || null}`
    );
    if (itemsForOrderSuccessForPixel) {
      ReactPixel.trackCustom("Purchase", itemsForOrderSuccessForPixel);
    }
    if (itemsForOrderSuccessForGtm) {
      gtm.track("purchase", itemsForOrderSuccessForGtm);
    }

    localStorage.removeItem("itemsForOrderSuccessForPixel");
    localStorage.removeItem("itemsForOrderSuccessForGtm");
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="order_success_page">
          <Header label="" navigate={() => navigate("/")} />
          <div className="order_success_image_container text-center">
            <OrderSuccessImage />
          </div>
          <div className="order_success_section">
            <div className="text-center">
              <h1 className="font20x700 mb-0 textColor3">Booking Placed.</h1>
            </div>
            <div className="order_success_text text-center">
              <h1 className="font20x700 mb-0 textColor3">Successfully!</h1>
            </div>
            <div className="text-center">
              <p className="font12x400 mb-0 textColor3">
                Order ID: {queryData.get("order_id")}
              </p>
            </div>
          </div>

          <div className="view_details_done_container">
            <button
              type="button"
              onClick={() => {
                navigate(`/myorder?order_id=${queryData.get("order_id")}`, {
                  replace: true,
                });
              }}
              className="view_details font14x400"
            >
              View Order Details
            </button>
            <button
              type="button"
              onClick={() => {
                navigate("/", {
                  replace: true,
                });
              }}
              className="done font14x400"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
