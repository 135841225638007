import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExtraFeeModal from "../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/CancelOrder/ExtraFeeModal";
import FeeModal from "../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/Reschedule/FeeModal";
import BottomTopAnimation from "../../../../../components/shared/BottomTopAnimation";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import Header from "../../../../../components/shared/Header";
import Separator from "../../../../../components/shared/Separator";
import useLoading from "../../../../../hooks/useLoading";
import { getAppointments } from "../../../../../lib/api functions/order/order";
import {
  Cancel_Order_Time_24H,
  Cancel_Order_Time_1H,
  ORDER_DETAILS_STATUS,
  Reschedule_Order_Time_1H,
  Reschedule_Order_Time_24H,
  conveyanceId,
  premiumId,
  surgeId,
} from "../../../../../utils/constant/constant";
import {
  calculateOrderPaymentDetails,
  isSlotAboveGivenTime,
  isShowStylistInfo,
  setOverflowToBodyHidden,
  randomSortData,
  calculateUpgradeCost,
  getServiceById,
} from "../../../../../utils/function/function";
import { OrderPage } from "../../../../../utils/metaInfo/metaInfo";
import { commonType } from "../../../type";
import OrdersLoader from "./loader";
import { ParamType } from "./type";
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { RiEBike2Line } from "react-icons/ri";
import { IoBag } from "react-icons/io5";
import { FaThumbsUp } from "react-icons/fa6";
import { RiEBike2Fill } from "react-icons/ri";

const BookingAndOrders = () => {
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [orders, setOrders] = useState<any>([]);
  const [params, setParams] = useState<ParamType>({});
  const [showUpgrade, setShowUpgrade] = useState<ParamType>(false);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const { state } = useLocation();

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    getOrderFn: async (params: ParamType) => {
      showLoader();
      const res = await getAppointments(params);
      if (res) {
        setOrders(res);
        hideLoader();
      }
    },
    cancelOrderChecks: (order: any): any => {
      let obj = {
        isShowButton: false,
        cancellationFeeModal: false,
      };

      let isCancelOrderOutOf1H = isSlotAboveGivenTime(
        order?.date,
        order?.timing,
        Cancel_Order_Time_1H
      );

      if ([0, 6, 7, 10].includes(order?.status)) {
        // pending status
        obj.isShowButton = true;
      } else if ([4, 13].includes(order?.status)) {
        // start, in-transit status
        obj.isShowButton = false;
      } else if ([2, 5, -1, 11].includes(order?.status)) {
        // ended, cancellation, cancellation requested status
        obj.isShowButton = false;
      } else {
        //   // confirm status
        obj.isShowButton = true;

        if (!isCancelOrderOutOf1H) {
          obj.isShowButton = false;
        }
      }

      return obj;
    },
    cancelOrderFeeModalChecks: (order: any): boolean => {
      let cancellationFeeModal = false;

      let isCancelOrderOutOf24H = isSlotAboveGivenTime(
        order?.date,
        order?.timing,
        Cancel_Order_Time_24H
      );
      let isCancelOrderOutOf1H = isSlotAboveGivenTime(
        order?.date,
        order?.timing,
        Cancel_Order_Time_1H
      );

      if (!isCancelOrderOutOf24H && isCancelOrderOutOf1H) {
        cancellationFeeModal = true;
      }

      return cancellationFeeModal;
    },
    rescheduleOrderChecks: (order: any): any => {
      let obj = {
        isShowButton: false,
        cancellationFeeModal: false,
      };

      let isRescheduleOutOf1H = isSlotAboveGivenTime(
        order?.date,
        order?.timing,
        Reschedule_Order_Time_1H
      );

      if ([0, 6, 7, 10].includes(order?.status)) {
        // pending status
        obj.isShowButton = true;
      } else if ([4, 13].includes(order?.status)) {
        // start, in-transit status
        obj.isShowButton = false;
      } else if ([2, 5, -1, 11].includes(order?.status)) {
        // ended, cancellation, cancellation requested status
        obj.isShowButton = false;
      } else {
        //   // confirm status
        obj.isShowButton = true;

        if (!isRescheduleOutOf1H) {
          obj.isShowButton = false;
        }
      }

      return obj;
    },
    rescheduleOrderFeeModalChecks: (order: any): boolean => {
      let rescheduleFeeModal = false;

      let isRescheduleOutOf24H = isSlotAboveGivenTime(
        order?.date,
        order?.timing,
        Reschedule_Order_Time_24H
      );
      let isRescheduleOutOf1H = isSlotAboveGivenTime(
        order?.date,
        order?.timing,
        Reschedule_Order_Time_1H
      );

      if (!isRescheduleOutOf24H && isRescheduleOutOf1H) {
        rescheduleFeeModal = true;
      }

      return rescheduleFeeModal;
    },
    reviewOrderChecks: (order: any): boolean => {
      let check = false;

      if ([2, 5, 11, -1].includes(order?.status)) {
        check = true;
      } else {
        check = false;
      }
      return check;
    },
    getRecommendedServices: (item: any) => {
      let tempService: any = [];

      item?.appointmentitem_set?.map((item: any) => {
        return (
          !item?.update &&
          item?.service?.serviceType?.update_services &&
          item?.service?.serviceType?.update_services !== "0" &&
          JSON.parse(item?.service?.serviceType?.update_services)?.map(
            (item2: any) => {
              // check upgrade service is active or not
              let service = getServiceById(item2?.id);

              if (
                (Object.keys(service)?.length > 0 &&
                  calculateUpgradeCost(item2?.id, item2?.id_p) >= 0 &&
                  item2?.header?.split(" ")[0] === "Upgrade") ||
                item2?.header?.split(" ")[0] === "Add"
              ) {
                return tempService.push({
                  previousServiceId: item?.serviceType?.id,
                  serviceType: item2,
                  serviceImage: service?.serviceType?.image_1,
                });
              }
            }
          )
        );
      });

      // random sorting
      const randomSortTempServices = randomSortData(tempService);

      if (randomSortTempServices?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fn.getOrderFn(params);
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  // const handleClickOnCUpgrade = (item: any) => {
  //   item?.appointmentitem_set?.forEach((service: any) => {
  //     if(service?.service?.serviceType?.id != conveyanceId &&
  //       service?.service?.serviceType?.id != premiumId &&
  //       service?.service?.serviceType?.id != surgeId &&
  //       service?.service?.serviceType?.id != walletId &&
  //       service?.service?.serviceType?.id != membershipDiscountId &&
  //       service?.service?.serviceType?.id != couponId &&
  //       service?.service?.serviceType?.id != safetyId &&
  //       service?.service?.serviceType?.id != upgradeId &&
  //       service?.service?.serviceType?.id != rescheduleChargeId &&
  //       service?.service?.serviceType?.id != previousDuesId) {

  //       }
  //   });
  // }

  return (
    <>
      <div
        className="mobile booking_details"
        style={{
          backgroundColor: "var(--bg-color2)",
        }}
      >
        <Helmet>
          <title>{OrderPage.title}</title>
          <meta name="description" content={OrderPage.description} />
          <meta name="keywords" content={OrderPage.keywords} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>

        <Header
          label="Bookings & Orders"
          navigate={() =>
            navigate(state?.comingFrom === "/" ? state?.comingFrom : "/profile")
          }
        />

        {/* orders */}
        <div className="orders_wrapper d-flex flex-column gap-2">
          {!loading ? (
            <>
              {orders?.data ? (
                <>
                  {orders?.data?.length > 0 ? (
                    <>
                      {orders?.data?.map((item: any, i: number) => {
                        return (
                          <div className="single_order">
                            <Link
                              to={{
                                pathname: "/myorder",
                                search: `?order_id=${item?.id}`,
                              }}
                              key={i}
                            >
                              <Link
                                to={{
                                  pathname: "/myorder",
                                  search: `?order_id=${item?.id}`,
                                }}
                                className="text-decoration-none"
                              >
                                <div className="d-flex justify-content-between align-items-start pb-3">
                                  <div>
                                    <p className="mb-1 textColor3 font12x500">{`Order#${item.id}`}</p>
                                    <p className="mb-1 textColor7 font12x400">
                                      {`Total Amount - ₹${
                                        calculateOrderPaymentDetails(item)
                                          ?.grandTotal
                                      }`}
                                    </p>
                                    <p className="mb-0 mt-1 textColor1 font12x500">
                                      View Details
                                    </p>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        backgroundColor:
                                          ORDER_DETAILS_STATUS[
                                            item?.status?.toString()
                                          ]?.backgroundColor,
                                        color:
                                          ORDER_DETAILS_STATUS[
                                            item?.status?.toString()
                                          ]?.color,
                                      }}
                                      className="status_detail font12x400"
                                    >
                                      {
                                        ORDER_DETAILS_STATUS[
                                          item?.status?.toString()
                                        ]?.status
                                      }
                                    </span>
                                  </div>
                                </div>
                                <Separator />
                                <div className="status-tracker-container">
                                  <div className="status-tracker-border"></div>
                                  <div className="status-tracker-icons-container d-flex align-items-center justify-content-between">
                                    <div className="status-icon-container">
                                      <IoCheckmarkCircle
                                        fill={
                                          item?.status == 0 ||
                                          item?.status == 1 ||
                                          item?.status == 13 ||
                                          item?.status == 4 ||
                                          item?.status == 5
                                            ? "#af031a"
                                            : "gray"
                                        }
                                      />
                                      <p
                                        style={{
                                          color:
                                            item?.status == 0 ||
                                            item?.status == 1 ||
                                            item?.status == 13 ||
                                            item?.status == 4 ||
                                            item?.status == 5
                                              ? "#af031a"
                                              : "gray",
                                        }}
                                        className="font6x400 mt-1 mb-0"
                                      >
                                        Booked
                                      </p>
                                    </div>
                                    <div className="status-icon-container">
                                      <FaUser
                                        fill={
                                          item?.status == 1 ||
                                          item?.status == 13 ||
                                          item?.status == 4 ||
                                          item?.status == 5
                                            ? "#af031a"
                                            : "gray"
                                        }
                                      />
                                      <p
                                        style={{
                                          color:
                                            item?.status == 1 ||
                                            item?.status == 13 ||
                                            item?.status == 4 ||
                                            item?.status == 5
                                              ? "#af031a"
                                              : "gray",
                                        }}
                                        className="font6x400 mt-1 mb-0"
                                      >
                                        Confirmed
                                      </p>
                                    </div>

                                    <div className="status-icon-container">
                                      <RiEBike2Fill
                                        fill={
                                          item?.status == 13 ||
                                          item?.status == 4 ||
                                          item?.status == 5
                                            ? "#af031a"
                                            : "gray"
                                        }
                                      />
                                      <p
                                        style={{
                                          color:
                                            item?.status == 13 ||
                                            item?.status == 4 ||
                                            item?.status == 5
                                              ? "#af031a"
                                              : "gray",
                                        }}
                                        className="font6x400 mt-1 mb-0"
                                      >
                                        On the Way
                                      </p>
                                    </div>
                                    <div className="status-icon-container">
                                      <IoBag
                                        fill={
                                          item?.status == 4 || item?.status == 5
                                            ? "#af031a"
                                            : "gray"
                                        }
                                      />
                                      <p
                                        style={{
                                          color:
                                            item?.status == 4 ||
                                            item?.status == 5
                                              ? "#af031a"
                                              : "gray",
                                        }}
                                        className="font6x400 mt-1 mb-0"
                                      >
                                        Start
                                      </p>
                                    </div>
                                    <div className="status-icon-container">
                                      <FaThumbsUp
                                        fill={
                                          item?.status == 5 ? "#af031a" : "gray"
                                        }
                                      />
                                      <p
                                        style={{
                                          color:
                                            item?.status == 5
                                              ? "#af031a"
                                              : "gray",
                                        }}
                                        className="font6x400 mt-1 mb-0"
                                      >
                                        Completed
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Link>

                              {![0, 6, 7, 10, -1, 11].includes(
                                item?.status
                              ) && (
                                <>
                                  <Separator />
                                  <div className="d-flex justify-content-between align-items-center py-3">
                                    {isShowStylistInfo(item) ? (
                                      <>
                                        {item?.appointmentitem_set?.some(
                                          (service: any) => {
                                            if (
                                              service?.service?.serviceType
                                                ?.id == 620 ||
                                              item?.stylist?.work_experience ==
                                                100
                                            ) {
                                              return item;
                                            }
                                          }
                                        ) ? (
                                          <div className="booking-order-list-premium d-flex align-items-center gap-3">
                                            {item?.stylist?.profile_picture ? (
                                              <div className="position-relative">
                                                <img
                                                  width={"50px"}
                                                  height={"50px"}
                                                  style={{
                                                    borderRadius: "8px",
                                                  }}
                                                  src={
                                                    item?.stylist
                                                      ?.profile_picture
                                                  }
                                                />
                                                <div className="stylist-star">
                                                  <img src="/static/svg/yellowstar.svg" />
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {item?.stylist
                                                  ?.work_experience === 100 ? (
                                                  <img
                                                    src="/static/img/premium_stylist.png"
                                                    alt="premium_stylist"
                                                  />
                                                ) : (
                                                  <img
                                                    src="/static/img/regular_stylist.png"
                                                    alt="regular_stylist"
                                                  />
                                                )}
                                              </>
                                            )}
                                            <div>
                                              <p className="mb-1 font14x500 textColor3">
                                                {item?.stylist?.user?.first_name?.trim()}
                                              </p>

                                              <p className="mb-0 font12x500 textColor2">
                                                Premium Stylist
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="d-flex align-items-center gap-3">
                                            {item?.stylist?.profile_picture ? (
                                              <img
                                                width={"50px"}
                                                height={"50px"}
                                                style={{ borderRadius: "8px" }}
                                                src={
                                                  item?.stylist?.profile_picture
                                                }
                                              />
                                            ) : (
                                              <>
                                                {item?.stylist
                                                  ?.work_experience === 100 ? (
                                                  <img
                                                    src="/static/img/premium_stylist.png"
                                                    alt="premium_stylist"
                                                  />
                                                ) : (
                                                  <img
                                                    src="/static/img/regular_stylist.png"
                                                    alt="regular_stylist"
                                                  />
                                                )}
                                              </>
                                            )}
                                            <p className="mb-0 font14x500 textColor3">
                                              {item?.stylist?.user?.first_name?.trim()}
                                            </p>
                                          </div>
                                        )}

                                        {/* <div></div> */}
                                      </>
                                    ) : (
                                      <p className="mb-0 font14x400 textColor1">
                                        Beautician will be assigned 2 hours
                                        prior to the booking
                                      </p>
                                    )}
                                  </div>
                                  <Separator />
                                </>
                              )}
                              <div className="d-flex justify-content-between align-items-center mt-2">
                                <div>
                                  <p className="mb-0 font12x400 textColor7">
                                    {moment(item.date).format("ddd, MMM D")},{" "}
                                    {item.timing}
                                  </p>
                                </div>
                                <p className="mb-0 font14x500 textColor3">
                                  {`${
                                    item.customer_discount < 10
                                      ? "Online"
                                      : "CASH"
                                  }`}
                                </p>
                              </div>
                            </Link>
                            <div className="pt-3">
                              <div className="d-flex justify-content-between gap-4">
                                {/* review button */}
                                {item?.rating == -2 ? (
                                  <>
                                    {fn.reviewOrderChecks(item) && (
                                      <button
                                        type="button"
                                        className="btn_outline2 font12x400 textColor1"
                                        style={{
                                          height: "28px",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/myorder?order_id=${item?.id}`,
                                            { state: { divId: "rating" } }
                                          )
                                        }
                                      >
                                        Rate your Experience
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                {/* cancel button */}
                                {fn.cancelOrderChecks(item)?.isShowButton && (
                                  <button
                                    type="button"
                                    className="btn_outline2 font12x400 textColor1"
                                    style={{
                                      height: "28px",
                                    }}
                                    onClick={() => {
                                      if (
                                        item?.status === 1 &&
                                        fn.cancelOrderFeeModalChecks(item)
                                      ) {
                                        // open cancellation fee modal
                                        fn.openModal(
                                          { order: item, fee: true },
                                          "extra_fee"
                                        );
                                      } else {
                                        navigate(`/cancel`, {
                                          state: { order: item },
                                        });
                                      }
                                    }}
                                  >
                                    Cancel
                                  </button>
                                )}

                                {/* cancel button */}
                                {fn.rescheduleOrderChecks(item)
                                  ?.isShowButton && (
                                  <button
                                    type="button"
                                    className="btn_outline2 font12x400 textColor1"
                                    style={{
                                      height: "28px",
                                    }}
                                    onClick={() => {
                                      if (
                                        item?.status === 1 &&
                                        fn.rescheduleOrderFeeModalChecks(item)
                                      ) {
                                        // open reschedule fee modal
                                        fn.openModal(
                                          { order: item, fee: 1 },
                                          "reschedule_extra_fee"
                                        );
                                      } else {
                                        navigate(`/reschedule-slot`, {
                                          state: { order: item },
                                        });
                                      }
                                    }}
                                  >
                                    Reschedule
                                  </button>
                                )}
                                {/* cancel button */}
                                {fn.cancelOrderChecks(item)?.isShowButton &&
                                  fn.rescheduleOrderChecks(item)
                                    ?.isShowButton &&
                                  fn?.getRecommendedServices(item) && (
                                    <Link
                                      to={{
                                        pathname: "/myorder",
                                        search: `?order_id=${item?.id}`,
                                        // hash: "#upgrades",
                                      }}
                                      state={{ divId: "upgrades" }}
                                      type="button"
                                      className="btn_2 font12x400 d-flex align-items-center justify-content-center"
                                      style={{
                                        height: "28px",
                                      }}
                                    >
                                      Upgrade
                                    </Link>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="not_found_in_order d-flex flex-column justify-content-center align-items-center">
                      <h1 className="font20x700">No order found</h1>
                      <button
                        type="button"
                        className="cart_empty_btn font14x400"
                        onClick={() => {
                          navigate("/category-new");
                        }}
                      >
                        Browse categories
                      </button>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <OrdersLoader />
          )}
        </div>
      </div>

      {/* extra fee for cancellation modal */}
      {commonData?.modal && commonData?.check === "extra_fee" && (
        <BottomTopAnimation>
          <ExtraFeeModal state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}

      {/* extra fee for reschedule modal */}
      {commonData?.modal && commonData?.check === "reschedule_extra_fee" && (
        <BottomTopAnimation>
          <FeeModal state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default BookingAndOrders;
