import React, { useContext, useEffect, useState } from "react";
import { showToast } from "../../../../lib/toast/showToast";
import { Auth } from "../../../../utils/context/AuthContext";
import { ClockIcon, StarIcon } from "../../../shared/Icons";
import "./index.css";
import {
  calculateDiscountInPercent,
  getImageURL,
  isUserValid,
} from "../../../../utils/function/function";
import { package99Ids } from "../../../../utils/constant/constant";
import { useNavigate, useParams } from "react-router-dom";
import { BiSolidOffer } from "react-icons/bi";

type PropType = {
  services: any;
  handleClickOnMinusPlus: (val1: any, val2: string) => void;
  setPaymentDetails: (val: any) => void;
  handleScrolledServices: (e: any) => void;
  showServicePageSpecialOffers: boolean;
};

const ChooseFour: React.FC<PropType> = (props) => {
  const {
    services,
    handleClickOnMinusPlus,
    setPaymentDetails,
    handleScrolledServices,
    showServicePageSpecialOffers,
  } = props;
  const { setCartItemsInContext, cartItemsInContext, setNewURLAfterReplace } =
    useContext(Auth);
  const [chooseCatServices, setChooseCatServices] = useState<any>();
  const navigate = useNavigate();
  const { homepageCategory } = useParams();

  const fn = {
    renderServiceNameList: (
      service: any,
      subCatIndex: number,
      serviceIndex: number,
      serviceItem: any
    ) => {
      const splitedNames = service?.name?.split(":");
      if (splitedNames[1]?.includes(" OR ")) {
        return splitedNames[1]?.split(" OR ")?.map((info: any, k: any) => {
          return (
            <div
              key={k}
              className="customCheck form-check d-flex align-items-center gap-2 p-0"
            >
              <input
                type="checkbox"
                id={`${info}_${service.id}`}
                name={info}
                value={info}
                checked={
                  fn.checkServiceChoosedOrNot(service, info) ? true : false
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  fn.handleOnChangeServiceCheckBox(
                    service?.id,
                    subCatIndex,
                    serviceIndex,
                    e.target.value,
                    e.target.checked,
                    +service?.name?.split("Any")[1]?.charAt(1),
                    e,
                    serviceItem
                  );
                }}
              />
              <label
                className="font12x500 textColor3 form-check-label cursor_pointer"
                htmlFor={`${info}_${service.id}`}
              >
                {info}
              </label>
            </div>
          );
        });
      } else if (splitedNames[1]?.includes(" , ")) {
        return splitedNames[1]?.split(" , ")?.map((info: any, k: any) => {
          return (
            <div
              key={k}
              className="customCheck d-flex align-items-center gap-2 form-check p-0"
            >
              <input
                type="checkbox"
                id={`${info}_${service.id}`}
                name={info}
                value={info}
                checked={
                  fn.checkServiceChoosedOrNot(service, info) ? true : false
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  fn.handleOnChangeServiceCheckBox(
                    service?.id,
                    subCatIndex,
                    serviceIndex,
                    e.target.value,
                    e.target.checked,
                    +service?.name?.split("Any")[1]?.charAt(1),
                    e,
                    serviceItem
                  );
                }}
              />
              <label
                className="font12x500 textColor3 form-check-label cursor_pointer"
                htmlFor={`${info}_${service.id}`}
              >
                {info}
              </label>
            </div>
          );
        });
      } else if (splitedNames[1]?.includes(" + ")) {
        return splitedNames[1]?.split(" + ")?.map((info: any, k: any) => {
          return (
            <div
              key={k}
              className="customCheck form-check d-flex align-items-center gap-2 p-0"
            >
              <input
                type="checkbox"
                id={`${info}_${service.id}`}
                name={info}
                value={info}
                checked={
                  fn.checkServiceChoosedOrNot(service, info) ? true : false
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  fn.handleOnChangeServiceCheckBox(
                    service?.id,
                    subCatIndex,
                    serviceIndex,
                    e.target.value,
                    e.target.checked,
                    +service?.name?.split("Any")[1]?.charAt(1),
                    e,
                    serviceItem
                  );
                }}
              />
              <label
                className="font12x500 textColor3 form-check-label cursor_pointer"
                htmlFor={`${info}_${service.id}`}
              >
                {info}
              </label>
            </div>
          );
        });
      } else {
        <></>;
      }
    },
    checkServiceChoosedOrNot: (service: any, info: any): boolean => {
      let isServiceChoosed = service?.chosenService?.some((item: any) => {
        return item?.id === service?.id && item?.label === info;
      });
      return isServiceChoosed;
    },
    handleClickOnAdd: (item: any) => {
      let maxService = +item?.serviceType?.name.split("Any")[1]?.charAt(1);
      if (
        !item?.serviceType?.chosenService ||
        item.serviceType?.chosenService?.length === 0
      ) {
        showToast("Please select a service first", "error");
      } else if (
        item.serviceType?.chosenService?.length > 0 &&
        item.serviceType?.chosenService?.length < maxService
      ) {
        showToast(
          `Please add ${
            maxService - item.serviceType?.chosenService?.length
          } more services`,
          "error"
        );
      } else {
      }
    },
    handleOnChangeServiceCheckBox: (
      serviceId: number,
      subCatIndex: number,
      serviceIndex: number,
      value: string,
      check: boolean,
      maxService: number,
      e: any,
      serviceItem: any
    ) => {
      let allChooseCatServices = [...chooseCatServices];
      let chosenService =
        allChooseCatServices[subCatIndex]?.services[serviceIndex]?.serviceType
          ?.chosenService || [];
      let serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      if (check) {
        if (chosenService?.length >= maxService) {
          e.target.checked = false;
          showToast(`You can select max ${maxService} services`, "error");
        } else {
          e.target.checked = true;
          chosenService.push({
            id: serviceId,
            label: value,
            header: null,
          });
          const isServiceInCart = serviceList.some((item: any) => {
            if (item?.serviceType?.id == serviceItem.serviceType?.id) {
              return true;
            }
          });
          if (isServiceInCart) {
            allChooseCatServices[subCatIndex].services[
              serviceIndex
            ].serviceType.chosenService = chosenService;
            serviceList?.forEach((item: any) => {
              if (item.serviceType.id == serviceItem.serviceType?.id) {
                item.serviceType.chosenService = chosenService;
              }
            });
          } else {
            serviceItem.count = 1;
            serviceItem.added = true;
            serviceItem.isPackage = true;
            serviceItem.isChoosePackage = true;
            serviceItem.special_instructions = [];
            serviceItem.serviceType.chosenService = chosenService;
            allChooseCatServices[subCatIndex].services[
              serviceIndex
            ].serviceType.chosenService = chosenService;
            serviceList.push(serviceItem);
          }
        }
      } else {
        // remove choosen services
        e.target.checked = false;

        // check service exist or not in cart
        const isServiceExist = serviceList?.some((item: any) => {
          return item?.serviceType?.id === serviceItem.serviceType?.id;
        });

        if (isServiceExist) {
          // remove service from cart
          const filteredCart = serviceList?.filter((item: any) => {
            return item.serviceType?.id !== serviceItem.serviceType?.id;
          });
          serviceList = filteredCart;

          // remove chosen service from state
          const filteredService = chosenService?.filter((item: any) => {
            return item?.label !== value;
          });
          allChooseCatServices[subCatIndex].services[
            serviceIndex
          ].serviceType.chosenService = filteredService;
        } else {
          // remove chosen service from state
          const filteredService = chosenService?.filter((item: any) => {
            return item?.label !== value;
          });
          allChooseCatServices[subCatIndex].services[
            serviceIndex
          ].serviceType.chosenService = filteredService;
        }
      }
      setChooseCatServices(allChooseCatServices);

      if (chosenService?.length >= maxService) {
        if (isUserValid()) {
          // original cart updation
          localStorage.setItem("cartItems", JSON.stringify(serviceList));
          setCartItemsInContext(serviceList);

          // updating current location in history
          setNewURLAfterReplace(
            `/services/${homepageCategory}/${serviceItem?.serviceType?.category?.id}/${serviceItem?.serviceType?.id}`
          );
          navigate(
            `/services/${homepageCategory}/${serviceItem?.serviceType?.category?.id}/${serviceItem?.serviceType?.id}`,
            { replace: true }
          );
        } else {
          // temporary cart updation
          fn.navigateToLogin(serviceItem, serviceList);
        }
      }
    },
    navigateToLogin: (item: any, serviceTobeAdded?: any) => {
      // updating current location in history
      navigate(
        `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        { replace: true }
      );

      // navigate to login
      navigate("/otp-login", {
        state: {
          previousURL: `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
          serviceTobeAdded,
        },
      });
    },
  };

  useEffect(() => {
    // add chosen service data into main services array
    const finalService = [...services];
    finalService?.forEach((packages: any) => {
      packages.services.forEach((service: any) => {
        if (cartItemsInContext.length > 0) {
          const isServiceInCart = cartItemsInContext.filter((cartItem: any) => {
            if (cartItem.serviceType.id === service.serviceType.id) {
              return true;
            }
          });
          if (isServiceInCart.length > 0) {
            service.serviceType.chosenService =
              isServiceInCart[0].serviceType.chosenService;
          } else {
            delete service.serviceType.chosenService;
          }
        } else {
          delete service.serviceType.chosenService;
        }
      });
    });
    setChooseCatServices(finalService);
  }, [services, cartItemsInContext]);

  return (
    <>
      <div
        style={{
          paddingBottom: showServicePageSpecialOffers ? "30px" : "0px",
        }}
        onScroll={handleScrolledServices}
        id="all_sub_category"
      >
        {/* rendering all services */}
        {chooseCatServices?.map((item: any, i: number) => {
          return (
            <div
              key={i}
              id={`category_${item?.subCategoryId}`}
              className="single_sub_category mb-3"
            >
              {/* category_name_container */}
              <div className="book-category_name_container">
                <h1 className="mb-0 font14x600 textColor3">
                  {item?.subCategoryName}
                </h1>
              </div>

              {/* services */}
              {item?.services?.map((serviceItem: any, index: number) => {
                return (
                  <div
                    key={index}
                    id={`service_${serviceItem?.serviceType?.id}`}
                    className="services_list"
                  >
                    <div className="service_section">
                      <div className="services_image_wrapper pt-3">
                        <div className="services_image_section d-flex">
                          {serviceItem?.serviceType?.image_1 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_1
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                          {serviceItem?.serviceType?.image_2 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_2
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                          {serviceItem?.serviceType?.image_3 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_3
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                          {serviceItem?.serviceType?.image_4 && (
                            <div className="single_image_service text-center">
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_4
                                )}
                                alt="package_image"
                              />
                            </div>
                          )}
                        </div>

                        <div className="category_info_and_add_container d-grid justify-content-between gap-2">
                          <div>
                            <div className="category_name_container mt-2">
                              <h1 className="mb-0 font14x600 textColor3">
                                {serviceItem?.serviceType?.name?.split(":")[0]}
                              </h1>
                            </div>
                            <div className="d-flex align-items-center mt-2 gap-1 mb-2">
                              <ClockIcon />
                              <p className="mb-0 font12x500 textColor7">{`${serviceItem?.serviceType?.time} mins`}</p>
                            </div>
                            {serviceItem?.serviceType?.commission > 0 ? (
                              <div className="rating_review_container d-flex align-items-center gap-1">
                                <div className="rating_container d-flex align-items-center gap-1">
                                  {/* <i>
                                    <StarIcon
                                      width="12"
                                      height="11"
                                      fill="#838383"
                                    />
                                  </i> */}
                                  <p className="mb-0 textColor7 booked-in-days">
                                    {/* {serviceItem?.serviceType?.commission / 10} */}
                                    {serviceItem?.serviceType?.commission/10}k+
                                    women booked in last 7 days
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="d-flex align-items-center">
                              <p className="mb-0 font12x700 ">{`₹${serviceItem?.cost}`}</p>
                              {serviceItem?.cost !==
                                serviceItem?.serviceType?.cost && (
                                <p className="mb-0 ms-2 font12x700 text-decoration-line-through textColor7">
                                  {`₹${serviceItem?.serviceType?.cost}`}
                                </p>
                              )}

                              {serviceItem?.cost !==
                                serviceItem?.serviceType?.cost && (
                                <p className="ms-1 mb-0 service_discount_tag gap-1 font10x600 d-flex align-items-center">
                                  | <BiSolidOffer />
                                  {calculateDiscountInPercent(
                                    serviceItem?.cost,
                                    serviceItem?.serviceType?.cost
                                  )}
                                  % OFF
                                </p>
                              )}
                            </div>
                          </div>

                          {/** @author Deepanshu Tyagi */}
                          {cartItemsInContext.find((cartItem: any) => {
                            return (
                              cartItem?.serviceType?.id ==
                              serviceItem?.serviceType?.id
                            );
                          }) ? (
                            <>
                              {cartItemsInContext.find((cartItem: any) => {
                                return (
                                  cartItem?.serviceType?.category?.id ==
                                  serviceItem?.serviceType?.category?.id
                                );
                              }) &&
                              package99Ids.includes(
                                serviceItem?.serviceType?.category?.id
                              ) ? (
                                <div className="mt-3 d-flex minus_plus_button_container align-items-center justify-content-center">
                                  <p className="font14x600 textColor1 mb-0">
                                    Added
                                  </p>
                                </div>
                              ) : (
                                <div className="mt-3 d-flex minus_plus_button_container align-items-center justify-content-between">
                                  <button
                                    onClick={() => {
                                      handleClickOnMinusPlus(serviceItem, "-");
                                    }}
                                    className="font14x600 textColor1 d-flex border-0"
                                  >
                                    -
                                  </button>
                                  <p className="font14x600 textColor1 mb-0">
                                    {
                                      cartItemsInContext.find(
                                        (cartItem: any) => {
                                          return (
                                            cartItem?.serviceType?.id ==
                                            serviceItem?.serviceType?.id
                                          );
                                        }
                                      )?.count
                                    }
                                  </p>
                                  <button
                                    onClick={() => {
                                      handleClickOnMinusPlus(serviceItem, "+");
                                    }}
                                    className="font14x600 textColor1 border-0"
                                  >
                                    +
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                fn.handleClickOnAdd(serviceItem);
                              }}
                              className="font14x600 add_button mt-3 textColor1 d-flex align-items-center justify-content-center"
                            >
                              ADD+
                            </button>
                          )}
                          {/** */}
                        </div>
                      </div>

                      <div className="services_container">
                        {fn.renderServiceNameList(
                          serviceItem?.serviceType,
                          i,
                          index,
                          serviceItem
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChooseFour;
