import { useCallback, useEffect } from "react";
import { setOverflowToBodyHidden, setOverflowToBodyUnset } from "../../../utils/function/function";
import SpinLoader from "../Loader/SpinLoader";

const FullPageLoader = () => {
  const callback = useCallback(() => {
    setOverflowToBodyUnset();
  }, []);

  useEffect(() => {
    setOverflowToBodyHidden();
    return () => {
      callback();
    };
  }, []);

  return (
    <div className="modal_wrapper">
      <SpinLoader height="62" width="62" color="#AF031A" />
    </div>
  );
};

export default FullPageLoader;
