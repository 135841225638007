import React from "react";

type proptype = {
  error: any;
};

const InputError: React.FC<proptype> = ({ error }) => {
  return (
    <>
      <p className="errorDanger mt-1 mb-0">{error as string}</p>
    </>
  );
};

export default InputError;
