import { AllLocation } from "./constant";

const AllServeLocation = () => {
  return (
    <>
      <div className="all_location_wrapper">
        {AllLocation?.map((item: any, i: number) => {
          return (
            <p
              key={i}
              className="mb-0 single_city position-relative font14x500 text-white"
            >
              {item?.name}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default AllServeLocation;
