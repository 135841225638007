export const GGoldBenefits = [
  {
    imagePath: "/static/svg/badge.svg",
    alt: "badge",
    heading: "100% moneyback guarantee",
    description:
      "If you save less than your membership price we will refund you the difference",
  },
  {
    imagePath: "/static/svg/star.svg",
    alt: "star",
    heading: "Top rated beauticians",
    description: "Get exclusively trained GetLook beauticians at your service",
  },
];

export const GoldNumbers = [
  {
    imagePath: "/static/svg/money-bill.svg",
    alt: "money_bill",
    heading: "₹26 lakhs",
    description: "Saved by Gold members",
    bgColor: "#EFF7F2",
  },
  {
    imagePath: "/static/svg/user-add.svg",
    alt: "user_add",
    heading: "1 lakh+",
    description: "Happy Gold members",
    bgColor: "#F2F0FE",
  },
  {
    imagePath: "/static/svg/gold-star.svg",
    alt: "gold_star",
    heading: "4.9",
    description: "Average rating of Gold Beauticians",
    bgColor: "#FEF7D9",
  },
  {
    imagePath: "/static/svg/memo-pad.svg",
    alt: "memo_pad",
    heading: "5.6",
    description: "Average bookings per member",
    bgColor: "#FBF3F2",
  },
];
