import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn } from "../../../../../shared/Icons";

const SocialLinks = () => {
  return (
    <>
      <div className="social_links_wrapper">
        <img src="/static/svg/logo_white.svg" width={186} alt="logo" />
        <div className="mt-4 d-flex gap-3 justify-content-center">
          <Link to="https://www.instagram.com/getlook.in/" target="_blank">
            <Instagram />
          </Link>
          <Link to="https://www.facebook.com/getlook.in" target="_blank">
            <Facebook />
          </Link>
          <Link to="https://www.linkedin.com/company/getlook" target="_blank">
            <LinkedIn />
          </Link>
        </div>
      </div>
    </>
  );
};

export default SocialLinks;
