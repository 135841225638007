import { useContext, useEffect, useRef, useState } from "react";
import { Auth } from "../../../utils/context/AuthContext";
import {
  checkingPopupTimeDifference,
  filterHomepageSections,
  setOverflowToBodyHidden,
  setOverflowToBodyUnset,
} from "../../../utils/function/function";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import BottomTopAnimation from "../BottomTopAnimation";
import { CloseIcon } from "../Icons";

const PopupBanner = () => {
  const { homepage_sections } = useContext(Auth);
  const popupBanner = filterHomepageSections(homepage_sections, "popup");
  const popup_banner_close_time = JSON.parse(
    localStorage.getItem("popup_banner_close_time")!
  );
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const popupModalRef = useRef<any>(null);
  const stickerRef = useRef<any>(null);

  const fn = {
    handleClosePopup: () => {
      setShowPopUp(false);
      window.localStorage.setItem(
        "popup_banner_close_time",
        JSON.stringify(moment())
      );
    },
    handleStickerClick: () => {
      setShowPopUp(true);
    },
  };

  useEffect(() => {
    let timeout: any;

    // if (popup_banner_close_time && timeDiff) {
    //   stickerRef.current.style.display = "block";
    // }

    timeout = setTimeout(() => {
      if (!popup_banner_close_time) {
        // timeout = setTimeout(() => {

        popupBanner?.forEach((item: any, i: number) => {
          if (item?.name?.includes("image_popup")) {
            setShowPopUp(true);
          }
        });

        // popupModalRef.current.style.display = "grid";

        // popupBanner?.length > 0 && setOverflowToBodyHidden();
        // }, 2000);

        stickerRef.current.style.display = "block";
      }

      const timeDiff = checkingPopupTimeDifference();
      if (popupBanner?.length > 0 && popupBanner[0]?.active) {
        if (timeDiff && popupModalRef && popupModalRef.current) {
          // popupModalRef.current.style.display = "grid";
          // popupBanner?.length > 0 && setOverflowToBodyHidden();
          setShowPopUp(true);
        }
      }
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [popupBanner]);

  useEffect(() => {
    if (showPopUp) {
      stickerRef.current.style.display = "none";
      popupModalRef.current.style.display = "grid";
      setOverflowToBodyHidden();
    } else {
      setOverflowToBodyUnset();
      stickerRef.current.style.display = "block";
    }
  }, [showPopUp]);

  return (
    <>
      <div ref={stickerRef} className="popup-sticker-wrapper">
        {popupBanner?.map((item: any, i: number) => {
          return (
            <>
              {item?.name?.includes("image_popup") ? (
                <img
                  key={i}
                  src={item?.information}
                  alt="popup-banner"
                  width={50}
                  onClick={fn.handleStickerClick}
                />
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>

      {popupBanner?.length > 0 && showPopUp ? (
        <div ref={popupModalRef} className="home_page_popup_details_page">
          {/* cancel */}
          <div className="cancel-container">
            <button
              onClick={fn.handleClosePopup}
              className="d-flex align-items-center justify-content-center bg-black text-white border-0"
            >
              <CloseIcon width="20" height="20" fill="#fff" />
            </button>
          </div>
          <div className="view_details_container">
            <div className="popup-banner-wrapper">
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <div className="position-relative">
                  {popupBanner.map((item: any, i: number) => {
                    return (
                      <>
                        {item?.name?.includes("image_popup") ? (
                          <Link
                            key={i}
                            to={`/services/${item?.items_required}`}
                            onClick={fn.handleClosePopup}
                          >
                            <img
                              src={item?.category_image}
                              alt="popup-banner"
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PopupBanner;
