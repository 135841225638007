import { useFormik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../../../components/shared/GTM";
import { BackIcon, EditProfile } from "../../../../../components/shared/Icons";
import InputError from "../../../../../components/shared/InputError/InputError";
import InputField from "../../../../../components/shared/InputField";
import InputMobile from "../../../../../components/shared/InputMobile";
import SpinLoader from "../../../../../components/shared/Loader/SpinLoader";
import ProfilePhoto from "../../../../../components/shared/ProfilePhoto";
import useLoading from "../../../../../hooks/useLoading";
import { postUserData } from "../../../../../lib/api functions/user/user";
import { showToast } from "../../../../../lib/toast/showToast";
import { Auth } from "../../../../../utils/context/AuthContext";
import { ProfileViewPage } from "../../../../../utils/metaInfo/metaInfo";
import { userSchema } from "../../../../../validation/validation";
import { UserData } from "./type";

const ViewProfile = () => {
  const { user, setUser } = useContext(Auth);
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [isChangesAndEdit, setIsChangesAndEdit] = useState<any>({
    isEdit: false,
    isChanges: false,
  });
  const initialValues: UserData = {
    name: user.first_name ?? "",
    email: user.email ?? "",
    phone: user.phone ?? "",
    birth_date: user.birth_date ?? "",
    alternate_phone: user.alternate_phone ?? "",
  };

  const fn = {
    checkFieldsEditedOrNot: (info: any) => {
      if (info?.name != user.first_name) {
        setIsChangesAndEdit((prev: any) => {
          return {
            ...prev,
            isChanges: true,
          };
        });
      } else if (info?.email != user?.email) {
        setIsChangesAndEdit((prev: any) => {
          return {
            ...prev,
            isChanges: true,
          };
        });
      } else if (info?.birth_date != user?.birth_date) {
        setIsChangesAndEdit((prev: any) => {
          return {
            ...prev,
            isChanges: true,
          };
        });
      } else if (info?.alternate_phone != user?.alternate_phone) {
        setIsChangesAndEdit((prev: any) => {
          return {
            ...prev,
            isChanges: true,
          };
        });
      } else {
        setIsChangesAndEdit((prev: any) => {
          return {
            ...prev,
            isChanges: false,
          };
        });
      }
    },
  };

  const formik = useFormik<UserData>({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      showLoader();
      let finalObj = {
        ...user,
        type: "profileUpdate",
        first_name: values.name,
        email: user.email === values.email ? undefined : values.email,
        birth_date: values.birth_date,
        alternate_phone: values.alternate_phone,
      };
      const res = await postUserData(finalObj);
      if (res) {
        showToast(`Profile updated`, "success");
        delete finalObj.type;
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            ...finalObj,
            ...res?.data?.data,
            email: values.email,
          })
        );
        setUser({
          ...finalObj,
          ...res?.data?.data,
          email: values.email,
        });
        setIsChangesAndEdit((prev: any) => {
          return {
            ...prev,
            isEdit: false,
          };
        });
        hideLoader();
      }
    },
  });

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="mobile">
        <Helmet>
          <title>{ProfileViewPage.title}</title>
          <meta name="description" content={ProfileViewPage.description} />
          <meta name="keywords" content={ProfileViewPage.keywords} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div className="profile_setup_wrapper">
          {/* view profile header */}
          <div className="header">
            <BackIcon
              width="17"
              height="14"
              fill="#0F0F0F"
              clickHandler={() => navigate(-1)}
            />
            <div className="w-100 text-center">
              <h1 className="mb-0 font16x500 textColor3">
                {isChangesAndEdit?.isEdit ? "Profile Edit" : "Profile View"}
              </h1>
            </div>
            {!isChangesAndEdit?.isEdit && (
              <EditProfile
                width="24"
                height="24"
                fill="#AF031A"
                clickHandler={() =>
                  setIsChangesAndEdit((prev: any) => {
                    return {
                      ...prev,
                      isEdit: true,
                    };
                  })
                }
              />
            )}
          </div>
          <div className="profile_setup_content">
            <form onSubmit={formik.handleSubmit}>
              <ProfilePhoto />
              <div className="mb-3">
                <InputField
                  id="name"
                  label="Name*"
                  type="text"
                  state={formik.values.name}
                  setState={(e) => {
                    formik.setFieldValue("name", e.target.value);
                    fn?.checkFieldsEditedOrNot({
                      name: e.target.value,
                      email: user?.email,
                      birth_date: user?.birth_date,
                      alternate_phone: user?.alternate_phone,
                    });
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                  isDisabled={isChangesAndEdit?.isEdit ? false : true}
                />
                {formik.touched.name && formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>
              <div className="mb-3">
                <InputMobile
                  id="phone"
                  label="Mobile No.*"
                  state={formik.values.phone}
                  setState={(e) => {
                    let isString = e.target.value.match(/[A-z]/g);
                    if (isString) {
                      showToast("Only number is allowed", "error");
                    } else {
                      formik.setFieldValue("phone", e.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  isDisabled={true}
                  max={10}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <InputError error={formik.errors.phone} />
                ) : null}
              </div>
              <div className="mb-3">
                <InputField
                  id="email"
                  label="Email Id*"
                  type="text"
                  state={formik.values.email}
                  setState={(e) => {
                    formik.setFieldValue("email", e.target.value);
                    fn?.checkFieldsEditedOrNot({
                      name: user?.first_name?.trim(),
                      email: e.target.value,
                      birth_date: user?.birth_date,
                      alternate_phone: user?.alternate_phone,
                    });
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                  isDisabled={isChangesAndEdit?.isEdit ? false : true}
                />
                {formik.touched.email && formik.errors.email ? (
                  <InputError error={formik.errors.email} />
                ) : null}
              </div>
              <div className="mb-3">
                <InputField
                  id="birth_date"
                  label="Your Birthday"
                  type="date"
                  state={formik.values.birth_date}
                  setState={(e) => {
                    formik.setFieldValue("birth_date", e.target.value);
                    fn?.checkFieldsEditedOrNot({
                      name: user.first_name,
                      email: user?.email,
                      birth_date: e.target.value,
                      alternate_phone: user?.alternate_phone,
                    });
                  }}
                  onBlur={formik.handleBlur}
                  labelColor="textColor1"
                  isDisabled={isChangesAndEdit?.isEdit ? false : true}
                  max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                />
                {formik.touched.birth_date && formik.errors.birth_date ? (
                  <InputError error={formik.errors.birth_date} />
                ) : null}
                <p className="mb-0 mt-3 font10x400 textColor2">
                  (Please provide your correct birthday and year. We have free
                  services and offers on Birthdays)
                </p>
              </div>
              <div className="mb-3">
                <InputMobile
                  id="alternate_phone"
                  label="Alternate No."
                  state={formik.values.alternate_phone}
                  setState={(e) => {
                    let isString = e.target.value.match(/[A-z]/g);
                    if (isString) {
                      showToast("Only number is allowed", "error");
                    } else {
                      formik.setFieldValue("alternate_phone", e.target.value);
                      fn?.checkFieldsEditedOrNot({
                        name: user?.first_name?.trim(),
                        email: user?.email,
                        birth_date: user?.birth_date,
                        alternate_phone: e.target.value,
                      });
                    }
                  }}
                  onBlur={formik.handleBlur}
                  isDisabled={isChangesAndEdit?.isEdit ? false : true}
                  max={10}
                />
                {formik.touched.alternate_phone &&
                formik.errors.alternate_phone ? (
                  <InputError error={formik.errors.alternate_phone} />
                ) : null}
              </div>
              {isChangesAndEdit?.isEdit && (
                <div className="form_btn_wrapper mt-4 d-flex gap-3">
                  <button
                    type="button"
                    className="btn_outline font14x400 textColor1"
                    onClick={() =>
                      setIsChangesAndEdit((prev: any) => {
                        return {
                          ...prev,
                          isEdit: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </button>
                  {isChangesAndEdit?.isChanges ? (
                    <button type="submit" className="btn_login font14x400">
                      {loading ? (
                        <SpinLoader height="22" width="22" color="#fff" />
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
