import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProfileSection2Menus } from "./constant";
import PopupModal from "../../shared/PopupModal";
import { commonType } from "../../../pages/main/type";
import {
  isUserValid,
  setOverflowToBodyHidden,
  setOverflowToBodyUnset,
} from "../../../utils/function/function";
import { RightChevronIcon } from "../../shared/Icons";
import BottomTopAnimation from "../../shared/BottomTopAnimation";

const ProfileMenusSection2 = () => {
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const navigate = useNavigate();

  const fn = {
    openModal: (check?: string) => {
      setCommonData({
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    logOut: () => {
      // window.localStorage.clear();
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("tracking_cache_timestamp");
      window.localStorage.removeItem("user_activity");
      window.localStorage.removeItem("cartItems");
      window.localStorage.removeItem("userAddress");
      window.location.href = "/";
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="profile_menus_section2_wrapper">
        {ProfileSection2Menus?.map((item: any, i: number) => {
          return (
            <Link key={i} to={item?.link} className="text-decoration-none">
              <div className="section2_individual_menu">
                <div className="d-flex gap-2 align-items-center">
                  <img src={item?.imagePath} alt={item?.alt} />
                  <p className="mb-0 font14x400 textColor3">{item?.label}</p>
                </div>
                <div>
                  <RightChevronIcon width="12" height="12" fill="#AF031A" />
                </div>
              </div>
            </Link>
          );
        })}

        {/*log in / log out */}
        {isUserValid() ? (
          <div
            className="section2_individual_menu"
            onClick={() => fn.openModal("logout")}
          >
            <div className="d-flex gap-2 align-items-center">
              <img src="/static/svg/logout.svg" alt="logout" />
              <p className="mb-0 font14x400 textColor3">Logout</p>
            </div>
            <div>
              <RightChevronIcon width="12" height="12" fill="#AF031A" />
            </div>
          </div>
        ) : (
          <div
            className="section2_individual_menu"
            onClick={() => navigate("/otp-login")}
          >
            <div className="d-flex gap-2 align-items-center">
              <img src="/static/svg/logout.svg" alt="logout" />
              <p className="mb-0 font14x400 textColor3">Login</p>
            </div>
            <div>
              <RightChevronIcon width="12" height="12" fill="#AF031A" />
            </div>
          </div>
        )}
      </div>

      {/* logout modal */}
      {commonData?.modal && commonData?.check === "logout" && (
        <BottomTopAnimation>
          <PopupModal
            state={commonData}
            setState={setCommonData}
            handlerFn={fn.logOut}
          />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default ProfileMenusSection2;
