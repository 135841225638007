import toast from "react-hot-toast";

export const showToast = (
  message: any,
  type: "success" | "error" | "custom"
): any => {
  return toast[type](message, {
    duration: 3000,
    position: "bottom-center",
    className: "toast_container",
    style: {
      fontSize: "12px",
      fontWeight: 500,
      border: "none",
      padding: "0px",
    },
    iconTheme: {
      primary: "#AF031A",
      secondary: "#ffffff",
    },
  });
};
