import React from "react";
import PhoneCode from "../PhoneCode";

type PropType = {
  id: string;
  label?: string;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  state?: string | number;
  setState?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  width?: string;
  border?: string;
  bgColor?: string;
  max?: number;
  labelColor?: string;
};

const InputMobile: React.FC<PropType> = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    isDisabled,
    state,
    setState,
    width,
    border,
    onBlur,
    isReadOnly,
    bgColor,
    max,
    labelColor,
  } = props;

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className={`form-label font12x400 ${
            labelColor ? labelColor : "textColor1"
          } mb-1`}
        >
          {label}
        </label>
      )}

      <div
        className="mobile_input_wrapper d-flex"
        style={{
          width,
          border: border,
          backgroundColor: bgColor,
        }}
      >
        <div className="input-group">
          <PhoneCode />
          <input
            type="tel"
            className="form-control shadow-none input_field"
            id={id}
            placeholder={placeholder ?? ""}
            name={name ?? ""}
            disabled={isDisabled}
            style={{
              cursor: isDisabled ? "no-drop" : "unset",
              backgroundColor: isDisabled ? "#fff" : bgColor,
              color: isDisabled ? "var(--text-color2)" : "var(--text-color3)",
            }}
            value={state}
            onChange={setState}
            onBlur={onBlur}
            readOnly={isReadOnly}
            maxLength={max}
          />
        </div>
      </div>
    </>
  );
};

export default InputMobile;
