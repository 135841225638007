import { Link } from "react-router-dom";
import { isUserValid } from "../../../../../utils/function/function";

const Header = () => {
  return (
    <>
      <div className="desktop_header_wrapper">
        <div className="logo_wrapper">
          <img src="/static/svg/logo.svg" width={111} alt="logo" />
        </div>
        <div className="d-flex gap-3 justify-content-end">
          <a
            href="https://apps.apple.com/in/app/getlook-salon-at-home/id1205081417"
            className="d-flex"
            target="_blank"
          >
            <img src="/static/img/app_store_download.png" alt="app_store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=salon.look.ui&hl=en&gl=US"
            className="d-flex"
            target="_blank"
          >
            <img src="/static/img/play_store_download.png" alt="play_store" />
          </a>
          {!isUserValid() && (
            <Link to="/otp-login">
              <button className="desktop_landing_page_login_btn font16x600">
                Login/Signup
              </button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
