import { useFormik } from "formik";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import { BackIcon } from "../../../components/shared/Icons";
import InputError from "../../../components/shared/InputError/InputError";
import InputMobile from "../../../components/shared/InputMobile";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import useLoading from "../../../hooks/useLoading";
import { otpLogin } from "../../../lib/api functions/common/common";
import { showToast } from "../../../lib/toast/showToast";
import { LoginPage } from "../../../utils/metaInfo/metaInfo";
import { otpLoginSchema } from "../../../validation/validation";
import { otpLoginType } from "./type";

const OtpLogin = () => {
  const [loading, showLoader] = useLoading();
  const { state } = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const phone = sessionStorage.getItem("phone");

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, []);

  const initialValues: otpLoginType = {
    phone: "",
    // phoneCode: "+91",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: otpLoginSchema,
    onSubmit: async (values) => {
      showLoader();
      const res = await otpLogin(values);
      if (res) {
        navigate("/verify-otp", {
          state: { ...state, ...res.data },
          replace: true,
        });
        window.sessionStorage.setItem("phone", formik.values.phone);
      }
    },
  });

  useEffect(() => {
    //persist phone state when click on back button from verify otp page
    phone
      ? formik.setFieldValue("phone", phone)
      : formik.setFieldValue("phone", "");
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="mobile">
        <Helmet>
          <title>{LoginPage.title}</title>
          <meta name="description" content={LoginPage.description} />
          <meta name="keywords" content={LoginPage.keywords} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div className="verify_header">
          <BackIcon
            width="17"
            height="14"
            fill="#0F0F0F"
            clickHandler={() => navigate(-1)}
          />
        </div>
        <div className="otp_screen_wrapper">
          <div>
            <img src="/static/svg/logo.svg" alt="logo" width={175} />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <InputMobile
              id="phone"
              placeholder="Mobile Number"
              state={formik.values.phone}
              setState={(e) => {
                let isString = e.target.value.match(/[A-z]/g);
                if (isString) {
                  showToast("Only number is allowed", "error");
                } else {
                  formik.setFieldValue("phone", e.target.value);
                }
              }}
              onBlur={formik.handleBlur}
              width="290px"
              border="1px solid var(--border-color1)"
              bgColor="#fff"
              max={10}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <InputError error={formik.errors.phone} />
            ) : null}
            <button
              type="submit"
              className="mt-3 btn_login font14x500"
              style={{ width: 290 }}
              disabled={formik.values.phone?.length === 10 ? false : true}
            >
              {loading ? (
                <SpinLoader height="22" width="22" color="#fff" />
              ) : (
                "Continue"
              )}
            </button>
          </form>
          <div>
            <div className="unable-to-login-whatsapp-container">
              <p>Unable to Login</p>
              <Link
                className="unable-to-login-whatsapp"
                to="https://api.whatsapp.com/send/?phone=919513862350&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <img
                  src="/static/svg/whatsapp-menu.svg"
                  alt="whatsapp-logo"
                  width="22"
                  height="22"
                />
                WhatsApp{" "}
              </Link>
            </div>
            <p className="mb-0 font14x400 textColor2">
              By signing in I agree to{" "}
              <a
                href="https://getlook.in/custtnc/"
                target="_blank"
                className="textColor1 text-decoration-none"
              >
                Terms & Conditions
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpLogin;
