import { useEffect, useState } from "react";
import { setOverflowToBodyHidden } from "../../../utils/function/function";
import "./style.css";

type propType = {
  children: any;
};

const BottomTopAnimation: React.FC<propType> = (props) => {
  const { children } = props;
  const [style, setStyle] = useState<any>();

  useEffect(() => {
    setOverflowToBodyHidden();
    setStyle({ transform: "translateY(0%)" });
  }, []);

  return (
    <div style={style} className="bottom_top_animation">
      {children}
    </div>
  );
};

export default BottomTopAnimation;
