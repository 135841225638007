import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "../../../../utils/context/AuthContext";
import {
  filterHomepageSections,
  getServiceById,
  increaseDecreaseItem,
  isUserValid,
  setOverflowToBodyHidden,
} from "../../../../utils/function/function";
import { ClockIcon } from "../../../shared/Icons";
import BottomTopAnimation from "../../../shared/BottomTopAnimation";
import PriceDropServiceInfoModal from "../../Cart/CartItem/PriceDropInfo";
import { commonType } from "../../../../pages/main/type";
import ViewDetails from "../../../shared/ViewServiceDetails";

const NewLaunchServices = () => {
  const {
    homepage_sections,
    setCartItemsInContext,
    cartItemsInContext,
    mainLoading,
  } = useContext(Auth);

  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
    data: undefined,
  });

  const newLaunches = filterHomepageSections(homepage_sections, "new");

  const navigate = useNavigate();
  const bsp = filterHomepageSections(homepage_sections, "bsp");

  const navigateToLogin = (item: any, serviceTobeAdded?: any) => {
    // updating current location in history
    navigate(`/`, { replace: true });

    // navigate to login
    navigate("/otp-login", {
      state: {
        previousURL: `/`,
        serviceTobeAdded,
      },
    });
  };

  const openModal = (serviceId: any) => {
    const serviceData = getServiceById(serviceId);
    setCommonData({
      data: serviceData,
      modal: true,
      check: "view_details",
    });
    setOverflowToBodyHidden();
  };

  const handleClickOnMinusPlus = (item: any, button: string) => {
    // handling increase and descrease of items
    setCartItemsInContext(
      increaseDecreaseItem(item, button, cartItemsInContext)
    );
  };

  const handleClickOnAdd = (item: any) => {
    const paymentDetailsList =
      JSON.parse(`${localStorage.getItem("paymentDetails")}`) || [];
    const serviceList =
      JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

    // please add service info
    if (item.id == "620") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "546") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "547") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "548") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "538") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "521") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "605") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else if (item.id == "497") {
      paymentDetailsList.push(item);
      localStorage.setItem(
        "paymentDetailsList",
        JSON.stringify(paymentDetailsList)
      );
    } else {
      item.count = 1;
      item.added = true;
      item.isService = true;
      serviceList.push(item);

      if (isUserValid()) {
        // checking special offer service is exist or not in cart
        const filteredCart = serviceList?.filter((cartItem: any) => {
          return cartItem?.parentId !== item?.serviceType?.id;
        });

        // original cart updation
        setCartItemsInContext(filteredCart);
        localStorage.setItem("cartItems", JSON.stringify(filteredCart));

        // updating current location in history
        // setNewURLAfterReplace(
        //   `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
        // );
        // navigate(
        //   `/services/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        //   { replace: true }
        // );
      } else {
        // temporary cart updation
        navigateToLogin(item, serviceList);
      }
    }
  };

  return (
    <>
      {newLaunches?.length > 0 && (
        <div className="packages_wrapper mt-2">
          <h2 className="p-0 mb-0 font14x700 text-center">
            Newly Launched Services
          </h2>
          <div className="packages_wrapper_scroll">
            <div className="package_section mt-3">
              {mainLoading ? (
                <div className="d-flex gap-3">
                  <Skeleton width="322px" height="161px" borderRadius="8px" />
                  <Skeleton width="322px" height="161px" borderRadius="8px" />
                </div>
              ) : (
                newLaunches?.map((item: any, i: number) => {
                  return (
                    <div className="new-launch-container">
                      <div>
                        <img
                          className="border_radius_8"
                          src={item?.category_image}
                          alt="new_launches"
                          width={275}
                          height={"auto"}
                        />
                      </div>
                      <div
                        onClick={() =>
                          openModal(+item?.items_required?.split("/")[2])
                        }
                        className="new-launches-info-container"
                      >
                        <p className="mb-1 font14x700 text-white">
                          {item?.name}
                        </p>

                        <div className="new-launch-info-container">
                          <div>
                            <div className="d-flex gap-2 align-items-center">
                              <p className="mb-0 font12x700 textColor3 text-white">
                                {/* original price */}
                                {`₹${
                                  getServiceById(
                                    +item?.items_required?.split("/")[2]
                                  )?.cost
                                }`}
                              </p>
                              {/* cut price */}
                              {getServiceById(
                                +item?.items_required?.split("/")[2]
                              )?.cost !==
                                getServiceById(
                                  +item?.items_required?.split("/")[2]
                                )?.serviceType?.cost && (
                                <p className="mb-0 font12x500 text-decoration-line-through text-white">
                                  {`₹${
                                    getServiceById(
                                      +item?.items_required?.split("/")[2]
                                    )?.serviceType?.cost
                                  }`}
                                </p>
                              )}
                              <p className="font12x500 d-flex align-items-center gap-1 mb-0 text-white">
                                <ClockIcon />
                                {`${
                                  getServiceById(
                                    +item?.items_required?.split("/")[2]
                                  )?.serviceType?.time
                                } mins`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="new-launches-add-button">
                        {/** @author Deepanshu Tyagi */}
                        {cartItemsInContext.find((cartItem: any) => {
                          return (
                            cartItem?.serviceType?.id ===
                            getServiceById(+item?.items_required?.split("/")[2])
                              ?.serviceType?.id
                          );
                        }) ? (
                          <div className="d-flex minus_plus_button_container best-selling-add align-items-center justify-content-between">
                            <button
                              onClick={() => {
                                handleClickOnMinusPlus(
                                  getServiceById(
                                    +item?.items_required?.split("/")[2]
                                  ),
                                  "-"
                                );
                              }}
                              className="font14x600 textColor1 d-flex border-0"
                            >
                              -
                            </button>
                            <p className="font14x600 textColor1 mb-0">
                              {
                                cartItemsInContext.find((cartItem: any) => {
                                  return (
                                    cartItem?.serviceType?.id ==
                                    getServiceById(
                                      +item?.items_required?.split("/")[2]
                                    )?.serviceType?.id
                                  );
                                })?.count
                              }
                            </p>
                            <button
                              onClick={() => {
                                handleClickOnMinusPlus(
                                  getServiceById(
                                    +item?.items_required?.split("/")[2]
                                  ),
                                  "+"
                                );
                              }}
                              className="font14x600 textColor1 border-0"
                            >
                              +
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              handleClickOnAdd(
                                getServiceById(
                                  +item?.items_required?.split("/")[2]
                                )
                              );
                            }}
                            className="font14x600 add_button best-selling-add textColor1 d-flex align-items-center justify-content-center"
                          >
                            ADD+
                          </button>
                        )}
                        {/** */}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}


      {commonData?.modal && commonData?.check === "view_details" && (
        <BottomTopAnimation>
          <ViewDetails state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default NewLaunchServices;
