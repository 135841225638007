const PhoneCode = () => {
  return (
    <>
      <div className="input-group-text border-0 cursor_pointer">
        <p className="mb-0 font14x400 textColor7">+91</p>
      </div>
    </>
  );
};

export default PhoneCode;
