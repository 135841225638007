import React from "react";
import { useNavigate } from "react-router-dom";
import { commonType } from "../../../../../../../pages/main/type";
import { rescheduleChargeId } from "../../../../../../../utils/constant/constant";
import { getServiceById, setOverflowToBodyUnset } from "../../../../../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const FeeModal: React.FC<propType> = (props) => {
  const { state, setState } = props;
  const navigate = useNavigate();

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
    navigateToRescheduleOrder: () => {
      navigate(`/reschedule-slot`, {
        state: { order: state?.data.order, fee: state?.data.fee },
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper">
        <div className="modal_section">
          <p className="mt-3 text-center font16x400 textColor3">
            {`Your appointment is already confirmed. Rescheduling fee of Rs.${
              getServiceById(rescheduleChargeId)?.cost
            }
            will be applied. Do you want to reschedule?`}
          </p>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="button"
              className="btn_outline font14x400 textColor1"
              onClick={fn.setPositionToBody}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn_login font14x400"
              onClick={() => fn.navigateToRescheduleOrder()}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeModal;
