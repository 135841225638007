import { GGoldBenefits } from "../../pages/MyProfile/SubPages/GetlookGold/constant";

const Benefits = () => {
  return (
    <>
      <div className="g_gold_benefits_wrapper">
        <h2 className="mb-0 font16x600 textColor3">Other benefits</h2>
        <div className="g_gold_benefits">
          {GGoldBenefits?.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className="g_gold_single_benefits d-flex gap-3 align-items-start"
              >
                <img src={item?.imagePath} alt={item?.alt} />
                <div className="">
                  <p className="mb-0 font14x600 textColor3">{item?.heading}</p>
                  <p className="mb-0 font12x400 textColor7">
                    {item?.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Benefits;
