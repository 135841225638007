import { useContext, useState } from "react";
import { Auth } from "../../../utils/context/AuthContext";
import {
  calculatePaymentDetails,
  filterHomepageSections,
  getImageURL,
  getServiceById,
  increaseDecreaseItem,
  setOverflowToBodyHidden,
} from "../../../utils/function/function";
import BottomTopAnimation from "../../shared/BottomTopAnimation";
import ViewServiceDetailsCheckout from "./ViewServiceDetailsCheckout";
import { commonType } from "../../../pages/main/type";
import { ClockIcon } from "../../shared/Icons";

type CheckoutServicesProps = {
  setPaymentDetails: (paymentDetails: any) => void;
  setWalletInfo: (walletInfo: any) => void;
};

const CheckoutServices: React.FC<CheckoutServicesProps> = (props) => {
  const { setPaymentDetails, setWalletInfo } = props;
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const { homepage_sections, cartItemsInContext, setCartItemsInContext } =
    useContext(Auth);
  const checkoutServices = filterHomepageSections(
    homepage_sections,
    "checkout"
  );

  const fn = {
    handleServiceAdd: (checkoutService: any) => {
      let cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      let serviceData: any = getServiceById(
        +checkoutService?.items_required?.split("/")[2]
      );
      let tempCartItems = [...cartItems];
      serviceData.count = 1;
      serviceData.added = true;
      serviceData.update = true;
      serviceData.type = "checkout_service";
      tempCartItems.push(serviceData);
      setCartItemsInContext(tempCartItems);
      localStorage.setItem("cartItems", JSON.stringify(tempCartItems));
      const payAmount = calculatePaymentDetails();
      setPaymentDetails(payAmount);
      setWalletInfo((prev: any) => {
        return {
          ...prev,
          isApplyWallet: false,
        };
      });
    },
    handleServiceUndo: (checkoutService: any) => {
      const cartItems =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      const filteredCart = cartItems.filter(
        (item: any) =>
          item?.serviceType?.id !==
          +checkoutService?.items_required?.split("/")[2]
      );
      setCartItemsInContext(filteredCart);
      localStorage.setItem("cartItems", JSON.stringify(filteredCart));
      const payAmount = calculatePaymentDetails();
      setPaymentDetails(payAmount);
    },
    handleClickOnMinusPlus: (checkoutService: any, button: string) => {
      let serviceData: any = getServiceById(
        +checkoutService?.items_required?.split("/")[2]
      );
      // handling increase and decrease of items
      setCartItemsInContext(
        increaseDecreaseItem(serviceData, button, cartItemsInContext)
      );
      const payAmount = calculatePaymentDetails();
      setPaymentDetails(payAmount);
      setWalletInfo((prev: any) => {
        return {
          ...prev,
          isApplyWallet: false,
        };
      });
    },
    handleClickOnImage: (serviceId: any) => {
      const serviceData = getServiceById(serviceId);
      setCommonData({
        data: serviceData,
        modal: true,
        check: "view_details_service_checkout",
      });
      setOverflowToBodyHidden();
    },
  };

  return (
    <>
      {checkoutServices?.length > 0 && (
        <div className="recommendation_wrapper checkoutpage-servic-section">
          <h2 className="mb-0 font16x700">People also availed</h2>
          <div className="checkout_services_section_container mt-3">
            {checkoutServices?.map((item: any, i: number) => {
              return (
                <div className="cart-upgrade-service-container">
                  {item?.serviceImage ? (
                    <img
                      className="cart-upgrade-service-container-img"
                      width={150}
                      height={150}
                      src={getImageURL(item?.category_image)}
                      alt="recommend_service"
                      onClick={() => {
                        fn.handleClickOnImage(
                          +item?.items_required?.split("/")[2]
                        );
                      }}
                    />
                  ) : (
                    <img
                      className="cart-upgrade-service-container-img"
                      width={150}
                      height={150}
                      src={getImageURL(item?.category_image)}
                      alt="recommend_service"
                      onClick={() => {
                        fn.handleClickOnImage(
                          +item?.items_required?.split("/")[2]
                        );
                      }}
                    />
                  )}
                  <div className="best-selling-package-info-container cart-upgrade-service-infor-container">
                    <div>
                      <p className="mb-1 textColor3 font12x600">{item?.name}</p>
                      <div className="best-selling-package-price-container mt-2">
                        <div>
                          <div className="d-flex gap-2 align-items-center">
                            <p className="mb-0 font14x500 textColor3">{`₹${
                              getServiceById(
                                +item?.items_required?.split("/")[2]
                              ).cost
                            }`}</p>
                            <p className="mb-0 font12x400 text-decoration-line-through textColor7">
                              {`₹${
                                getServiceById(
                                  +item?.items_required?.split("/")[2]
                                )?.serviceType?.cost
                              }`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      {/** */}
                      {cartItemsInContext?.find((cartItem: any) => {
                        return (
                          cartItem?.serviceType?.id ===
                            +item?.items_required?.split("/")[2] &&
                          cartItem?.update &&
                          cartItem?.type === "checkout_service"
                        );
                      }) ? (
                        <div className="w-100 d-flex minus_plus_button_container align-items-center justify-content-between">
                          <button
                            onClick={() => {
                              fn.handleClickOnMinusPlus(item, "-");
                            }}
                            className="font14x600 textColor1 d-flex border-0"
                          >
                            -
                          </button>
                          <p className="font14x600 textColor1 mb-0">
                            {
                              cartItemsInContext.find((cartItem: any) => {
                                return (
                                  cartItem?.serviceType?.id ===
                                    +item?.items_required?.split("/")[2] &&
                                  cartItem?.update &&
                                  cartItem?.type === "checkout_service"
                                );
                              })?.count
                            }
                          </p>
                          <button
                            onClick={() => {
                              fn.handleClickOnMinusPlus(item, "+");
                            }}
                            className="font14x600 textColor1 border-0"
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <button
                          className="w-100 font14x600 add_button textColor1 d-flex align-items-center justify-content-center"
                          onClick={() => fn.handleServiceAdd(item)}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                // <div
                //   key={i}
                //   className="single_rec_service"
                //   style={{ width: "auto" }}
                // >
                //   {item?.category_image ? (
                //     <img
                //       src={getImageURL(item?.category_image)}
                //       alt="recommend_service"
                //       onClick={() => {
                //         fn.handleClickOnImage(
                //           +item?.items_required?.split("/")[2]
                //         );
                //       }}
                //     />
                //   ) : (
                //     <img
                //       src="/static/img/no-image.png"
                //       alt="recommend_service"
                //       onClick={() => {
                //         fn.handleClickOnImage(
                //           +item?.items_required?.split("/")[2]
                //         );
                //       }}
                //     />
                //   )}
                //   <div className="rec_service_details">
                //     <p className="mb-1 textColor3 font12x600">{item?.name}</p>
                //     <div>
                //       <div className="mt-2 d-flex justify-content-between align-items-center">
                //         <div>
                //           <p className="mb-0 font14x500 textColor3">{`₹${
                //             item?.information?.split(",")[0]
                //           }`}</p>
                //           <p className="mb-0 font12x400 text-decoration-line-through textColor7">
                //             {`₹${item?.information?.split(",")[1]}`}
                //           </p>
                //         </div>
                //         {cartItemsInContext?.find((cartItem: any) => {
                //           return (
                //             cartItem?.serviceType?.id ===
                //               +item?.items_required?.split("/")[2] &&
                //             cartItem?.update &&
                //             cartItem?.type === "checkout_service"
                //           );
                //         }) ? (
                //           <div className="d-flex minus_plus_button_container align-items-center justify-content-between">
                //             <button
                //               onClick={() => {
                //                 fn.handleClickOnMinusPlus(item, "-");
                //               }}
                //               className="font14x600 textColor1 d-flex border-0"
                //             >
                //               -
                //             </button>
                //             <p className="font14x600 textColor1 mb-0">
                //               {
                //                 cartItemsInContext.find((cartItem: any) => {
                //                   return (
                //                     cartItem?.serviceType?.id ===
                //                       +item?.items_required?.split("/")[2] &&
                //                     cartItem?.update &&
                //                     cartItem?.type === "checkout_service"
                //                   );
                //                 })?.count
                //               }
                //             </p>
                //             <button
                //               onClick={() => {
                //                 fn.handleClickOnMinusPlus(item, "+");
                //               }}
                //               className="font14x600 textColor1 border-0"
                //             >
                //               +
                //             </button>
                //           </div>
                //         ) : (
                //           <button
                //             className="font14x600 add_button textColor1 d-flex align-items-center justify-content-center"
                //             onClick={() => fn.handleServiceAdd(item)}
                //           >
                //             Add
                //           </button>
                //         )}
                //       </div>
                //     </div>
                //   </div>
                // </div>
              );
            })}
          </div>
        </div>
      )}

      {/* view details */}
      {commonData?.modal &&
        commonData?.check === "view_details_service_checkout" && (
          <BottomTopAnimation>
            <ViewServiceDetailsCheckout
              state={commonData}
              setState={setCommonData}
            />
          </BottomTopAnimation>
        )}
    </>
  );
};

export default CheckoutServices;
