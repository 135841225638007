import React, { useContext, useState } from "react";
import Separator from "../../../shared/Separator";
import { filterHomepageSections } from "../../../../utils/function/function";
import { Auth } from "../../../../utils/context/AuthContext";
import SpinLoader from "../../../shared/Loader/SpinLoader";
import { showToast } from "../../../../lib/toast/showToast";

type propType = {
  handleClickOnApplyCoupon: (val: any, val2: boolean) => void;
  loading: boolean;
};

const CouponList: React.FC<propType> = (props) => {
  const { handleClickOnApplyCoupon, loading } = props;
  const { homepage_sections } = useContext(Auth);
  const coupons = filterHomepageSections(homepage_sections, "coupon");
  const [couponCode, setCouponCode] = useState<any>("");
  const appliedCoupon =
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;

  const fn = {
    handleClickOnApply: (coupon: any) => {
      setCouponCode(coupon);
      handleClickOnApplyCoupon(coupon, false);
    },
  };

  return (
    <>
      <div className="coupon_lists mt-2 d-flex flex-column gap-3">
        {coupons?.map((item: any, i: any) => {
          return (
            <div key={i} className="single_coupon">
              <div className="w-100 coupon_details">
                <div className="py-2 d-flex align-items-center justify-content-between">
                  <span className="coupon_code font16x600 textColor3">
                    {item.name}
                  </span>
                  {appliedCoupon?.couponCode == item.name ? (
                    <p className="mb-0 font14x600 textColor1">Applied</p>
                  ) : (
                    <div>
                      {loading && couponCode == item.name ? (
                        <SpinLoader height="16" width="16" color="#AF031A" />
                      ) : (
                        <p
                          onClick={() => {
                            fn.handleClickOnApply(item.name);
                          }}
                          className="mb-0 font14x600 textColor1"
                        >
                          Apply
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <Separator />
                <div className="py-2">
                  <p className="mb-1 font16x400 textColor3">
                    {item?.items_required}
                  </p>
                  <p className="mb-0 font12x400 textColor7">
                    {item?.information}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CouponList;
