import React from "react";
import { commonType } from "../../../../../../../pages/main/type";
import { getImageURL, setOverflowToBodyUnset } from "../../../../../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const StylistPhoto: React.FC<propType> = (props) => {
  const { state, setState } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper" onClick={fn.setPositionToBody}>
        <div className="w-auto p-2 modal_section text-center">
          <img
            className="border_radius_8"
            src={getImageURL(state?.data?.profile_picture)}
            alt="stylist"
            width={200}
            height={200}
          />
        </div>
      </div>
    </>
  );
};

export default StylistPhoto;
