import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Auth } from "../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../utils/function/function";
import { Browser } from "@capacitor/browser";

const Videos = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const videos = filterHomepageSections(homepage_sections, "videos");

  const openVideo = async (url: any) => {
    await Browser.open({ url: url });
  };

  return (
    <>
      {videos?.length > 0 && (
        <div className="videos_wrapper mt-2">
          <h2 className="mb-0 p-0 font14x700 text-center">GetLook Videos</h2>
          <div className="videos_wrapper_scroll">
            <div className="vedio_section mt-3">
              {mainLoading ? (
                <div className="d-flex gap-3">
                  <Skeleton width="300px" height="195px" borderRadius="8px" />
                  <Skeleton width="300px" height="195px" borderRadius="8px" />
                </div>
              ) : (
                videos?.map((item: any, i: number) => {
                  return (
                    <button
                      className="video-container"
                      onClick={() => {
                        openVideo(`${item?.items_required}?autoplay=true`);
                      }}
                    >
                      <img src={`${item?.category_image}`} />
                    </button>
                    // <div key={i} className="single_video">
                    //   {/* <video poster={item?.category_image} controls>
                    //     <source src={item?.items_required} />
                    //   </video> */}
                    //   <iframe
                    //     width="200"
                    //     height="700"
                    //     src={`${item?.items_required}`}
                    //     title="key"
                    //     // frameborder="0"
                    //     // allowfullscreen=""
                    //   ></iframe>

                    // </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Videos;
