import { commonType } from "../../../../pages/main/type";
import {
  getServiceById,
  setOverflowToBodyUnset,
} from "../../../../utils/function/function";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const PriceDropServiceInfoModal: React.FC<propType> = (props) => {
  const { state, setState } = props;

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="modal_wrapper">
        <div className="modal_section">
          <p className="mt-3 mb-2 font14x400 textColor3">
            Applicable only on weekdays. Price may change on weekends
          </p>
          <p className="mb-0 font14x400 textColor3">
            Weekend Price:
            <span className="ms-2 font16x700 textColor3">
              ₹{getServiceById(state?.data?.parentId)?.cost}
            </span>
            <span className="ms-2 font14x400 text-decoration-line-through textColor7">
              ₹{getServiceById(state?.data?.parentId)?.serviceType?.cost}
            </span>
          </p>
          <div className="form_btn_wrapper mt-4 d-flex gap-3">
            <button
              type="button"
              className="w-50 btn_login font14x400"
              onClick={fn.setPositionToBody}
            >
              Okay, Got it
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceDropServiceInfoModal;
