/**
 * @author Deepanshu Tyagi
 */

import { useNavigate } from "react-router-dom";
import Header from "../../../components/shared/Header";
import "./index.css";
import { BsInfoCircle } from "react-icons/bs";
import { LuClock8 } from "react-icons/lu";
import PopupModal from "../../../components/pages/slot/PopupModal";
import { useContext, useEffect, useRef, useState } from "react";
import { commonType } from "../type";
import { getSlot } from "../../../lib/api functions/slot/slot";
import { Auth } from "../../../utils/context/AuthContext";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../../lib/toast/showToast";
import { checkServiceInLocation } from "../../../lib/api functions/address/address";
import {
  calculatePaymentDetails,
  checkingTrackingTimeDifference,
  getDeviceSource,
  getServiceById,
  isUserValid,
  setOverflowToBodyHidden,
} from "../../../utils/function/function";
import {
  premiumId,
  SLOT_TYPE,
  TEMP_SLOT_TYPE,
} from "../../../utils/constant/constant";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import BottomTopAnimation from "../../../components/shared/BottomTopAnimation";
import CheckWeekendCoupon0 from "../../../components/shared/PopupModal/CheckWeekendCoupon0";
import CheckWeekendCoupon1 from "../../../components/shared/PopupModal/CheckWeekendCoupon1";
import PriceDropServiceWeekendModal from "../../../components/shared/PopupModal/PriceDropServiceWeekend";

const Slot = () => {
  const navigate = useNavigate();
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [beauticianInfo, setBeauticianInfo] = useState<any>();
  const [beauticianError, setBeauticianError] = useState<boolean>(false);
  const beauticianErrorRef = useRef<any>(null);
  const { user, trackUserActivity } = useContext(Auth);
  const [slotData, setSlotData] = useState<any>({
    date: moment().format("YYYY-MM-DD"),
    time: "",
    beautician: "",
  });
  const [times, setTimes] = useState<any>({});
  const [isTimesLoader, setIsTimesLoader] = useState<boolean>(true);
  const user_activity = JSON.parse(localStorage.getItem("user_activity")!);
  const premiumService: any = getServiceById(premiumId);
  const [address, setAddress] = useState<any>(
    JSON.parse(`${localStorage.getItem("userAddress")}`)
  );
  const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`) || [];

  const [appliedCoupon, setAppliedCoupon] = useState<any>(
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null
  );

  const fn = {
    openModal: (check?: string) => {
      setCommonData({
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },

    handleGetSlot: async (item: any) => {
      const date = moment(item).format("YYYY-MM-DD");

      setIsTimesLoader(true);
      setTimes({});

      const body = {
        lat: address?.latitude,
        long: address?.longitude,
        city: address?.area?.city,
        date: date,
        phone: user?.phone ?? "",
        total: calculatePaymentDetails()?.grandTotal,
      };
      const res = await getSlot(body);
      if (res) {
        setIsTimesLoader(false);
        setTimes(res?.data);
        fn.isSlotsBlockOfActiveDate(res?.data, date);
      }
    },

    checkWeekendCoupon: () => {
      const date = moment(slotData.date).format("YYYY-MM-DD");

      if (
        moment(date).format("ddd") == "Sun" ||
        moment(date).format("ddd") == "Sat" ||
        moment(date).format("ddd") == "Fri"
      ) {
        if (appliedCoupon && appliedCoupon?.weekend == 0) {
          return true;
        } else if (appliedCoupon && appliedCoupon?.weekend == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    checkPriceDropServicesForWeekends: () => {
      const date = moment(slotData.date).format("YYYY-MM-DD");
      if (
        moment(date).format("ddd") === "Sun" ||
        moment(date).format("ddd") === "Sat" ||
        moment(date).format("ddd") === "Fri"
      ) {
        return cartItems?.some((item: any) => {
          return (
            item?.serviceType?.isPackage === 99 && item?.serviceType?.cost_promotion);
        });
      } else {
        return false;
      }
    },
    handleClickOnDate: async (item: any) => {
      fn.handleGetSlot(item);
    },

    handleClickOnRemove: async () => {
      localStorage.removeItem("coupon_applied");

      localStorage.setItem("slotInfo", `${JSON.stringify(slotData)}`);
      navigate("/checkout");
      fn.userTracking();
    },

    handleClickOnYesOn1: async () => {
      localStorage.setItem("slotInfo", `${JSON.stringify(slotData)}`);
      navigate("/checkout");
      fn.userTracking();

      // const discount = appliedCoupon?.discount / 2

      // localStorage.setItem(
      //   "coupon_applied",
      //   JSON.stringify({
      //     ...appliedCoupon,
      //     discount: discount,
      //   })
      // );

      // setAppliedCoupon({
      //   ...appliedCoupon,
      //   discount: discount,
      // })
    },

    getDateOfNextDays: (nextDays: number): any => {
      const dates = [];
      dates.push(moment());
      for (let day = 1; day <= nextDays; day++) {
        dates.push(moment().add(day, "days"));
      }
      return dates;
    },
    handleGetPremiumBeatucian: async () => {
      const latLng = {
        lat: address?.latitude,
        long: address?.longitude,
      };
      const res = await checkServiceInLocation({
        ...latLng,
        phone: user?.phone ?? "",
      });
      if (res) {
        setBeauticianInfo(res?.data);
        if (res?.data?.premium == 0) {
          setSlotData((prev: any) => {
            return {
              ...prev,
              beautician: "regular",
            };
          });
        }
      }
    },
    handleClickOnProceed: () => {
      if (!slotData?.beautician) {
        setBeauticianError(true);
        showToast("Please select Regular/Premium Beautician", "error");
        beauticianErrorRef.current?.scrollIntoView(true);
      } else if (!slotData?.date) {
        showToast("Please select date", "error");
      } else if (!slotData?.time) {
        showToast("Please select time", "error");
      } else if (fn.checkWeekendCoupon()) {
        if (appliedCoupon && appliedCoupon?.weekend == 0) {
          setCommonData({
            modal: true,
            check: "check-weekend0",
            data: {
              msg: "Applied coupon not applicable on weekend. Do you want to remove the coupon?",
            },
          });
        } else if (appliedCoupon && appliedCoupon?.weekend == 1) {
          setCommonData({
            modal: true,
            check: "check-weekend1",
            data: {
              msg: "You will get only 50% of coupon value on weekends. Do you want to continue?",
            },
          });
        }
      } else if (fn.checkPriceDropServicesForWeekends()) {
        setCommonData({
          modal: true,
          check: "priceDropServicesForWeekends",
          data: {
            msg: "Added services not applicable on weekend",
          },
        });
      } else {
        localStorage.setItem("slotInfo", `${JSON.stringify(slotData)}`);
        navigate("/checkout");
        fn.userTracking();
      }
    },
    handleClickOnTime: (item: any, surge: any, i: any) => {
      let slotNumber = 0;
      if (i == 0 || i == 1) {
        slotNumber = 1;
      }
      if (i == 2 || i == 3) {
        slotNumber = 2;
      }
      if (i == 4 || i == 5) {
        slotNumber = 3;
      }
      setSlotData((prev: any) => {
        return {
          ...prev,
          time: item,
          surge: surge,
          slotNumber: slotNumber,
        };
      });
    },
    userTracking: () => {
      // user tracking
      if (isUserValid()) {
        const trackingTimeDiff = checkingTrackingTimeDifference();
        if (trackingTimeDiff) {
          // removing user activity after 6 hours
          window.localStorage.removeItem("user_activity");

          trackUserActivity({
            type: SLOT_TYPE,
            tempType: TEMP_SLOT_TYPE,
            area_id: localStorage.getItem("area_id")!,
            source: getDeviceSource(),
          });

          window.localStorage.setItem(
            "tracking_cache_timestamp",
            JSON.stringify(moment())
          );
        } else {
          if (user_activity) {
            if (TEMP_SLOT_TYPE > user_activity?.tempType) {
              trackUserActivity({
                ...user_activity,
                type: SLOT_TYPE,
                tempType: TEMP_SLOT_TYPE,
                slot: slotData?.slotNumber,
                date: slotData?.date,
              });
            }
          }
        }
      }
    },
    handleClickOnBack: () => {
      localStorage.removeItem("slotInfo");
      navigate(-1);
    },
    isSlotsBlockOfActiveDate: (slotsToBeCheck: any, date: any) => {
      const blockSlot: any = [];
      const slots = Object.keys(slotsToBeCheck);
      slots.forEach((item: any) => {
        if (
          slotsToBeCheck[item][0] == 0 ||
          (moment().isSame(date, "date") &&
            moment(moment(item.split("-")[0], "LT")).isBefore(moment()))
        ) {
          blockSlot.push(item);
        }
      });
      if (blockSlot.length == slots.length) {
        if (date == moment(fn.getDateOfNextDays(6)[6]).format("YYYY-MM-DD")) {
          showToast("There is no slots avalable", "error");
        } else {
          setSlotData((prev: any) => {
            return {
              ...prev,
              date: moment(date).add(1, "days").format("YYYY-MM-DD"),
            };
          });
          fn.handleClickOnDate(
            moment(date).add(1, "days").format("YYYY-MM-DD")
          );
        }
      }
    },
  };

  useEffect(() => {
    if (address) {
      fn.handleClickOnDate(moment().format("YYYY-MM-DD"));
      fn.handleGetPremiumBeatucian();
    }
  }, [address]);

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);

    gtm.page();
    ReactPixel.pageView();
    ReactPixel.trackCustom("Schedule", "");
  }, []);

  // check cart is empty or not
  useEffect(() => {
    localStorage.removeItem("previousDues");
    cartItems?.length === 0 && navigate("/");
  }, []);

  return (
    <>
      <div className="mobile">
        <div className="slot_page">
          <Header
            label="Select Date & Time"
            navigate={() => fn.handleClickOnBack()}
          />
          <div ref={beauticianErrorRef} className="beautician_section bg-white">
            <div className="select_beautician_text_container d-flex align-items-center justify-content-between">
              <h1 className="mb-0 font16x700">Select Beautician</h1>
              {beauticianInfo?.premium == 1 && (
                <button
                  onClick={() => {
                    fn.openModal("modal");
                  }}
                  className="textColor1 border-0 bg-white"
                >
                  <BsInfoCircle />
                </button>
              )}
            </div>
            <div className="beauticians_container d-flex">
              <button
                className="beautician_container bg-white border-0"
                onClick={() => {
                  setSlotData((prev: any) => {
                    return {
                      ...prev,
                      beautician: "regular",
                    };
                  });
                  setBeauticianError(false);
                }}
              >
                <div
                  className={`image_container ${
                    slotData.beautician == "regular"
                      ? "beautician_selected"
                      : ""
                  }`}
                >
                  <img src="/static/svg/regular.svg" alt="regular_stylist" />
                </div>
                <p className="font12x600 mt-3 mb-0  textColor1 text-center">
                  REGULAR
                </p>
              </button>
              {beauticianInfo?.premium == 1 ? (
                <button
                  className="beautician_container position-relative bg-white border-0"
                  onClick={() => {
                    setSlotData((prev: any) => {
                      return {
                        ...prev,
                        beautician: "premium",
                        premiumBeauticianCharge: premiumService?.cost,
                      };
                    });
                    setBeauticianError(false);
                  }}
                >
                  <div
                    className={`image_container ${
                      slotData.beautician == "premium"
                        ? "beautician_selected"
                        : ""
                    }`}
                  >
                    <img src="/static/svg/premium.svg" alt="premium_stylist" />
                  </div>
                  <p className="font12x600 mt-3 mb-0 textColor1 text-center">
                    PREMIUM
                  </p>
                  <div className="yellow_star_container">
                    <img src="/static/svg/yellowstar.svg" />
                  </div>
                  <div className="beautician_prime_price_container">
                    <p className="font10x600 textColor1 d-flex align-items-center justify-content-center mb-0">
                      {`₹ ${premiumService?.cost}`}
                    </p>
                  </div>
                </button>
              ) : (
                ""
              )}
            </div>
            {/* beautician select error */}
            {/* {beauticianError && (
              <p className="errorDanger font12x500 pb-3 mb-0">
                Please select beautician
              </p>
            )} */}
          </div>
          <div className="slot_section bg-white">
            <div className="time_slot_text_container d-flex align-items-center">
              <div className="textColor1 d-flex">
                <LuClock8 />
              </div>
              <h1 className="mb-0 font16x600 ms-2">Time Slot</h1>
            </div>
            <div className="slot_dates_container d-flex align-items-center">
              {fn.getDateOfNextDays(6).map((item: any, i: number) => {
                return (
                  <button
                    onClick={() => {
                      setSlotData((prev: any) => {
                        return {
                          ...prev,
                          date: moment(item).format("YYYY-MM-DD"),
                        };
                      });
                      fn.handleClickOnDate(item);
                    }}
                    key={i}
                    className={`slot_date_container ${
                      slotData.date == moment(item).format("YYYY-MM-DD")
                        ? "slot_date_selected"
                        : ""
                    }`}
                  >
                    <p className="mb-1 font16x400 textColor1">
                      {moment(item).format("ddd")}
                    </p>
                    <p className="mb-0 font16x400 textColor3">
                      {moment(item).format("MMM DD")}
                    </p>
                  </button>
                );
              })}
            </div>
            <div className="slots">
              <div className="mt-3">
                <p className="mb-2 font16x600 textColor3">
                  Select start time of service
                </p>
                <p className="mb-0 font12x400 textColor7">
                  Note: Beautician will arrive within this time
                </p>
              </div>
              {isTimesLoader ? (
                <div className="slot_times_loaders_container d-grid">
                  <Skeleton className="time_loader" />
                  <Skeleton className="time_loader" />
                  <Skeleton className="time_loader" />
                  <Skeleton className="time_loader" />
                </div>
              ) : (
                <>
                  {Object.keys(times).length > 0 ? (
                    <div className="slot_times_container d-grid">
                      {Object.keys(times).map((item: any, i: any) => {
                        return (
                          <div
                            key={i}
                            className="slot_time_container position-relative"
                          >
                            <button
                              className={`font14x400 textColor7 ${
                                slotData.time == item
                                  ? "slot_time_selected"
                                  : ""
                              }`}
                              onClick={() => {
                                fn.handleClickOnTime(item, times[item][1], i);
                              }}
                              disabled={
                                times[item][0] == 0 ||
                                (moment().isSame(slotData?.date, "date") &&
                                  moment(
                                    moment(item.split("-")[0], "LT")
                                  ).isBefore(moment()))
                                  ? true
                                  : false
                              }
                            >
                              {item}
                            </button>
                            {times[item][1] > 0 ? (
                              <div className="time_prime_price_container red_time_prime_price_container">
                                <p className="font10x600 textColor1 d-flex align-items-center justify-content-center mb-0">
                                  +₹{times[item][1]}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {times[item][1] < 0 ? (
                              <div className="time_prime_price_container green_time_prime_price_container">
                                <p className="font10x600 textColor1 d-flex align-items-center justify-content-center mb-0">
                                  -₹{times[item][1]}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="mb-0 font16x400 mt-3">
                      No slots available for this date
                    </p>
                  )}
                </>
              )}
            </div>
            <div
              className="py-2 slot_alert"
              style={{ backgroundColor: "var(--bg-color9)" }}
            >
              <p className="mb-0 font12x500 textColor1">
                We will confirm the exact service start time well in advance
              </p>
            </div>
          </div>
        </div>

        {/* proceed btn */}
        <div className="fix_section_at_bottom">
          <button
            type="button"
            className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
            onClick={() => {
              fn.handleClickOnProceed();
            }}
          >
            Proceed
          </button>
        </div>
      </div>

      {/* premium beautician info modal */}
      {commonData?.modal && commonData?.check === "modal" && (
        <BottomTopAnimation>
          <PopupModal setState={setCommonData} />
        </BottomTopAnimation>
      )}

      {/* check weekend 0 */}
      {commonData?.modal && commonData?.check === "check-weekend0" && (
        <BottomTopAnimation>
          <CheckWeekendCoupon0
            state={commonData}
            setState={setCommonData}
            handleClickOnRemove={fn?.handleClickOnRemove}
          />
        </BottomTopAnimation>
      )}

      {/* check weekend 1 */}
      {commonData?.modal && commonData?.check === "check-weekend1" && (
        <BottomTopAnimation>
          <CheckWeekendCoupon1
            state={commonData}
            setState={setCommonData}
            handleClickOnYesOn1={fn?.handleClickOnYesOn1}
          />
        </BottomTopAnimation>
      )}

      {/* price drop service modal*/}
      {commonData?.modal &&
        commonData?.check === "priceDropServicesForWeekends" && (
          <BottomTopAnimation>
            <PriceDropServiceWeekendModal
              state={commonData}
              setState={setCommonData}
            />
          </BottomTopAnimation>
        )}
    </>
  );
};

export default Slot;
