import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Auth } from "../../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../../utils/function/function";

const DesktopBrands = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const brands = filterHomepageSections(homepage_sections, "brands");

  return (
    <>
      <div className="desktop_brands_wrapper">
        <h2 className="mb-0 font30x600 textColor1">
          We use best Brands in 1-Time use packs
        </h2>
        <div className="desktop_brands_section">
          {mainLoading ? (
            <>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
              <div>
                <Skeleton className="w-100" height="170px" borderRadius="8px" />
              </div>
            </>
          ) : (
            brands?.map((item: any, i: number) => {
              return (
                <div key={i} className="desktop_single_brand text-center">
                  <img
                    src={item?.category_image}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default DesktopBrands;
