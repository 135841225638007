import React from "react";
import "./index.css";
import { CloseIcon } from "../../../shared/Icons";
import { commonType } from "../../../../pages/main/type";
import { getServiceById, setOverflowToBodyUnset } from "../../../../utils/function/function";
import { premiumId } from "../../../../utils/constant/constant";

type PropType = {
  setState: (val: commonType) => void;
};

const PopupModal: React.FC<PropType> = (props) => {
  const { setState } = props;
  const premiumService: any = getServiceById(premiumId);

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
  };

  return (
    <>
      <div className="mobile">
        <div className="modal_wrapper">
          <div className="modal_section">
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0 text-center font14x600 textColor3">
                Premium Beautician Features
              </p>
              <button
                onClick={fn.setPositionToBody}
                className="border-0 bg-white"
              >
                <CloseIcon width="11" height="10" fill="#0f0f0f" />
              </button>
            </div>
            <div className="image_container mt-2 d-flex gap-3">
              <img
                className="w-100"
                src={premiumService?.serviceType?.image_1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupModal;
