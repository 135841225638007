import React, { useContext, useState } from "react";
import { commonType } from "../../../pages/main/type";
import { Auth } from "../../../utils/context/AuthContext";
import { setOverflowToBodyHidden, setOverflowToBodyUnset } from "../../../utils/function/function";
import MapView from "../../pages/Home/Location/ChooseLocation";
import SearchLocation from "../../pages/Home/Location/SearchLocation";
import BottomTopAnimation from "../BottomTopAnimation";
import { CloseIcon } from "../Icons";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const CityNotMatched: React.FC<propType> = (props) => {
  const { state, setState } = props;
  const { latitude, longitude } = useContext(Auth);
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [latLng, setLatLng] = useState<any>({
    lat: latitude,
    long: longitude,
  });
  const city: any = JSON.parse(`${localStorage.getItem("city")}`);

  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
  };

  return (
    <>
      <div className="details_modal_wrapper">
        {/* cancel */}
        <div className="cancel_modal_wrapper">
          <button
            onClick={fn.setPositionToBody}
            className="d-flex align-items-center justify-content-center bg-black text-white border-0"
          >
            <CloseIcon width="20" height="20" fill="#fff" />
          </button>
        </div>

        <div className="details_container">
          <h1 className="mb-2 font16x600 textColor3">{`Selected location is outside ${city}`}</h1>
          <p className="font14x400 textColor3">
            Please change your location to continue booking the services in cart
            or visit our homepage to explore services at the new location
          </p>
          <button
            type="button"
            className="btn_login font14x400"
            onClick={() => fn.openModal(undefined, "search_location")}
          >
            Change your location
          </button>
        </div>
      </div>

      {commonData?.modal && commonData?.check === "search_location" && (
        <BottomTopAnimation>
          <SearchLocation
            state={commonData}
            setState={setCommonData}
            setLatLng={setLatLng}
          />
        </BottomTopAnimation>
      )}

      {/* search location */}
      {commonData?.modal && commonData?.check === "choose_location" && (
        <MapView propsLatLng={latLng} setState={setCommonData} />
      )}
    </>
  );
};

export default CityNotMatched;
