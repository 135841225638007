import React, { useState } from "react";
import { commonType } from "../../../pages/main/type";
import {
  calculateDiscountInPercent,
  getImageURL,
  setOverflowToBodyHidden,
} from "../../../utils/function/function";
import BottomTopAnimation from "../../shared/BottomTopAnimation";
import { StarIcon } from "../../shared/Icons";
import Separator from "../../shared/Separator";
import ViewDetails from "../../shared/ViewServiceDetails";
import "./index.css";
import { BiSolidOffer } from "react-icons/bi";

type PropType = {
  services: any;
  cartItemsInContext: any;
  handleClickOnMinusPlus: (val1: any, val2: string) => void;
  handleClickOnAdd: (val1: any) => void;
};

const AllProducts: React.FC<PropType> = (props) => {
  const {
    services,
    cartItemsInContext,
    handleClickOnMinusPlus,
    handleClickOnAdd,
  } = props;

  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
  };

  return (
    <>
      <div id="all_sub_category">
        {/* rendering all services */}
        {services?.map((item: any, i: number) => {
          return (
            <div
              id={`category_${item?.subCategoryId}`}
              key={i}
              className="single_sub_category mb-3"
            >
              {/* category_name_container */}
              <div className="book-category_name_container d-flex align-items-center justify-content-between">
                <div>
                  <h1 className="mb-0 font14x600 textColor3">
                    {item?.subCategoryName}
                  </h1>
                </div>
              </div>

              {/* services */}
              {item?.services?.map((serviceItem: any, index: number) => {
                return (
                  <div key={index}>
                    <div
                      id={`service_${serviceItem?.serviceType?.id}`}
                      className="services_list"
                    >
                      <div className="service_container">
                        <div className="service_info_and_add_container">
                          {serviceItem?.serviceType?.recommended && (
                            <p className="mb-2 service_tag font10x600">
                              {serviceItem?.serviceType?.recommended}
                            </p>
                          )}
                          <h1 className="mb-0 font14x600 textColor3">
                            {serviceItem?.serviceType?.name?.split(":")[0]}
                          </h1>
                          <div className="d-flex align-items-center mt-2 mb-0 gap-1">
                            <p className="mb-0 font12x500 textColor7">
                              {`${serviceItem?.serviceType?.time} ml`}
                            </p>
                          </div>
                          <div className="rating_review_container pt-1 d-flex align-items-center gap-1">
                            {serviceItem?.serviceType?.commission !== -1 && (
                              <div className="rating_container d-flex align-items-center gap-1">
                                {/* <i>
                                  <StarIcon
                                    width="12"
                                    height="11"
                                    fill="#838383"
                                  />
                                </i> */}
                                <p className="mb-0 mt-0 font12x700 textColor7 booked-in-days mb-0">
                                  {/* {serviceItem?.serviceType?.commission / 10} */}
                                  {serviceItem?.serviceType?.commission}k+ women
                                  purchased in last 7 days
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="w-100 price_and_time_container d-flex align-items-center pb-0">
                            <p className="mb-0 font12x700 textColor3">
                              {`₹${serviceItem?.cost}`}
                            </p>
                            {serviceItem?.cost !==
                              serviceItem?.serviceType?.cost && (
                              <p className="mb-0 ms-2 font12x700 text-decoration-line-through textColor7">
                                {`₹${serviceItem?.serviceType?.cost}`}
                              </p>
                            )}
                            {serviceItem?.cost !==
                              serviceItem?.serviceType?.cost && (
                              <p className="ms-1 mb-0 service_discount_tag gap-1 font10x600 d-flex align-items-center">
                                | <BiSolidOffer />
                                {calculateDiscountInPercent(
                                  serviceItem?.cost,
                                  serviceItem?.serviceType?.cost
                                )}
                                % OFF
                              </p>
                            )}
                          </div>
                          <div className="description_container">
                            <ul className="mb-0 ps-3">
                              {serviceItem?.serviceType?.benefit
                                ?.split("+")
                                ?.slice(0, 2)
                                ?.map((item: string, i: number) => {
                                  return (
                                    <li
                                      key={i}
                                      className="mb-1 font12x400 textColor7"
                                    >
                                      {item?.trim()}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="view_details_container">
                            <button
                              onClick={() =>
                                fn.openModal(serviceItem, "view_details")
                              }
                              className="font14x600 bg-white border-0 textColor1"
                            >
                              View Details
                            </button>
                          </div>
                        </div>
                        <div className="ms-4">
                          <div className="service_image position-relative overflow-hidden">
                            {serviceItem?.serviceType?.image_1 && (
                              <img
                                className="border_radius_8"
                                src={getImageURL(
                                  serviceItem?.serviceType?.image_1
                                )}
                                alt="service"
                              />
                            )}
                          </div>
                          {/** @author Deepanshu Tyagi */}
                          {cartItemsInContext.find((cartItem: any) => {
                            return (
                              cartItem?.serviceType?.id ===
                              serviceItem?.serviceType?.id
                            );
                          }) ? (
                            <div className="d-flex mt-2 minus_plus_button_container align-items-center justify-content-between">
                              <button
                                onClick={() => {
                                  handleClickOnMinusPlus(serviceItem, "-");
                                }}
                                className="font14x600 textColor1 d-flex border-0"
                              >
                                -
                              </button>
                              <p className="font14x600 textColor1 mb-0">
                                {
                                  cartItemsInContext.find((cartItem: any) => {
                                    return (
                                      cartItem?.serviceType?.id ==
                                      serviceItem?.serviceType?.id
                                    );
                                  })?.count
                                }
                              </p>
                              <button
                                onClick={() => {
                                  handleClickOnMinusPlus(serviceItem, "+");
                                }}
                                className="font14x600 textColor1 border-0"
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                handleClickOnAdd(serviceItem);
                              }}
                              className="font14x600 mt-2 add_button textColor1 d-flex align-items-center justify-content-center"
                            >
                              ADD+
                            </button>
                          )}
                          {/** */}
                        </div>
                      </div>
                    </div>
                    {/* <Separator /> */}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* view details */}
      {commonData?.modal && commonData?.check === "view_details" && (
        <BottomTopAnimation>
          <ViewDetails state={commonData} setState={setCommonData} />
        </BottomTopAnimation>
      )}
    </>
  );
};

export default AllProducts;
