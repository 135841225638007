import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useLoading from "../../../../../../hooks/useLoading";
import { review } from "../../../../../../lib/api functions/order/order";
import { showToast } from "../../../../../../lib/toast/showToast";
import { reviewTitles } from "../../../../../../utils/constant/constant";
import {
  getDeviceSource,
  getRating,
} from "../../../../../../utils/function/function";
import { reviewSchema } from "../../../../../../validation/validation";
import { StarIcon } from "../../../../../shared/Icons";
import InputError from "../../../../../shared/InputError/InputError";
import SpinLoader from "../../../../../shared/Loader/SpinLoader";
import Separator from "../../../../../shared/Separator";

type PropType = {
  singleOrder: any;
  getSingleOrderFn: () => void;
};

type ReviewType = {
  rating: number;
  description: string;
  title: string[];
};

const OrderRating: React.FC<PropType> = (props) => {
  const { singleOrder, getSingleOrderFn } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const { state } = useLocation();

  const initialValues: ReviewType = {
    rating: 0,
    description: "",
    title: [],
  };

  const fn = {
    updateReviewTitles: (value: string) => {
      if (formik.values.title?.includes(value)) {
        const filteredTitle = formik.values.title?.filter((item: any) => {
          return item !== value;
        });
        formik.setFieldValue("title", filteredTitle);
      } else {
        formik.setFieldValue("title", [...formik.values.title, value]);
      }
    },
  };

  const formik = useFormik<ReviewType>({
    initialValues,
    validationSchema: reviewSchema,
    onSubmit: async (values) => {
      showLoader();
      let finalObj = {
        id: singleOrder?.id,
        title: formik.values.title.join(","),
        description: values.description,
        rating: values.rating,
        stylist_username: singleOrder.stylist.user.username,
        source: getDeviceSource(),
      };

      const res = await review(finalObj);
      if (res) {
        showToast(`Order reviewed`, "success");
        getSingleOrderFn();
        hideLoader();
      }
    },
  });

  // scroll to rating section
  useEffect(() => {
    if (state?.divId) {
      let rating = document.getElementById(state?.divId);
      rating?.scrollIntoView(true);
    }
  }, []);

  return (
    <>
      {/* before rating */}
      {[2, 5, -1].includes(singleOrder?.status) &&
        ![-1, 0, 1, 7].includes(singleOrder?.rating) && (
          <div className="rating_wrapper" id="rating">
            <div className="before_rated">
              <h2 className="font14x700 textColor3">Rate this order</h2>
              <Separator />
              <form onSubmit={formik.handleSubmit}>
                {/* no. of rating */}
                <div className="d-flex gap-2 mt-3">
                  {[...Array(5)].map((_, i: number) => {
                    return (
                      <div
                        key={i}
                        className={`${
                          formik.values.rating <= i
                            ? "ratings_unselected"
                            : "ratings"
                        } cursor_pointer`}
                        onClick={() => {
                          formik.setFieldValue("rating", i + 1);
                        }}
                      >
                        <p
                          className={`${
                            formik.values.rating <= i
                              ? "textColor7"
                              : "textColor1"
                          } mb-0 font12x400 d-flex align-items-center gap-1`}
                        >
                          {i + 1}
                          <StarIcon
                            width="12"
                            height="11"
                            fill={`${
                              formik.values.rating <= i ? "#838383" : "#AF031A"
                            }`}
                          />
                        </p>
                      </div>
                    );
                  })}
                </div>

                {/* review title */}
                <div className="mt-3 review_titles_wrapper">
                  {reviewTitles?.map((item: string, i: number) => {
                    return (
                      <button
                        type="button"
                        key={i}
                        className={`${
                          formik.values.title?.includes(item)
                            ? "single_review_title_selected"
                            : "single_review_title"
                        } font12x400`}
                        onClick={() => fn.updateReviewTitles(item)}
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>

                {/* review description */}
                <div className="mt-3 rating_text">
                  <textarea
                    rows={3}
                    className="w-100"
                    placeholder="Write a review for this order."
                    value={formik.values.description}
                    onChange={(e) =>
                      formik.setFieldValue("description", e.target.value)
                    }
                  ></textarea>
                  {formik.errors.description ? (
                    <InputError error={formik.errors.description} />
                  ) : null}
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn_2 font14x400 submit_review"
                    style={{
                      height: "32px",
                      borderRadius: 8,
                    }}
                  >
                    {loading ? (
                      <SpinLoader height="22" width="22" color="#fff" />
                    ) : (
                      " Submit Review"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

      {/* after rating */}
      {[2, 5, -1].includes(singleOrder?.status) &&
        [-1, 0, 1, 7].includes(singleOrder?.rating) && (
          <div className="rating_wrapper" id="rating">
            <div className="after_rated d-flex justify-content-between align-items-center">
              <p className="mb-0 font14x400 textColor3">
                Thank you for rating!
              </p>
              <div className="ratings">
                <p className="mb-0 textColor1 font14x400 d-flex align-items-center gap-1">
                  {getRating(singleOrder?.rating)}{" "}
                  <StarIcon width="12" height="11" fill="#AF031A" />
                </p>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default OrderRating;
