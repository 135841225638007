import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import Header from "../../../components/shared/Header";
import { SearchIcon } from "../../../components/shared/Icons";
import { Auth } from "../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../utils/function/function";
import { CategoriesPage } from "../../../utils/metaInfo/metaInfo";
import AppBanner from "../../../components/pages/Home/App Banner";

const Categories = () => {
  const navigate = useNavigate();
  const { homepage_sections, mainLoading } = useContext(Auth);
  const allCategories = filterHomepageSections(
    homepage_sections,
    "parent"
  )?.sort((a: any, b: any) => {
    return a.category_order - b.category_order;
  });

  // ad tracking
  useEffect(() => {
    window.scrollTo(0, 0);
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="padding_bottom">
        <Helmet>
          <title>{CategoriesPage.title}</title>
          <meta name="description" content={CategoriesPage.description} />
          <meta name="keywords" content={CategoriesPage.keywords} />
        </Helmet>

        <AppBanner />
        <Header label="Categories" navigate={() => navigate("/")} />

        {/* search */}
        <div
          className="search_wrapper mt-3"
          onClick={() => navigate("/search")}
        >
          <div className="search_input_section">
            <input type="text" placeholder="Search for salon services etc" />
            <SearchIcon />
          </div>
        </div>

        {/* services */}
        <div className="services_wrapper pt-3 m-0">
          <h2 className="mb-0 font16x700 p-0">Shop by Category</h2>
          <div className="services_section mt-3">
            {mainLoading ? (
              <>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
                <div className="single_service">
                  <Skeleton
                    className="w-100"
                    height="106px"
                    borderRadius="8px"
                  />
                </div>
              </>
            ) : (
              allCategories?.map((item: any, i: number) => {
                return (
                  <Link
                    key={i}
                    to={`/services/${item?.id}`}
                    className="text-decoration-none"
                  >
                    <div className="single_service text-center">
                      <img
                        src={item?.category_image}
                        alt="category"
                        style={{
                          borderRadius: 8,
                          width: "100%",
                          height: "auto",
                        }}
                      />
                      <p className="mb-0 mt-2 px-2 font12x500 textColor3">
                        {item?.name}
                      </p>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
