const HeroSectionBanner = () => {
  return (
    <>
      <div className="hero_section_banner_wrapper">
        <img src="/static/img/hero_section_banner.png" alt="banner" />
      </div>
    </>
  );
};

export default HeroSectionBanner;
