import { useContext, useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import AllProducts from "../../../components/pages/Products";
import BottomTopAnimation from "../../../components/shared/BottomTopAnimation";
import Categories from "../../../components/shared/Category/categories";
import Menu from "../../../components/shared/Category/menu";
import { gtm, ReactPixel } from "../../../components/shared/GTM";
import { BackIcon, CloseIcon } from "../../../components/shared/Icons";
import {
  membershipId,
  PRODUCT_TYPE,
  TEMP_PRODUCT_TYPE,
} from "../../../utils/constant/constant";
import { Auth } from "../../../utils/context/AuthContext";
import {
  checkingTrackingTimeDifference,
  checkItemsInCart,
  checkUserExpiry,
  filterHomepageSections,
  filterSubCategories,
  getDeviceSource,
  getImageURL,
  getServiceById,
  increaseDecreaseItem,
  isUserValid,
  returnGrandTotal,
  setOverflowToBodyHidden,
} from "../../../utils/function/function";
import { commonType } from "../type";
import "./index.css";
import moment from "moment";
import { Helmet } from "react-helmet";
import { ProductPage } from "../../../utils/metaInfo/metaInfo";
import ProductLoader from "../../../components/pages/Products/loader";
import useLoading from "../../../hooks/useLoading";
import SpinLoader from "../../../components/shared/Loader/SpinLoader";
import AppBanner from "../../../components/pages/Home/App Banner";
import { Carousel } from "react-responsive-carousel";

const Products = () => {
  const navigate = useNavigate();
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const {
    user,
    homepage_sections,
    homepage_categories,
    itemsPageLoading,
    setCartItemsInContext,
    cartItemsInContext,
    trackUserActivity,
    newURLAfterReplace,
    setNewURLAfterReplace,
    updateUserData,
    setUser,
  } = useContext(Auth);
  const allCategories = filterHomepageSections(
    homepage_sections,
    "products"
  )?.sort((a: any, b: any) => {
    return a.category_order - b.category_order;
  });
  const { homepageCategory, subCategory, serviceId } = useParams();
  const [products, setProducts] = useState<any>([]);
  const [categoryName, setCategoryName] = useState<string>("");
  const user_activity = JSON.parse(localStorage.getItem("user_activity")!);
  const [loading, showLoader, hideLoader] = useLoading();

  const [specialOffers, setSpecialOffers] = useState<any>([]);

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    getItemsFromHomepageCategory: () => {
      let itemsId = "";

      // getting items required
      allCategories?.map((item: any) => {
        if (item?.id?.toString() === homepageCategory) {
          itemsId = item?.items_required;
          setCategoryName(item?.name);
        }
      });

      // calling sub categories function
      setProducts(
        filterSubCategories(homepage_categories, itemsId?.split(","))
      );
    },
    scrollToSubCategory: (id?: string) => {
      const all_single_sub_category = document.getElementsByClassName(
        "single_sub_category"
      );

      let loop = 0;
      for (loop = 0; loop <= all_single_sub_category?.length - 1; loop++) {
        if (all_single_sub_category[loop].id.split("_")[1] === id) {
          all_single_sub_category[loop].scrollIntoView(true);
          break;
        }
      }
    },
    scrollToService: (serviceId?: string, subCategoryId?: string) => {
      const all_single_sub_category = document.getElementsByClassName(
        "single_sub_category"
      );

      let loop = 0;
      for (loop = 0; loop <= all_single_sub_category?.length - 1; loop++) {
        if (all_single_sub_category[loop].id.split("_")[1] === subCategoryId) {
          const all_single_service_list =
            all_single_sub_category[loop].getElementsByClassName(
              "services_list"
            );

          for (let j = 0; j <= all_single_service_list?.length - 1; j++) {
            if (all_single_service_list[j].id.split("_")[1] === serviceId) {
              all_single_service_list[j].scrollIntoView({ block: "center" });
              break;
            }
          }
        }
      }
    },
    handleClickOnAdd: (item: any) => {
      const paymentDetailsList =
        JSON.parse(`${localStorage.getItem("paymentDetails")}`) || [];
      const serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      if (item.id == "620") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "546") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "547") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "548") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "538") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "521") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "605") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else if (item.id == "497") {
        paymentDetailsList.push(item);
        localStorage.setItem(
          "paymentDetailsList",
          JSON.stringify(paymentDetailsList)
        );
      } else {
        item.count = 1;
        item.added = true;
        item.isProduct = true;
        serviceList.push(item);

        // setPaymentDetails(fn.calculatePaymentDetails());

        if (isUserValid()) {
          // original cart updation
          setCartItemsInContext((prev: any) => {
            return [...prev, item];
          });
          localStorage.setItem("cartItems", JSON.stringify(serviceList));

          // updating current location in history
          setNewURLAfterReplace(
            `/products/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
          );
          navigate(
            `/products/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
            { replace: true }
          );
        } else {
          // temporary cart updation
          fn.navigateToLogin(item, serviceList);
        }
      }
    },
    handleClickOnMinusPlus: (item: any, button: string) => {
      // handling increase and descrease of items
      setCartItemsInContext(
        increaseDecreaseItem(item, button, cartItemsInContext)
      );
      setPaymentDetails(fn.calculatePaymentDetails());

      // updating current location in history
      setNewURLAfterReplace(
        `/products/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`
      );
      navigate(
        `/products/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        { replace: true }
      );
    },
    handleClickOnProceed: async () => {
      let isMembershipExistInCart = cartItemsInContext?.some((item: any) => {
        return item?.serviceType?.id === membershipId;
      });

      // updating current location in history
      navigate(newURLAfterReplace, { replace: true });

      if (user?.isMember) {
        if (
          checkUserExpiry(
            user?.membershipActivationDate,
            user?.membershipDuration
          )
        ) {
          const latestUserData = await updateUserData();
          if (latestUserData?.isMember) {
            // check membership expired or not
            if (
              checkUserExpiry(
                latestUserData?.membershipActivationDate,
                latestUserData?.membershipDuration
              )
            ) {
              window.localStorage.setItem(
                "user",
                JSON.stringify({
                  ...user,
                  isMember: false,
                })
              );
              setUser({
                ...user,
                isMember: false,
              });
              if (isMembershipExistInCart) {
                hideLoader();
                navigate("/cart", { state: { previousURL: "/products" } });
              } else {
                hideLoader();
                navigate("/membership");
              }
            } else {
              hideLoader();
              navigate("/cart", { state: { previousURL: "/products" } });
            }
          } else {
            if (isMembershipExistInCart) {
              hideLoader();
              navigate("/cart", { state: { previousURL: "/products" } });
            } else {
              hideLoader();
              navigate("/membership");
            }
          }
        } else {
          hideLoader();
          navigate("/cart", { state: { previousURL: "/products" } });
        }
      } else {
        const latestUserData = await updateUserData();
        if (latestUserData?.isMember) {
          // check membership expired or not
          if (
            checkUserExpiry(
              latestUserData?.membershipActivationDate,
              latestUserData?.membershipDuration
            )
          ) {
            window.localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                isMember: false,
              })
            );
            setUser({
              ...user,
              isMember: false,
            });
            if (isMembershipExistInCart) {
              hideLoader();
              navigate("/cart", { state: { previousURL: "/products" } });
            } else {
              hideLoader();
              navigate("/membership");
            }
          } else {
            hideLoader();
            navigate("/cart", { state: { previousURL: "/products" } });
          }
        } else {
          if (isMembershipExistInCart) {
            hideLoader();
            navigate("/cart", { state: { previousURL: "/products" } });
          } else {
            hideLoader();
            navigate("/membership");
          }
        }
      }
    },
    calculatePaymentDetails: () => {
      const cartItems = JSON.parse(`${localStorage.getItem("cartItems")}`);
      let paymentDetails = {
        subTotal: 0,
        discount: 0,
        premiumCharge: 0,
        surgeFee: 0,
        membershipDiscount: 0,
        upgradedServiceCharge: 0,
        total: 0,
      };
      cartItems.forEach((item: any) => {
        if (item.added) {
          // subtotal
          paymentDetails.subTotal += item?.cost * item.count;
          paymentDetails.total += item?.serviceType?.cost * item.count;
        }
      });
      return paymentDetails;
    },
    navigateToLogin: (item: any, serviceTobeAdded?: any) => {
      // updating current location in history
      navigate(
        `/products/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
        { replace: true }
      );

      // navigate to login
      navigate("/otp-login", {
        state: {
          previousURL: `/products/${homepageCategory}/${item?.serviceType?.category?.id}/${item?.serviceType?.id}`,
          serviceTobeAdded,
        },
      });
    },
    
  };

  useEffect(() => {
    if (cartItemsInContext.length > 0) {
      setPaymentDetails(fn.calculatePaymentDetails());
    }
  }, [cartItemsInContext]);

  useEffect(() => {
    !itemsPageLoading && fn.getItemsFromHomepageCategory();
  }, [homepageCategory, itemsPageLoading]);

  useEffect(() => {
    !itemsPageLoading &&
      subCategory &&
      products?.length > 0 &&
      fn.scrollToSubCategory(subCategory);
  }, [subCategory, products, itemsPageLoading]);

  useEffect(() => {
    !itemsPageLoading &&
      serviceId &&
      products?.length > 0 &&
      fn.scrollToService(serviceId, subCategory);
  }, [serviceId, products, itemsPageLoading]);

  // user tracking
  useEffect(() => {
    if (isUserValid()) {
      const trackingTimeDiff = checkingTrackingTimeDifference();
      if (trackingTimeDiff) {
        // removing user activity after 6 hours
        window.localStorage.removeItem("user_activity");

        trackUserActivity({
          type: PRODUCT_TYPE,
          tempType: TEMP_PRODUCT_TYPE,
          area_id: localStorage.getItem("area_id")!,
          source: getDeviceSource(),
        });

        window.localStorage.setItem(
          "tracking_cache_timestamp",
          JSON.stringify(moment())
        );
      } else {
        if (user_activity) {
          if (TEMP_PRODUCT_TYPE > user_activity?.tempType) {
            trackUserActivity({
              ...user_activity,
              type: PRODUCT_TYPE,
              tempType: TEMP_PRODUCT_TYPE,
            });
          }
        } else {
          trackUserActivity({
            ...user_activity,
            type: PRODUCT_TYPE,
            tempType: TEMP_PRODUCT_TYPE,
            area_id: localStorage.getItem("area_id")!,
            source: getDeviceSource(),
          });
        }
      }
    }
  }, []);

  // ad tracking
  useEffect(() => {
    gtm.page();
    ReactPixel.pageView();
    ReactPixel.trackCustom("ViewContent", "");
    gtm.track("view_content", "");
  }, []);

  const handleClickOnChildCategory = (childCategoryLink: any) => {
    navigate(childCategoryLink);
  };

  return (
    <>
      <div className="services_page">
        <AppBanner />
        <div
          style={{ width: `${window.innerWidth}px` }}
          className="header_categories_container"
        >
          <Helmet>
            <title>{ProductPage.title}</title>
            <meta name="description" content={ProductPage.description} />
            <meta name="keywords" content={ProductPage.keywords} />
          </Helmet>

          <div
            style={{
              padding: "16px",
              display: "grid",
              gridTemplateColumns: "max-content auto",
              alignItems: "center",
              columnGap: "10px",
              paddingBottom: "4px",
            }}
            className="service_page_header_container"
          >
            <div onClick={() => navigate("/")} className="back_icon">
              <BackIcon width="17" height="14" fill="#0F0F0F" />
            </div>

            {/*Categories */}
            <Categories
              allCategories={allCategories}
              mainLoading={itemsPageLoading}
              linkFor="products"
            />
          </div>
          <div className="child-categories-container">
            {products?.map((item: any) => {
              return (
                <div>
                  <button
                    onClick={() => {
                      handleClickOnChildCategory(
                        `/products/${homepageCategory}/${item?.subCategoryId}`
                      );
                    }}
                    className={
                      subCategory == item?.subCategoryId
                        ? "child-category-container active-child-category"
                        : "child-category-container"
                    }
                  >
                    {item?.subCategoryName}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        {/* render ChooseFour component */}
        {itemsPageLoading ? (
          <div>
            <ProductLoader />
            <ProductLoader />
            <ProductLoader />
          </div>
        ) : (
          <AllProducts
            services={products}
            cartItemsInContext={cartItemsInContext}
            handleClickOnMinusPlus={fn.handleClickOnMinusPlus}
            handleClickOnAdd={fn.handleClickOnAdd}
          />
        )}
      </div>

      {/* sub total section */}
      {checkItemsInCart()?.length > 0 && (
        <div className="fix_section_at_bottom cart_alert_section">
          
          <div className="grand_total_section d-flex justify-content-between align-items-center">
            <div>
              {/* <p className="mb-0 textColor1 font12x500">Sub Total</p> */}
              <div className="d-flex align-items-center gap-2">
                <p className="mb-0 textColor1 font16x500">
                  {paymentDetails?.subTotal
                    ? `₹${paymentDetails?.subTotal}`
                    : ""}
                </p>
                <p className="mb-0 textColor2 font12x500 text-decoration-line-through">
                  {paymentDetails?.total ? `₹${paymentDetails?.total}` : ""}
                </p>
              </div>
              <p className="mb-0 textColor11 font12x500 cut-price">
                Saved ₹{paymentDetails?.total - paymentDetails?.subTotal}
              </p>
            </div>
            <button
              type="button"
              className="font16x500 text-white"
              onClick={() => {
                fn.handleClickOnProceed();
              }}
            >
              {loading ? (
                <SpinLoader height="22" width="22" color="#fff" />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      )}

      {/* menu button */}
      {/* <div className="menu_button_container d-flex align-items-center justify-content-center">
        <button
          onClick={() => fn.openModal(products, "menu")}
          className="d-flex align-items-center text-white border-0"
        >
          <GiHamburgerMenu />
          <p className="mb-0 font14x400">Menu</p>
        </button>
      </div> */}

      {/* menu */}
      {/* {commonData?.modal && commonData?.check === "menu" && (
        <BottomTopAnimation>
          <Menu
            state={commonData}
            setState={setCommonData}
            linkFor="products"
          />
        </BottomTopAnimation>
      )} */}
    </>
  );
};

export default Products;
