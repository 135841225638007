type PropType = {
  label?: string;
  htmlFor?: string;
  name: string;
  value: number | string | any;
  state?: string | number | boolean;
  setState: (val: any) => void;
  isDisabled?: boolean;
  onBlur?: any;
  id: string;
};

const RadioButton: React.FC<PropType> = (props) => {
  const { label, value, state, setState, id, name } = props;

  return (
    <>
      <div className="radioCustom d-flex align-items-center">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={state ? true : false}
          onChange={(e) => {
            setState(e);
          }}
        />
        {label && (
          <label
            className="font12x500 textColor1 form-check-label"
            htmlFor={id}
          >
            {label}
          </label>
        )}
      </div>
    </>
  );
};

export default RadioButton;
