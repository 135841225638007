import React, { useContext, useState } from "react";
import { commonType } from "../../../../pages/main/type";
import Header from "../../../shared/Header";
import CouponList from "./CouponList";
import moment from "moment";
import { applyCoupon } from "../../../../lib/api functions/coupon/coupon";
import { showToast } from "../../../../lib/toast/showToast";
import {
  COUPON_APPLIED_TYPE,
  membershipId,
  TEMP_COUPON_APPLIED_TYPE,
} from "../../../../utils/constant/constant";
import {
  calculatePaymentDetails,
  checkingTrackingTimeDifference,
  getDeviceSource,
  getServiceById,
  isUserValid,
  setOverflowToBodyUnset,
} from "../../../../utils/function/function";
import { Auth } from "../../../../utils/context/AuthContext";
import SpinLoader from "../../../shared/Loader/SpinLoader";
import useLoading from "../../../../hooks/useLoading";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  setPaymentDetails: (val: any) => void;
  setAppliedCoupon: (val: any) => void;
};

const Coupon: React.FC<propType> = (props) => {
  const { state, setState, setPaymentDetails, setAppliedCoupon } =
    props;
  const { user, trackUserActivity, setCartItemsInContext, cartItemsInContext } =
    useContext(Auth);
  const [inputCouponCode, setInputCouponCode] = useState<string>("");
  const [appliedCoupon, setIsAppliedCoupon] = useState<any>(
    JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null
  );
  const user_activity = JSON.parse(localStorage.getItem("user_activity")!);
  const [isApplyInputLoading, showApplyInputLoader, hideApplyInputLoader] =
    useLoading();
  const [loading, showLoader, hideLoader] = useLoading();
  const fn = {
    setPositionToBody: () => {
      setState({
        modal: false,
        check: "",
      });
      setOverflowToBodyUnset();
    },
    handleOnAdd: (item: any) => {
      const serviceList =
        JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
      item.count = 1;
      item.added = true;
      item.isService = true;
      serviceList.push(item);
      setCartItemsInContext(serviceList);
      localStorage.setItem("cartItems", JSON.stringify(serviceList));
      setPaymentDetails(calculatePaymentDetails());
    },
    handleClickOnApplyCoupon: async (
      couponCode: string,
      isApplyByInput: boolean
    ) => {
      if (appliedCoupon?.couponCode == couponCode) {
        showToast("You have already applied this coupon", "error");
      } else if (couponCode.length > 0) {
        const cartItems =
          JSON.parse(`${localStorage.getItem("cartItems")}`) || [];
        let subTotal = 0;
        let ids: any = [];
        cartItems.forEach((item: any) => {
          if (item.added) {
            if (item.update) {
              ids.push(item.serviceType.id);
            } else if (item.serviceType.id != membershipId) {
              ids.push(item.serviceType.id);
            }
            if (item.serviceType.id != membershipId) {
              subTotal += item.cost * item.count;
            }
          }
        });
        const city = JSON.parse(`${localStorage.getItem("city")}`);
        const body = {
          code: couponCode,
          total: subTotal,
          ids: ids.join(","),
          city: city,
          date: moment().format("YYYY-MM-DD"),
          source: getDeviceSource(),
        };
        if (isApplyByInput) {
          showApplyInputLoader();
        } else {
          showLoader();
        }
        await applyCoupon(body)
          .then((res: any) => {
            if (res) {
              hideLoader();
              hideApplyInputLoader();
              if (res?.data?.success) {
                if (res?.data.type == "Service") {
                  const freeService = getServiceById(+res?.data?.discount);
                  if (Object.keys(freeService).length == 0) {
                    showToast("Free service is not available", "error");
                  } else {
                    // removing already present free service
                    const appliedCoupon =
                      JSON.parse(`${localStorage.getItem("coupon_applied")}`) ||
                      null;
                    let serviceList = cartItemsInContext;
                    serviceList = serviceList.filter((serviceItem: any) => {
                      if (
                        serviceItem?.serviceType?.id ===
                          +appliedCoupon?.discount &&
                        appliedCoupon?.type == "Service"
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    });
                    setCartItemsInContext(serviceList);
                    localStorage.setItem(
                      "cartItems",
                      JSON.stringify(serviceList)
                    );

                    // add coupon
                    fn?.handleOnAdd(freeService);

                    // check membership add in cart or not or user is memberbed or not
                    if (
                      user?.isMember ||
                      cartItemsInContext?.some((item: any) => {
                        return item?.serviceType?.id === membershipId;
                      })
                    ) {
                      localStorage.setItem(
                        "coupon_applied",
                        JSON.stringify({
                          ...res?.data,
                          discountAmount: Math.ceil(res.data?.discount / 2),
                          couponCode: couponCode,
                        })
                      );
                      setAppliedCoupon({
                        ...res?.data,
                        discountAmount: Math.ceil(res.data?.discount / 2),
                        couponCode: couponCode,
                      });
                    } else {
                      localStorage.setItem(
                        "coupon_applied",
                        JSON.stringify({
                          ...res?.data,
                          discountAmount: res.data?.discount,
                          couponCode: couponCode,
                        })
                      );
                      setAppliedCoupon({
                        ...res?.data,
                        discountAmount: res.data?.discount,
                        couponCode: couponCode,
                      });
                    }

                    setState({
                      modal: true,
                      check: "coupon_applied",
                    });
                    setPaymentDetails(calculatePaymentDetails());
                  }
                } else {
                  let serviceList = cartItemsInContext;
                  const appliedCoupon =
                    JSON.parse(`${localStorage.getItem("coupon_applied")}`) ||
                    null;
                  serviceList = serviceList.filter((serviceItem: any) => {
                    if (
                      serviceItem?.serviceType?.id ===
                        +appliedCoupon?.discount &&
                      appliedCoupon?.type == "Service"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  });

                  // check membership add in cart or not or user is memberbed or not
                  if (
                    user?.isMember ||
                    cartItemsInContext?.some((item: any) => {
                      return item?.serviceType?.id === membershipId;
                    })
                  ) {
                    localStorage.setItem(
                      "coupon_applied",
                      JSON.stringify({
                        ...res?.data,
                        discountAmount: Math.ceil(res.data?.discount / 2),
                        couponCode: couponCode,
                      })
                    );
                    setAppliedCoupon({
                      ...res?.data,
                      discountAmount: Math.ceil(res.data?.discount / 2),
                      couponCode: couponCode,
                    });
                  } else {
                    localStorage.setItem(
                      "coupon_applied",
                      JSON.stringify({
                        ...res?.data,
                        discountAmount: res.data?.discount,
                        couponCode: couponCode,
                      })
                    );
                    setAppliedCoupon({
                      ...res?.data,
                      discountAmount: res.data?.discount,
                      couponCode: couponCode,
                    });
                  }

                  setCartItemsInContext(serviceList);
                  localStorage.setItem(
                    "cartItems",
                    JSON.stringify(serviceList)
                  );
                  setState({
                    modal: true,
                    check: "coupon_applied",
                  });
                  setPaymentDetails(calculatePaymentDetails());
                }
                // user tracking
                fn.userTracking({ ...res?.data, couponCode: couponCode });
              } else {
                showToast(res?.data?.msg, "error");
              }
            }
          })
          .catch((err: any) => {});
      } else {
        showToast("Please provide the coupon code", "error");
      }
    },
    handleClickOnRemove: () => {
      let serviceList = cartItemsInContext;
      const appliedCoupon =
        JSON.parse(`${localStorage.getItem("coupon_applied")}`) || null;
      serviceList = serviceList.filter((serviceItem: any) => {
        if (
          serviceItem?.serviceType?.id === +appliedCoupon?.discount &&
          appliedCoupon?.type == "Service"
        ) {
          return false;
        } else {
          return true;
        }
      });
      localStorage.setItem("cartItems", JSON.stringify(serviceList));
      setCartItemsInContext(serviceList);
      localStorage.removeItem("coupon_applied");
      setAppliedCoupon(null);
      setIsAppliedCoupon(null);
      setPaymentDetails(calculatePaymentDetails());
    },
    userTracking: (appliedCoupon: any) => {
      if (isUserValid()) {
        const trackingTimeDiff = checkingTrackingTimeDifference();
        if (trackingTimeDiff) {
          // removing user activity after 6 hours
          window.localStorage.removeItem("user_activity");
        } else {
          if (user_activity) {
            if (TEMP_COUPON_APPLIED_TYPE > user_activity?.tempType) {
              trackUserActivity({
                ...user_activity,
                type: COUPON_APPLIED_TYPE,
                tempType: TEMP_COUPON_APPLIED_TYPE,
                coupon: appliedCoupon?.couponCode,
              });
            }
          }
        }
      }
    },
  };

  return (
    <>
      <div
        className="full_page_modal"
        style={{
          backgroundColor: "var(--bg-color2)",
        }}
      >
        <Header label="Coupons" navigate={() => fn.setPositionToBody()} />
        {/* coupon search */}
        <div className="coupon_search_wrapper">
          <div className="coupon_search_input_section">
            <input
              onChange={(e: any) => {
                setInputCouponCode(e.target.value.toUpperCase());
              }}
              type="text"
              placeholder="Enter Coupon Code"
            />
            <button
              onClick={() => {
                fn.handleClickOnApplyCoupon(inputCouponCode, true);
              }}
              className="mb-0 font14x600 textColor1 border-0 bg-white"
              disabled={
                inputCouponCode.length == 0 || isApplyInputLoading
                  ? true
                  : false
              }
            >
              {isApplyInputLoading ? (
                <SpinLoader height="16" width="16" color="#AF031A" />
              ) : (
                "APPLY"
              )}
            </button>
          </div>
        </div>
        {/* coupon list */}
        <div className="coupon_list_wrapper mt-4">
          <div className="d-flex justify-content-between">
            <h2 className="mb-0 font16x700 textColor3">Available coupons</h2>
            {appliedCoupon?.couponCode ? (
              <p
                onClick={() => {
                  fn.handleClickOnRemove();
                }}
                className="mb-0 font14x600 textColor3 cursor_pointer"
              >
                Remove
              </p>
            ) : (
              ""
            )}
          </div>
          <CouponList
            loading={loading}
            handleClickOnApplyCoupon={fn.handleClickOnApplyCoupon}
          />
        </div>
      </div>
    </>
  );
};

export default Coupon;
