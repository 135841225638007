import React from "react";
import { HomeIcon } from "../../../../../shared/Icons";
import Separator from "../../../../../shared/Separator";

type PropType = {
  singleOrder: any;
};

const OrderAddress: React.FC<PropType> = (props) => {
  const { singleOrder } = props;

  return (
    <>
      <div className="order_address_wrapper">
        <h2 className="font14x700 textColor3">Address</h2>
        <Separator />
        <div className="pt-3">
          <div className="d-flex gap-2">
            <div>
              <HomeIcon />
            </div>
            <div>
              <p className="font12x500 textColor3 mb-1">
                {singleOrder?.address}
              </p>
              <div className="mb-0 d-flex gap-1">
                <p className="mb-0 font12x500 textColor3">
                  {singleOrder?.address1}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderAddress;
