export const membershipFAQs = [
  {
    id: 1,
    question: "What are the benefits of Gold Member?",
    answer:
      "Exclusively for Gold members: Unlock savings of up to 15% on booking, capped at a maximum discount of ₹200 per request. The charges for repairs will be applied according to the actual costs incurred.",
  },
  {
    id: 2,
    question: "How does 100% money back guarantee work?",
    answer:
      "If, at the conclusion of the membership period, a subscriber's savings fall short of the membership price, an automatic refund will be provided in GetLook cash, equal to the membership price minus the total discount availed. This GetLook cash can be utilized when booking any service across all categories.",
  },
  {
    id: 3,
    question: "Is there a limit to maximum discount per transaction?",
    answer: "The maximum discount per transaction is capped at ₹200.",
  },
  {
    id: 4,
    question:
      "Is there a limit to number of transactions under the membership?",
    answer:
      "There is no constraint on the quantity of transactions within the membership. Nonetheless, following our fair usage policy, the total discount you can receive is restricted to six times the membership price.",
  },
  {
    id: 5,
    question: "Is there a limit to the total maximum discount I can avail?",
    answer:
      "Following our fair usage policy, your access to discounts is capped at six times the amount you paid for the membership.",
  },
  {
    id: 6,
    question: "Who is a GetLook Gold professional?",
    answer:
      "GetLook Gold professionals consist of a meticulously chosen team of highly-rated, specially trained experts within their domains.",
  },
  {
    id: 7,
    question: "Can I pay for membership through cash?",
    answer:
      "The acquisition of membership is exclusive to online payment. Yet, for all successive bookings, you have the choice to make payments either in cash or online.",
  },
  {
    id: 8,
    question: "Can I cancel the membership after buying it or transfer it?",
    answer: "No, the membership cannot be cancelled or transferred",
  },
  {
    id: 9,
    question:
      "Can I get Gold discount on extra services taken after the professional reaches my place?",
    answer:
      "The membership discount is specifically applicable to services booked through the GetLook app/website and does not extend to any additional services acquired directly from the professional.",
  },
];

export const generalFAQs = [
  {
    id: 1,
    question: "Why choose at-home services?",
    answer:
      "In the contemporary hustle and bustle, being efficient and quick is crucial. Balancing work, family, friends, and personal aspirations is a constant juggle. Taking a moment for self-pampering becomes a necessity to revive and rejuvenate. GetLook brings skilled salon and home service experts to your door, allowing you to indulge in some personal time. We seamlessly adapt to your busy schedules, leaving you not just looking good but also feeling relaxed!",
  },
  {
    id: 2,
    question: "How can I book GetLook Salon Services?",
    answer:
      "Book GetLook services via our app (Available on Android and iOS), website (www.getlook.in), or call our Customer Happiness Team at 9513862350.",
  },
  {
    id: 3,
    question: "What are your operating hours?",
    answer:
      "Our customer support team is available daily from 9 AM to 7 PM, ready to promptly address your inquiries. Feel free to contact us for efficient solutions. Safety is our top priority for both customers and partners, leading us to limit service booking time slots from 8 AM to 6:30 PM. If you're looking for early morning services, we suggest booking 1-2 days in advance to secure your preferred time and ensure a convenient experience.",
  },
  {
    id: 4,
    question: "GetLook provides services in which cities?",
    answer:
      "We operate in Bangalore, Hyderabad, Jodhpur, Gorakhpur, Siliguri, Kolkata, Chennai, Aurangabad, Noida and Mumbai.",
  },
  {
    id: 5,
    question: "Do I need to provide anything for the service?",
    answer:
      "We come fully prepared with all the necessary tools for your booked service. Just relax and enjoy top-notch at-home services. Please provide access to a bed or comfortable seating for beauty service appointments. Our experts may request some water, and that's about it.We bring disposable sheets to maintain cleanliness, disposable towels for hygiene, and a disposable gown to protect your clothing.",
  },
  {
    id: 6,
    question:
      "What is the difference between a Premium Beautician and a Standard Beautician?",
    answer:
      "GetLook's beauty professionals are highly trained and qualified. We offer services in two categories: Standard and Premium. All our experts have at least two years of experience, while Premium professionals boast a minimum of 5 years. Each beauty expert holds a 4-5 star rating, with our Premium experts earning a stellar 7-star rating and a flawless feedback record. Their experience ensures your relaxation and rejuvenation, prioritizing your comfort and satisfaction.",
  },
  {
    id: 7,
    question: "Are your products branded and of good quality?",
    answer:
      "At GetLook, we're committed to providing exceptional service using premium-quality products. We partner with trusted brands like O3+, Rica, Sara, Lotus, and more. Our products undergo rigorous quality checks before use. We exclusively use 100% branded and high-quality products for cleaning services, ensuring efficient cleaning and thorough disinfection for a safe, hygienic environment. Your satisfaction and safety are our top priorities, reflecting our unwavering commitment to excellence.",
  },
  {
    id: 8,
    question: "What if the expert reaches late?",
    answer:
      "We're dedicated to delivering top-notch service, and our experts take the time needed for excellence. Although we strive to be punctual, unforeseen delays may occur due to prior appointments. We apologize for any inconvenience and appreciate your understanding. If you need to reschedule due to time constraints, we're here to help. Your satisfaction is our priority, and we're committed to making your experience convenient and enjoyable.",
  },
  {
    id: 9,
    question: "Will there be any mess created during the service?",
    answer:
      "We are renowned for providing hygienic and impeccably tidy services. Our professionals undergo rigorous training, instilling them with the necessary work ethics for their roles. Through training and skill development sessions, we emphasize creating a customer experience that feels truly special. Our professionals are well-versed in proper disposal using garbage bags, upholding cleanliness standards, and ensuring a spotless environment. Rest assured, we guarantee a clean and mess-free service at your location.",
  },
  {
    id: 10,
    question: "How can I pay for the services?",
    answer:
      "You can pay for the selected services in advance using various online modes like Paytm, Google Pay, or debit/credit cards. Alternatively, post-service, you have the flexibility to settle the payment with cash, UPI, or PayTm.",
  },
  {
    id: 11,
    question:
      "What if I want to add on service during the time of service being provided or after making the booking?",
    answer:
      "At GetLook, your satisfaction, time efficiency, and love for our services are our top priorities. Contact your service provider directly if you'd like to add extra services. They'll assess feasibility and provide details. For post-booking additions, contact customer support, but please note that availability depends on the provider's schedule. Early communication is crucial for smooth arrangements, and adding services during appointments depends on the expert's availability.",
  },
  {
    id: 12,
    question: "Can I cancel or reschedule the service anytime?",
    answer:
      "You can cancel or reschedule your booking up to 4 hours before your appointment. Contact our Customer Happiness Team at +91-9513862350 to make changes. We'll do our best to accommodate your preferred date and time, but it will depend on the availability of the slot and the expert.",
  },
  {
    id: 13,
    question:
      "What to do if I am not satisfied with the services of your expert?",
    answer:
      "We apologize for any inconvenience caused by our service. If unsatisfied, please contact our Customer Happiness Team within five days at +91 9513862350 or email us at hi@getlook.in. Your satisfaction matters greatly to us, and we're committed to promptly addressing and resolving your concerns for an improved service experience.",
  },
  {
    id: 14,
    question: "What is coupon capping?",
    answer:
      "The coupon come with capping i.e. setting a maximum discount limit that can be applied when using a coupon, regardless of the total order value. Coupon may also come with certain Terms & Policies.",
  },
];
