import Api from "../../../config/api";
import { GET_APPOINTMENTS, REVIEW } from "../../../utils/constant/constant";
import { showToast } from "../../toast/showToast";

export const getAppointments = async (params: any) => {
  try {
    const res = await Api(false).get(GET_APPOINTMENTS, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const cancelOrder = async (body: any) => {
  try {
    const res = await Api(false).post(GET_APPOINTMENTS, body);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const rescheduleOrder = async (body: any) => {
  try {
    const res = await Api(false).post(GET_APPOINTMENTS, body);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const review = async (body: any) => {
  try {
    const res = await Api(false).post(REVIEW, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};
