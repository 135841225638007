import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkServiceInLocation, getAddressFromLatLng } from "../../../../../../lib/api functions/address/address";
import { Auth } from "../../../../../../utils/context/AuthContext";
import { MapPointer } from "../../../../../shared/Icons";

const HeroSectionLocation = () => {
  const { latitude, longitude, mainLoading } = useContext(Auth);
  const [gotCurrentAddress, setGotCurrentAddress] = useState<any>();

  const fn = {
    getAddress: () => {
      const finalLatLng = {
        lat: latitude,
        long: longitude,
      };
      getAddressFromLatLng(finalLatLng)
        .then((res: any) => {
          if (res) {
            res.data.results.forEach((addresses: any) => {
              addresses.address_components.forEach((address: any) => {
                address.types.forEach((item: any) => {
                  if (item === "locality") {
                    setGotCurrentAddress(() => {
                      return { address: res, city: address.long_name };
                    });
                  }
                });
              });
            });
          }
        })
        .catch((err: any) => {});
    },
    getAddressFromLocationSlot: async () => {
      const lat = latitude;
      const long = longitude;
      const res = await checkServiceInLocation({
        lat,
        long,
        // phone: user?.phone ?? "",
      });
      if (res?.data?.msg) {
        setGotCurrentAddress(() => {
          return { address: "", city: "Location" };
        });
      } else {
        setGotCurrentAddress(() => {
          return { address: "", city: res?.data.city };
        });
      }
    },
  };

  useEffect(() => {
    if (latitude && longitude) {
      if (localStorage.getItem("useGeocode")) {
        fn.getAddress();
      } else {
        fn.getAddressFromLocationSlot();
      }
    }
  }, [latitude, longitude]);

  return (
    <>
      <div className="hero_section_location_wrapper">
        <Link to="/" className="text-decoration-none d-inline-block">
          <div className="hero_section_location_section d-flex justify-content-between">
            <div className="location_details d-flex align-items-center gap-3">
              <div>
                <MapPointer />
              </div>
              <div>
                <p className="mb-1 font20x600 textColor3">
                  {mainLoading ? "Bengaluru" : gotCurrentAddress?.city}
                </p>
                <p className="mb-0 font16x400 textColor3">
                  {mainLoading
                    ? "67/1, KG Halli, D' Souza Layou"
                    : gotCurrentAddress?.address?.data?.results[0]?.formatted_address.substring(
                        0,
                        30
                      )}
                </p>
              </div>
            </div>
            <div className="explore_service_btn">
              <button className="font16x600">Explore Services</button>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default HeroSectionLocation;
