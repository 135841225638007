import { useContext, useEffect } from "react";
import { BiCheck } from "react-icons/bi";
import DesktopBrands from "../../../components/desktop/pages/Home/Brands";
import DownloadApp from "../../../components/desktop/pages/Home/DownloadApp";
import Footer from "../../../components/desktop/pages/Home/Footer";
import Header from "../../../components/desktop/pages/Home/Header";
import HeroSection from "../../../components/desktop/pages/Home/HeroSection";
import ProductsAndDOTD from "../../../components/desktop/pages/Home/ProductsAndDOTD";
import DesktopServices from "../../../components/desktop/pages/Home/Services";
import { WhyPoints } from "../../../components/pages/Home/WhyGetlook/constant";
import { Auth } from "../../../utils/context/AuthContext";

const DesktopHome = () => {
  const { servingOnLocation } = useContext(Auth);

  // navigate between desktop and mobile homepages
  useEffect(() => {
    window.sessionStorage.setItem("isDesktop", "true");
  }, []);

  return (
    <>
      <div className="desktop">
        <Header />

        <HeroSection />

        {servingOnLocation?.isServing && <DesktopServices />}

        {/* why getlook */}
        <div
          className="desktop_why_getlook_wrapper"
          style={{
            marginTop: servingOnLocation?.isServing ? 88 : 48,
          }}
        >
          <div className="desktop_why_getlook_section">
            <h2 className="mb-0 font30x600 textColor1">Why GetLook?</h2>
            <div className="desktop_why_getlook_steps_wrapper d-flex flex-column gap-3">
              {WhyPoints?.map((item: any, i: number) => {
                return (
                  <div key={i} className="d-flex align-items-center gap-3">
                    <p className="mb-0 lh-lg desktop_why_getlook_steps textColor1 font20x500">
                      <BiCheck />
                    </p>
                    <p className="mb-0 textColor3 lh-lg font20x500">{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {servingOnLocation?.isServing && <ProductsAndDOTD />}

        {servingOnLocation?.isServing && <DesktopBrands />}

        <DownloadApp />

        <Footer />
      </div>
    </>
  );
};

export default DesktopHome;
