import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../../../utils/function/function";

const DealOfTheDay = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const deal = filterHomepageSections(homepage_sections, "dod");
  const navigate = useNavigate();

  return (
    <>
      {deal?.map((item: any, i: number) => {
        return (
          <div key={i} className="desktop_dotd_wrapper">
            <img
              src={item?.category_image}
              className="img-fluid"
              alt="service_ad"
              onClick={() => navigate(`/services/${item?.items_required}`)}
            />
            <div className="dotd_details_section d-flex flex-column justify-content-between">
              <p className="mb-0 text-white font16x500">{item?.information}</p>
              <h2 className="mb-0 text-white font24x600">{item?.name}</h2>
              <button
                type="button"
                className="textColor3 desktop_dotd_btn font16x600"
                onClick={() => navigate(`/services/${item?.items_required}`)}
              >
                Book Now
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default DealOfTheDay;
