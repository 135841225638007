import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CancelReasons } from "../../../../../../../components/pages/MyProfile/SubPages/BookingsAndOrders/SubPages/CancelOrder/constant";
import { gtm, ReactPixel } from "../../../../../../../components/shared/GTM";
import Header from "../../../../../../../components/shared/Header";
import InputError from "../../../../../../../components/shared/InputError/InputError";
import SpinLoader from "../../../../../../../components/shared/Loader/SpinLoader";
import Separator from "../../../../../../../components/shared/Separator";
import TextareaInput from "../../../../../../../components/shared/TextareaInput";
import useLoading from "../../../../../../../hooks/useLoading";
import { cancelOrder } from "../../../../../../../lib/api functions/order/order";
import { showToast } from "../../../../../../../lib/toast/showToast";
import { ORDER_STATUS } from "../../../../../../../utils/constant/constant";
import { cancelOrderSchema } from "../../../../../../../validation/validation";

type CancelOrder = {
  reason: string;
};

const CancelOrder = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, showLoader, hideLoader] = useLoading();
  const [isOtherReason, setIsOtherReason] = useState<boolean>(false);
  const initialValues: CancelOrder = {
    reason: "",
  };

  const formik = useFormik<CancelOrder>({
    initialValues,
    validationSchema: cancelOrderSchema,
    onSubmit: async (values) => {
      showLoader();
      let finalObj = {
        create_type: "cancel",
        status: -1,
        id: state?.order?.id,
        reason: values.reason,
        fee: state?.fee ? state?.fee : undefined,
      };

      const res = await cancelOrder(finalObj);
      if (res) {
        showToast(`Your order is cancelled`, "success");
        navigate("/order");
        hideLoader();
      }
    },
  });

  // ad tracking
  useEffect(() => {

    window.scrollTo(0, 0);
    
    gtm.page();
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <div
        className="mobile"
        style={{
          backgroundColor: "var(--bg-color2)",
        }}
      >
        <Header label="Cancel Order" navigate={() => navigate(-1)} />

        {/* order details */}
        <div>
          <div className="order_id_price d-flex justify-content-between">
            <p className="mb-0 text-white font16x600">{`Order#${state?.order?.id}`}</p>
            <p className="mb-0 text-white font16x600">{`₹${state?.order?.appointmentitem_set?.reduce(
              (accumulator: number, currentValue: any) =>
                currentValue.cost *
                  (currentValue.count > 0 ? currentValue.count : 0) +
                accumulator,
              0
            )}`}</p>
          </div>
          <div className="order_date_paymentstatus">
            <div className="mb-3 d-flex justify-content-between">
              <div>
                <p className="mb-0 font16x500 textColor3">{`Order#${state?.order.id}`}</p>
                <p className="mb-0 font12x400 textColor7">
                  {`Total Amount - ₹${state?.order?.appointmentitem_set?.reduce(
                    (accumulator: number, currentValue: any) =>
                      currentValue.cost *
                        (currentValue.count > 0 ? currentValue.count : 0) +
                      accumulator,
                    0
                  )}`}
                </p>
              </div>
              <div>
                <span className="status_detail font12x400">
                  {ORDER_STATUS[state?.order?.status?.toString()]}
                </span>
              </div>
            </div>
            <Separator />
            <div className="py-3 d-flex justify-content-between">
              <p className="mb-1 font14x400 textColor7">By Stylist</p>
              <p className="mb-0 font14x500 textColor3">
                {state?.order.stylist.user.first_name}
              </p>
            </div>
            <Separator />
            <div className="pt-3 d-flex justify-content-between">
              <div>
                <p className="mb-1 font12x400 textColor7">Appointment On</p>
                <p className="mb-0 font14x500 textColor3">
                  {`${moment(state?.order.date).format("ddd, D MMM")} ${
                    state?.order.timing
                  }`}
                </p>
              </div>
              <div>
                <p className="mb-1 font12x400 textColor7">Payment mode</p>
                <p className="mb-0 font14x500 text-end textColor3">
                  {`${state?.order.customer_discount < 10 ? "Online" : "COD"}`}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* cancel reason */}
        <div className="cancel_reason_wrapper">
          <div className="title_container">
            <h2 className="mb-3 font16x600 textColor3">
              Select a valid reason
            </h2>
          </div>
          <Separator />
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-3 reasons_container">
              <div className="d-flex flex-column gap-2">
                {CancelReasons?.map((item: string, i: number) => {
                  return (
                    <div
                      key={i}
                      className={`${
                        item === formik.values.reason
                          ? "cancel_reason"
                          : "cancel_reason_selected"
                      } `}
                      onClick={() => {
                        if (item === "Other") {
                          setIsOtherReason(true);
                        } else {
                          formik.setFieldValue("reason", item);
                          setIsOtherReason(false);
                        }
                      }}
                    >
                      <p className="mb-0 font14x400">{item}</p>
                    </div>
                  );
                })}

                <div
                  className={`${
                    isOtherReason ? "cancel_reason" : "cancel_reason_selected"
                  } `}
                  onClick={() => {
                    formik.setFieldValue("reason", "");
                    setIsOtherReason(true);
                  }}
                >
                  <p className="mb-0 font14x400">Other</p>
                </div>

                {isOtherReason && (
                  <div className="mt-2">
                    <TextareaInput
                      id="extra_ins"
                      placeholder="enter your reason here..."
                      state={formik.values.reason}
                      setState={(e: any) => {
                        formik.setFieldValue("reason", e.target.value);
                      }}
                      border="1px solid var(--border-color1)"
                    />
                  </div>
                )}
              </div>

              {formik.errors.reason ? (
                <InputError error={formik.errors.reason} />
              ) : null}
            </div>
            <div className="mt-5">
              <button
                type="submit"
                className="font16x500 btn_full_width d-flex justify-content-center align-items-center gap-2"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <SpinLoader height="22" width="22" color="#fff" />
                ) : (
                  "Cancel Order"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CancelOrder;
