import moment from "moment";
import React from "react";
import {
  conveyanceId,
  couponId,
  handlingFeeId,
  membershipDiscountId,
  membershipId,
  ORDER_DETAILS_STATUS,
  premiumId,
  previousDuesId,
  rescheduleChargeId,
  safetyId,
  surgeId,
  upgradeId,
  walletId,
} from "../../../../../../../utils/constant/constant";
import { getDataFromSIns } from "../../../../../../../utils/function/function";
import { ClockIcon } from "../../../../../../shared/Icons";
import Separator from "../../../../../../shared/Separator";
import StylistInfo from "../StylistInfo";

type PropType = {
  singleOrder: any;
};

const ServiceOrderDetails: React.FC<PropType> = (props) => {
  const { singleOrder } = props;

  return (
    <>
      <div className="order_details_wrapper">
        <div>
          
          <h2 className="mb-0 font14x700 textColor3">Services</h2>
          {singleOrder &&
            singleOrder?.appointmentitem_set?.map((item: any, i: number) => {
              return item?.service?.serviceType?.id != conveyanceId &&
                item?.service?.serviceType?.id != premiumId &&
                item?.service?.serviceType?.id != surgeId &&
                item?.service?.serviceType?.id != walletId &&
                item?.service?.serviceType?.id != membershipDiscountId &&
                item?.service?.serviceType?.id != couponId &&
                item?.service?.serviceType?.id != safetyId &&
                item?.service?.serviceType?.id != upgradeId &&
                item?.service?.serviceType?.id != rescheduleChargeId &&
                item?.service?.serviceType?.id != previousDuesId &&
                item?.service?.serviceType?.id != handlingFeeId ? (
                <div
                  key={i}
                  className="single_order_item d-flex justify-content-between gap-2"
                >
                  <div>
                    <div className="d-flex gap-2">
                      <div>
                        <p className="mb-1 font13x500 textColor3">
                          {item?.name?.split(":")[0]}
                        </p>
                        {item?.service?.serviceType?.id == membershipId ? (
                          ""
                        ) : (
                          <p className="mb-1 font12x400 textColor7 d-flex align-items-center gap-1">
                            <ClockIcon />
                            {`${item?.time} mins`}
                          </p>
                        )}
                        <p className="mb-1 font12x400 textColor7">
                          Quantity: {item?.count}
                        </p>
                      </div>
                    </div>
                    {getDataFromSIns(
                      singleOrder?.specialInstructions,
                      item?.service?.serviceType?.id
                    )?.length > 0 && (
                      <div className="my-2">
                        <ul className="mb-0 ps-3">
                          {getDataFromSIns(
                            singleOrder?.specialInstructions,
                            item?.service?.serviceType?.id
                          )?.map((item: string, i: number) => {
                            return (
                              <li
                                key={i}
                                className="mb-0 line_height_20 font12x400 textColor3"
                              >
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                  {item?.cost !== 0 && (
                    <div className="d-flex gap-2">
                      {item?.service?.serviceType?.cost > item?.cost ? (
                        <p className="mb-0 font14x500 text-decoration-line-through textColor7">
                          {`₹${item?.service?.serviceType?.cost * item?.count}`}
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="mb-0 font14x500 textColor3">{`₹${
                        item?.cost * item?.count
                      }`}</p>
                    </div>
                  )}
                </div>
              ) : (
                ""
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ServiceOrderDetails;
