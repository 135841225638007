import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Auth } from "../../../../utils/context/AuthContext";
import { filterHomepageSections } from "../../../../utils/function/function";

const Brands = () => {
  const { homepage_sections, mainLoading } = useContext(Auth);
  const brands = filterHomepageSections(homepage_sections, "brands");

  return (
    <>
      {brands?.length > 0 && (
        <div className="brand_use_wrapper">
          <div className="brand_use_section">
            <h2 className="mb-0 textColor1 text-center font14x700">
              We use best Brands in 1-Time use packs
            </h2>
            <div className="brand_section mt-3">
              {mainLoading ? (
                <>
                  <div className="single_service">
                    <Skeleton
                      className="w-100"
                      height="97px"
                      borderRadius="8px"
                    />
                  </div>
                  <div className="single_service">
                    <Skeleton
                      className="w-100"
                      height="97px"
                      borderRadius="8px"
                    />
                  </div>
                  <div className="single_service">
                    <Skeleton
                      className="w-100"
                      height="97px"
                      borderRadius="8px"
                    />
                  </div>
                </>
              ) : (
                brands?.map((item: any, i: number) => {
                  return (
                    <div key={i} className="single_brand">
                      <img
                        src={item?.category_image}
                        alt="brand"
                        className="img-fluid"
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Brands;
