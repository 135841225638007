import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import useLoading from "../../hooks/useLoading";
import {
  getHomePageCategories,
  getHomePageSections,
} from "../../lib/api functions/homepage/homepage";
import { checkServiceInLocation } from "../../lib/api functions/address/address";
import { showToast } from "../../lib/toast/showToast";
import { commonType } from "../../pages/main/type";
import SearchLocation from "../../components/pages/Home/Location/SearchLocation";
import MapView from "../../components/pages/Home/Location/ChooseLocation";
import {
  cartUpdateAfterCacheExpire,
  checkingTimeDifference,
  fetchMapKey,
  getDeviceSource,
  isUserValid,
  setOverflowToBodyHidden,
} from "../function/function";
import { bookingTracker } from "../../lib/api functions/tracker/tracker";
import { GET_ADDRESS, HOME_TYPE, TEMP_HOME_TYPE } from "../constant/constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { postUserData } from "../../lib/api functions/user/user";

export const Auth = createContext<any>(null);

type PropType = {
  children: any;
};

const AuthContext: React.FC<PropType> = ({ children }) => {
  const [commonData, setCommonData] = useState<commonType>({
    modal: false,
    check: "",
  });
  const [token, setToken] = useState<string>("");
  const [mainLoading, showLoader, hideLoader] = useLoading();
  const [itemsPageLoading, showServicePageLoader, hideServicePageLoader] =
    useLoading();
  const [addressLoading, showAddressLoader, hideAddressLoader] = useLoading();
  const [queryData, setQueryData] = useSearchParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(
    JSON.parse(localStorage.getItem("user")!)
  );
  let latitude = JSON.parse(localStorage.getItem("latitude")!);
  let longitude = JSON.parse(localStorage.getItem("longitude")!);
  const homepage_sections = JSON.parse(
    localStorage.getItem("homepage_sections")!
  );
  const homepage_categories = JSON.parse(
    localStorage.getItem("homepage_categories")!
  );
  const [cartItemsInContext, setCartItemsInContext] = useState<any>([]);
  const [addresses, setAddresses] = useState<any>([]);
  const [useGeocode, setUseGeocode] = useState<boolean>(
    localStorage.getItem("useGeocode") === "true"
  );
  const [latLng, setLatLng] = useState<any>({
    lat: latitude,
    long: longitude,
  });
  const [servingOnLocation, setServingOnLocation] = useState({
    isServing: true,
    data: undefined,
  });
  const [newURLAfterReplace, setNewURLAfterReplace] = useState<string>("");
  const [mapKey, setMapKey] = useState<string>(
    localStorage.getItem("map_key")!
  );

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      setOverflowToBodyHidden();
    },
    getHomepageDataFn: async (latitude: string, longitude: string) => {
      showLoader();
      showServicePageLoader();
      const formData = new FormData();
      formData.append("lat", latitude);
      formData.append("long", longitude);

      const res = await getHomePageSections(formData);

      if (res) {
        window.localStorage.setItem(
          "homepage_sections",
          JSON.stringify(res.data)
        );
        hideLoader();
      }

      const res2 = await getHomePageCategories(formData);

      if (res2) {
        if (res2.data?.msg) {
          // show msg "we do not serve in your locality"
        } else {
          window.localStorage.setItem(
            "homepage_categories",
            JSON.stringify(res2.data)
          );

          hideServicePageLoader();

          // setting API cache and updating cartitems
          const timeDiff = checkingTimeDifference();

          if (timeDiff) {
            window.localStorage.setItem(
              "api_cache_timestamp",
              JSON.stringify(moment())
            );

            localStorage.setItem(
              "cartItems",
              JSON.stringify(cartUpdateAfterCacheExpire())
            );

            setCartItemsInContext(cartUpdateAfterCacheExpire());
          }
        }
      }
    },
    getUserAddresses: async () => {
      const params = "";
      showAddressLoader();
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_ADDRESS}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          }
        );

        if (res) {
          if (res?.data?.length > 0) {
            const defaultAddress = res.data?.filter((item: any) => {
              return item.isDefault == true && item?.active;
            });
            const noDefaultAddress = res.data?.filter((item: any) => {
              return item.isDefault == false && item?.active;
            });

            if (window.location.pathname == "/select-address") {
              if (defaultAddress?.length > 0) {
                localStorage.setItem(
                  "userAddress",
                  JSON.stringify(defaultAddress[0])
                );
              } else if (noDefaultAddress?.length === 1) {
                localStorage.setItem(
                  "userAddress",
                  JSON.stringify(noDefaultAddress[0])
                );
              }
            }

            setAddresses([...defaultAddress, ...noDefaultAddress]);
          }
          hideAddressLoader();
        }
      } catch (error: any) {
        if (error.response.status >= 400 && error.response.status <= 499) {
          showToast(`${error.response.statusText}`, "error");
        } else {
          showToast(`${error.response.data.message}`, "error");
        }
      }
    },
    getCurrentLocationCoords: () => {
      showLoader();
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            latitude = position.coords.latitude?.toString();
            longitude = position.coords.longitude?.toString();
            window.localStorage.setItem(
              "latitude",
              position.coords.latitude?.toString()
            );
            window.localStorage.setItem(
              "longitude",
              position.coords.longitude?.toString()
            );
            fn.getLocationId(
              position.coords.latitude?.toString(),
              position.coords.longitude?.toString()
            );
          },
          (error: GeolocationPositionError) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                // default lat long for banglore
                window.localStorage.setItem("latitude", "12.9716");
                window.localStorage.setItem("longitude", "77.5946");
                fn.getLocationId("12.9716", "77.5946");
                break;
              case error.POSITION_UNAVAILABLE:
                showToast("Location information is unavailable.", "error");
                break;
              case error.TIMEOUT:
                showToast(
                  "The request to get user location timed out.",
                  "error"
                );
                break;
              default:
                showToast("An unknown error occurred.", "error");
            }
          },
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        // default lat long for banglore
        fn.getLocationId("12.9716", "77.5946");
        window.localStorage.setItem("latitude", "12.9716");
        window.localStorage.setItem("longitude", "77.5946");
      }
    },
    trackUserActivity: async (params: any) => {
      window.localStorage.setItem("user_activity", JSON.stringify(params));
      const finalObj = { ...params };
      delete finalObj?.tempType;

      const res = await bookingTracker(finalObj);
      if (res) {
        window.localStorage.setItem(
          "user_activity",
          JSON.stringify({
            ...params,
            unique_id: res.data.data.id,
          })
        );
      }
    },
    getLocationId: async (lat: string, long: string) => {
      showLoader();
      const res = await checkServiceInLocation({
        lat,
        long,
        phone: user?.phone ?? "",
      });
      if (res) {
        if (res?.data?.msg) {
          // show msg "we do not serve in your locality"
          setServingOnLocation({
            isServing: false,
            data: res.data,
          });
          window.localStorage.setItem(
            "serving_on_location",
            JSON.stringify({
              isServing: false,
              data: res.data,
            })
          );
          hideLoader();
        } else {
          window.localStorage.setItem("map_key", fetchMapKey(res.data));
          setMapKey(fetchMapKey(res.data));
          window.localStorage.setItem("area_id", res.data.locationId);
          window.localStorage.setItem("city", JSON.stringify(res?.data?.city));
          fn.getHomepageDataFn(lat, long);
          window.localStorage.setItem(
            "serving_on_location",
            JSON.stringify({
              isServing: true,
              data: undefined,
            })
          );
          setServingOnLocation({
            isServing: true,
            data: undefined,
          });
          // calling user activity whenever location changed and user logged in
          isUserValid() &&
            fn.trackUserActivity({
              type: HOME_TYPE,
              tempType: TEMP_HOME_TYPE,
              area_id: localStorage.getItem("area_id")!,
              source: getDeviceSource(),
            });
        }
      }
    },
    updateUserData: async () => {
      let finalObj = {
        ...user,
        type: "profileUpdate",
        email: undefined,
      };
      const res = await postUserData(finalObj);
      if (res) {
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            ...finalObj,
            isMember: res?.data?.data?.isMember,
            membershipActivationDate: res?.data?.data?.membershipActivationDate,
            membershipDuration: res?.data?.data?.membershipDuration,
            membershipStartDate: res?.data?.data?.membershipStartDate,
            email: user?.email,
          })
        );
        setUser({
          ...finalObj,
          isMember: res?.data?.data?.isMember,
          membershipActivationDate: res?.data?.data?.membershipActivationDate,
          membershipDuration: res?.data?.data?.membershipDuration,
          membershipStartDate: res?.data?.data?.membershipStartDate,
          email: user?.email,
        });
        return {
          ...finalObj,
          isMember: res?.data?.data?.isMember,
          membershipActivationDate: res?.data?.data?.membershipActivationDate,
          membershipDuration: res?.data?.data?.membershipDuration,
          membershipStartDate: res?.data?.data?.membershipStartDate,
          email: user?.email,
        };
      }
    },
  };

  useEffect(() => {
    // getting user addresses after login
    isUserValid() && fn.getUserAddresses();

    // setting initial timestamp for main APIs calling
    const api_cache_timestamp =
      window.localStorage.getItem("api_cache_timestamp") || null;

    if (!api_cache_timestamp) {
      window.localStorage.setItem(
        "api_cache_timestamp",
        JSON.stringify(moment())
      );
    }

    // serve on location or not
    const servingOnLocation = JSON.parse(
      localStorage.getItem("serving_on_location")!
    );
    if (servingOnLocation) {
      setServingOnLocation({
        isServing: servingOnLocation?.isServing,
        data: servingOnLocation?.data,
      });
    }

    // getting cartItems from local storage
    if (localStorage.getItem("cartItems")) {
      setCartItemsInContext(JSON.parse(`${localStorage.getItem("cartItems")}`));
    }
  }, []);

  useEffect(() => {
    if (queryData.get("lat") && queryData.get("long")) {
      // when user coming from ad
      window.localStorage.setItem("latitude", queryData.get("lat")!);
      window.localStorage.setItem("longitude", queryData.get("long")!);
      fn.getLocationId(queryData.get("lat")!, queryData.get("long")!);

      // setQueryData("");
      // navigate("/", { replace: true });
    } else {
      // when user directly access link
      const lat = window.localStorage.getItem("latitude");
      const lng = window.localStorage.getItem("longitude");
      const homepage_sections =
        window.localStorage.getItem("homepage_sections") || null;
      const homepage_categories =
        window.localStorage.getItem("homepage_categories") || null;
      // homepage_sections
      if (lat && lng) {
        latitude = lat;
        longitude = lng;

        if (!homepage_sections && !homepage_categories) {
          fn.getLocationId(latitude, longitude);
        }
      } else {
        fn.getCurrentLocationCoords();
      }
    }
  }, []);

  // function deleteAllCookies() {
  //   const cookies = document.cookie.split(";");
  //   for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i];
  //     const eqPos = cookie.indexOf("=");
  //     const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
  //     document.cookie =
  //       name +
  //       "=; domain=.getlook.in; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  //     document.cookie =
  //       name +
  //       "=; domain=getlook.in; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  //   }
  // }

  // getting homepage data access. to location
  // useEffect(() => {
  //   if (latitude && longitude) {
  //     fn.getHomepageDataFn(latitude, longitude);
  //   }
  // }, []);

  // removed old app data
  useEffect(() => {
    if (
      localStorage.getItem("isGetLookNewApp") &&
      localStorage.getItem("isGetLookNewApp") === "2"
    ) {
      // no code
    } else {
      localStorage.clear();
      sessionStorage.clear();

      // deleteAllCookies();

      localStorage.setItem("isGetLookNewApp", "2");
      window.location.reload();
    }
  }, []);

  // dynamic load map key script
  useEffect(() => {
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`
    );
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      console.log("File loaded");
    });
    // error event
    script.addEventListener("error", (ev) => {
      console.log("Error on loading file", ev);
    });
  }, [mapKey]);

  return (
    <>
      <Auth.Provider
        value={{
          user,
          setUser,
          token,
          setToken,
          getHomepageDataFn: fn.getHomepageDataFn,
          homepage_sections,
          homepage_categories,
          mainLoading,
          cartItemsInContext,
          setCartItemsInContext,
          addresses,
          getUserAddresses: fn.getUserAddresses,
          latitude,
          longitude,
          trackUserActivity: fn.trackUserActivity,
          getLocationId: fn.getLocationId,
          servingOnLocation,
          newURLAfterReplace,
          setNewURLAfterReplace,
          addressLoading,
          itemsPageLoading,
          setUseGeocode,
          useGeocode,
          updateUserData: fn.updateUserData,
        }}
      >
        {children}

        {/* when location is disable */}
        {commonData?.modal &&
          commonData?.check === "choose_different_location" && (
            <SearchLocation
              state={commonData}
              setState={setCommonData}
              setLatLng={setLatLng}
            />
          )}

        {/* search location */}
        {commonData?.modal && commonData?.check === "choose_location" && (
          <MapView propsLatLng={latLng} setState={setCommonData} />
        )}
      </Auth.Provider>
    </>
  );
};

export default AuthContext;
