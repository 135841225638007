import React from "react";
import DealOfTheDay from "./DealOfTheDay";
import Products from "./Products";

const ProductsAndDOTD = () => {
  return (
    <>
      <div className="product_dotd_wrapper">
        <Products />
        <DealOfTheDay />
      </div>
    </>
  );
};

export default ProductsAndDOTD;
